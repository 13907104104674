import { createSlice } from "@reduxjs/toolkit";
import { SUMMARY_REPORT } from "./slice-names";

const initialState = {
    loading: true,
    quickOverview: null,
    message: "",
    error: "",

    branchesReportLoading: false,
    branchesReport: [],
    branchesReportError: "",

    categoryReportLoading: false,
    categoryReport: [],
    categoryReportError: "",

    productReportLoading: false,
    productReport: [],
    productReportError: "",

    staffReportLoading: false,
    staffReport: [],
    staffReportError: "",

    feedBackReportLoading: false,
    feedBackReport: [],
    feedBackReportError: "",

    feedbackModalLoading: true,
    feedbackModal: [],
    feedbackModalError: "",

    filter: {
        "branch_id": [],
        "experience_type_id": [],
        "rating": [],
        "category_id": [],
        "staff_id": [],
        "date": [null, null]
    },

    saveLoading: false,
    saveMessage: null,
    saveError: null,
};

export const summaryReportSlice = createSlice({
    name: SUMMARY_REPORT,
    initialState,
    reducers: {
        getReportsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        getReportsSuccess: (state, action) => {
            state.loading = false;
            state.quickOverview = action.payload;
        },
        getReportsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getBranchesForReportRequest: (state) => {
            state.branchesReportLoading = true;
            state.branchesReportError = null;
        },
        getBranchesForReportSuccess: (state, action) => {
            state.branchesReportLoading = false;
            state.branchesReport = action.payload;
        },
        getBranchesForReportError: (state, action) => {
            state.branchesReportLoading = false;
            state.branchesReportError = action.payload;
        },
        getCategoryForReportRequest: (state) => {
            state.categoryReportLoading = true;
            state.categoryReportError = null;
        },
        getCategoryForReportSuccess: (state, action) => {
            state.categoryReportLoading = false;
            state.categoryReport = action.payload;
        },
        getCategoryForReportError: (state, action) => {
            state.categoryReportLoading = false;
            state.categoryReportError = action.payload;
        },
        getProductForReportRequest: (state) => {
            state.productReportLoading = true;
            state.productReportError = null;
        },
        getProductForReportSuccess: (state, action) => {
            state.productReportLoading = false;
            state.productReport = action.payload;
        },
        getProductForReportError: (state, action) => {
            state.productReportLoading = false;
            state.productReportError = action.payload;
        },
        getStaffForReportRequest: (state) => {
            state.staffReportLoading = true;
            state.staffReportError = null;
        },
        getStaffForReportSuccess: (state, action) => {
            state.staffReportLoading = false;
            state.staffReport = action.payload;
        },
        getStaffForReportError: (state, action) => {
            state.staffReportLoading = false;
            state.staffReportError = action.payload;
        },
        getFeedBackForReportRequest: (state) => {
            state.feedBackReportLoading = true;
            state.feedBackReportError = null;
        },
        getFeedBackForReportSuccess: (state, action) => {
            state.feedBackReportLoading = false;
            state.feedBackReport = action.payload;
        },
        getFeedBackForReportError: (state, action) => {
            state.feedBackReportLoading = false;
            state.feedBackReportError = action.payload;
        },
        getFeedbackDataForModalRequest: (state) => {
            state.feedbackModalLoading = true;
            state.feedbackModalError = null;
            state.feedbackModal = [];
        },
        getFeedbackDataForModalSuccess: (state, action) => {
            state.feedbackModalLoading = false;
            state.feedbackModal = action.payload;
        },
        getFeedbackDataForModalError: (state, action) => {
            state.feedbackModalLoading = false;
            state.feedbackModalError = action.payload;
        },
    },
});


export const {
    getReportsRequest,
    getReportsSuccess,
    getReportsError,
    getBranchesForReportRequest,
    getBranchesForReportSuccess,
    getBranchesForReportError,
    getCategoryForReportRequest,
    getCategoryForReportSuccess,
    getCategoryForReportError,
    getProductForReportRequest,
    getProductForReportSuccess,
    getProductForReportError,
    getStaffForReportError,
    getStaffForReportRequest,
    getStaffForReportSuccess,
    getFeedBackForReportError,
    getFeedBackForReportRequest,
    getFeedBackForReportSuccess,
    getFeedbackDataForModalRequest,
    getFeedbackDataForModalSuccess,
    getFeedbackDataForModalError,
} = summaryReportSlice.actions;

export default summaryReportSlice.reducer;
