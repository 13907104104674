import React from "react";
import search from "../../../assets/images/search.svg";
import GoogleFeedbackData from "./GoogleFeedbackData";

const AllGoogleFeedback = () => {
  return (
    <>
      <div className="feedbackBody">
        <div className="feedbackLeft">
          <div className="feedbackSearch">
            <div className="inputWrap">
              <button>
                <img src={search} alt="" />
              </button>
              <input
                type="text"
                name="search"
                className="form-control"
                placeholder="Search by name or phone..."
              />
            </div>
          </div>
          <div
            className="feedbackUsers content mCustomScrollbar"
            data-mcs-theme="dark"
          >
            <ul className="feedbackUsersUL">
              <li className="active">
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      Mahmoud Mokhtar <em>2</em>
                    </strong>
                    <span className="Unresolved">Unresolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      بلال سيف <em>1</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      Omar Ahmed Ali<em>3</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      Khaled Mohamed<em>4</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      محمود مختار<em>1</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      Mahmoud Mokhtar <em>2</em>
                    </strong>
                    <span className="Unresolved">Unresolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      بلال سيف <em>1</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      Omar Ahmed Ali<em>3</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      Khaled Mohamed<em>4</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="feedbackUsersBox">
                  <div className="feedbackUsersHead">
                    <strong>
                      محمود مختار<em>1</em>
                    </strong>
                    <span className="Resolved">Resolved</span>
                  </div>
                  <div className="feedbackUsersFTR">
                    <h3>
                      Last Rating:
                      <strong>4</strong>
                      <em>
                        <img src="images/starOne.svg" alt="" />
                      </em>
                    </h3>
                    <span>6 October 2022</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <GoogleFeedbackData />
      </div>
    </>
  );
};

export default AllGoogleFeedback;
