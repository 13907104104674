import { createSlice } from "@reduxjs/toolkit";
import { REPORTS } from "./slice-names";

const initialState = {
    loading: true,
    reports: [],
    message: "",
    error: "",
    languages: null,
    offSet: 0,

    updateLoading: false,
    singleReport: null,
    updateError: "",

    saveReportLoading: false,
    saveReportMessage: null,
    saveReportError: null,

    saveUsersLoading: false,
    users: [],
    saveUsersError: null,

    saveLoading: false,
    saveMessage: null,
    saveError: null,

    disableLoading: false,
    disableMessage: null,
    disableError: null,

    deleteLoading: false,
    deleteMessage: null,
    deleteError: null,
};

export const reportsSlice = createSlice({
    name: REPORTS,
    initialState,
    reducers: {
        getReportsRequest: (state) => {
            state.loading = true;
            state.error = "";
            state.saveMessage = null;
            state.saveReportMessage = null;
            state.disableMessage = null;
            state.deleteMessage = null;
            state.singleReport = null;
        },
        getReportsSuccess: (state, action) => {
            state.loading = false;
            state.reports = action.payload.reports;
            state.languages = action.payload.lang_det;
        },
        getReportsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getReportsByIdRequest: (state) => {
            state.updateLoading = true;
            state.updateError = "";
            state.saveBranchMessage = "";
            state.saveReportMessage = null;
        },
        getReportsByIdSuccess: (state, action) => {
            state.updateLoading = false;
            state.singleReport = action.payload;
        },
        getReportsByIdError: (state, action) => {
            state.updateLoading = false;
            state.updateError = action.payload;
        },
        addReportRequest: (state) => {
            state.saveLoading = true;
            state.saveError = null;
        },
        addReportSuccess: (state, action) => {
            state.saveLoading = false;
            state.saveMessage = action.payload;
        },
        addReportError: (state, action) => {
            state.saveLoading = false;
            state.saveError = action.payload;
            state.saveMessage = null;
        },
        editReportRequest: (state) => {
            state.saveReportLoading = true;
            state.saveReportMessage = null;
            state.saveReportError = null;
        },
        editReportSuccess: (state, action) => {
            state.saveReportLoading = false;
            state.saveReportMessage = action.payload;
        },
        editReportError: (state, action) => {
            state.saveReportLoading = false;
            state.saveReportError = action.payload;
        },
        deleteReportRequest: (state) => {
            state.deleteLoading = true;
            state.deleteMessage = null;
            state.deleteError = null;
        },
        deleteReportSuccess: (state, action) => {
            state.deleteLoading = false;
            state.deleteMessage = action.payload;
        },
        deleteReportError: (state, action) => {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        disableReportRequest: (state) => {
            state.disableLoading = true;
            state.disableMessage = null;
            state.disableError = null;
        },
        disableReportSuccess: (state, action) => {
            state.disableLoading = false;
            state.disableMessage = action.payload;
        },
        disableReportError: (state, action) => {
            state.disableLoading = false;
            state.disableError = action.payload;
        },
        getUsersRequest: (state) => {
            state.saveUsersLoading = true;
            state.saveUsersError = "";
        },
        getUsersSuccess: (state, action) => {
            state.saveUsersLoading = false;
            state.users = action.payload;
        },
        getUsersError: (state, action) => {
            state.saveUsersLoading = false;
            state.saveUsersError = action.payload;
        },
        setOffSet: (state, action) => {
            state.offSet = action.payload;
        },
        resetForm: (state) => {
            state.singleReport = null;
        },
    },
});


export const {
    getReportsRequest,
    getReportsSuccess,
    getReportsError,
    getReportsByIdRequest,
    getReportsByIdSuccess,
    getReportsByIdError,
    addReportRequest,
    addReportSuccess,
    addReportError,
    editReportRequest,
    editReportSuccess,
    editReportError,
    deleteReportRequest,
    deleteReportSuccess,
    deleteReportError,
    disableReportRequest,
    disableReportError,
    disableReportSuccess,
    getUsersError,
    getUsersRequest,
    getUsersSuccess,
    resetForm,
    setOffSet,
} = reportsSlice.actions;

export default reportsSlice.reducer;
