import { useState } from "react";
import trusTree from "../../assets/images/new-logo.svg";
import styles from "./onboarding.module.css";
import Stepper from "../../components/stepper/Stepper";
import { useTranslation } from "react-i18next";
import BrandModal from "./BrandModal";
import BrandRow from "./BrandsRow";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Spinner } from "react-bootstrap";
import BrandAccordion from "./BrandAccordion";
import IconBtn from "./IconBtn";
import PaymentPlanCard from "../../components/paymentPlanCard/PaymentPlanCard";
import Receipt from "../../components/receipt/Receipt";
import { useMutation, useQuery } from "react-query";
import Payment from "../../services/payment";
import { v4 } from "uuid";
import Onboarding from "../../services/onboarding";
import ToastService from "../../helpers/toast-services";
import { useNavigate } from "react-router-dom";
import Brands from "../../services/brands";
import Branches from "../../services/branches";
import Logout from "../logout/Logout";

/**
 *
 * @param {T[]} arr
 * @param {T} item
 * @param {(item:T) => boolean} eq
 */
function updateItem(arr = [], item, eq) {
  const idx = arr.findIndex((i) => eq(i));
  if (idx >= 0) {
    arr[idx] = item;
  }
  return [...arr];
}

function OnboardingStepper() {
  const [stepIndex, setStepIndex] = useState(0);

  const [brands, setBrands] = useState([]);
  const [existingBranches, setExistingBranches] = useState([]);
  const [plan, setPlan] = useState();

  // Brand Modal
  const [editedBrand, setEditedBrand] = useState();
  const [brandModal, setBrandModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const { data: existingBrands, refetch: refetchExisting } = useQuery(
    "existing-brands",
    {
      queryFn: Onboarding.getExistingBrands,
      onSuccess: (data) =>
        !existingBranches.length &&
        setExistingBranches(
          data?.data?.map((brand) => ({ brandId: brand._id, branches: [] }))
        ),
    }
  );

  const brandsMutation = useMutation({
    mutationFn: (brand) => {
      const formData = new FormData();
      if (brand.logo) {
        formData.append("file", brand.logo[0]);
        formData.append("delete_logo", false);
      } else {
        formData.append("file", "");
        formData.append("delete_logo", true);
      }
      formData.append("name", brand.name);
      formData.append("category", brand.category);
      Brands.updateBrand(formData, brand._id);
    },
    onSuccess: () => {
      refetchExisting();
    },
  });

  const branchMutation = useMutation({
    mutationFn: ({ branchId, branch }) =>
      Branches.updateBranch({ updateBranch: branch }, branchId),
    onSuccess: () => refetchExisting(),
  });

  const { data } = useQuery("paymentPlans", {
    queryFn: Payment.getAllPaymentPlans,
    onSuccess: (data) =>
      data?.data?.data?.length && !plan && setPlan(data?.data?.data[0]),
  });

  const paymentPlans = data?.data?.data;

  const navigate = useNavigate();

  const checkoutMutation = useMutation({
    mutationFn: ({ paymentPlanId, brands, existingBranches }) => {
      const formData = new FormData();
      const newBrands = [];
      for (const brand of brands) {
        if (brand.logo) {
          formData.append(
            "logos",
            brand.logo[0],
            // the filename will be the brand id + original file extension
            `${brand.id}.${brand.logo[0].name.split(".").pop()}`
          );
        }
        newBrands.push({
          id: brand.id,
          name: brand.name,
          category: brand.category,
          branches: brand.branches,
        });
      }
      formData.append("logos", "");
      formData.append("paymentPlanId", JSON.stringify(paymentPlanId));
      formData.append("brands", JSON.stringify(newBrands));
      formData.append("existingBrands", JSON.stringify(existingBranches));
      return Onboarding.create(formData);
    },
    onSuccess: (data) => {
      ToastService.success(data?.data?.message);
      navigate("/");
    },
  });

  const { t } = useTranslation();
  const highestPrice = Math.max(...(paymentPlans?.map((p) => p.amount) || [0]));
  const branchCount =
    brands.reduce((acc, brand) => acc + (brand.branches || []).length, 0) +
    existingBranches.reduce(
      (acc, brand) => acc + (brand.branches || []).length,
      0
    ) +
    existingBrands?.data?.reduce(
      (acc, brand) => acc + (brand.branches || []).length,
      0
    );

  return (
    <main className={styles.main}>
      <a
        href="#"
        className={styles.logoutNav}
        onClick={() => setLogoutModal(true)}
      >
        <em></em>
        <strong>{t("Log out")}</strong>
      </a>
      <Logout show={logoutModal} setIsShow={() => setLogoutModal(false)} />
      <header className={styles.header}>
        <img src={trusTree} alt="Trustree Logo" />
      </header>
      <form className={styles.form}>
        <Stepper activeStep={stepIndex}>
          <Stepper.Step label={t("WELCOME")} className={styles.stepPanel}>
            <div className={styles.welcomeContainer}>
              <h1>{t("THANK_YOU_FOR_CHOOSING_TRUSTREE")}</h1>
              <p>{t("FOLLOW_NEXT_STEPS_TO_SETUP_ACCOUNT")}</p>
            </div>
            <div className={styles.textCenter}>
              <button
                type="button"
                onClick={() => setStepIndex(1)}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnPrimary}`}
              >
                {t("GET_STARTED")}
              </button>
            </div>
          </Stepper.Step>
          <Stepper.Step label={t("BRAND")} className={styles.stepPanel}>
            <BrandModal
              show={brandModal}
              centered
              initialBrand={editedBrand}
              onSubmit={(brand) => {
                if (brand) {
                  if (editedBrand) {
                    if (editedBrand.id) {
                      setBrands(
                        updateItem(
                          brands,
                          brand,
                          (a) => a.id === editedBrand.id
                        )
                      );
                    } else {
                      brandsMutation.mutate(brand);
                    }
                  } else
                    setBrands((brands) => [...brands, { ...brand, id: v4() }]);
                }
                setBrandModal(false);
                setEditedBrand(false);
              }}
            />
            <div
              className={`${styles.brandContainer} ${
                brands.length || existingBrands?.data?.length
                  ? ""
                  : styles.center
              }`}
            >
              {brands.length || existingBrands?.data?.length ? (
                <div className={styles.brandsList}>
                  {existingBrands?.data?.map((brand, i) => (
                    <BrandRow
                      key={brand._id}
                      existing
                      brand={brand}
                      onUpdate={(brand) => {
                        setEditedBrand(brand);
                        setBrandModal(true);
                      }}
                    />
                  ))}
                  {brands.map((brand, i) => (
                    <BrandRow
                      key={brand.id}
                      brand={brand}
                      onRemove={(brand) =>
                        setBrands((bs) => bs.filter((b) => b.id !== brand.id))
                      }
                      onUpdate={(brand) => {
                        setEditedBrand(brand);
                        setBrandModal(true);
                      }}
                    />
                  ))}
                  <IconBtn
                    type="button"
                    onClick={() => setBrandModal(true)}
                    icon={faPlusSquare}
                    label={t("ADD_NEW_BRAND_ONBOARDING")}
                  />
                </div>
              ) : (
                <div className={styles.textCenter}>
                  <h2>{t("CREATE_YOUR_FIRST_BRAND")}</h2>
                  <button
                    type="button"
                    onClick={() => setBrandModal(true)}
                    className={`${styles.btn} ${styles.btnPrimary}`}
                  >
                    {t("ADD_NEW_BRAND_ONBOARDING")}
                  </button>
                </div>
              )}
            </div>
            <div className={styles.btnRow}>
              <button
                type="button"
                onClick={() => setStepIndex(0)}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnSecondary}`}
              >
                {t("BACK")}
              </button>
              <button
                type="button"
                disabled={!brands.length && !existingBrands?.data?.length}
                onClick={() => setStepIndex(2)}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnPrimary}`}
              >
                {t("NEXT")}
              </button>
            </div>
          </Stepper.Step>
          <Stepper.Step label={t("BRANCHES")} className={styles.stepPanel}>
            <Accordion className={styles.branchesContainer}>
              {existingBrands?.data?.map((brand, i) => (
                <BrandAccordion
                  key={brand._id}
                  brand={brand}
                  existing
                  existingBranches={existingBranches.find(
                    (b) => b.brandId === brand._id
                  )}
                  onUpdate={(branch) => branchMutation.mutate(branch)}
                  onChange={(newBrand) => {
                    setExistingBranches((bs) =>
                      bs.map((brand) =>
                        brand.brandId === newBrand.brandId ? newBrand : brand
                      )
                    );
                  }}
                />
              ))}
              {brands.map((brand, i) => (
                <BrandAccordion
                  key={brand.id}
                  brand={brand}
                  onChange={(newBrand) => {
                    setBrands((bs) =>
                      bs.map((brand) =>
                        brand.id === newBrand.id ? newBrand : brand
                      )
                    );
                  }}
                />
              ))}
            </Accordion>
            <div className={styles.btnRow}>
              <button
                type="button"
                onClick={() => setStepIndex(1)}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnSecondary}`}
              >
                {t("BACK")}
              </button>
              <button
                type="button"
                disabled={!brands.every((b) => b.branches?.length)}
                onClick={() => setStepIndex(3)}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnPrimary}`}
              >
                {t("NEXT")}
              </button>
            </div>
          </Stepper.Step>
          <Stepper.Step label={t("CHECKOUT")} className={styles.stepPanel}>
            <div className={styles.checkoutContainer}>
              <h2>{t("CHOOSE_PAYMENT_PLAN")}</h2>
              <div className={styles.paymentsContainer}>
                {paymentPlans?.map((p) => (
                  <PaymentPlanCard
                    onClick={() => setPlan(p)}
                    selected={p._id === plan?._id}
                    key={p._id}
                    plan={p}
                    highestPrice={highestPrice}
                  />
                ))}
              </div>
              <div className={styles.receiptContainer}>
                <h2>{t("RECEIPT")}</h2>
                <Receipt
                  items={[
                    {
                      label: t("PRICE_FOR_BRANCH"),
                      info: `${highestPrice}$ x${branchCount}`,
                      amount: branchCount * highestPrice,
                    },
                    {
                      label: t("DISCOUNT"),
                      amount:
                        (highestPrice - (plan?.amount || 0)) * branchCount,
                    },
                    {
                      label: t("TOTAL_PER_MONTH"),
                      amount: (plan?.amount || 0) * branchCount,
                    },
                    {
                      label: t("TOTAL"),
                      amount: (plan?.amount || 0) * branchCount * plan?.months,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={styles.btnRow}>
              <button
                type="button"
                onClick={() => setStepIndex(2)}
                disabled={checkoutMutation.isLoading}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnSecondary}`}
              >
                {t("BACK")}
              </button>
              <button
                type="button"
                disabled={checkoutMutation.isLoading}
                onClick={() => {
                  plan &&
                    checkoutMutation.mutate({
                      paymentPlanId: plan._id,
                      brands,
                      existingBranches,
                    });
                }}
                className={`${styles.btn} ${styles.btnLg} ${styles.btnPrimary}`}
              >
                {checkoutMutation.isLoading ? (
                  <Spinner className={styles.spinner} size="sm" />
                ) : (
                  t("GET_YOUR_FREE_TRIAL")
                )}
              </button>
            </div>
          </Stepper.Step>
        </Stepper>
      </form>
    </main>
  );
}

export default OnboardingStepper;
