import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button, Accordion, FormCheck } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getBranchesListing } from "../../middlewares/branches";
import { useTranslation } from "react-i18next";
import { getMonth, getYear } from "date-fns";
import LeftArrow from "../../assets/images/u_arrow-left.svg";
import RightArrow from "../../assets/images/u_arrow-right.svg";
import { dateFormat } from "../../helpers/dateFormat";
import {
  setFliterValue,
  setToInitialFliterValue,
} from "../../slices/brands.slice";
import { categoryFilterList } from "../../middlewares/brands";
import locale from "../../helpers/date-importer";

const BrandFilter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [categoryFilterData, setCategoryFilterData] = useState([]);
  const [filterWithSameCat, setFilterWithSameCat] = useState([]);
  const [isMonth, setIsMonth] = useState(new Date().getMonth());
  const [isYear, setIsYear] = useState(new Date().getFullYear());
  const [isFirst, setIsFirst] = useState([]);
  const [isSecond, setIsSecond] = useState([]);
  const { categoryList } = useSelector((state) => state.brand);
  const { setFieldValue, close } = props;
  const { brands } = useSelector((state) => state.brand);
  const selectedBrand = brands.find((o) => o._id === props.brand_id);
  const { filter, appliedFiltersCount } = selectedBrand;
  const range = (a, b) => {
    let year = [];
    for (let i = a; i <= b; i++) {
      year.push(i);
    }
    return year;
  };

  useEffect(() => {
    if (props.brand_id) {
      dispatch(categoryFilterList(props.brand_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.brand_id]);

  useEffect(() => {
    let month = isMonth;
    let first = new Date(isYear, month, 1);
    let last = new Date(isYear, month + 1, 0);
    setIsFirst(first);
    setIsSecond(last);
  }, [isMonth, isYear]);

  const years = range(getYear(new Date()) - 100, getYear(new Date()) + 1, 1);
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const filterSchema = Yup.object().shape(
    {
      trustree_min: Yup.number()
        .min(0)
        .when("trustree_max", (value, schema) => {
          return schema.max(value);
        }),
      trustree_max: Yup.number()
        .max(5)
        .when("trustree_min", (value, schema) => {
          return schema.min(value);
        }),
      google_min: Yup.number()
        .min(0)
        .when("google_max", (value, schema) => {
          return schema.max(value);
        }),
      google_max: Yup.number()
        .max(5)
        .when("google_min", (value, schema) => {
          return schema.min(value);
        }),
      team_min: Yup.number()
        .min(0)
        .when("team_max", (value, schema) => {
          return schema.max(value);
        }),
      team_max: Yup.number().when("team_min", (value, schema) => {
        return schema.min(value);
      }),
    },
    [
      ["trustree_min", "trustree_max"],
      ["google_min", "google_max"],
      ["team_min", "team_max"],
    ]
  );

  const handleSubmit = (values) => {
    const payload = {
      search: props.search,
      sort: {
        column: props.sort.column,
        order: props.sort.order,
      },
      brand_id: props.brand_id,
      per_page: props.per_page,
      current_page: props.current_page,
      filter: {
        trustree_rating: {
          min: values.trustree_min,
          max: values.trustree_max,
        },
        google_rating: {
          min: values.google_min,
          max: values.google_max,
        },
        low_category: values.low_category,
        best_category: values.best_category,
        team: {
          min: values.team_min,
          max: values.team_max,
        },
        is_disable: { enable: values.enable, disable: values.disable },
        date:
          values.date && values.date[0] !== null
            ? values.date[1] === null
              ? {
                  start: `${dateFormat(values.date[0])}T00:00:00.000Z`,
                  end: `${dateFormat(values.date[0])}T23:59:59.999Z`,
                }
              : {
                  start: `${dateFormat(values.date[0])}T00:00:00.000Z`,
                  end: `${dateFormat(values.date[1])}T23:59:59.999Z`,
                }
            : [null, null],
      },
    };
    if (values.low_category && values.low_category.length > 0) {
      let filterData = [];
      values.low_category.map((o) => {
        let catData = filterWithSameCat.filter((s) => {
          return s.name.en === o;
        });
        filterData.push(catData);
        return o;
      });
      const FinalData = filterData.flat().map((s) => {
        return s._id;
      });
      payload.filter.low_category = FinalData;
    }
    if (values.best_category && values.best_category.length > 0) {
      let filterData = [];
      values.best_category.map((o) => {
        let catData = filterWithSameCat.filter((s) => {
          return s.name.en === o;
        });
        filterData.push(catData);
        return o;
      });
      const FinalData = filterData.flat().map((s) => {
        return s._id;
      });
      payload.filter.best_category = FinalData;
    }
    setFieldValue("filter", payload);
    dispatch(getBranchesListing(payload));
    dispatch(
      setFliterValue({
        filter: { ...payload.filter, date: values.date },
        brand_id: props.brand_id,
      })
    );
    close();
  };

  const formik = useFormik({
    initialValues: {
      trustree_min: filter?.trustree_rating?.min || 0,
      trustree_max: filter?.trustree_rating?.max || 5,
      google_min: filter?.google_rating?.min || 0,
      google_max: filter?.google_rating?.max || 5,
      low_category: filter?.low_category || [],
      best_category: filter?.best_category || [],
      team_min: filter?.team?.min || 0,
      team_max: filter?.team?.max || 20,
      enable: filter?.is_disable?.enable || false,
      disable: filter?.is_disable?.disable || false,
      date: filter?.date || [null, null],
    },
    validationSchema: filterSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (filterWithSameCat && filterWithSameCat.length > 0) {
      if (filter && filter.low_category && filter.low_category.length > 0) {
        let lowCatArray = [];
        filter.low_category.map((o) => {
          let lowCatData =
            filterWithSameCat &&
            filterWithSameCat.length > 0 &&
            filterWithSameCat.find((s) => {
              return s._id === o;
            });
          lowCatArray.push(lowCatData);
          return o;
        });
        let lowCatName =
          lowCatArray &&
          lowCatArray.length > 0 &&
          lowCatArray.map((j) => {
            return j.name.en;
          });
        const uniqueLowCatName = lowCatName.filter(
          (item, index) => lowCatName.indexOf(item) === index
        );
        formik.setFieldValue("low_category", uniqueLowCatName);
      }
      if (filter && filter.best_category && filter.best_category.length > 0) {
        let bestCatArray = [];
        filter.best_category.map((o) => {
          let bestCatData =
            filterWithSameCat &&
            filterWithSameCat.length > 0 &&
            filterWithSameCat.find((s) => {
              return s._id === o;
            });
          bestCatArray.push(bestCatData);
          return o;
        });
        let bestCatName =
          bestCatArray &&
          bestCatArray.length > 0 &&
          bestCatArray.map((j) => {
            return j.name.en;
          });
        const uniqueBestCatName = bestCatName.filter(
          (item, index) => bestCatName.indexOf(item) === index
        );
        formik.setFieldValue("best_category", uniqueBestCatName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterWithSameCat]);

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      const filterSameCategory =
        categoryList &&
        categoryList.length > 0 &&
        categoryList.flatMap((j) => {
          let filterValue =
            j.category_question.length > 0 &&
            j.category_question.map((o) => {
              return o;
            });
          return filterValue;
        });
      setFilterWithSameCat(filterSameCategory);
      const uniqueCategory =
        filterSameCategory?.length > 0 &&
        filterSameCategory.filter((obj, index) => {
          return (
            index ===
            filterSameCategory.findIndex(
              (o) => obj.name[t("language")] === o.name[t("language")]
            )
          );
        });
      setCategoryFilterData(uniqueCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList]);

  const clearAll = () => {
    dispatch(setToInitialFliterValue(props.brand_id));
    dispatch(
      getBranchesListing({
        search: props.search,
        sort: {
          column: props.sort.column,
          order: props.sort.order,
        },
        brand_id: props.brand_id,
        filter: {},
        per_page: 5,
        current_page: 1,
      })
    );
    close();
  };
  return (
    <div className="position-relative">
      <Form onSubmit={formik.handleSubmit}>
        <div className="brand-modal" style={{ marginTop: "50px" }}>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("TRUSTREE_RATING")}</Accordion.Header>
              <Accordion.Body>
                <div className="brand-filter-sub-menu">
                  <div>
                    <Form.Control
                      type="number"
                      name="trustree_min"
                      value={formik.values.trustree_min}
                      className={` brand-rating-input ${
                        formik.errors.trustree_min && "is-invalid"
                      }`}
                      onChange={formik.handleChange}
                      min={0}
                      max={formik.values.trustree_max - 1}
                    />
                  </div>
                  <div>{t("TO")}</div>
                  <div>
                    <Form.Control
                      name="trustree_max"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.trustree_max}
                      className={`brand-rating-input ${
                        formik.errors.trustree_max && "is-invalid"
                      }`}
                      min={formik.values.trustree_min + 1}
                      max={5}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("Google Rating")}</Accordion.Header>
              <Accordion.Body>
                <div className="brand-filter-sub-menu">
                  <div>
                    <Form.Control
                      type="number"
                      name="google_min"
                      value={formik.values.google_min}
                      className={` brand-rating-input ${
                        formik.errors.google_min && "is-invalid"
                      }`}
                      onChange={formik.handleChange}
                      max={formik.values.google_max - 1}
                      min={0}
                    />
                  </div>
                  <div>{t("TO")}</div>
                  <div>
                    <Form.Control
                      type="number"
                      name="google_max"
                      value={formik.values.google_max}
                      className={` brand-rating-input ${
                        formik.errors.google_max && "is-invalid"
                      }`}
                      onChange={formik.handleChange}
                      min={formik.values.google_min + 1}
                      max={5}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t("Low Category")}</Accordion.Header>
              <Accordion.Body className="brandScroll">
                {categoryFilterData &&
                  categoryFilterData.length > 0 &&
                  categoryFilterData.map((j, index) => (
                    <div key={index}>
                      <div className="brand-filter-sub-menu-align-left">
                        <FormCheck.Input
                          name="low_category"
                          type="checkbox"
                          value={j.name.en}
                          onChange={formik.handleChange}
                          checked={
                            formik.values.low_category &&
                            formik.values.low_category.includes(
                              j.name.en ? j.name.en : j._id
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        <FormCheck.Label className="ps-1">
                          <span>
                            {j.name[t("language")]
                              ? j.name[t("language")]
                              : j.name[t("en")]}
                          </span>
                        </FormCheck.Label>
                      </div>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{t("Best Category")}</Accordion.Header>
              <Accordion.Body className="brandScroll">
                {categoryFilterData &&
                  categoryFilterData.length > 0 &&
                  categoryFilterData.map((j, index) => (
                    <div key={index}>
                      <div className="brand-filter-sub-menu-align-left">
                        <FormCheck.Input
                          name="best_category"
                          type="checkbox"
                          value={j.name.en}
                          onChange={formik.handleChange}
                          checked={
                            formik.values.best_category &&
                            formik.values.best_category.includes(j.name.en)
                          }
                          onBlur={formik.handleBlur}
                        />
                        <FormCheck.Label className="ps-1">
                          <span>
                            {j.name[t("language")]
                              ? j.name[t("language")]
                              : j.name[t("en")]}
                          </span>
                        </FormCheck.Label>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      ></div>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>{t("Team")}</Accordion.Header>
              <Accordion.Body>
                <div className="brand-filter-sub-menu">
                  <div>
                    <Form.Control
                      type="number"
                      name="team_min"
                      value={formik.values.team_min}
                      className={` brand-rating-input ${
                        formik.errors.team_min && "is-invalid"
                      }`}
                      onChange={formik.handleChange}
                      min={0}
                      max={formik.values.team_max - 1}
                    />
                  </div>
                  <div>{t("TO")}</div>
                  <div>
                    <Form.Control
                      type="number"
                      name="team_max"
                      value={formik.values.team_max}
                      className={` brand-rating-input ${
                        formik.errors.team_max && "is-invalid"
                      }`}
                      onChange={formik.handleChange}
                      min={formik.values.team_min + 1}
                      max={10000}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>{t("STATUS")}</Accordion.Header>
              <Accordion.Body>
                <div>
                  <div className="brand-filter-sub-menu-align-left">
                    <span>
                      <Form.Check
                        type="checkbox"
                        name="enable"
                        checked={formik.values.enable}
                        onChange={formik.handleChange}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                    <span style={{ marginLeft: "10px" }}>{t("ENABLE")}</span>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  ></div>
                </div>
                <div>
                  <div className="brand-filter-sub-menu-align-left">
                    <span>
                      <Form.Check
                        type="checkbox"
                        name="disable"
                        checked={formik.values.disable}
                        onChange={formik.handleChange}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                    <span style={{ marginLeft: "10px" }}>{t("DISABLE")}</span>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  ></div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>{t("DATE")}</Accordion.Header>
              <Accordion.Body className="calendar-box text-center calenderWPR tt-cal">
                <DatePicker
                  selectsRange={true}
                  startDate={formik.values.date[0]}
                  endDate={formik.values.date[1]}
                  onChange={(dateArr) => {
                    formik.setFieldValue("date", dateArr);
                  }}
                  minDate={isFirst}
                  maxDate={isSecond}
                  isClearable={true}
                  inline
                  locale={locale[localStorage.getItem("i18nextLng")]}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <>
                      <div
                        style={{
                          margintop: 10,
                          marginbottom: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginRight: "10px", cursor: "pointer" }}>
                          <span
                            onClick={() => {
                              decreaseMonth();
                              setIsMonth(isMonth === 0 ? 11 : isMonth - 1);
                              setIsYear(isMonth === 0 ? isYear - 1 : isYear);
                            }}
                            disabled={prevMonthButtonDisabled}
                          >
                            <img src={LeftArrow} alt="monthDecrease" />
                          </span>
                        </div>

                        <select
                          className="form-select monthsSelect"
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) => {
                            changeMonth(months.indexOf(value));
                            setIsMonth(months.indexOf(value));
                          }}
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {t(option)}
                            </option>
                          ))}
                        </select>

                        <select
                          className="form-select yearSelect "
                          value={getYear(date)}
                          onChange={({ target: { value } }) => {
                            changeYear(value);
                            setIsYear(value);
                          }}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                          <span
                            onClick={() => {
                              increaseMonth();
                              setIsMonth(isMonth === 11 ? 0 : isMonth + 1);
                              setIsYear(isMonth === 11 ? isYear + 1 : isYear);
                            }}
                            disabled={nextMonthButtonDisabled}
                          >
                            <img src={RightArrow} alt="monthIncrease" />
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                />
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={() => {
                    formik.setFieldValue("date", [null, null]);
                  }}
                >
                  {t("CLEAR_SELECTION")}
                </button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="brand-button">
            <Button
              type="btn"
              className="brand-apply-btn "
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            >
              {t("APPLY")}
            </Button>
          </div>

          {appliedFiltersCount > 0 && (
            <div className="brand-button">
              <button
                onClick={() => {
                  formik.resetForm();
                  clearAll();
                }}
                className="clear-all"
                style={{ marginBottom: "16px", textAlign: "center" }}
              >
                {t("CLEAR_ALL")}
              </button>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default BrandFilter;
