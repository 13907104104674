import DetailReport from "../services/detailReport";
import {
  getPerformanceChartError,
  getPerformanceChartRequest,
  getPerformanceChartSuccess,
  getBranchesForDashboardError,
  getBranchesForDashboardRequest,
  getBranchesForDashboardSuccess,
  getCategoriesListingRequest,
  getCategoriesListingSuccess,
  getCategoriesListingError,
  getStaffListingRequest,
  getStaffListingSuccess,
  getStaffListingError,
  getReportsByIdSuccess,
  getReportsByIdError,
  getReportsByIdRequest,
  getAdditionalQuestionError,
  getAdditionalQuestionRequest,
  getAdditionalQuestionSuccess,
} from "../slices/detailReport.slice";

export function getPerformanceChart(payload) {
  return (dispatch) => {
    dispatch(getPerformanceChartRequest());
    DetailReport.getPerformanceChartData(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getPerformanceChartSuccess(data));
        } else {
          dispatch(getPerformanceChartError(error));
        }
      })
      .catch((error) => {
        dispatch(getPerformanceChartError(error));
      });
  };
}

export function getReportsById(brand_id, report_id) {
  return (dispatch) => {
    dispatch(getReportsByIdRequest());
    DetailReport.getReportsById(brand_id, report_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getReportsByIdSuccess(data));
        } else {
          dispatch(getReportsByIdError(error));
        }
      })
      .catch((error) => {
        dispatch(getReportsByIdError(error));
      });
  };
}

export function getBranchesForReport(payload) {
  return (dispatch) => {
    dispatch(getBranchesForDashboardRequest());
    DetailReport.getBranchData(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getBranchesForDashboardSuccess(data));
        } else {
          dispatch(getBranchesForDashboardError(error));
        }
      })
      .catch((error) => {
        dispatch(getBranchesForDashboardError(error));
      });
  };
}

export function getCategoriesListing(payload, exp_id) {
  return (dispatch) => {
    dispatch(getCategoriesListingRequest());
    DetailReport.getCategoriesListingReport(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getCategoriesListingSuccess({ data, exp_id }));
        } else {
          dispatch(getCategoriesListingError(error));
        }
      })
      .catch((error) => {
        dispatch(getCategoriesListingError(error));
      });
  };
}

export function getStaffListing(payload, exp_id) {
  return (dispatch) => {
    dispatch(getStaffListingRequest());
    DetailReport.getStaffListing(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getStaffListingSuccess({ data, exp_id }));
        } else {
          dispatch(getStaffListingError(error));
        }
      })
      .catch((error) => {
        dispatch(getStaffListingError(error));
      });
  };
}

export function getAdditionalQuestion(payload) {
  return (dispatch) => {
    dispatch(getAdditionalQuestionRequest());
    DetailReport.getAdditionalQuestion(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getAdditionalQuestionSuccess(data));
        } else {
          dispatch(getAdditionalQuestionError(error));
        }
      })
      .catch((error) => {
        dispatch(getAdditionalQuestionError(error));
      });
  };
}