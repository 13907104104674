import React from "react";
import { Navigate, useNavigate } from "react-router";
import ROUTE_URLS from "../../config/routes";
import Payment from "../../services/payment";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import NoAccess from "../no-access/NoAccess";

const SubscriptionGuard = ({ children }) => {
  const { data, isLoading, isError } = useQuery("subscription", {
    queryFn: Payment.getUserSubscription,
    retry: 3,
  });
  const navigation = useNavigate();

  const { loggedInUser } = useSelector((state) => state.auth);

  if (!loggedInUser) return children;

  if (isLoading) return <Spinner style={{ color: "#2bbc91" }} />;

  const isPermitted = loggedInUser?.permission === "owner";

  // If error do nothing
  if (isError) return children;

  const subscription = data?.data?.data.length && data?.data?.data[0];

  // Subscription doesn't exist
  if (!subscription) {
    // redirect to onboarding if owner/master
    if (isPermitted) return <Navigate to={ROUTE_URLS.ONBOARDING} replace />;
    // Render no access for normal users
    else return <NoAccess />;
  }

  // Subscription exists and valid, normal
  if (!subscription.isExpired) return children;

  // Normal user, Render no Access
  if (!isPermitted) {
    return <NoAccess />;
  }

  if (document.location.pathname !== ROUTE_URLS.PAYMENT) {
    navigation(ROUTE_URLS.PAYMENT, { replace: true });
  }

  return children;
};

export default SubscriptionGuard;
