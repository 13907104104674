import styles from "./datePicker.module.css";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfMonth, getMonth, getYear, startOfMonth } from "date-fns";
import { Button, Dropdown, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import locale from "../../helpers/date-importer";
import { useState } from "react";
import CalenderIcon from "../icons/CalenderIcon";

const year = getYear(new Date());
const YEARS_RANGE = 20;
const YEARS = [...new Array(YEARS_RANGE)].map(
  (_, i) => year + i - YEARS_RANGE / 2
);
const MONTHS = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

/**
 *
 * @param {{name: string, label: string value?: Date, onChange?: (date: Date|null)=> void onApply?: (date: Date|null)=> void}} props
 * @returns
 */
function DatePicker({ label, name, value, onChange, onApply, onBlur }) {
  const { t } = useTranslation();

  const [currentDate, setCurrentDate] = useState(value || new Date());

  const [show, setShow] = useState(false);
  const [date, setDate] = useState(value);

  const handleChange = (date) => {
    date && date.setHours(0, 0, 0, 0);
    setDate(date);
    onChange && onChange(date);
  };
  return (
    <>
      {label && <Form.Label htmlFor={name}>{t(label)}</Form.Label>}
      <Dropdown
        show={show}
        onToggle={(show) => setShow(show)}
        autoClose={"outside"}
      >
        <Dropdown.Toggle
          className={styles.control}
          id={name}
          name={name}
          onBlur={onBlur}
        >
          <span style={{ color: "black" }}>
            {value
              ? value.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : t("DATE")}
          </span>
          <CalenderIcon />
        </Dropdown.Toggle>
        {/* EXTERNAL STYLES HERE */}
        <Dropdown.Menu className="calenderWPR date tt-cal">
          <Dropdown.Item>
            <ReactDatePicker
              value={date}
              onChange={handleChange}
              minDate={startOfMonth(currentDate)}
              maxDate={endOfMonth(currentDate)}
              isClearable={true}
              locale={locale[localStorage.getItem("i18nextLng")]}
              inline
              onYearChange={setCurrentDate}
              onMonthChange={setCurrentDate}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
              }) => (
                <>
                  <div className={styles.container}>
                    <button
                      className={styles.iconBtn}
                      onClick={decreaseMonth}
                      disabled={!getMonth(date) && getYear(date) === YEARS[0]}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <select
                      className={styles.select}
                      value={getMonth(date)}
                      onChange={({ target: { value } }) => changeMonth(value)}
                    >
                      {MONTHS.map((option, i) => (
                        <option key={i} value={i}>
                          {t(option)}
                        </option>
                      ))}
                    </select>
                    <select
                      className={styles.select}
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {YEARS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={increaseMonth}
                      className={styles.iconBtn}
                      disabled={
                        getMonth(date) === 11 &&
                        getYear(date) === YEARS[YEARS.length - 1]
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </>
              )}
            />
            <Button
              className={styles.btn}
              type="button"
              disabled={!date && !currentDate}
              onClick={() => {
                setShow(false);
                currentDate && currentDate.setHours(0, 0, 0, 0);
                onApply && onApply(date || currentDate);
              }}
            >
              {t("APPLY")}
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default DatePicker;
