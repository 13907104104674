import React from "react";

const Men = ({ color }) => {
  return (
    <svg
      width="446"
      height="527"
      viewBox="0 0 446 527"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0589164 71.2539C0.468546 77.1009 2.19109 82.7804 5.09844 87.8699C11.2258 98.6309 21.7334 106.422 33.0504 111.053C47.1893 116.839 62.9504 117.017 77.9554 115.727C91.8921 114.529 105.687 111.751 119.675 111.179C130.493 110.832 141.321 111.091 152.11 111.952C173.042 113.438 193.886 116.195 214.597 119.536C220.206 120.43 225.803 121.391 231.388 122.418C231.916 122.464 232.438 122.561 232.948 122.707C233.049 122.749 233.212 122.716 233.305 122.775C233.474 122.881 232.623 122.506 233.139 122.932C233.857 123.686 234.498 124.51 235.051 125.393L242.7 135.236C244.893 138.059 246.929 141.488 249.764 143.713C251.763 145.257 254.174 146.175 256.694 146.351C259.214 146.526 261.729 145.952 263.923 144.7C266.215 143.252 268.042 141.176 269.188 138.719C270.948 135.492 273.047 132.463 275.449 129.682C284.549 119.066 297.196 112.536 307.774 103.604C312.898 99.4547 317.271 94.456 320.702 88.8261C323.63 83.7315 325.45 78.0768 326.044 72.2312C327.311 60.3599 323.668 48.2536 317.541 38.1545C310.877 27.3047 301.303 18.5417 289.907 12.861C277.105 6.33876 262.939 3.16181 248.739 1.57866C233.662 -0.102196 218.411 -0.0969562 203.261 0.0727438C186.258 0.262004 169.286 1.0097 152.346 2.31582C135.382 3.62886 118.485 5.49523 101.654 7.91497C85.505 10.2368 69.1583 12.6568 53.3505 16.7652C39.3856 20.3946 25.46 26.0674 15.0319 36.3461C5.75991 45.4852 -0.688954 57.9915 0.0589164 71.2539Z"
        fill={`${color ? color : "#ED1C24"}`}
      />
      <path
        d="M78.1216 75.0316C77.4312 75.0005 76.7547 74.8268 76.1347 74.5215C75.5148 74.2162 74.9647 73.7858 74.5192 73.2575C74.0737 72.7292 73.7424 72.1143 73.5461 71.4517C73.3499 70.7891 73.2929 70.093 73.3789 69.4073L73.7377 66.5824C73.8062 66.0406 73.7421 65.4902 73.5509 64.9786C73.3597 64.467 73.0471 64.0096 72.64 63.6455L70.5182 61.7494C69.8233 61.1288 69.316 60.326 69.0536 59.4321C68.7912 58.5381 68.7843 57.5885 69.0335 56.6907C69.2826 55.793 69.7781 54.9829 70.4637 54.352C71.1493 53.7212 71.9978 53.2948 72.9132 53.1211L75.7105 52.5897C76.2467 52.488 76.7502 52.2574 77.1775 51.9179C77.6048 51.5783 77.9432 51.14 78.1635 50.6406L79.3117 48.0332C79.6876 47.1808 80.2945 46.4506 81.0637 45.9251C81.8329 45.3996 82.7337 45.0997 83.6644 45.0594C84.5951 45.019 85.5186 45.2398 86.3304 45.6968C87.1422 46.1538 87.8099 46.8287 88.2582 47.6453L89.6269 50.1413C89.8895 50.6205 90.2647 51.0287 90.7202 51.3306C91.1757 51.6324 91.6978 51.8189 92.2414 51.874L95.0733 52.1601C96.0003 52.2538 96.8825 52.6051 97.6202 53.1742C98.358 53.7432 98.9217 54.5073 99.2478 55.3801C99.5739 56.2529 99.6492 57.1995 99.4653 58.1129C99.2814 59.0263 98.8456 59.87 98.2072 60.5486L96.2569 62.6221C95.8825 63.0194 95.6105 63.5019 95.4644 64.0279C95.3183 64.5538 95.3025 65.1075 95.4184 65.641L96.0201 68.4253C96.217 69.3357 96.1553 70.2831 95.842 71.1603C95.5287 72.0374 94.9763 72.8095 94.2472 73.3891C93.518 73.9688 92.6413 74.3329 91.7161 74.4403C90.7908 74.5477 89.854 74.3942 89.0115 73.997L86.4362 72.7836C85.9425 72.5497 85.3992 72.4397 84.8535 72.4632C84.3077 72.4866 83.7758 72.6428 83.304 72.9182L80.8434 74.3511C80.0197 74.8331 79.0752 75.0692 78.1216 75.0316Z"
        fill="white"
      />
      <path
        d="M118.212 73.8646C116.942 73.806 115.741 73.2697 114.849 72.3631C114.31 71.7924 113.908 71.1066 113.674 70.3577C113.439 69.6087 113.378 68.8162 113.495 68.0402L113.824 65.4483C113.894 64.8964 113.829 64.3357 113.634 63.8146C113.44 63.2934 113.121 62.8274 112.706 62.4567L110.738 60.6979C110.158 60.2024 109.695 59.5837 109.384 58.8868C109.072 58.1899 108.921 57.4324 108.939 56.6694C108.982 55.5391 109.408 54.457 110.146 53.6003C110.885 52.7436 111.892 52.1631 113.004 51.954L115.769 51.4289C116.315 51.3251 116.829 51.0898 117.264 50.7434C117.7 50.3971 118.044 49.95 118.269 49.4407L119.299 47.1009C119.667 46.2128 120.274 45.4436 121.051 44.8779C121.828 44.3122 122.747 43.972 123.705 43.8947C124.644 43.8414 125.579 44.056 126.402 44.5133C127.224 44.9707 127.899 45.6521 128.349 46.4783L129.702 48.9453C129.969 49.4337 130.352 49.8495 130.816 50.157C131.281 50.4645 131.813 50.6545 132.367 50.7105L134.992 50.9756C135.753 51.0352 136.491 51.2639 137.153 51.6451C137.815 52.0263 138.383 52.5504 138.816 53.1792C139.445 54.119 139.736 55.2443 139.642 56.371C139.548 57.4978 139.074 58.5592 138.298 59.3815L136.37 61.431C135.989 61.8359 135.713 62.3274 135.564 62.863C135.415 63.3987 135.399 63.9625 135.516 64.5058L136.072 67.0767C136.25 67.8187 136.261 68.591 136.104 69.3378C135.947 70.0847 135.627 70.7874 135.166 71.3954C134.469 72.2885 133.488 72.9174 132.386 73.1782C131.284 73.439 130.125 73.3162 129.102 72.83L126.554 71.6291C126.051 71.3922 125.498 71.2811 124.943 71.3052C124.387 71.3294 123.846 71.4881 123.366 71.7678L120.934 73.1841C120.11 73.6661 119.166 73.9022 118.212 73.8646Z"
        fill="white"
      />
      <path
        d="M158.411 73.6957C157.72 73.6646 157.044 73.4909 156.424 73.1856C155.804 72.8802 155.254 72.4499 154.808 71.9216C154.363 71.3932 154.031 70.7784 153.835 70.1158C153.639 69.4531 153.582 68.7571 153.668 68.0713L154.027 65.2465C154.095 64.7047 154.031 64.1543 153.84 63.6427C153.649 63.1311 153.336 62.6736 152.929 62.3095L150.807 60.4135C150.112 59.7928 149.605 58.9901 149.343 58.0961C149.08 57.2021 149.073 56.2525 149.322 55.3548C149.572 54.457 150.067 53.6469 150.753 53.0161C151.438 52.3852 152.287 51.9588 153.202 51.7851L156 51.2538C156.536 51.152 157.039 50.9215 157.467 50.5819C157.894 50.2424 158.232 49.804 158.453 49.3046L159.601 46.6973C159.977 45.8449 160.584 45.1147 161.353 44.5892C162.122 44.0637 163.023 43.7638 163.953 43.7234C164.884 43.6831 165.808 43.9039 166.619 44.3608C167.431 44.8178 168.099 45.4928 168.547 46.3094L169.916 48.8054C170.179 49.2846 170.554 49.6928 171.009 49.9946C171.465 50.2965 171.987 50.4829 172.531 50.538L175.362 50.8241C176.289 50.9179 177.172 51.2691 177.909 51.8382C178.647 52.4072 179.211 53.1714 179.537 54.0442C179.863 54.917 179.938 55.8636 179.754 56.7769C179.57 57.6903 179.135 58.534 178.496 59.2126L176.546 61.2861C176.172 61.6834 175.9 62.1659 175.753 62.6919C175.607 63.2179 175.592 63.7715 175.707 64.305L176.309 67.0894C176.506 67.9998 176.444 68.9471 176.131 69.8243C175.818 70.7015 175.265 71.4735 174.536 72.0532C173.807 72.6328 172.93 72.9969 172.005 73.1043C171.08 73.2118 170.143 73.0582 169.301 72.6611L166.725 71.4476C166.232 71.2137 165.688 71.1037 165.143 71.1272C164.597 71.1506 164.065 71.3068 163.593 71.5822L161.132 73.0151C160.309 73.4971 159.364 73.7333 158.411 73.6957Z"
        fill="white"
      />
      <path
        d="M197.52 72.6332C196.83 72.6021 196.153 72.4284 195.533 72.1231C194.913 71.8177 194.363 71.3874 193.918 70.8591C193.472 70.3307 193.141 69.7159 192.945 69.0533C192.748 68.3906 192.691 67.6946 192.777 67.0088L193.136 64.184C193.205 63.6422 193.141 63.0918 192.949 62.5802C192.758 62.0686 192.446 61.6111 192.038 61.247L189.917 59.351C189.222 58.7303 188.714 57.9276 188.452 57.0336C188.19 56.1396 188.183 55.19 188.432 54.2923C188.681 53.3945 189.177 52.5844 189.862 51.9536C190.548 51.3227 191.396 50.8963 192.312 50.7226L195.109 50.1913C195.645 50.0896 196.149 49.859 196.576 49.5194C197.003 49.1799 197.342 48.7415 197.562 48.2421L198.71 45.6348C199.086 44.7824 199.693 44.0522 200.462 43.5267C201.231 43.0012 202.132 42.7013 203.063 42.6609C203.994 42.6206 204.917 42.8414 205.729 43.2984C206.541 43.7553 207.208 44.4303 207.657 45.2469L209.025 47.7429C209.288 48.2221 209.663 48.6303 210.119 48.9321C210.574 49.234 211.096 49.4205 211.64 49.4755L214.472 49.7616C215.399 49.8554 216.281 50.2067 217.019 50.7757C217.756 51.3448 218.32 52.1089 218.646 52.9817C218.972 53.8545 219.048 54.8011 218.864 55.7145C218.68 56.6278 218.244 57.4715 217.606 58.1501L215.655 60.2237C215.281 60.6209 215.009 61.1034 214.863 61.6294C214.717 62.1554 214.701 62.7091 214.817 63.2425L215.418 66.0269C215.615 66.9373 215.554 67.8846 215.24 68.7618C214.927 69.639 214.375 70.411 213.646 70.9907C212.916 71.5703 212.04 71.9344 211.114 72.0419C210.189 72.1493 209.252 71.9957 208.41 71.5986L205.835 70.3851C205.341 70.1512 204.798 70.0413 204.252 70.0647C203.706 70.0882 203.174 70.2444 202.702 70.5197L200.242 71.9526C199.418 72.4346 198.474 72.6708 197.52 72.6332Z"
        fill="white"
      />
      <path
        d="M236.614 71.5746C235.923 71.5435 235.247 71.3698 234.627 71.0645C234.007 70.7591 233.457 70.3288 233.011 69.8005C232.566 69.2721 232.235 68.6573 232.038 67.9947C231.842 67.332 231.785 66.636 231.871 65.9502L232.23 63.1254C232.298 62.5836 232.234 62.0332 232.043 61.5216C231.852 61.01 231.539 60.5525 231.132 60.1884L229.01 58.2924C228.316 57.6717 227.808 56.869 227.546 55.975C227.283 55.081 227.276 54.1314 227.526 53.2337C227.775 52.3359 228.27 51.5258 228.956 50.895C229.641 50.2641 230.49 49.8377 231.405 49.664L234.203 49.1327C234.739 49.0309 235.242 48.8004 235.67 48.4608C236.097 48.1213 236.435 47.6829 236.656 47.1836L237.804 44.5762C238.18 43.7238 238.787 42.9936 239.556 42.4681C240.325 41.9426 241.226 41.6427 242.157 41.6023C243.087 41.562 244.011 41.7828 244.823 42.2398C245.634 42.6967 246.302 43.3717 246.75 44.1883L248.119 46.6843C248.382 47.1636 248.757 47.5717 249.212 47.8736C249.668 48.1754 250.19 48.3619 250.734 48.4169L253.565 48.7031C254.492 48.7968 255.375 49.1481 256.112 49.7172C256.85 50.2862 257.414 51.0504 257.74 51.9231C258.066 52.7959 258.141 53.7425 257.957 54.6559C257.774 55.5693 257.338 56.413 256.699 57.0916L254.749 59.1651C254.375 59.5624 254.103 60.0449 253.957 60.5709C253.81 61.0969 253.795 61.6505 253.911 62.184L254.512 64.9683C254.709 65.8787 254.647 66.8261 254.334 67.7033C254.021 68.5804 253.468 69.3525 252.739 69.9321C252.01 70.5118 251.133 70.8759 250.208 70.9833C249.283 71.0907 248.346 70.9372 247.504 70.54L244.928 69.3266C244.435 69.0927 243.891 68.9827 243.346 69.0062C242.8 69.0296 242.268 69.1858 241.796 69.4612L239.336 70.8941C238.512 71.376 237.567 71.6122 236.614 71.5746Z"
        fill="white"
      />
      <path
        d="M377.832 514.817L365.442 514.816L359.547 467.027L377.834 467.028L377.832 514.817Z"
        fill="#A0616A"
      />
      <path
        d="M377.507 510.768L364.287 505.398L363.897 505.238L356.597 510.768C352.591 510.768 348.739 512.314 345.846 515.085C342.952 517.855 341.24 521.636 341.067 525.638C341.047 525.858 341.047 526.088 341.047 526.318V526.828H380.997V510.768H377.507Z"
        fill="#2F2E41"
      />
      <path
        d="M348.821 503.288L337.133 507.4L315.711 464.275L332.961 458.207L348.821 503.288Z"
        fill="#A0616A"
      />
      <path
        d="M347.165 499.58L332.912 498.902L332.491 498.881L327.44 506.52C323.661 507.85 320.541 510.587 318.731 514.16C316.922 517.734 316.561 521.869 317.726 525.702C317.78 525.916 317.857 526.133 317.933 526.35L318.102 526.831L355.788 513.571L350.457 498.422L347.165 499.58Z"
        fill="#2F2E41"
      />
      <path
        d="M382.214 308.76C393.55 308.32 379.508 398.649 379.508 398.649C379.508 398.649 383.615 477.955 382.745 482.117C381.874 486.278 382.635 497.503 382.635 497.503C380.414 493.461 357.503 496.512 357.503 496.512L354.386 416.136L338.473 351.736L316.41 394.904L337.556 461.165C337.556 461.165 346.119 469.089 345.209 472.22C344.298 475.351 321.868 482.413 317.746 482.573C313.624 482.733 315.485 477.501 315.285 472.348C315.086 467.196 295.69 419.444 288.868 403.196C282.046 386.949 287.349 364.039 290.832 347.391C294.314 330.744 312.635 299.503 312.635 299.503C335.377 273.853 370.879 309.199 382.214 308.76Z"
        fill="#2F2E41"
      />
      <path
        d="M244.078 235.761C244.699 236.948 245.572 237.983 246.636 238.796C247.701 239.608 248.93 240.177 250.239 240.462C251.547 240.748 252.902 240.743 254.208 240.449C255.514 240.154 256.739 239.576 257.798 238.757L285.056 256.541L284.973 239.628L259.101 225.333C257.606 223.646 255.545 222.565 253.307 222.296C251.068 222.027 248.809 222.589 246.957 223.874C245.106 225.16 243.79 227.08 243.259 229.271C242.728 231.462 243.019 233.771 244.078 235.761Z"
        fill="#A0616A"
      />
      <path
        d="M376.328 323.242C348.218 323.243 332.727 317.616 324.501 312.592C314.442 306.448 312.721 299.97 312.653 299.696L312.633 299.618L313.612 287.298L314.04 286.339C316.013 281.922 316.275 276.93 314.774 272.332L314.744 272.237L314.751 272.139L321.324 186.894L344.584 179.275L351.351 172.476L381.478 172.039L385.676 180.549L409.427 189.491L397.758 258.947L392.164 268.589L394.415 279.092L394.4 279.186L387.061 322.986L386.658 323.004C383.039 323.166 379.595 323.245 376.328 323.242Z"
        fill="#3F3D56"
      />
      <path
        d="M303.607 270.061L292.579 263.523L291.888 262.525C290.484 260.505 288.626 258.842 286.464 257.67C284.301 256.498 281.893 255.85 279.434 255.777L279.306 255.773L264.117 246.843L270.413 228.4L294.223 235.434L310.823 194.189C311.734 191.924 313.256 189.956 315.22 188.504C317.183 187.053 319.511 186.174 321.943 185.966C324.376 185.758 326.819 186.229 329 187.327C331.182 188.424 333.016 190.105 334.298 192.183C335.612 194.354 336.294 196.85 336.266 199.388V226.375L336.166 226.509L303.607 270.061Z"
        fill="#3F3D56"
      />
      <path
        d="M377.334 307.377C377.57 306.059 378.092 304.809 378.863 303.714C379.634 302.62 380.636 301.708 381.798 301.042C382.96 300.377 384.254 299.974 385.588 299.863C386.922 299.752 388.265 299.934 389.521 300.398L410.177 275.246L415.179 291.402L394.797 312.81C393.878 314.869 392.236 316.519 390.182 317.447C388.128 318.376 385.805 318.519 383.652 317.85C381.5 317.18 379.668 315.744 378.503 313.814C377.339 311.884 376.923 309.593 377.334 307.377Z"
        fill="#A0616A"
      />
      <path
        d="M408.212 304.202L394.496 290.484L403.228 269.21L415.881 251.931C402.397 245.367 392.276 229.816 384.942 216.943L384.859 216.799L386.686 202.495C387.113 199.149 388.453 195.986 390.559 193.352C392.391 191.061 394.744 189.242 397.422 188.047C400.101 186.852 403.027 186.316 405.955 186.483C408.883 186.65 411.728 187.516 414.253 189.009C416.778 190.501 418.909 192.576 420.468 195.061L445.06 249.729L444.783 250.142L425.002 283.861L419.604 287.362L408.212 304.202Z"
        fill="#3F3D56"
      />
      <path
        d="M388.047 143.363C387.142 149.954 383.658 155.917 378.36 159.942C373.061 163.966 366.382 165.722 359.79 164.825C353.197 163.928 347.23 160.45 343.2 155.156C339.17 149.862 337.406 143.185 338.296 136.591L338.345 136.235C339.385 129.73 342.94 123.894 348.245 119.986C353.549 116.079 360.177 114.413 366.698 115.348C373.219 116.283 379.112 119.745 383.104 124.985C387.097 130.226 388.869 136.826 388.039 143.361L388.047 143.363Z"
        fill="#A0616A"
      />
      <path
        d="M381.657 156.294C382.946 153.675 384.27 150.864 383.908 147.967C383.546 145.071 380.705 142.303 377.916 143.163C376.248 143.678 374.988 145.325 373.244 145.409C370.845 145.525 369.47 142.78 368.723 140.497L365.682 131.201C362.762 133.477 359.375 135.08 355.764 135.896C352.153 136.712 348.406 136.721 344.791 135.921C341.968 135.276 339.115 134.017 337.459 131.641C335.803 129.265 335.813 125.58 338.084 123.783C339.198 122.902 340.681 122.567 341.745 121.627C342.406 121.044 342.861 120.264 343.043 119.402C343.226 118.54 343.126 117.642 342.758 116.841C342.391 116.041 341.775 115.38 341.003 114.956C340.231 114.532 339.342 114.368 338.47 114.489L346.127 113.532L343.845 109.405C344.712 109.996 345.693 110.398 346.725 110.588C347.757 110.777 348.817 110.749 349.837 110.505C351.897 110.134 353.837 109.286 355.852 108.72C360.737 107.472 365.896 107.854 370.544 109.806C375.193 111.758 379.077 115.174 381.607 119.534C383.491 118.905 385.522 118.864 387.43 119.415C389.339 119.966 391.035 121.084 392.294 122.62C394.344 125.222 394.901 128.694 394.98 132.005C395.129 138.533 393.706 145.002 390.83 150.865C390.005 152.727 388.82 154.408 387.343 155.811C386.6 156.506 385.713 157.03 384.746 157.347C383.778 157.663 382.753 157.764 381.742 157.642"
        fill="#2F2E41"
      />
    </svg>
  );
};

export default Men;
