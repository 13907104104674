import { get, post, del } from ".";

const URI = "/brands/team-reward";

const getTeamRewards = (brand_id) => {
  const URL = `${URI}/${brand_id}`;
  return get(URL);
};

const getTeamRewardsById = (brand_id, reward_id) => {
  const URL = `${URI}/${brand_id}/${reward_id}`;
  return get(URL);
};

const addTeamRewards = (payload) => {
  const URL = `${URI}`;
  return post(URL, payload);
};

const getUsers = () => {
  const URL = `/brands/reports/users`;
  return get(URL);
};

const updateTeamRewards = (payload, reward_id) => {
  const URL = `${URI}/${reward_id}`;
  return post(URL, payload);
};

const deleteTeamRewards = (reward_id, payload) => {
  const URL = `${URI}/${reward_id}`;
  return del(URL, payload);
};

const disableTeamRewards = (reward_id) => {
  const URL = `${URI}/update/${reward_id}`;
  return post(URL);
};

const getQuestions = (select_id, payload) => {
  const URL = `${URI}/category/${select_id}`;
  return post(URL, payload);
};

const getStaffMembers = (brand_id, payload) => {
  const URL = `${URI}/staff/${brand_id}`;
  return post(URL, payload);
};

const getRewradAchieved = (payload, brand_id) => {
  const URL = `${URI}/reward-achieved/${brand_id}`;
  return post(URL, payload);
};

/**
 * @typedef {{
 *   branch_id: string[]
 *   experience_type_id: string[]
 *   staff_id: string[]
 *   date: {
 *    start: string,
 *    end: string,
 *  }
 * }} Filter
 * @param {{
 *  brand_id: string,
 *  filter: Filter
 * }} payload
 * @returns { Promise<import("axios").AxiosResponse<[{total: number}]>>}
 */
const getTotalRatings = (payload) => {
  const URL = `${URI}/ratings/total`;
  return post(URL, payload);
};
/**
 * @typedef {{
 *   branch_id: string[]
 *   experience_type_id: string[]
 *   staff_id: string[]
 *   date: {
 *    start: string,
 *    end: string,
 *  }
 * }} Filter
 * @param {{
 *  brand_id: string,
 *  filter: Filter
 * }} payload
 * @returns { Promise<import("axios").AxiosResponse<[{average: number}]>>}
 */
const getAverageRatings = (payload) => {
  const URL = `${URI}/ratings/average`;
  return post(URL, payload);
};

const TeamRewards = {
  getTeamRewards,
  getTeamRewardsById,
  addTeamRewards,
  updateTeamRewards,
  deleteTeamRewards,
  disableTeamRewards,
  getRewradAchieved,
  getQuestions,
  getStaffMembers,
  getUsers,
  getTotalRatings,
  getAverageRatings,
};
export default TeamRewards;
