import { createSlice, current } from "@reduxjs/toolkit";
import { DETAIL_REPORT } from "./slice-names";

export const detailReportSlice = createSlice({
  name: DETAIL_REPORT,
  initialState: {
    loading: true,
    quickOverview: null,
    message: "",
    error: "",

    performanceChartLoading: false,
    performanceChart: [],
    performanceChartErro: "",

    branchesReportLoading: false,
    branchesReport: [],
    branchesReportError: "",

    additionalQuestionsLoading: false,
    additionalQuestions: [],
    additionalQuestionsError: "",

    updateLoading: false,
    singleReport: null,
    updateError: "",

    categoriesLoading: false,
    categories: [],
    categoriesError: "",

    staffListingLoading: false,
    staffListing: [],
    staffListingError: "",

    branchFilter: false,
    expTypeFilter: false,
    ratingFilter: false,
    staffFilter: false,
    dateFilter: false,
    categoryFilter: false,
  },
  reducers: {
    getPerformanceChartRequest: (state, action) => {
      state.performanceChartLoading = true;
      state.performanceChartErro = null;
    },
    getPerformanceChartSuccess: (state, action) => {
      state.performanceChartLoading = false;
      state.performanceChart = action.payload;
      state.performanceChartErro = null;
    },
    getPerformanceChartError: (state, action) => {
      state.performanceChartLoading = false;
      state.performanceChartErro = action.payload;
    },
    getBranchesForDashboardRequest: (state) => {
      state.branchesReportLoading = true;
      state.branchesReportError = null;
    },
    getBranchesForDashboardSuccess: (state, action) => {
      state.branchesReportLoading = false;
      state.branchesReport = action.payload;
    },
    getBranchesForDashboardError: (state, action) => {
      state.branchesReportLoading = false;
      state.branchesReportError = action.payload;
    },
    getCategoriesListingRequest: (state) => {
      state.categoriesLoading = true;
      state.categoriesError = null;
    },
    getCategoriesListingSuccess: (state, action) => {
      state.categoriesLoading = false;
      const previousCategories = current(state.categories);
      if (
        previousCategories &&
        previousCategories.length > 0 &&
        action.payload.exp_id
      ) {
        const data = previousCategories.map((o) => {
          const dataObj = { ...o };
          const newData = action.payload.data.find(
            (s) => s.experience_type._id === action.payload.exp_id
          );
          if (dataObj.experience_type._id === action.payload.exp_id) {
            dataObj.data = newData.data;
          }
          return dataObj;
        });
        state.categories = data;
      } else {
        state.categories = action.payload.data;
      }
    },
    getCategoriesListingError: (state, action) => {
      state.categoriesLoading = false;
      state.categoriesError = action.payload;
    },
    getStaffListingRequest: (state) => {
      state.staffListingLoading = true;
      state.staffListingError = null;
    },
    getStaffListingSuccess: (state, action) => {
      state.staffListingLoading = false;
      const previousStaff = current(state.staffListing);
      if (previousStaff && previousStaff.length > 0 && action.payload.exp_id) {
        const data = previousStaff.map((o) => {
          const dataObj = { ...o };
          const newData = action.payload.data.find(
            (s) => s.experience_type._id === action.payload.exp_id
          );
          if (dataObj.experience_type._id === action.payload.exp_id) {
            dataObj.data = newData.data;
          }
          return dataObj;
        });
        state.staffListing = data;
      } else {
        state.staffListing = action.payload.data;
      }
    },
    getStaffListingError: (state, action) => {
      state.staffListingLoading = false;
      state.staffListingError = action.payload;
    },
    getReportsByIdRequest: (state) => {
      state.updateLoading = true;
      state.updateError = "";
    },
    getReportsByIdSuccess: (state, action) => {
      state.updateLoading = false;
      state.singleReport = action.payload;
    },
    getReportsByIdError: (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    },
    getAdditionalQuestionRequest: (state, action) => {
      state.additionalQuestionsLoading = true;
      state.additionalQuestionsError = "";
    },
    getAdditionalQuestionSuccess: (state, action) => {
      state.additionalQuestionsLoading = false;
      state.additionalQuestions = action.payload;
    },
    getAdditionalQuestionError: (state, action) => {
      state.additionalQuestionsLoading = false;
      state.additionalQuestionsError = action.payload;
    },
  },
});


export const {
  getPerformanceChartRequest,
  getPerformanceChartSuccess,
  getPerformanceChartError,
  getBranchesForDashboardRequest,
  getBranchesForDashboardSuccess,
  getBranchesForDashboardError,
  getCategoriesListingRequest,
  getCategoriesListingSuccess,
  getCategoriesListingError,
  getStaffListingRequest,
  getStaffListingSuccess,
  getStaffListingError,
  getReportsByIdRequest,
  getReportsByIdSuccess,
  getReportsByIdError,
  getAdditionalQuestionError,
  getAdditionalQuestionRequest,
  getAdditionalQuestionSuccess,
} = detailReportSlice.actions;

export default detailReportSlice.reducer;
