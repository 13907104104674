import Dashboard from "../services/dashboard";
import {
  exportFeedbackDataAsPdfError,
  exportFeedbackDataAsPdfRequest,
  exportFeedbackDataAsPdfSuccess,
  exportFeedbackDataError,
  exportFeedbackDataRequest,
  exportFeedbackDataSuccess,
  getAdditionalQuestionError,
  getAdditionalQuestionRequest,
  getAdditionalQuestionSuccess,
  getBranchesForDashboardError,
  getBranchesForDashboardRequest,
  getBranchesForDashboardSuccess,
  getCategoriesListingByBrandIdError,
  getCategoriesListingByBrandIdRequest,
  getCategoriesListingByBrandIdSuccess,
  getCategoriesListingError,
  getCategoriesListingRequest,
  getCategoriesListingSuccess,
  getFeedbackDataForModalError,
  getFeedbackDataForModalRequest,
  getFeedbackDataForModalSuccess,
  getOverAllRatingError,
  getOverAllRatingRequest,
  getOverAllRatingSuccess,
  getPerformanceChartError,
  getPerformanceChartRequest,
  getPerformanceChartSuccess,
  getQuickOverviewError,
  getQuickOverviewRequest,
  getQuickOverviewSuccess,
  getStaffListingError,
  getStaffListingRequest,
  getStaffListingSuccess,
  getComplimentsByIdError,
  getComplimentsByIdRequest,
  getComplimentsByIdSuccess,
  getSuggestionsByIdError,
  getSuggestionsByIdRequest,
  getSuggestionsByIdSuccess,
  getProductListingError,
  getProductListingRequest,
  getProductListingSuccess,
  getFeedbackRequest,
  getFeedbackError,
  getFeedbackSuccess
} from "../slices/dashboard.slice";

export function getQuickOverview(payload, brand_id) {
  return (dispatch) => {
    dispatch(getQuickOverviewRequest());
    Dashboard.getQuickOverview(payload, brand_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getQuickOverviewSuccess(data));
        } else {
          dispatch(getQuickOverviewError(error));
        }
      })
      .catch((error) => {
        dispatch(getQuickOverviewError(error));
      });
  };
}

export function getBranchesForDashboard(payload) {
  return (dispatch) => {
    dispatch(getBranchesForDashboardRequest());
    Dashboard.getBranchesForDashboard(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getBranchesForDashboardSuccess(data));
        } else {
          dispatch(getBranchesForDashboardError(error));
        }
      })
      .catch((error) => {
        dispatch(getBranchesForDashboardError(error));
      });
  };
}

export function getCategoriesListingByBrandId(b_id) {
  return (dispatch) => {
    dispatch(getCategoriesListingByBrandIdRequest());
    Dashboard.getCategoriesListingByBrandId(b_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getCategoriesListingByBrandIdSuccess(data));
        } else {
          dispatch(getCategoriesListingByBrandIdError(error));
        }
      })
      .catch((error) => {
        dispatch(getCategoriesListingByBrandIdError(error));
      });
  };
}

export function getCategoriesListing(payload, exp_id) {
  return (dispatch) => {
    dispatch(getCategoriesListingRequest());
    Dashboard.getCategoriesListing(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getCategoriesListingSuccess({ data, exp_id }));
        } else {
          dispatch(getCategoriesListingError(error));
        }
      })
      .catch((error) => {
        dispatch(getCategoriesListingError(error));
      });
  };
}

export function getPerformanceChart(payload) {
  return (dispatch) => {
    dispatch(getPerformanceChartRequest());
    Dashboard.getPerformanceChartData(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getPerformanceChartSuccess(data));
        } else {
          dispatch(getPerformanceChartError(error));
        }
      })
      .catch((error) => {
        dispatch(getPerformanceChartError(error));
      });
  };
}

export function getSuggestionsById(payload) {
  return (dispatch) => {
    const experience_type_id = payload?.filter?.experience_type_id[0];
    if (!experience_type_id) return;
    dispatch(getSuggestionsByIdRequest(experience_type_id));
    Dashboard.getSuggestions(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (
          status === 1 &&
          data[0] &&
          data[0].data?.data?.every(
            (item) => Object.keys(item.lowest_rated_data).length
          )
        ) {
          dispatch(getSuggestionsByIdSuccess(data[0]));
        } else {
          dispatch(getSuggestionsByIdError({ ...error, experience_type_id }));
        }
      })
      .catch((error) => {
        dispatch(getSuggestionsByIdError({ ...error, experience_type_id }));
      });
  };
}

export function getComplimentsById(payload) {
  return (dispatch) => {
    const experience_type_id = payload?.filter?.experience_type_id[0];
    if (!experience_type_id) return;
    dispatch(getComplimentsByIdRequest(experience_type_id));
    Dashboard.getCompliments(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (
          status === 1 &&
          data[0] &&
          data[0].data?.data?.every(
            (item) => Object.keys(item.highest_rated_data).length
          )
        ) {
          dispatch(getComplimentsByIdSuccess(data[0]));
        } else {
          dispatch(getComplimentsByIdError({ ...error, experience_type_id }));
        }
      })
      .catch((error) => {
        dispatch(getComplimentsByIdError({ ...error, experience_type_id }));
      });
  };
}

export function getStaffListing(payload, exp_id) {
  return (dispatch) => {
    dispatch(getStaffListingRequest());
    Dashboard.getStaffListing(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getStaffListingSuccess({ data, exp_id }));
        } else {
          dispatch(getStaffListingError(error));
        }
      })
      .catch((error) => {
        dispatch(getStaffListingError(error));
      });
  };
}

export function getProductListing(payload, exp_id) {
  return (dispatch) => {
    dispatch(getProductListingRequest());
    Dashboard.getProductListing(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getProductListingSuccess({ data, exp_id }));
        } else {
          dispatch(getProductListingError(error));
        }
      })
      .catch((error) => {
        dispatch(getProductListingError(error));
      });
  };
}

export function getFeedbackListing(payload, exp_id) {
  return (dispatch) => {
    dispatch(getFeedbackRequest());
    Dashboard.getFeedbackListing(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getFeedbackSuccess({ data, exp_id }));
        } else {
          dispatch(getFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(getFeedbackError(error));
      });
  };
}

export function getFeedbackDataForModal(f_id) {
  return (dispatch) => {
    dispatch(getFeedbackDataForModalRequest());
    Dashboard.getFeedbackDataForModal(f_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getFeedbackDataForModalSuccess(data));
        } else {
          dispatch(getFeedbackDataForModalError(error));
        }
      })
      .catch((error) => {
        dispatch(getFeedbackDataForModalError(error));
      });
  };
}

export function getOverAllRating(payload) {
  return (dispatch) => {
    dispatch(getOverAllRatingRequest());
    Dashboard.getOverAllRatingData(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getOverAllRatingSuccess(data));
        } else {
          dispatch(getOverAllRatingError(error));
        }
      })
      .catch((error) => {
        dispatch(getOverAllRatingError(error));
      });
  };
}

export function getAdditionalQuestion(payload) {
  return (dispatch) => {
    dispatch(getAdditionalQuestionRequest());
    Dashboard.getAdditionalQuestion(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getAdditionalQuestionSuccess(data));
        } else {
          dispatch(getAdditionalQuestionError(error));
        }
      })
      .catch((error) => {
        dispatch(getAdditionalQuestionError(error));
      });
  };
}

export function exportFeedbackData(payload) {
  return (dispatch) => {
    dispatch(exportFeedbackDataRequest());
    Dashboard.exportFeedbackData(payload)
      .then((response) => {
        dispatch(exportFeedbackDataSuccess());
        if (response.data.status === 0) {
          dispatch(exportFeedbackDataError(response.data.message));
        } else {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.target = "_blank";
          downloadLink.click();
        }
      })
      .catch((error) => {
        dispatch(exportFeedbackDataError(error));
      });
  };
}

export function exportFeedbackDataAsPdf(payload) {
  return (dispatch) => {
    dispatch(exportFeedbackDataAsPdfRequest());
    Dashboard.exportFeedbackDataAsPdf(payload)
      .then((response) => {
        dispatch(exportFeedbackDataAsPdfSuccess());
        if (response.data.status === 0) {
          dispatch(exportFeedbackDataError(response.data.message));
        } else {
          const linkSource = `data:application/pdf;base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `${payload.brand_id}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      })
      .catch((error) => {
        dispatch(exportFeedbackDataAsPdfError(error));
      });
  };
}