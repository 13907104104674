import styles from "./teamRewardModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "../select/Select";
import DatePicker from "../datepicker/DatePicker";
import TargetRow from "./TargetRow";
import { useEffect } from "react";
import ClockIcon from "../icons/ClockIcon";
import { teamReward } from "../../helpers/yup.validation.schema";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import EmailRow from "./EmailRow";
import UserRow from "./UserRow";
import StaffRow from "./StaffRow";
import { getStaffMembers } from "../../middlewares/teamRewards";
import { useDispatch } from "react-redux";
import { getBranchesByBrandId } from "../../middlewares/branches";
import { getExperienceTypeByBrandId } from "../../middlewares/experienceType";
import { getUsers } from "../../middlewares/users";
import { useQuery } from "react-query";
import TeamRewards from "../../services/teamRewards";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";

const DEFAULT_TARGET = {
  kpi: "averageRating",
  value: 5,
  valueType: "percent",
  cond: "reached",
};

const periods = ["day", "week", "month", "year"];

function formateTime(d) {
  return d?.toLocaleTimeString(undefined, {
    minute: "2-digit",
    hour: "2-digit",
  });
}

function dateFromHour(n) {
  const d = new Date();

  d.setHours(n, 0, 0, 0, 0);
  return d;
}

function periodDate(period) {
  if (period === "week") {
    return {
      start: startOfWeek(new Date()),
      end: endOfWeek(new Date()),
    };
  } else if (period === "month") {
    return {
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    };
  } else if (period === "year") {
    return {
      start: startOfYear(new Date()),
      end: endOfYear(new Date()),
    };
  }
  return {
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  };
}

function prevPeriodDate(period) {
  if (period === "week") {
    return {
      start: startOfWeek(subWeeks(new Date(), 1)),
      end: endOfWeek(subWeeks(new Date(), 1)),
    };
  } else if (period === "month") {
    return {
      start: startOfMonth(subMonths(new Date(), 1)),
      end: endOfMonth(subMonths(new Date(), 1)),
    };
  } else if (period === "year") {
    return {
      start: startOfYear(subYears(new Date(), 1)),
      end: endOfYear(subYears(new Date(), 1)),
    };
  }
  return {
    start: startOfDay(subDays(new Date(), 1)),
    end: endOfDay(subDays(new Date(), 1)),
  };
}

/**
 * @typedef {{}} TeamReward
 * @param {{
 *  brandId: string
 *  initialReward: TeamReward
 *  initialRewardLoading: boolean
 *  onSubmit: (teamReward?: TeamReward) => void
 *  show: boolean
 *  loading: boolean
 * }} props
 * @returns
 */
function TeamRewardModal({
  initialReward,
  onSubmit,
  show,
  loading,
  initialRewardLoading,
  brandId,
}) {
  const { t } = useTranslation();

  const { branches } = useSelector((state) => state.branch);
  const { experienceTypeData } = useSelector((state) => state.experience);
  const { users } = useSelector((state) => state.user);
  const { members } = useSelector((state) => state.teamReward);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: undefined,
      branchIds: [],
      experienceTypeIds: [],
      period: undefined,
      startDate: undefined,
      sendingHour: undefined,
      rewardMessage: undefined,
      targets: [DEFAULT_TARGET],
      users: [],
      staff: [],
      emails: [],
    },
    validationSchema: teamReward,
    onSubmit: (teamReward) => {
      onSubmit && onSubmit(teamReward);
      formik.resetForm();
    },
  });

  const prevPeriod = prevPeriodDate(formik.values.period);
  const currentPeriod = periodDate(formik.values.period);

  const { data: averageRating } = useQuery({
    queryFn: async () =>
      show &&
      formik.values.branchIds.length &&
      formik.values.experienceTypeIds.length &&
      formik.values.period &&
      (
        await TeamRewards.getAverageRatings({
          brand_id: brandId,
          filter: {
            branch_id: formik.values.branchIds,
            experience_type_id: formik.values.experienceTypeIds,
            date: currentPeriod,
          },
        })
      ).data?.at(0)?.average,
    queryKey: [
      "averageRating",
      brandId,
      ...formik.values.branchIds,
      ...formik.values.experienceTypeIds,
      currentPeriod.start.toISOString(),
      currentPeriod.end.toISOString(),
    ],
  });

  const { data: ratingCount } = useQuery({
    queryFn: async () =>
      show &&
      formik.values.branchIds.length &&
      formik.values.experienceTypeIds.length &&
      formik.values.period &&
      (
        await TeamRewards.getTotalRatings({
          brand_id: brandId,
          filter: {
            branch_id: formik.values.branchIds,
            experience_type_id: formik.values.experienceTypeIds,
            date: currentPeriod,
          },
        })
      ).data?.at(0)?.total,
    queryKey: [
      "totalRatings",
      brandId,
      ...formik.values.branchIds,
      ...formik.values.experienceTypeIds,
      currentPeriod.start.toISOString(),
      currentPeriod.end.toISOString(),
    ],
  });

  const { data: prevRatingCount } = useQuery({
    queryFn: async () =>
      show &&
      formik.values.branchIds.length &&
      formik.values.experienceTypeIds.length &&
      formik.values.period &&
      (
        await TeamRewards.getTotalRatings({
          brand_id: brandId,
          filter: {
            branch_id: formik.values.branchIds,
            experience_type_id: formik.values.experienceTypeIds,
            date: prevPeriod,
          },
        })
      ).data?.at(0)?.total,
    queryKey: [
      "totalRatings",
      brandId,
      ...formik.values.branchIds,
      ...formik.values.experienceTypeIds,
      prevPeriod.start.toISOString(),
      prevPeriod.end.toISOString(),
    ],
  });

  const { data: staffAverageRating } = useQuery({
    queryFn: async () =>
      show &&
      formik.values.branchIds.length &&
      formik.values.experienceTypeIds.length &&
      formik.values.period &&
      (
        await TeamRewards.getAverageRatings({
          brand_id: brandId,
          filter: {
            branch_id: formik.values.branchIds,
            experience_type_id: formik.values.experienceTypeIds,
            staff_id: formik.values.staff.map((s) => s.staffId),
            date: currentPeriod,
          },
        })
      ).data?.at(0)?.average,
    queryKey: [
      "staffAverageRating",
      brandId,
      ...formik.values.branchIds,
      ...formik.values.experienceTypeIds,
      ...formik.values.staff.map((s) => s.staffId),
      currentPeriod.start.toISOString(),
      currentPeriod.end.toISOString(),
    ],
  });

  const { data: staffRatingCount } = useQuery({
    queryFn: async () =>
      show &&
      formik.values.branchIds.length &&
      formik.values.experienceTypeIds.length &&
      formik.values.period &&
      (
        await TeamRewards.getTotalRatings({
          brand_id: brandId,
          filter: {
            branch_id: formik.values.branchIds,
            experience_type_id: formik.values.experienceTypeIds,
            staff_id: formik.values.staff.map((s) => s.staffId),
            date: currentPeriod,
          },
        })
      ).data?.at(0)?.total,
    queryKey: [
      "staffRatingCount",
      brandId,
      ...formik.values.branchIds,
      ...formik.values.experienceTypeIds,
      ...formik.values.staff.map((s) => s.staffId),
      currentPeriod.start.toISOString(),
      currentPeriod.end.toISOString(),
    ],
  });

  const { data: prevStaffRatingCount } = useQuery({
    queryFn: async () =>
      show &&
      formik.values.branchIds.length &&
      formik.values.experienceTypeIds.length &&
      formik.values.period &&
      (
        await TeamRewards.getTotalRatings({
          brand_id: brandId,
          filter: {
            branch_id: formik.values.branchIds,
            experience_type_id: formik.values.experienceTypeIds,
            staff_id: formik.values.staff.map((s) => s.staffId),
            date: prevPeriod,
          },
        })
      ).data?.at(0)?.total,
    queryKey: [
      "staffRatingCount",
      brandId,
      ...formik.values.branchIds,
      ...formik.values.experienceTypeIds,
      ...formik.values.staff.map((s) => s.staffId),
      prevPeriod.start.toISOString(),
      prevPeriod.end.toISOString(),
    ],
  });

  useEffect(() => {
    dispatch(getBranchesByBrandId(brandId));
    dispatch(getExperienceTypeByBrandId(brandId));
    dispatch(
      getUsers({
        perPage: "",
        currentPage: "",
        sort: "",
        search: "",
        filter: "",
      })
    );
  }, [brandId]);

  useEffect(() => {
    const payload = { branch_id: formik.values.branchIds };
    dispatch(getStaffMembers(brandId, payload));
  }, [brandId, formik.values.branchIds]);

  useEffect(() => {
    !show && formik.resetForm();
    show && initialReward && formik.setValues(initialReward);
  }, [show, initialReward]);

  return (
    <Modal show={show} className={"modal-lg " + styles.modal}>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          {initialReward ? t("THE_UPDATE_REWARD") : t("NEW_REWARD")}
        </Modal.Title>
        <Button className="close_btn" onClick={() => onSubmit()}>
          <FontAwesomeIcon icon={`xmark`} />
        </Button>
      </Modal.Header>
      {initialRewardLoading ? (
        <Spinner className={styles.lgSpinner} />
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body className={styles.body}>
            <div className={styles.grid}>
              <div className={styles.inputGroup}>
                <Form.Label htmlFor="name">{t("NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  className={`${styles.input} ${
                    formik.touched.name && formik.errors.name && "is-invalid"
                  }`}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.name}
                  </div>
                )}
                <br />
                <Select
                  name="period"
                  label="PERIOD"
                  options={periods.map((v) => ({
                    value: v,
                    label: t(v.toUpperCase()),
                  }))}
                  selected={formik.values.period}
                  onBlur={formik.handleBlur}
                  onChange={(p) => formik.setFieldValue("period", p)}
                  title={t(
                    formik.values.period?.toUpperCase() || "SELECT_PERIOD"
                  )}
                />
                {formik.touched.period && formik.errors.period && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.period}
                  </div>
                )}
                <br />
                <DatePicker
                  name="startDate"
                  label={"START_DATE"}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.startDate && new Date(formik.values.startDate)
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "startDate",
                      date && date.toISOString()
                    )
                  }
                  onApply={(date) =>
                    formik.setFieldValue(
                      "startDate",
                      date && date.toISOString()
                    )
                  }
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.startDate}
                  </div>
                )}
              </div>
              <div className={styles.inputGroup}>
                <Select
                  multiple
                  name="branchIds"
                  label="BRANCHES"
                  options={branches.map((v) => ({
                    value: v._id,
                    label: v.name,
                  }))}
                  selected={formik.values.branchIds}
                  onBlur={formik.handleBlur}
                  onChange={(p) => formik.setFieldValue("branchIds", p)}
                  title={t(
                    formik.values.branchIds.length
                      ? `${formik.values.branchIds.length} ${t(
                          formik.values.branchIds.length > 1
                            ? "BRANCHES"
                            : "BRANCH"
                        )}`
                      : "SELECT_BRANCHES"
                  )}
                />
                {formik.touched.branchIds && formik.errors.branchIds && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.branchIds}
                  </div>
                )}
                <br />
                <Select
                  name="experienceTypeIds"
                  label="EXPERIENCE_TYPES"
                  options={experienceTypeData?.map((exp) => ({
                    value: exp._id,
                    label: exp.title,
                  }))}
                  selected={formik.values.experienceTypeIds}
                  multiple
                  onBlur={formik.handleBlur}
                  onChange={(exps) =>
                    formik.setFieldValue("experienceTypeIds", exps)
                  }
                  title={t(
                    formik.values.experienceTypeIds.length
                      ? `${formik.values.experienceTypeIds.length} ${t(
                          formik.values.experienceTypeIds.length > 1
                            ? "EXPERIENCE_TYPES"
                            : "EXPERIENCE_TYPE"
                        )}`
                      : "SELECT_EXPERIENCE_TYPE"
                  )}
                />
                {formik.touched.experienceTypeIds &&
                  formik.errors.experienceTypeIds && (
                    <div className="invalid-feedback d-block">
                      {formik.errors.experienceTypeIds}
                    </div>
                  )}
                <br />
                <Select
                  options={[...new Array(24)].map((_, i) => ({
                    value: i,
                    label: formateTime(dateFromHour(i)),
                  }))}
                  label="SENDING_HOUR"
                  name="sendingHour"
                  title={
                    formik.values.sendingHour === undefined
                      ? t("SELECT_HOUR")
                      : formateTime(new Date(formik.values.sendingHour))
                  }
                  onBlur={formik.handleBlur}
                  onChange={(v) =>
                    formik.setFieldValue(
                      "sendingHour",
                      dateFromHour(v).toISOString()
                    )
                  }
                  icon={<ClockIcon />}
                />
                {formik.touched.sendingHour && formik.errors.sendingHour && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.sendingHour}
                  </div>
                )}
              </div>
            </div>
            <div>
              <Form.Label>{t("REWARD_MESSAGE")}</Form.Label>
              <Form.Control
                type="textarea"
                as={"textarea"}
                rows={5}
                name="rewardMessage"
                onBlur={formik.handleBlur}
                className={`${styles.textarea} ${
                  formik.touched.rewardMessage &&
                  formik.errors.rewardMessage &&
                  "is-invalid"
                }`}
                value={formik.values.rewardMessage}
                onChange={formik.handleChange}
              />
              {formik.touched.rewardMessage && formik.errors.rewardMessage && (
                <div className="invalid-feedback d-block">
                  {formik.errors.rewardMessage}
                </div>
              )}
            </div>
            <FormikProvider value={formik}>
              <FieldArray
                name="targets"
                render={(helpers) => (
                  <div>
                    <Form.Label>{t("TARGETS")}</Form.Label>
                    {formik.values.targets.map((target, i) => (
                      <TargetRow
                        currentValues={{
                          averageRating,
                          ratingCount,
                          staffAverageRating,
                          staffRatingCount,
                        }}
                        pastValues={{
                          ratingCount: prevRatingCount,
                          staffRatingCount: prevStaffRatingCount,
                        }}
                        name={`targets.${i}`}
                        key={i}
                        index={i}
                        onChange={(t) => helpers.replace(i, t)}
                        value={target}
                        onRemove={() => {
                          helpers.remove(i);
                        }}
                      />
                    ))}
                    <button
                      type="button"
                      className={styles.iconBtn}
                      onClick={() => helpers.push(DEFAULT_TARGET)}
                    >
                      <FontAwesomeIcon icon={faPlusSquare} />
                      {t("ADD_NEW_TARGET")}
                    </button>
                  </div>
                )}
              />
              <FieldArray
                name="staff"
                render={(helpers) => (
                  <div>
                    <Form.Label>{t("STAFF")}</Form.Label>
                    <header className={styles.tableHeader}>
                      <span>#</span>
                      <span>{t("STAFF")}</span>
                      <span>{t("EMAIL")}</span>
                    </header>
                    {formik.values.staff.map((staff, i) => (
                      <StaffRow
                        key={i}
                        handleBlur={formik.handleBlur}
                        onChange={(staff) => helpers.replace(i, staff)}
                        value={staff}
                        staff={members || []}
                        filterStaff={(s) =>
                          !formik.values.staff.find(
                            ({ staffId }) => staffId === s._id
                          )
                        }
                        index={i}
                        name={`staff.${i}`}
                        onRemove={() => helpers.remove(i)}
                        touched={formik.touched.staff?.at(i)}
                        error={formik.errors.staff?.at(i)}
                      />
                    ))}
                    <button
                      type="button"
                      className={styles.iconBtn}
                      onClick={() =>
                        helpers.push({ staffId: undefined, email: undefined })
                      }
                    >
                      <FontAwesomeIcon icon={faPlusSquare} />
                      {t("ADD_NEW_STAFF")}
                    </button>
                  </div>
                )}
              />
              <FieldArray
                name="users"
                render={(helpers) => (
                  <div>
                    <Form.Label>{t("USERS")}</Form.Label>
                    <header className={styles.tableHeader}>
                      <span>#</span>
                      <span>{t("USER")}</span>
                      <span>{t("EMAIL")}</span>
                    </header>
                    {formik.values.users.map((userId, i) => (
                      <UserRow
                        key={i}
                        handleBlur={formik.handleBlur}
                        onChange={(id) => helpers.replace(i, id)}
                        value={userId}
                        users={users || []}
                        filterUsers={(u) =>
                          !formik.values.users.includes(u._id)
                        }
                        index={i}
                        name={`users.${i}`}
                        onRemove={() => helpers.remove(i)}
                        touched={formik.touched.users?.at(i)}
                        error={formik.errors.users?.at(i)}
                      />
                    ))}
                    <button
                      type="button"
                      className={styles.iconBtn}
                      onClick={() => helpers.push(undefined)}
                    >
                      <FontAwesomeIcon icon={faPlusSquare} />
                      {t("ADD_NEW_USER")}
                    </button>
                  </div>
                )}
              />
              <FieldArray
                name="emails"
                render={(helpers) => (
                  <div>
                    <Form.Label>{t("EMAILS")}</Form.Label>
                    <header className={styles.tableHeader}>
                      <span>#</span>
                      <span>{t("NAME")}</span>
                      <span>{t("EMAIL")}</span>
                    </header>
                    {formik.values.emails.map((email, i) => (
                      <EmailRow
                        key={i}
                        handleBlur={formik.handleBlur}
                        handleChange={formik.handleChange}
                        value={email}
                        index={i}
                        name={`emails.${i}`}
                        onRemove={() => helpers.remove(i)}
                        touched={formik.touched.emails?.at(i)}
                        error={formik.errors.emails?.at(i)}
                      />
                    ))}
                    <button
                      type="button"
                      className={styles.iconBtn}
                      onClick={() =>
                        helpers.push({ email: undefined, name: undefined })
                      }
                    >
                      <FontAwesomeIcon icon={faPlusSquare} />
                      {t("ADD_NEW_EMAIL")}
                    </button>
                  </div>
                )}
              />
            </FormikProvider>
          </Modal.Body>
          <Modal.Footer className={styles.footer}>
            <Button type="submit" className="btn" disabled={loading}>
              {loading ? (
                <Spinner className={styles.spinner} size="sm" />
              ) : (
                t("SAVE")
              )}
            </Button>
            <Button
              className="btn btn-outline-secondary"
              onClick={() => {
                onSubmit();
                formik.resetForm();
              }}
            >
              {t("CANCEL")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}

export default TeamRewardModal;
