import React, { useEffect, useState, lazy } from "react";
import './brandreport.scss';
import { useTranslation } from "react-i18next";
import star from '../../../assets/images/starOne.svg'
import UpArrow from '../../../assets/images/Up_Arrow.svg';
import DownArrow from '../../../assets/images/Down__Arrow.svg'
import { useDispatch, useSelector } from "react-redux";
import { getReportsById } from '../../../middlewares/detailReport';
import { getExperienceTypeByBrandId } from "../../../middlewares/experienceType";
import { getBranchesByBrandId } from "../../../middlewares/branches";
import { getOneBrandToUpdate } from '../../../middlewares/brands'
import { getQuickOverviewReport, getBranchReport, getCategoryReport, getProductReport, getStaffReport, getFeedBackReport, getFeedbackDataForModal } from "../../../middlewares/summaryReport";
import CustomerFeedbackModalReport from "./feedBackModal";
import { Button } from "react-bootstrap";
import PlaceholderLoader from "../../user-listing/PlaceholderLoader";

const SummaryReport = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = window.location.href
  const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
  const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
  const date = new Date(dateInSeconds * 1000);
  const brand_id = url.split('/')[5];
  const report_id = url.split('/')[6];
  const [isShow, setIsShow] = useState(false);
  const { singleReport } = useSelector(
    (state) => state.detailReport
  );

  const {
    quickOverview,
    branchesReport,
    categoryReport,
    productReport,
    staffReport,
    feedBackReport,
    feedbackModal
  } = useSelector((state) => state.summaryReport);

  const matchingBranches = branchesReport?.filter(branch => singleReport?.report[0].branch_id.includes(branch._id));
  matchingBranches?.sort((a, b) => {
    if (a.rating_avg > b.rating_avg) {
      return -1;
    } else if (a.rating_avg < b.rating_avg) {
      return 1;
    } else {
      if (a.rating_count > b.rating_count) {
        return -1;
      } else if (a.rating_count < b.rating_count) {
        return 1;
      } else {
        return 0;
      }
    }
  });
  const highestRatedBranch = matchingBranches[0];


  const categoryComparisonData = categoryReport

  // Initialize variables to hold highest and lowest values and their corresponding elements
  let highestRatingAvg = -Infinity;
  let lowestRatingAvg = Infinity;
  let highestRatingElement = null;
  let lowestRatingElement = null;

  // Loop through each entry in the categoryComparisonData
  if (categoryComparisonData) {
    for (const entry of categoryComparisonData) {
      for (const data of entry.data) {
        if (data.rating_avg > highestRatingAvg) {
          highestRatingAvg = data.rating_avg;
          highestRatingElement = data;
        }
        if (data.rating_avg < lowestRatingAvg) {
          lowestRatingAvg = data.rating_avg;
          lowestRatingElement = data;
        }
      }
    }
  }

  let highestRatedProduct = null;
  let lowestRatedProduct = null;

  productReport?.forEach((item) => {
    if (item.ratings.length >= 1) {
      if (highestRatedProduct === null || item.rating_avg > highestRatedProduct.rating_avg) {
        highestRatedProduct = item;
      }
      if (lowestRatedProduct === null || item.rating_avg < lowestRatedProduct.rating_avg) {
        lowestRatedProduct = item;
      }
    }
  });

  let highestStaff = null;
  let lowestStaff = null;

  if (staffReport) {
    staffReport?.forEach((item) => {
      if (item.ratings.length >= 1) {
        if (highestStaff === null || item.rating_avg > highestStaff.rating_avg) {
          highestStaff = item;
        }
        if (lowestStaff === null || item.rating_avg < lowestStaff.rating_avg) {
          lowestStaff = item;
        }
      }
    });
  }

  useEffect(() => {
    if (brand_id && singleReport) {
      const filter = {
        branch_id: singleReport?.report[0].branch_id,
        experience_type_id: singleReport?.report[0].experience_type_id,
        rating: [],
        category_id: [],
        staff_id: [],
        date: [null, null]
      };
      const payload = {
        report_id: singleReport?.report[0]._id,
        filter: filter,
        reportDate: date,
      };
      dispatch(getQuickOverviewReport(payload, brand_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand_id, singleReport]);


  useEffect(() => {
    if (brand_id && singleReport) {
      const filter = {
        experience_key: singleReport?.report[0].experience_type_id,
        rating: { min: 0, max: 5 },
        branch_id: singleReport?.report[0].branch_id,
      }
      const payload = {
        b_id: brand_id,
        report_id: singleReport?.report[0]._id,
        filter: filter,
        reportDate: date,
      }
      dispatch(getStaffReport(payload))
    }
  }, [brand_id, singleReport])

  useEffect(() => {
    if (brand_id && singleReport) {
      const filter = {
        experience_type_id: singleReport?.report[0].experience_type_id,
        rating: [],
        branch_id: singleReport?.report[0].branch_id,
        staff_id: []
      };
      const payload = {
        brand_id: brand_id,
        report_id: singleReport?.report[0]._id,
        filter: filter,
        reportDate: date,
      };
      dispatch(getFeedBackReport(payload));
    }
  }, [brand_id, singleReport]);


  useEffect(() => {
    if (brand_id && singleReport) {
      const filter = {
        experience_key: singleReport?.report[0].experience_type_id,
        rating: { min: 0, max: 5 },
        branch_id: singleReport?.report[0].branch_id,
      }
      const payload = {
        b_id: brand_id,
        filter: filter,
        reportDate: date,
        report_id: singleReport?.report[0]._id,
      }
      dispatch(getProductReport(payload))
    }
  }, [brand_id, singleReport])

  useEffect(() => {
    if (brand_id && singleReport) {
      const filterData = {
        branch_id: singleReport?.report[0].branch_id,
        experience_type_id: singleReport?.report[0].experience_type_id,
        rating: [],
        category_id: [],
        staff_id: [],
        date: [null, null]
      }
      const payload = {
        brand_id: brand_id,
        filter: filterData,
        report_id: singleReport?.report[0]._id,
        reportDate: date,
      };
      dispatch(getBranchReport(payload));
      dispatch(getCategoryReport(payload))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand_id, singleReport]);


  useEffect(() => {
    if (brand_id && report_id) {
      dispatch(getReportsById(brand_id, report_id))
    }
  }, [brand_id, report_id]);


  const { highest, lowest } = feedBackReport.reduce(
    (result, item) => {
      if (item.rating_avg > result.highest.rating_avg) {
        result.highest = item;
      }
      if (item.rating_avg < result.lowest.rating_avg) {
        result.lowest = item;
      }
      return result;
    },
    { highest: { rating_avg: 0 }, lowest: { rating_avg: 0 } }
  );

  const handleModalOpen = (e) => {
    setIsShow(true);
    dispatch(getFeedbackDataForModal(e));
  };


  const handleButtonClick = () => {
    const url = 'https://app.staging.trustree.com';
    window.open(url, '_blank');
  };

  // const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad it with a leading zero if needed
  // const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (adding 1 because months are zero-based) and pad it with a leading zero if needed
  // const year = date.getFullYear(); // Get the full year

  // const formattedDate = `${day}-${month}-${year}`;
  // console.log(formattedDate);

  return (
    <>
      {
        (singleReport && singleReport !== null && singleReport.report[0].status === true && singleReport.report[0].report_type === "summery_report") ?
          singleReport.report.map((o, idx) => {
            return (
              <>
                <div className="brand_report">
                  <div className="content_head">
                    <li>
                      <img
                        src={
                          o.brand_name[0] ?
                            `${process.env.REACT_APP_BASE_URL}${o.brand_name[0]}`
                            :
                            "https://res.cloudinary.com/dhpvia1ae/image/upload/v1693831105/Group_826_oyntqe.png"
                        }
                        height={48}
                        width={48}
                        style={{ borderRadius: "25px" }}
                        alt="logo"
                      />
                    </li>
                    <div style={{ marginTop: "10px" }}>
                      <strong className="brand_name">{t(o.title)}</strong>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <span className="brand_name">{t(o.report_send === "day" ? "Daily Report" : "Weekly Report")} : {(o.report_send === "day" ? `${(date.getDate() - 1)}-${(date.getMonth() + 1)}-${(date.getFullYear())}` : `${(date.getDate() - 7)}-${(date.getMonth() + 1)}-${(date.getFullYear())} to ${(date.getDate() - 1)}-${(date.getMonth() + 1)}-${(date.getFullYear())}`)}</span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <span className="experience_type" style={{ marginRight: "5px" }}>
                        {t(`EXPERIENCE_TYPES`)} ({o.experience_type_names.length}):
                      </span>
                      {
                        o.experience_type_names.map((name, indx) => {
                          return (
                            <span className="experience_type" >
                              {name}
                              {indx < o.experience_type_names.length - 1 ? ', ' : ''}
                            </span>
                          )
                        })
                      }
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      <span className="experience_type" style={{ marginRight: "5px" }}>
                        {t(`BRANCHES`)} ({o.branch_names.length}):
                      </span>
                      {
                        o.branch_names.map((name, indx) => {
                          return (
                            <span className="experience_type" >
                              {name}
                              {indx < o.branch_names.length - 1 ? ', ' : ''}
                            </span>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"OVERALL_RATING"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {quickOverview?.average_rating.toFixed(2) ? quickOverview?.average_rating.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <div className={quickOverview?.percentageChange < 0 ? "decrement_status" : "increment_status"}>
                          {quickOverview?.percentageChange < 0 ? (
                            <img src={DownArrow} alt="" />
                          ) : (
                            <img src={UpArrow} alt="" />
                          )}
                          <span className={quickOverview?.percentageChange < 0 ? "decrement_count" : "increment_count"}>
                            {quickOverview?.percentageChange ? Math.abs(quickOverview?.percentageChange).toFixed(0) : 0}%
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"NUMBER_OF_RATING"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {quickOverview?.feedback_count ? quickOverview?.feedback_count : 0}
                        <div className={quickOverview?.feedbackCountCountPercentageChange < 0 ? "decrement_status" : "increment_status"}>
                          {quickOverview?.feedbackCountCountPercentageChange < 0 ? (
                            <img src={DownArrow} alt="" />
                          ) : (
                            <img src={UpArrow} alt="" />
                          )}
                          <span className={quickOverview?.feedbackCountCountPercentageChange < 0 ? "decrement_count" : "increment_count"}>
                            {quickOverview?.feedbackCountCountPercentageChange ? Math.abs(quickOverview?.feedbackCountCountPercentageChange).toFixed(0) : 0}%
                          </span>
                        </div>
                      </div>
                    </div>
                    {
                      o.branch_id.length > 1 &&
                      <div className="summary_table">
                        <div className="summary_title">
                          <strong className="detail_name">
                            {t(`${"BEST_BRANCH"}`)}
                          </strong>
                        </div>
                        <div className="summary_name">
                          {highestRatedBranch?.rating_avg.toFixed(2) ? highestRatedBranch?.rating_avg.toFixed(2) : 0}
                          <img src={star} alt="" />
                          <span>
                            ({highestRatedBranch?.rating_count ? highestRatedBranch?.rating_count : 0})
                          </span>
                          <div className="detail_name">
                            {highestRatedBranch?.name}
                          </div>
                        </div>
                      </div>
                    }
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"HIGHEST_CATEGORY"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {highestRatingElement?.rating_avg.toFixed(2) ? highestRatingElement?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <span>
                          ({highestRatingElement?.rating_count ? highestRatingElement?.rating_count : 0})
                        </span>
                        <div className="detail_name">
                          {highestRatingElement?.category_name?.en}
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"LOWEST_CATEGORY"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {lowestRatingElement?.rating_avg.toFixed(2) ? lowestRatingElement?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <span>
                          ({lowestRatingElement?.rating_count ? lowestRatingElement?.rating_count : 0})
                        </span>
                        <div className="detail_name">
                          {lowestRatingElement?.category_name?.en}
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"HIGHEST_PRODUCT"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {highestRatedProduct?.rating_avg.toFixed(2) ? highestRatedProduct?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <span>
                          ({highestRatedProduct?.ratings?.length ? highestRatedProduct?.ratings?.length : 0})
                        </span>
                        <div className="detail_name">
                          {highestRatedProduct?.name.en}
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"LOWEST_PRODUCT"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {lowestRatedProduct?.rating_avg.toFixed(2) ? lowestRatedProduct?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <span>
                          ({lowestRatedProduct?.ratings?.length ? lowestRatedProduct?.ratings?.length : 0})
                        </span>
                        <div className="detail_name">
                          {lowestRatedProduct?.name.en}
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"BEST_EMPLOYEE"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {highestStaff?.rating_avg.toFixed(2) ? highestStaff?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <span>
                          ({highestStaff?.ratings?.length ? highestStaff?.ratings?.length : 0})
                        </span>
                        <div className="detail_name">
                          {highestStaff?.name.en}
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"LOWEST_EMPLOYEE"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {lowestStaff?.rating_avg.toFixed(2) ? lowestStaff?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        <span>
                          ({lowestStaff?.ratings.length ? lowestStaff?.ratings.length : 0})
                        </span>
                        <div className="detail_name">
                          {lowestStaff?.name.en}
                        </div>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"HIGHEST_FEEDBACK"}`)}
                        </strong>
                      </div>
                      <div className="summary_name" >
                        {highest?.rating_avg.toFixed(2)}
                        <img src={star} alt="" />
                        {
                          highest?._id &&
                          <div style={{ marginLeft: "15px" }} onClick={() => {
                            handleModalOpen(highest?._id)
                          }}>
                            <strong className="view_button">{t("View")}</strong>
                          </div>
                        }
                        {/* <div className="decrement_status">
                          <img src={DownArrow} alt="" />
                          <span className="decrement_count">
                            {t("4%")}
                          </span>
                        </div> */}
                        {
                          highest?._id &&
                          <div className="increment_status">
                            <img src={UpArrow} alt="" />
                            <span className="increment_count">
                              {t("0%")}
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"LOWEST_FEEDBACK"}`)}
                        </strong>
                      </div>
                      <div className="summary_name" >
                        {lowest?.rating_avg.toFixed(2) ? lowest?.rating_avg.toFixed(2) : 0}
                        <img src={star} alt="" />
                        {
                          lowest?.id &&
                          <div style={{ marginLeft: "15px" }} onClick={() => handleModalOpen(lowest?._id)}>
                            <strong className="view_button">{t("View")}</strong>
                          </div>
                        }
                        {
                          lowest?.id &&
                          <div className="decrement_status">
                            <img src={DownArrow} alt="" />
                            <span className="decrement_count">
                              {t("0%")}
                            </span>
                          </div>
                        }
                        {/* <div className="increment_status">
                          <img src={UpArrow} alt="" />
                          <span className="increment_count">
                            {t("12%")}
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_title">
                        <strong className="detail_name">
                          {t(`${"UNRESOLVED_FEEDBACK"}`)}
                        </strong>
                      </div>
                      <div className="summary_name">
                        {quickOverview?.unresolved_count ? quickOverview?.unresolved_count : 0}
                        <div className={quickOverview?.unresolved_count_percentage_change < 0 ? "decrement_status" : "increment_status"}>
                          {quickOverview?.unresolved_count_percentage_change < 0 ? (
                            <img src={DownArrow} alt="" />
                          ) : (
                            <img src={UpArrow} alt="" />
                          )}
                          <span className={quickOverview?.unresolved_count_percentage_change < 0 ? "decrement_count" : "increment_count"}>
                            {quickOverview?.unresolved_count_percentage_change ? Math.abs(quickOverview?.unresolved_count_percentage_change).toFixed(0) : 0}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  isShow && (
                    <CustomerFeedbackModalReport
                      show={isShow}
                      setIsShow={(flag) => setIsShow(flag)}
                      feedbackData={feedbackModal}
                    />
                  )
                }
              </>
            )
          })
          :
          <>
            <div className="mt-3">
              <PlaceholderLoader />
            </div>

            {
              (singleReport?.report[0].status === false || singleReport?.report[0]?.report_type === "detailed_report") &&
              <div className="contentWrap d-flex flex-column justify-content-center" style={{ padding: "25px", textAlign: "center", fontSize: "30px" }}>
                The Report has been disabled!
                <Button
                  type="btn"
                  className="btn"
                  onClick={handleButtonClick}
                  style={{ width: "15%", margin: "20px auto", }}
                >
                  {'View More'}
                </Button>
              </div>
            }
          </>
      }

    </>
  );
};

export default SummaryReport;
