import { get, post} from ".";

const URI = "/brands/detail-report";

const getPerformanceChartData = (payload) => {
  const URL = `${URI}/performance-chart`;
  return post(URL, payload);
};

const getBranchData = (payload) => {
  const URL = `${URI}/branch-comparison`;
  return post(URL, payload);
};

const getCategoriesListingReport = (payload) => {
  const URL = `${URI}/category-comparison`;
  return post(URL, payload);
};

const getStaffListing = (payload) => {
  const URL = `${URI}/staff-comparison`;
  return post(URL, payload);
};

const getReportsById = (brand_id, report_id) => {
  const URL = `${URI}/${brand_id}/${report_id}`;
  return get(URL);
};

const getAdditionalQuestion = (payload) => {
  const URL = `${URI}/additional-questions`;
  return post(URL, payload);
};

const DetailReport = {
  getPerformanceChartData,
  getBranchData,
  getCategoriesListingReport,
  getStaffListing,
  getReportsById,
  getAdditionalQuestion
};
export default DetailReport;
