import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import starOne from "../../../assets/images/starOne.svg";
import expImg from "../../../assets/images/exp-img.png";
import { getBranchesForReport } from "../../../middlewares/detailReport";

const BranchesComparisonReport = ({ selectBrand, report }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { branchesReport } = useSelector(
        (state) => state.detailReport
    );
    const url = window.location.href
    const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
    const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
    const date = new Date(dateInSeconds * 1000);
    const { deleteFeedbackMessage } = useSelector((state) => state.feedbacks);
    const [sort, setSort] = useState([
        {
            key: "branch",
            column: "branch",
            value: "Branch",
            order: -1,
            activeSort: true,
        },
        {
            key: "rating",
            column: "rating",
            value: "Rating",
            order: -1,
            activeSort: false,
        },
        {
            key: "ratings",
            column: "ratings",
            value: "RATINGS",
            order: -1,
            activeSort: false,
        },
        {
            key: "low_category",
            column: "low_category",
            value: "Low Catgeory",
            order: -1,
            activeSort: false,
        },
        {
            key: "best_category",
            column: "best_category",
            value: "Best Catgeory",
            order: -1,
            activeSort: false,
        },
    ]);

    useEffect(() => {
        if (selectBrand && report) {
            const filter = {
                experience_type_id: report ? report.report[0].experience_type_id : [],
                rating: [],
                branch_id: report ? report.report[0].branch_id : [],
                category_id: [],
                staff_id: [],
                date: [null, null]
            }
            const sortObj = sort.find((o) => o.activeSort === true);
            const payload = {
                brand_id: selectBrand,
                sort: sortObj,
                filter: filter,
                report_id: report.report[0]._id,
                reportDate: date,
            };
            dispatch(getBranchesForReport(payload));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectBrand, report]);

    const handleSort = (key) => {
        let sortObj = {};

        const newSortOrder = sort.map((o) => {
            if (o.key === key) {
                const newObj = {
                    ...o,
                    order: o.order === 1 ? -1 : 1,
                    activeSort: true,
                };
                sortObj = { ...newObj, column: newObj.key };
                return newObj;
            }
            return { ...o, order: -1, activeSort: false };
        });
        setSort(newSortOrder);
        const filter = {
            experience_type_id: report ? report.report[0].experience_type_id : [],
            rating: [],
            branch_id: report ? report.report[0].branch_id : [],
            category_id: [],
            staff_id: [],
            date: [null, null]
        }
        const payload = {
            brand_id: selectBrand,
            sort: sortObj,
            filter: filter,
            report_id: report?.report[0]._id,
            reportDate: date
        };
        dispatch(getBranchesForReport(payload));
    };

    return (
        <div className="box">
            <h2>
                <strong>{t("BRANCHES_COMPARISON")}</strong>
            </h2>
            {(branchesReport && branchesReport.length > 0) ? (
                <div className="PerformanceGraph customerSuggestions content  mCustomScrollbar scrollbar">
                    <table className="table">
                        <thead>
                            <tr>
                                {sort &&
                                    sort.map((obj, index) => (
                                        <th key={obj.key} className="width260">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleSort(obj.key)}
                                            >
                                                {t(obj.value)}
                                                {obj.order !== -1 ? (
                                                    <FontAwesomeIcon key={index} icon={`angle-up`} />
                                                ) : (
                                                    <FontAwesomeIcon key={index} icon={`angle-down`} />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {branchesReport && branchesReport.length > 0 ? (
                                branchesReport.map((o, i) => (
                                    <tr key={i}>
                                        <td className="width160">
                                            <p>{o.name}</p>
                                        </td>
                                        <td>
                                            {o.rating_avg !== null ? o.rating_avg.toFixed(2) : 0}
                                            <em>
                                                <img src={starOne} alt="star One" />
                                            </em>
                                        </td>
                                        <td>{o.rating_count}</td>
                                        <td>
                                            {o.low_category
                                                ? o.low_category[t("language")]
                                                    ? o.low_category[t("language")]
                                                    : o.low_category[t("en")]
                                                : "---"}
                                        </td>
                                        <td>
                                            {o.best_category
                                                ? o.best_category[t("language")]
                                                    ? o.best_category[t("language")]
                                                    : o.best_category[t("en")]
                                                : "---"}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>{t("NO_DATA_FOUND")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="experience-wpr flex">
                    <div className="experience-inner">
                        <img src={expImg} alt="loadding" />
                        <p>{t("NO_FEEDBACKS_FOUND")}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BranchesComparisonReport;
