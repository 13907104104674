export const customer_data = [
  { value: "", label: "SELECT_CUSTOMER_DATA" },
  { value: "Ask", label: "ASK_CUSTOMER_TO_SHARE_HIS_DATA" },
  { value: "Must", label: "CUSTOMER_MUST_INPUT_HIS_DATA" },
  { value: "Anonymous", label: "ANONYMOUS_CUSTOMER" },
];

export const trustree_ratings = [
  { value: "", label: "SELECT_AUTO_RESOLVE_TRUSTREE_RATINGS" },
  { value: "1", label: "FROM_1_STAR" },
  { value: "2", label: "FROM_2_STAR" },
  { value: "3", label: "FROM_3_STAR" },
  { value: "4", label: "FROM_4_STAR" },
  { value: "5", label: "5_STAR" },
  { value: "Do Auto", label: "AUTO_RESOLVE_ALL" },
  { value: "Do Not", label: "DONT_AUTO_RESOLVE" },
];

export const email_notifications = [
  { value: "", label: "SELECT_EMAIL_NOTIFICATION" },
  { value: "1", label: "1_STAR" },
  { value: "2", label: "LESS_THAN_2_STAR" },
  { value: "3", label: "LESS_THAN_3_STAR" },
  { value: "4", label: "LESS_THAN_4_STAR" },
  { value: "5", label: "LESS_THAN_5_STAR" },
  { value: "Do All", label: "FOR_EVERY_FEEDBACK" },
  { value: "Do Not", label: "CANT_SEND_ANY_NOTIFICATION" },
];

export const google_ratings = [
  { value: "", label: "SELECT_AUTO_RESOLVE_GOOGLE_RATINGS" },
  { value: "1", label: "FROM_1_STAR" },
  { value: "2", label: "FROM_2_STAR" },
  { value: "3", label: "FROM_3_STAR" },
  { value: "4", label: "FROM_4_STAR" },
  { value: "5", label: "5_STAR" },
  { value: "Do Auto", label: "AUTO_RESOLVE_ALL" },
  { value: "Do Not", label: "DONT_AUTO_RESOLVE" },
];

export const valid_from_data = [
  { value: "", label: "SELECT_THE_DATA_REWARD" },
  { value: "same", label: "SAME_DAY" },
  { value: "next", label: "NEXT_DAY" },
];

export const brand_category_data = [
  { value: "", label: "SELECT_THE_BRAND_CATEGORY" },
  { value: "restaurant", label: "RESTAURANT" },
  { value: "cafe", label: "CAFE" },
];

export const report_type_data = [
  { value: "summery_report", label: "SUMMERY_REPORT" },
  { value: "detailed_report", label: "DETAILED_REPORT" },
];

export const send_every = [
  { value: "day", label: "DAY" },
  { value: "week", label: "WEEK" },
  { value: "month", label: "MONTH" },
  { value: "year", label: "YEAR" },
];

export const choice_data = [
  { value: "single", label: "ONE_ITEM" },
  { value: "multiple", label: "MULTIPLE_ITEMS" },
];

export const question_type_data = [
  { value: "single", label: "OPEN_ENDED" },
  { value: "multiple", label: "MULTIPLE_CHOICE" },
];

export const user_permission_data = [
  { value: "", label: "SELECT_USER_PERMISSION" },
  { value: "master", label: "MASTER" },
  { value: "editor", label: "EDITOR" },
  { value: "viewer", label: "VIEWER" },
];

export const rating_filter = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const select_rating = [
  "Brand Rating",
  "No of Brand Reviews",
  "Personal Rating",
  "No of Personal Reviews ",
];

export const select_status = [
  { value: "increased", label: "INCREASED" },
  { value: "reached", label: "REACHED" },
  // { value: "decrease", label: "DECREASED" },
];

export const select_status_rating = [
  { value: "reached", label: "REACHED" },
  // { value: "decrease", label: "DECREASED" },
];

export const select_type = [
  { value: "percentage", label: "PERCENTAGE" },
  { value: "fixed_value", label: "Certain Value" },
];

export const select_progress = [
  // { value: "min-of-all-time", label: "MIN_ALL_TIME" },
  // { value: "max-of-all-time", label: "MAX_ALL_TIME" },
  { value: "minimum_of", label: "MINIMUM" },
  // { value: "all-time-average", label: "ALL_TIME_AVERAGE" },
];

export const status_filter = [
  { value: "resolved", label: "Resolved" },
  { value: "unresolved", label: "UnResolved" },
];
