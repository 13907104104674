import React from "react";

const NotAvailableMobile = ({ text }) => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="">
          <svg
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="96"
              height="96"
              rx="48"
              fill="#2BBC91"
              fill-opacity="0.25"
            />
            <path
              d="M59.12 59.94H36.88C28.92 59.94 26.5 57.52 26.5 49.56V36.88C26.5 28.92 28.92 26.5 36.88 26.5H59.1C67.06 26.5 69.48 28.92 69.48 36.88V49.54C69.5 57.52 67.08 59.94 59.12 59.94ZM36.88 29.5C30.6 29.5 29.5 30.6 29.5 36.88V49.54C29.5 55.82 30.6 56.92 36.88 56.92H59.1C65.38 56.92 66.48 55.82 66.48 49.54V36.88C66.48 30.6 65.38 29.5 59.1 29.5H36.88Z"
              fill="#2BBC91"
            />
            <path
              d="M48 69.5014C47.18 69.5014 46.5 68.8214 46.5 68.0014V58.4414C46.5 57.6214 47.18 56.9414 48 56.9414C48.82 56.9414 49.5 57.6214 49.5 58.4414V68.0014C49.5 68.8214 48.82 69.5014 48 69.5014Z"
              fill="#2BBC91"
            />
            <path
              d="M68 51.5H28C27.18 51.5 26.5 50.82 26.5 50C26.5 49.18 27.18 48.5 28 48.5H68C68.82 48.5 69.5 49.18 69.5 50C69.5 50.82 68.82 51.5 68 51.5Z"
              fill="#2BBC91"
            />
            <path
              d="M57 69.5H39C38.18 69.5 37.5 68.82 37.5 68C37.5 67.18 38.18 66.5 39 66.5H57C57.82 66.5 58.5 67.18 58.5 68C58.5 68.82 57.82 69.5 57 69.5Z"
              fill="#2BBC91"
            />
          </svg>
        </div>
        <div className="mt-4">{ text }</div>
      </div>
    </>
  );
};

export default NotAvailableMobile;
