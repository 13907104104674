import styles from "./userRow.module.css";
import { Dropdown, Form } from "react-bootstrap";
import TrashIcon from "../icons/TrashIcon";
import { useState } from "react";

/**
 *
 * @typedef {{
 * _id: string
 * name: string
 * email: string
 * }} User
 * @param {{
 * index: number
 * name: string
 * value: string
 * users: User[]
 * filterUsers: (user: User) => boolean
 * error?: string
 * touched?:boolean
 * onRemove: () => void
 * onChange: (id: string) => void
 * handleBlur: (e) => void
 * }} props
 * @returns
 */
function UserRow({
  index,
  name,
  error,
  touched,
  handleBlur,
  onChange,
  value,
  users,
  filterUsers,
  onRemove,
}) {
  const [search, setSearch] = useState(null);
  const user = value && users.find((user) => user._id === value);
  return (
    <div className={styles.tableRow}>
      <span>{index + 1}</span>
      <span>
        <Dropdown
          onSelect={(id) => {
            onChange(id);
            setSearch((s) => users.find((user) => user._id === id)?.name || s);
          }}
        >
          <Dropdown.Toggle className={styles.dropdownToggle} as={"span"}>
            <Form.Control
              type="text"
              name={`${name}`}
              className={`${styles.input} ${touched && error && "is-invalid"}`}
              value={search ?? user?.name}
              onBlur={handleBlur}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {users
              .filter(
                (user) =>
                  user.name
                    .toLocaleLowerCase()
                    .includes(search?.toLocaleLowerCase()) && filterUsers(user)
              )
              .map((user) => (
                <Dropdown.Item
                  key={user._id}
                  eventKey={user._id}
                  className={styles.dropdownItem}
                >
                  {user.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>

        {touched && error && (
          <div className="invalid-feedback d-block">{error}</div>
        )}
      </span>
      <span>
        <Form.Control
          type="text"
          className={styles.input}
          disabled
          value={user?.email}
        />
      </span>
      <button
        type="button"
        className={styles.iconBtn}
        onClick={() => onRemove()}
      >
        <TrashIcon />
      </button>
    </div>
  );
}

export default UserRow;
