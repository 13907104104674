import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import styles from "./brandAccordion.module.css";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import ImageLoader from "../../components/imageLoader/ImageLoader";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import IconBtn from "./IconBtn";
import EditIcon from "../../components/icons/EditIcon";
import TrashIcon from "../../components/icons/TrashIcon";
import ArrowCircleIcon from "../../components/icons/ArrowCircleIcon";
import BranchModal from "../../components/branchModal/BranchModal";
import { v4 } from "uuid";

function AccordionHeader({ children, eventKey }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      className={`${styles.accordionHeader} ${
        isCurrentEventKey ? styles.accordionHeaderActive : ""
      }`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
/**
 *
 * @param {T[]} arr
 * @param {T} item
 * @param {(item:T) => boolean} eq
 */
function updateItem(arr = [], item, eq) {
  const idx = arr.findIndex((i) => eq(i));
  if (idx >= 0) {
    arr[idx] = { ...arr[idx], ...item };
  }
  return arr;
}

/**
 * @typedef {{branches?: import("./BranchModal").Branch[]} & import("./BrandModal").Brand} Brand
 * @param {{
 *  brand: Brand | import("../../services/onboarding").ExistingBrand,
 *  onChange: (brand:Brand) => void
 *  onUpdate: (branch: import("../../services/onboarding").ExistingBranch & {branchId: string}) => void
 *  existing: boolean
 *  existingBranches: {brandId: string, branches: import("../../components/branchModal/BranchModal").Branch[]}
 * }} props
 * @returns
 */
function BrandAccordion({
  brand,
  onChange,
  onUpdate,
  existing,
  existingBranches,
}) {
  const { t } = useTranslation();

  // Branches Modal
  const [editedBranch, setEditedBranch] = useState();
  const [branchModal, setBranchModal] = useState(false);

  return (
    <Accordion.Item eventKey={brand.id || brand._id}>
      <BranchModal
        show={branchModal}
        centered
        initialBranch={editedBranch}
        onSubmit={(branch) => {
          if (!branch) {
            setBranchModal(false);
            setEditedBranch();
            return;
          }
          if (editedBranch) {
            if (editedBranch._id) {
              onUpdate({ branch, branchId: editedBranch._id });
            } else {
              onChange({
                ...(existingBranches || brand),
                branches: updateItem(
                  existingBranches?.branches || brand.branches || [],
                  branch,
                  (a) => a.id === editedBranch.id
                ),
              });
            }
          } else {
            onChange({
              ...(existingBranches || brand),
              branches: [
                ...(existingBranches?.branches || brand.branches || []),
                { ...branch, id: v4() },
              ],
            });
          }
          setBranchModal(false);
          setEditedBranch();
        }}
      />
      <AccordionHeader eventKey={brand.id || brand._id}>
        <span className={styles.brandInfo}>
          <span className={styles.brandLogo}>
            {existing ? (
              <img
                src={`${process.env.REACT_APP_BASE_URL}${brand.logo}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require("../../assets/images/Brandlogo.png");
                }}
                alt="logo"
              />
            ) : (
              <ImageLoader
                img={brand.logo && brand.logo[0]}
                fallback="https://api.app.trustree.com/images/Brandlogo.png"
                alt="Brand Logo"
              />
            )}
          </span>
          <span>
            {brand.name}{" "}
            <span className={styles.lightText}>{`(${
              brand.branches?.length +
                (existingBranches?.branches?.length || 0) || 0
            })`}</span>
          </span>
        </span>
        <span className={styles.accordionChevron}>
          <ArrowCircleIcon />
        </span>
      </AccordionHeader>
      <Accordion.Body className={styles.accordionBody}>
        {brand.branches?.map((branch) => (
          <div key={branch.id || branch._id} className={styles.branchRow}>
            <div className={styles.branchInfo}>
              {branch.name}
              <span className={styles.lightText}>
                {branch.address ? ` - ${branch.address}` : ""}
              </span>
            </div>
            <div className={styles.branchActions}>
              <button
                type="button"
                className={styles.iconBtn}
                onClick={() => {
                  setEditedBranch(branch);
                  setBranchModal(true);
                }}
              >
                <EditIcon />
              </button>
              {!branch._id && (
                <button
                  type="button"
                  className={styles.iconBtn}
                  onClick={() =>
                    onChange({
                      ...brand,
                      branches: (brand.branches || []).filter(
                        (b) => b.id !== branch.id
                      ),
                    })
                  }
                >
                  <TrashIcon />
                </button>
              )}
            </div>
          </div>
        ))}
        {existingBranches?.branches?.map((branch) => (
          <div key={branch.id} className={styles.branchRow}>
            <div className={styles.branchInfo}>
              {branch.name}
              <span className={styles.lightText}>
                {branch.address ? ` - ${branch.address}` : ""}
              </span>
            </div>
            <div className={styles.branchActions}>
              <button
                type="button"
                className={styles.iconBtn}
                onClick={() => {
                  setEditedBranch(branch);
                  setBranchModal(true);
                }}
              >
                <EditIcon />
              </button>
              <button
                type="button"
                className={styles.iconBtn}
                onClick={() =>
                  onChange({
                    ...existingBranches,
                    branches: (existingBranches?.branches || []).filter(
                      (b) => b.id !== branch.id
                    ),
                  })
                }
              >
                <TrashIcon />
              </button>
            </div>
          </div>
        ))}
        <div className={styles.branchRow}>
          <IconBtn
            onClick={() => setBranchModal(true)}
            type="button"
            label={t("ADD_NEW_BRANCH_ONBOARDING")}
            icon={faPlusSquare}
          />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default BrandAccordion;
