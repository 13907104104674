import Questions from "../services/questions";
import {
  getQuestionsError,
  getQuestionsRequest,
  getQuestionsSuccess,
  addQuestionsError,
  addQuestionsRequest,
  addQuestionsSuccess,
  getOneQuestionToUpdateError,
  getOneQuestionToUpdateRequest,
  getOneQuestionToUpdateSuccess,
  updateQuestionsError,
  updateQuestionsRequest,
  updateQuestionsSuccess,
  disableQuestionError,
  disableQuestionRequest,
  disableQuestionSuccess,
  deleteQuestionsError,
  deleteQuestionsRequest,
  deleteQuestionsSuccess,
  updateSequenceRequest,
  updateSequenceSuccess,
  updateSequenceError,
  checkAvailabilityRequest,
  checkAvailabilitySuccess,
  checkAvailabilityError,
} from "../slices/question.slice";

export function getQuestions(select_id, payload) {
  return (dispatch) => {
    dispatch(getQuestionsRequest());
    Questions.getQuestions(select_id, payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getQuestionsSuccess(data));
        } else {
          dispatch(getQuestionsError(error));
        }
      })
      .catch((error) => {
        dispatch(getQuestionsError(error));
      });
  };
}

export function addQuestions(payload, select_id) {
  return (dispatch) => {
    dispatch(addQuestionsRequest());
    Questions.addQuestions(payload, select_id)
      .then((response) => {
        const { message, status, error } = response.data;
        if (status === 1) {
          dispatch(addQuestionsSuccess(message));
        } else {
          dispatch(addQuestionsError(error));
        }
      })
      .catch((error) => {
        dispatch(addQuestionsError(error));
      });
  };
}

export function getOneQuestionToUpdate(payload, survey_Id) {
  return (dispatch) => {
    dispatch(getOneQuestionToUpdateRequest());
    Questions.getOneQuestionToUpdate(payload, survey_Id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getOneQuestionToUpdateSuccess(data));
        } else {
          dispatch(getOneQuestionToUpdateError(error));
        }
      })
      .catch((error) => {
        dispatch(getOneQuestionToUpdateError(error));
      });
  };
}

export function updateQuestions(payload, survey_Id) {
  return (dispatch) => {
    dispatch(updateQuestionsRequest());
    Questions.updateQuestions(payload, survey_Id)
      .then((response) => {
        const { message, status, error } = response.data;
        if (status === 1) {
          dispatch(updateQuestionsSuccess(message));
        } else {
          dispatch(updateQuestionsError(error));
        }
      })
      .catch((error) => {
        dispatch(updateQuestionsError(error));
      });
  };
}

export function deleteQuestions(payload, survey_Id) {
  return (dispatch) => {
    dispatch(deleteQuestionsRequest());
    Questions.deleteQuestions(payload, survey_Id)
      .then((response) => {
        const { status, error, message } = response.data;
        if (status === 1) {
          dispatch(deleteQuestionsSuccess(message));
        } else {
          dispatch(deleteQuestionsError(error));
        }
      })
      .catch((error) => {
        dispatch(deleteQuestionsError(error));
      });
  };
}

export function disableQuestion(payload, survey_Id) {
  return (dispatch) => {
    dispatch(disableQuestionRequest());
    Questions.disableQuestion(payload, survey_Id)
      .then((response) => {
        const { status, error, message } = response.data;
        if (status === 1) {
          dispatch(disableQuestionSuccess(message));
        } else {
          dispatch(disableQuestionError(error));
        }
      })
      .catch((error) => {
        dispatch(disableQuestionError(error));
      });
  };
}

export function updateSequence(payload, survey_Id) {
  return (dispatch) => {
    dispatch(updateSequenceRequest());
    Questions.updateSequence(payload, survey_Id)
      .then((response) => {
        const { status, error, message, data } = response.data;
        if (status === 1) {
          dispatch(updateSequenceSuccess({ message, payload, data }));
        } else {
          dispatch(updateSequenceError(error));
        }
      })
      .catch((error) => {
        dispatch(updateSequenceError(error));
      });
  };
}

export function checkAvailabilityData(payload) {
  return (dispatch) => {
    dispatch(checkAvailabilityRequest());
    Questions.getCheckAvailability(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(checkAvailabilitySuccess(data));
        } else {
          dispatch(checkAvailabilityError(error));
        }
      })
      .catch((error) => {
        dispatch(checkAvailabilityError(error));
      });
  };
}
