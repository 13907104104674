import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import trashSquare from "../../assets/images/trash-square.svg";
import refreshSquare from "../../assets/images/refresh-square-2.svg";
import ImageLoader from "../../components/imageLoader/ImageLoader";

/**
 * @param {string} url
 * @returns
 */
async function getFileFromImageUrl(url) {
  let response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`);
  // for some reason, response.blob doesn't work in Chrome
  let data = await response.arrayBuffer();
  return new File([data], url);
}

/**
 *
 * @param {{ logo: string | File[], setFieldValue: (key: "logo", files: File[])=> void }} props
 * @returns
 */
function BrandImage({ setFieldValue, logo, multiple }) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    multiple,
    maxSize: 1048576, // 1 mb
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setFieldValue("logo", acceptedFiles);
    },
  });

  useEffect(() => {
    if (!logo) return;
    if (typeof logo === "string") {
      getFileFromImageUrl(logo).then((file) => setFiles([file]));
    } else setFiles(logo);
  }, [logo]);

  const removeFile = () => {
    setFiles([]);
    setFieldValue("logo", undefined);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} name="logo" />
        {files.length ? (
          files.map((f) => (
            <div key={f.name} className="modal-picture-uploaded">
              <div className="pictures">
                <ImageLoader
                  img={f}
                  fallback="https://api.app.trustree.com/images/Brandlogo.png"
                  alt="profile_pic"
                />
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="drop_box">
              <h4>
                {t("UPLOAD_FILE_FROM_FROM_YOUR_DEVICE")}{" "}
                <span>{t("OR_DRAG_FILE_HERE")}</span>
              </h4>
              <p>{t("PNG_JPG_GIF_UP_TO_1_MB")}</p>
            </div>
            {fileRejections && fileRejections.length <= 0 ? null : (
              <span className="invalid-feedback d-block">
                {t("FILE_TYPE_IS_NOT_SUPPORTED")}
              </span>
            )}
          </>
        )}
      </div>
      {files.length ? (
        <div className="prive">
          <ul className="flex ul-uploaded">
            <li onClick={removeFile} style={{ cursor: "pointer" }}>
              <img src={trashSquare} alt="trashSquare" />
            </li>
            <li onClick={handleClick} style={{ cursor: "pointer" }}>
              <input {...getInputProps()} name="profile_pic" ref={inputRef} />
              <img src={refreshSquare} alt="refreshSquare" />
            </li>
          </ul>
        </div>
      ) : null}
    </section>
  );
}

export default BrandImage;
