import React from "react";
import { Navigate } from "react-router";
import ROUTE_URLS from "../../config/routes";
import Payment from "../../services/payment";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import ToastService from "../../helpers/toast-services";
import { useSelector } from "react-redux";

const OnboardingGuard = ({ children }) => {
  const { data, isLoading, isError, error } = useQuery("subscription", {
    queryFn: Payment.getUserSubscription,
    onError: (err) => {
      err?.message && ToastService.error(err?.message);
    },
    retry: 3,
  });

  const { loggedInUser } = useSelector((state) => state.auth);

  if (!loggedInUser) return children;

  if (isLoading) return <Spinner style={{ color: "#2bbc91" }} />;

  // If error do nothing
  if (isError) return children;

  const isPermitted = loggedInUser?.permission === "owner";

  if (!data?.data?.data.length && isPermitted) return children;
  return <Navigate to={ROUTE_URLS.HOME} replace />;
};

export default OnboardingGuard;
