import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const MultipleQuestionModal = ({ multipleTypeQuestion, experience_type }) => {


    const { t } = useTranslation();
    const [isLabelName, setIsLabelName] = useState();
    const selectLang = localStorage.getItem("i18nextLng");

    useEffect(() => {
        if (multipleTypeQuestion?.options?.length) {
            const lableName =
                multipleTypeQuestion?.options?.length > 0 &&
                multipleTypeQuestion?.options?.map((o) => {
                    return o[t("language")] ? o[t("language")] : o[t("en")];
                });
            setIsLabelName(lableName);
        } else {
            const lableName = Object.keys(multipleTypeQuestion?.data);
            setIsLabelName(lableName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multipleTypeQuestion]);

    const dataValue =
        isLabelName &&
        isLabelName.length > 0 &&
        isLabelName.map((o) => {
            return multipleTypeQuestion?.data[o] ? multipleTypeQuestion?.data[o] : 0;
        });

    const data = {
        labels: isLabelName,
        datasets: [
            {
                label: "Count",
                data: dataValue ? dataValue : [],
                backgroundColor: [
                    "#FF8787",
                    "#F8C4B4",
                    "#E5EBB2",
                    "#BCE29E",
                    "#2BBC91",
                ],
                borderColor: [
                    "#FF8787",
                    "#F8C4B4",
                    "#E5EBB2",
                    "#BCE29E",
                    "#2BBC91",
                ],
                borderWidth: 1,
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        cutout: 100,
        layout: {
            padding: {
                responsive: true,
                maintainAspectRatio: true,
            },
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataValues = ctx.chart.data.datasets[0].data;
                    dataValues.map((data) => (sum += data));
                    let percentage = ((value * 100) / sum).toFixed(2);
                    return percentage === "0.0"
                        ? null
                        : percentage === "100.0"
                            ? (percentage = "100%")
                            : percentage + "%";
                },
                color: "#000",
                align: `${selectLang === "ar" ||
                        selectLang === "fa" ||
                        selectLang === "he" ||
                        selectLang === "ur"
                        ? "right"
                        : "center"
                    }`,
                padding: {
                    right: `${selectLang === "ar" ||
                            selectLang === "fa" ||
                            selectLang === "he" ||
                            selectLang === "ur"
                            ? 20
                            : 0
                        }`,
                },
            },
            legend: {
                rtl:
                    selectLang === "ar" ||
                        selectLang === "fa" ||
                        selectLang === "he" ||
                        selectLang === "ur"
                        ? true
                        : false,
                labels: {
                    usePointStyle: true,
                    pointStyle: "rectRounded",
                    boxWidth: 16,
                    boxHight: 16,
                    font: {
                        size: 14,
                    },
                },
                position: "bottom",
                title: {
                    display: true,
                },
            },
        },
    };

    
    return (
        <>
            <div className="box">
                <h2>
                    <strong>
                        {multipleTypeQuestion?.question[t("language")]
                            ? multipleTypeQuestion?.question[t("language")]
                            : multipleTypeQuestion?.question[t("en")]
                                ? multipleTypeQuestion?.question[t("en")]
                                : multipleTypeQuestion?.question}
                    </strong>{" "}
                    <span>({experience_type?.title})</span>
                </h2>
                <div className="OverallGraph">
                    <Doughnut data={data} options={options} />
                </div>
            </div>
        </>
    );
};

export default MultipleQuestionModal;
