import { createSlice, current } from "@reduxjs/toolkit";
import { CUSTOMER } from "./slice-names";

export const customerSlice = createSlice({
  name: CUSTOMER,
  initialState: {
    loading: true,
    customer: [],
    message: "",
    error: "",
    pagination: null,

    filter: {
      rating: { min: 0, max: 5 },
      last_rating: { min: 0, max: 5 },
      feedback: { min: 0, max: 5000 },
      experience_type: { min: 0, max: 5000 },
      brand: { min: 0, max: 5000 },
      date: [null, null],
    },
    customerFilter: {
      brand_id: [],
      branch_id: [],
      experience_type_id: [],
      rating: [],
      status: {
        unresolved: false,
        resolved: false,
      },
      date: [null, null],
    },
    isFilter: false,
    appliedFiltersCount: 0,
    appliedCustomerFilter: [],

    feedbackLoading: true,
    feedbackData: null,
    feedbackError: "",

    feedbackListLoading: true,
    feedbackListData: [],
    feedbackListError: "",

    deleteCustomerLoading: false,
    deleteCustomerMessage: null,
    deleteCustomerError: "",

    disableCustomerLoading: true,
    disableCustomerMessage: null,
    disableCustomerError: "",

    brandFilter: false,
    branchFilter: false,
    expTypeFilter: false,
    ratingFilter: false,
    statusFilter: false,
    dateFilter: false,
    isFilterApply: false,
    currentPage: 1,
  },
  reducers: {
    getCustomerDataRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getCustomerDataSuccess: (state, action) => {
      state.loading = false;
      state.customer = action.payload.data;
      state.pagination = action.payload.metaData;
    },
    getCustomerDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setFilterValue: (state, action) => {
      state.filter = { ...action.payload, is_active: true };
      state.appliedFiltersCount = getAppliedFiltersCount(action.payload);
    },
    clearFilter: (state, action) => {
      state.filter = { ...action.payload };
      state.appliedFiltersCount = 0;
    },
    getcustomerFeedbackDataRequest: (state) => {
      state.feedbackLoading = true;
      state.feedbackData = [];
      state.feedbackError = null;
    },
    getcustomerFeedbackDataSuccess: (state, action) => {
      state.feedbackLoading = false;
      state.feedbackData = action.payload;
    },
    getcustomerFeedbackDataError: (state, action) => {
      state.feedbackLoading = false;
      state.feedbackError = action.payload;
    },
    getCustomerFeedbackListRequest: (state) => {
      state.feedbackListLoading = true;
      state.feedbackListError = null;
    },
    getCustomerFeedbackListSuccess: (state, action) => {
      state.feedbackListLoading = false;
      const prevData = current(state.feedbackListData);
      const prevFilterLength = current(state.appliedCustomerFilter);
      if (prevData && prevData.length > 0 && prevFilterLength.length > 0) {
        const dataLength = action.payload.data.filter(
          (s) => s.feedback_id === action.payload.f_id
        );
        const data =
          action.payload &&
          action.payload.data &&
          action.payload.data.map((o, i) => {
            if (action.payload.f_id) {
              if (dataLength.length > 0) {
                if (o.feedback_id === action.payload.f_id) {
                  return {
                    isOpen: true,
                    ...o,
                  };
                } else {
                  return {
                    isOpen: false,
                    ...o,
                  };
                }
              } else if (i === 0) {
                return {
                  isOpen: true,
                  ...o,
                };
              } else {
                return {
                  isOpen: false,
                  ...o,
                };
              }
            } else {
              if (i === 0) {
                return {
                  isOpen: true,
                  ...o,
                };
              } else {
                return {
                  isOpen: false,
                  ...o,
                };
              }
            }
          });
        state.feedbackListData = data;
      } else {
        const dataLength = action.payload.data.filter(
          (s) => s.feedback_id === action.payload.f_id
        );
        const data =
          action.payload &&
          action.payload.data &&
          action.payload.data.map((o, i) => {
            if (action.payload.f_id) {
              if (dataLength.length > 0) {
                if (o.feedback_id === action.payload.f_id) {
                  return {
                    isOpen: true,
                    ...o,
                  };
                } else {
                  return {
                    isOpen: false,
                    ...o,
                  };
                }
              } else if (i === 0) {
                return {
                  isOpen: true,
                  ...o,
                };
              } else {
                return {
                  isOpen: false,
                  ...o,
                };
              }
            } else if (i === 0) {
              return {
                isOpen: true,
                ...o,
              };
            } else {
              return {
                isOpen: false,
                ...o,
              };
            }
          });
        state.feedbackListData = data;
      }
    },
    getCustomerFeedbackListError: (state, action) => {
      state.feedbackListLoading = false;
      state.feedbackListError = action.payload;
    },
    isOpenModalById: (state, action) => {
      const prevFeedbackListData = current(state.feedbackListData);
      state.feedbackListData = prevFeedbackListData.map((o) => {
        const newObj = { ...o };
        if (o.feedback_id === action.payload) {
          newObj.isOpen = true;
        } else {
          newObj.isOpen = false;
        }
        return newObj;
      });
    },
    setCustomerFilter: (state, action) => {
      state.customerFilter = { ...action.payload, is_active: true };
      state.appliedCustomerFilter = getAppliedFiltersForCustomer(
        action.payload
      );
    },
    deleteCustomerRequest: (state) => {
      state.deleteCustomerLoading = true;
      state.deleteCustomerError = null;
    },
    deleteCustomerSuccess: (state, action) => {
      state.deleteCustomerLoading = false;
      state.deleteCustomerMessage = action.payload;
    },
    deleteCustomerError: (state, action) => {
      state.deleteCustomerLoading = false;
      state.deleteCustomerError = action.payload;
    },
    filterToggle: (state, action) => {
      if (action.payload === "brand_id") {
        state.brandFilter = !state.brandFilter;
      } else if (action.payload === "branch_id") {
        state.branchFilter = !state.branchFilter;
      } else if (action.payload === "experience_type_id") {
        state.expTypeFilter = !state.expTypeFilter;
      } else if (action.payload === "rating") {
        state.ratingFilter = !state.ratingFilter;
      } else if (action.payload === "status") {
        state.statusFilter = !state.statusFilter;
      } else if (action.payload === "date") {
        state.dateFilter = !state.dateFilter;
      }
    },
    resetFeedbackData: (state, action) => {
      for (const key in action.payload) {
        if (Object.hasOwnProperty.call(action.payload, key)) {
          const element = action.payload[key];
          state[key] = element;
        }
      }
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

const getAppliedFiltersCount = (filters) => {
  let appliedCounter = 0;
  if (
    filters.rating &&
    (filters.rating.min !== 0 || filters.rating.max !== 5)
  ) {
    appliedCounter += 1;
  }
  if (
    filters.last_rating &&
    (filters.last_rating.min !== 0 || filters.last_rating.max !== 5)
  ) {
    appliedCounter += 1;
  }
  if (
    filters.feedback &&
    (filters.feedback.min !== 0 || filters.feedback.max !== 5000)
  ) {
    appliedCounter += 1;
  }
  if (
    filters.experience_type &&
    (filters.experience_type.min !== 0 || filters.experience_type.max !== 5000)
  ) {
    appliedCounter += 1;
  }
  if (
    filters.brand &&
    (filters.brand.min !== 0 || filters.brand.max !== 5000)
  ) {
    appliedCounter += 1;
  }
  if (
    filters.date &&
    filters.date.length > 0 &&
    (filters.date[0] !== null || filters.date[1] !== null)
  ) {
    appliedCounter += 1;
  } else if (
    filters?.date &&
    filters?.data?.start &&
    filters?.data?.end &&
    (filters?.data?.start !== null || filters?.date?.end !== null)
  ) {
    appliedCounter += 1;
  }
  return appliedCounter;
};

const getAppliedFiltersForCustomer = (filters) => {
  let appliedCustomerFilter = [];
  if (filters.brand_id && filters.brand_id.length > 0) {
    appliedCustomerFilter.push({ title: "BRAND", value: "brand_id" });
  }
  if (filters.branch_id && filters.branch_id.length > 0) {
    appliedCustomerFilter.push({ title: "Branch", value: "branch_id" });
  }
  if (filters.experience_type_id && filters.experience_type_id.length > 0) {
    appliedCustomerFilter.push({
      title: "EXPERIENCE_TYPE",
      value: "experience_type_id",
    });
  }
  if (filters.rating && filters.rating.length > 0) {
    appliedCustomerFilter.push({ title: "Rating", value: "rating" });
  }
  if (
    filters.status &&
    (filters.status.resolved !== false || filters.status.unresolved !== false)
  ) {
    appliedCustomerFilter.push({ title: "STATUS", value: "status" });
  }
  if (
    filters.date &&
    filters.date.length &&
    ((filters.date[0] && filters.date[0] === null) ||
      (filters.date[1] && filters.date[1] === null))
  ) {
    appliedCustomerFilter.remove({ title: "DATE", value: "date" });
  } else if (filters.date && filters.date.start) {
    appliedCustomerFilter.push({ title: "DATE", value: "date" });
  }
  return appliedCustomerFilter;
};

export const {
  getCustomerDataRequest,
  getCustomerDataSuccess,
  getCustomerDataError,
  setFilterValue,
  clearFilter,
  getcustomerFeedbackDataRequest,
  getcustomerFeedbackDataSuccess,
  getcustomerFeedbackDataError,
  getCustomerFeedbackListRequest,
  getCustomerFeedbackListSuccess,
  getCustomerFeedbackListError,
  isOpenModalById,
  setCustomerFilter,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerError,
  disableCustomerRequest,
  disableCustomerSuccess,
  disableCustomerError,
  filterToggle,
  resetFeedbackData,
  setCurrentPage,
} = customerSlice.actions;

export default customerSlice.reducer;
