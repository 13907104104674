import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import gift from "../../assets/images/gift.svg";
import noreward from "../../assets/images/noreward.svg";
import BrandLogo from "../components/BrandLogo";
import Menu from "../components/Menu";
import ServiceList from "../components/ServiceList";
import "../pages/preview.scss";
import "../pages/previewArabic.scss";
import backArrow from "../../assets/images/arrowLeft.svg";

const HomeReward = ({ previewData, click, backClick, languageData }) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const [isShowService, setIsShowService] = useState(false);
  const {
    surveyButton,
    productButton,
    serviceButton,
    buttonColor,
    buttonTextColor,
    previewLanguage,
    rewardOn,
    termsAndCoditionText,
    rewardsText,
  } = useSelector((state) => state.feedbacks);

  return (
    <>
      {!isShow && !isShowService ? (
        <div>
          <div className="BackArrrow_Btn">
            {languageData && languageData.length > 1 && (
              <div className="navBack">
                <img
                  src={backArrow}
                  style={{ width: "15px", margin: "12px 10px" }}
                  onClick={backClick}
                  alt="backArrow"
                />
              </div>
            )}
          </div>
          <div className="mb-3 pt-4">
            <BrandLogo object={previewData} />
          </div>
          <div className="TopBorder"></div>
          <div className="paddingApp">
            <div className="HomeReward">
              <div className="RewardSec">
                {previewData?.product_category?.length > 0 &&
                  previewData?.brand_design?.via_qr_or_survey_link_product ===
                    true && (
                    <Button
                      style={{
                        backgroundColor: buttonColor
                          ? buttonColor
                          : previewData?.brand_design?.button_colour,
                        color: buttonTextColor
                          ? buttonTextColor
                          : previewData?.brand_design?.button_text_colour,
                        borderColor: buttonColor
                          ? buttonColor
                          : previewData?.brand_design?.button_colour,
                      }}
                      className="btn btn-primary mob_Btn"
                      onClick={() => setIsShow(!isShow)}
                    >
                      {productButton[previewLanguage]
                        ? productButton[previewLanguage]
                        : previewData?.brand_design
                            ?.edit_button_text_products_list[previewLanguage]
                        ? previewData?.brand_design
                            ?.edit_button_text_products_list[previewLanguage]
                        : "Product List"}
                    </Button>
                  )}
                {previewData?.service_category?.length > 0 &&
                  previewData?.brand_design?.via_qr_or_survey_link_service ===
                    true && (
                    <Button
                      className="btn btn-primary mob_Btn"
                      style={{
                        backgroundColor: buttonColor
                          ? buttonColor
                          : previewData?.brand_design?.button_colour,
                        color: buttonTextColor
                          ? buttonTextColor
                          : previewData?.brand_design?.button_text_colour,
                        borderColor: buttonColor
                          ? buttonColor
                          : previewData?.brand_design?.button_colour,
                      }}
                      onClick={() => setIsShowService(!isShowService)}
                    >
                      {serviceButton[previewLanguage]
                        ? serviceButton[previewLanguage]
                        : previewData?.brand_design
                            ?.edit_button_text_services_list[previewLanguage]
                        ? previewData?.brand_design
                            ?.edit_button_text_services_list[previewLanguage]
                        : "Service List"}
                    </Button>
                  )}
              </div>
              <div
                className="borderDashed"
                style={{
                  border: `2px dashed${buttonColor ? buttonColor : "var(--primary)"}`,
                }}
              >
                <h6>{t("GET_BETTER")}</h6>
                <div className="SurvetBox">
                  <div>
                    <img src={rewardOn === true ? gift : noreward} alt="gift" />
                    <p>
                      {rewardOn === true
                        ? rewardsText[previewLanguage]
                          ? rewardsText[previewLanguage]
                          : previewData?.rewards?.title[previewLanguage]
                          ? previewData?.rewards?.title[previewLanguage]
                          : "Rewards Text"
                        : ""}
                    </p>
                  </div>
                </div>
                <Button
                  className="btn btn-primary mob_Btn"
                  style={{
                    backgroundColor: buttonColor
                      ? buttonColor
                      : previewData?.brand_design?.button_colour,
                    color: buttonTextColor
                      ? buttonTextColor
                      : previewData?.brand_design?.button_text_colour,
                    borderColor: buttonColor
                      ? buttonColor
                      : previewData?.brand_design?.button_colour,
                  }}
                  onClick={click}
                >
                  {surveyButton[previewLanguage]
                    ? surveyButton[previewLanguage]
                    : previewData?.brand_design?.edit_button_text_survey_button[
                        previewLanguage
                      ]
                    ? previewData?.brand_design?.edit_button_text_survey_button[
                        previewLanguage
                      ]
                    : "Start the Survey"}
                </Button>
              </div>
              <ul>
                {rewardOn === true && (
                  <>
                    <li>
                      <strong>{t("TERMS_CONDITION")}</strong>
                    </li>
                    <li>
                      <div>
                        {termsAndCoditionText &&
                        termsAndCoditionText.length > 0 ? (
                          <>
                            {termsAndCoditionText.map((o, i) => {
                              return (
                                <div key={i}>
                                  <p>
                                    {o[previewLanguage]
                                      ? o[previewLanguage]
                                      : ""}
                                  </p>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {previewData.rewards?.terms_and_condition &&
                              previewData.rewards?.terms_and_condition.length >
                                0 &&
                              previewData.rewards?.terms_and_condition.map(
                                (o, i) => {
                                  return (
                                    <div key={i}>
                                      <p>{o[previewLanguage]}</p>
                                    </div>
                                  );
                                }
                              )}
                          </>
                        )}
                      </div>
                      {/* )} */}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isShow && (
            <Menu
              previewData={previewData}
              show={isShow}
              setIsShow={(flag) => setIsShow(flag)}
            />
          )}
          {isShowService && (
            <ServiceList
              previewData={previewData}
              show={isShowService}
              setIsShow={(flag) => setIsShowService(flag)}
            />
          )}
        </>
      )}
    </>
  );
};

export default HomeReward;
