import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./select.module.css";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

/**
 *
 * @param {string} value
 * @param {string[]} arr
 * @returns
 */
function toggleFromArray(value, arr = []) {
  if (arr.find((item) => value === item)) {
    return arr.filter((item) => item !== value);
  } else return [...arr, value];
}

/**
 * @typedef {{value: string label: string}} Option
 * @typedef {{multiple: false selected?: string onChange: (selected:  string) => void}| {multiple: true selected: string[] onChange: (selected: string[]) => void}} Handler
 * @param {{options: Option[] label: string name: string title: string icon?: import("react").ReactNode} & Handler} props
 * @returns
 */

function Select({
  name,
  label,
  options,
  multiple = false,
  title,
  selected,
  onChange,
  onBlur,
  icon = <FontAwesomeIcon icon={faChevronDown} />,
}) {
  const { t } = useTranslation();
  return (
    <>
      {label && <Form.Label htmlFor={name}>{t(label)}</Form.Label>}
      <Dropdown
        autoClose={multiple ? "outside" : undefined}
        onSelect={(eventKey) => !multiple && onChange(eventKey)}
      >
        <Dropdown.Toggle
          id={name}
          name={name}
          onBlur={onBlur}
          className={styles.control}
        >
          {title}
          {icon}
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdownMenu}>
          {options.map(({ value, label }) => (
            <Dropdown.Item
              key={value}
              eventKey={value}
              className={styles.dropdownItem}
              htmlFor={value}
              as={multiple ? Form.Label : "option"}
            >
              {multiple && (
                <Form.Check
                  id={value}
                  onChange={() => onChange(toggleFromArray(value, selected))}
                  checked={selected?.includes(value)}
                />
              )}
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default Select;
