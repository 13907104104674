import SummaryReport from "../services/summaryReport";
import {
  getReportsRequest,
  getReportsSuccess,
  getReportsError,
  getBranchesForReportRequest,
  getBranchesForReportSuccess,
  getBranchesForReportError,
  getCategoryForReportRequest,
  getCategoryForReportSuccess,
  getCategoryForReportError,
  getProductForReportRequest,
  getProductForReportSuccess,
  getProductForReportError,
  getStaffForReportError,
  getStaffForReportRequest,
  getStaffForReportSuccess,
  getFeedBackForReportError,
  getFeedBackForReportRequest,
  getFeedBackForReportSuccess,
  getFeedbackDataForModalRequest,
  getFeedbackDataForModalSuccess,
  getFeedbackDataForModalError,
} from "../slices/summaryReport.slice";

export function getQuickOverviewReport(payload, brand_id) {
  return (dispatch) => {
    dispatch(getReportsRequest());
    SummaryReport.getSummaryReport(payload, brand_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getReportsSuccess(data));
        } else {
          dispatch(getReportsError(error));
        }
      })
      .catch((error) => {
        dispatch(getReportsError(error));
      });
  };
}

export function getBranchReport(payload, brand_id) {
  return (dispatch) => {
    dispatch(getBranchesForReportRequest());
    SummaryReport.getSummaryReportBranch(payload, brand_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getBranchesForReportSuccess(data));
        } else {
          dispatch(getBranchesForReportError(error));
        }
      })
      .catch((error) => {
        dispatch(getBranchesForReportError(error));
      });
  };
}

export function getCategoryReport(payload) {
  return (dispatch) => {
    dispatch(getCategoryForReportRequest());
    SummaryReport.getSummaryReportCategory(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getCategoryForReportSuccess(data));
        } else {
          dispatch(getCategoryForReportError(error));
        }
      })
      .catch((error) => {
        dispatch(getCategoryForReportError(error));
      });
  };
}

export function getProductReport(payload) {
  return (dispatch) => {
    dispatch(getProductForReportRequest());
    SummaryReport.getProductReport(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getProductForReportSuccess(data));
        } else {
          dispatch(getProductForReportError(error));
        }
      })
      .catch((error) => {
        dispatch(getProductForReportError(error));
      });
  };
}

export function getStaffReport(payload) {
  return (dispatch) => {
    dispatch(getStaffForReportRequest());
    SummaryReport.getStaffReport(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getStaffForReportSuccess(data));
        } else {
          dispatch(getStaffForReportError(error));
        }
      })
      .catch((error) => {
        dispatch(getStaffForReportError(error));
      });
  };
}

export function getFeedBackReport(payload) {
  return (dispatch) => {
    dispatch(getFeedBackForReportRequest());
    SummaryReport.getFeedBackReport(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getFeedBackForReportSuccess(data));
        } else {
          dispatch(getFeedBackForReportError(error));
        }
      })
      .catch((error) => {
        dispatch(getFeedBackForReportError(error));
      });
  };
}

export function getFeedbackDataForModal(f_id) {
  return (dispatch) => {
    dispatch(getFeedbackDataForModalRequest());
    SummaryReport.getFeedbackDataForModal(f_id)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getFeedbackDataForModalSuccess(data));
        } else {
          dispatch(getFeedbackDataForModalError(error));
        }
      })
      .catch((error) => {
        dispatch(getFeedbackDataForModalError(error));
      });
  };
}