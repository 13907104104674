import { useEffect, useRef } from "react";
import useNetworkStatus from "../../helpers/useNetworkStatus";
import styles from "./networkStatus.module.css";
import { useTranslation } from "react-i18next";

function NetWorkStatus() {
  const status = useNetworkStatus();

  const { t } = useTranslation();

  const ref = useRef();

  useEffect(() => {
    let timeout;
    if (status) {
      ref.current?.classList.remove(styles.hide);
      let _ = ref.current?.offsetHeight; // trigger reflow
      ref.current?.classList.add(styles.show);
      timeout = setTimeout(() => {
        ref.current?.classList.remove(styles.show);
        ref.current?.classList.add(styles.hide);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  return (
    <div
      ref={ref}
      className={`${styles.container} ${
        status === "offline" ? styles.offline : styles.online
      }`}
    >
      {t(status)}
    </div>
  );
}

export default NetWorkStatus;
