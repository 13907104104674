import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const NoPreview = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="noPreviewAvailable">
      <h6 style={{textAlign: "center", lineHeight: 1.5}}>
        {t("GENERATE_SURVEY_OR_QR")}
        </h6>
        <Footer />
      </div>
    </>
  );
};

export default NoPreview;
