import { createSlice } from "@reduxjs/toolkit";
import { FEEDBACKS } from "./slice-names";

export const feedbackSlice = createSlice({
  name: FEEDBACKS,
  initialState: {
    loading: true,
    QrCodeData: null,
    message: "",
    error: "",

    previewData: {},
    previewLoading: true,
    previewError: "",
    languageData: [],
    Index: 0,
    questionIndex: 0,
    surveyButton: "",
    productButton: "",
    serviceButton: "",
    termsAndCoditionText: "",
    rewardsText: "",

    buttonColor: "",
    buttonTextColor: "",
    previewLanguage: "",
    previewLogo: false,
    googleLogo: false,
    instagramLogo: false,
    facebookLogo: false,
    rewardOn: false,
    resolveLoading: false,
    resolveMessage: "",
    resolveError: "",

    replyLoading: true,
    replyMessage: "",
    replyError: "",

    deleteFeedbackLoading: false,
    deleteFeedbackMessage: "",
    deleteFeedbackError: "",

    sharePdfLoading: false,
    sharePdfMessage: "",
    sharePdfError: "",

    printPdfLoading: false,
    printPdfMessage: "",
    printPdfError: "",
  },
  reducers: {
    genrateQrRequest: (state) => {
      state.loading = true;
      state.error = "";
    },
    genrateQrSuccess: (state, action) => {
      state.QrCodeData = action.payload.data;
      state.loading = false;
    },
    genrateQrError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getFeedbackDataforPreviewRequest: (state) => {
      state.previewLoading = true;
      state.previewData = {};
      state.previewError = "";
    },
    getFeedbackDataforPreviewSuccess: (state, action) => {
      state.previewLoading = false;
      state.previewData = action.payload.data;
      state.languageData = action.payload.lang;
    },
    getFeedbackDataforPreviewError: (state, action) => {
      state.previewLoading = false;
      state.previewError = action.payload;
    },
    setIndex: (state, action) => {
      state.Index = action.payload;
    },
    setQuestionIndex: (state, action) => {
      state.questionIndex = action.payload;
    },
    setRatingIndex: (state, action) => {
      state.ratingIndex = action.payload;
    },
    setSurveyButton: (state, action) => {
      state.surveyButton = action.payload;
    },
    setProductButton: (state, action) => {
      state.productButton = action.payload;
    },
    setServiceButton: (state, action) => {
      state.serviceButton = action.payload;
    },
    setButtonColor: (state, action) => {
      state.buttonColor = action.payload;
    },
    setButtonTextColor: (state, action) => {
      state.buttonTextColor = action.payload;
    },
    setLanguage: (state, action) => {
      state.previewLanguage = action.payload;
    },
    setPreviewLogo: (state, action) => {
      state.previewLogo = action.payload;
    },
    setGoogleLogo: (state, action) => {
      state.googleLogo = action.payload;
    },
    setInstagramLogo: (state, action) => {
      state.instagramLogo = action.payload;
    },
    setFacebookLogo: (state, action) => {
      state.facebookLogo = action.payload;
    },
    setRewardStatus: (state, action) => {
      state.rewardOn = action.payload;
    },
    resolveFeedbackRequest: (state) => {
      state.resolveLoading = true;
      state.resolveError = "";
      state.resolveMessage = "";
    },
    resolveFeedbackSuccess: (state, action) => {
      state.resolveLoading = false;
      state.resolveMessage = action.payload;
    },
    resolveFeedbackError: (state, action) => {
      state.resolveLoading = false;
      state.resolveError = action.payload;
    },
    replyFeedbackRequest: (state) => {
      state.replyLoading = true;
      state.replyError = "";
    },
    replyFeedbackSuccess: (state, action) => {
      state.replyLoading = false;
      state.replyMessage = action.payload;
    },
    replyFeedbackError: (state, action) => {
      state.replyLoading = false;
      state.replyError = action.payload;
    },
    deleteFeedbackRequest: (state) => {
      state.deleteFeedbackLoading = true;
      state.deleteFeedbackError = "";
    },
    deleteFeedbackSuccess: (state, action) => {
      state.deleteFeedbackLoading = false;
      state.deleteFeedbackMessage = action.payload;
    },
    deleteFeedbackError: (state, action) => {
      state.deleteFeedbackLoading = false;
      state.deleteFeedbackError = action.payload;
    },
    clearState: (state) => {
      state.resolveMessage = "";
      state.deleteFeedbackMessage = "";
      state.replyMessage = "";
      state.sharePdfMessage = "";
    },
    sharePDFRequest: (state) => {
      state.sharePdfLoading = true;
      state.sharePdfError = "";
    },
    sharePDFSuccess: (state, action) => {
      state.sharePdfLoading = false;
      state.sharePdfMessage = action.payload;
    },
    sharePDFError: (state, action) => {
      state.sharePdfLoading = false;
      state.sharePdfError = action.payload;
    },
    setTermsAndCondition: (state, action) => {
      state.termsAndCoditionText = action.payload;
    },
    setRewardsText: (state, action) => {
      state.rewardsText = action.payload;
    },
    printPDFRequest: (state) => {
      state.printPdfLoading = true;
      state.printPdfError = "";
    },
    printPDFSuccess: (state, action) => {
      state.printPdfLoading = false;
      state.printPdfMessage = action.payload;
    },
    printPDFError: (state, action) => {
      state.printPdfLoading = false;
      state.printPdfError = action.payload;
    },
  },
});

export const {
  setPreviewLogo,
  genrateQrRequest,
  genrateQrSuccess,
  genrateQrError,
  getFeedbackDataforPreviewRequest,
  getFeedbackDataforPreviewSuccess,
  getFeedbackDataforPreviewError,
  setIndex,
  setQuestionIndex,
  setRatingIndex,
  setSurveyButton,
  setProductButton,
  setServiceButton,
  setButtonTextColor,
  setButtonColor,
  setLanguage,
  setGoogleLogo,
  setInstagramLogo,
  setFacebookLogo,
  setRewardStatus,
  resolveFeedbackRequest,
  resolveFeedbackSuccess,
  resolveFeedbackError,
  replyFeedbackRequest,
  replyFeedbackSuccess,
  replyFeedbackError,
  deleteFeedbackRequest,
  deleteFeedbackSuccess,
  deleteFeedbackError,
  clearState,
  sharePDFRequest,
  sharePDFSuccess,
  sharePDFError,
  setTermsAndCondition,
  setRewardsText,
  printPDFRequest,
  printPDFSuccess,
  printPDFError,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
