import styles from "./staffRow.module.css";
import { Dropdown, Form } from "react-bootstrap";
import TrashIcon from "../icons/TrashIcon";
import { useState } from "react";

/**
 *
 * @typedef {{
 * staffId: string
 * email: string
 * }} Staff
 * @param {{
 * index: number
 * name: string
 * value: Staff
 * staff: {_id: string name: string }[]
 * filterStaff: (staff: {_id: string name: string })=> boolean
 * error?: { staffId: string staffId: string }
 * touched?:{ staffId: boolean staffId: boolean }
 * onRemove: () => void
 * onChange: (staff: Staff) => void
 * handleBlur: (e) => void
 * }} props
 * @returns
 */
function StaffRow({
  index,
  name,
  error,
  touched,
  handleBlur,
  onChange,
  value,
  staff,
  filterStaff,
  onRemove,
}) {
  const [search, setSearch] = useState(null);
  const language = localStorage.getItem("i18nextLng");

  const selectedName = staff.find((s) => s._id === value?.staffId)?.name;

  return (
    <div className={styles.tableRow}>
      <span>{index + 1}</span>
      <span>
        <Dropdown
          onSelect={(staffId) => {
            onChange({ ...value, staffId });
            setSearch((search) => {
              const s = staff.find((staff) => staff._id === staffId);
              return s.name[language] || s.name["en"] || search;
            });
          }}
        >
          <Dropdown.Toggle className={styles.dropdownToggle} as={"span"}>
            <Form.Control
              type="text"
              name={`${name}.staffId`}
              className={`${styles.input} ${
                touched?.staffId && error?.staffId && "is-invalid"
              }`}
              value={
                search ??
                (selectedName && (selectedName[language] || selectedName["en"]))
              }
              onBlur={handleBlur}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {staff
              .filter((s) => {
                for (const key in s.name) {
                  if (
                    s.name[key]
                      .toLocaleLowerCase()
                      .includes(search?.toLocaleLowerCase()) &&
                    filterStaff(s)
                  )
                    return true;
                }
                return false;
              })
              .map((s) => (
                <Dropdown.Item
                  key={s._id}
                  eventKey={s._id}
                  className={styles.dropdownItem}
                >
                  {s.name[language] || s.name["en"]}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        {touched?.staffId && error?.staffId && (
          <div className="invalid-feedback d-block">{error?.staffId}</div>
        )}
      </span>
      <span>
        <Form.Control
          type="text"
          name={`${name}.email`}
          className={`${styles.input} ${
            touched?.email && error?.email && "is-invalid"
          }`}
          value={value.email}
          onBlur={handleBlur}
          onChange={(e) => onChange({ ...value, email: e.target.value })}
        />
        {touched?.email && error?.email && (
          <div className="invalid-feedback d-block">{error?.email}</div>
        )}
      </span>
      <button
        type="button"
        className={styles.iconBtn}
        onClick={() => onRemove()}
      >
        <TrashIcon />
      </button>
    </div>
  );
}

export default StaffRow;
