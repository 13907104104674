import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const LanguagesViewModal = ({ show, setIsShow, data }) => {
  const { t } = useTranslation();
  const { languages } = useSelector((state) => state.questions);
  const { AllLanguages } = useSelector((state) => state.brand);

  const languageListing =
    languages &&
    languages.languages &&
    AllLanguages &&
    AllLanguages.length > 0 &&
    languages.languages.map((o) => {
      const langData =
        AllLanguages &&
        AllLanguages.find((obj) => {
          return obj.key === o;
        });
      return langData;
    });

  const languageLable = (o) => {
    const newObj =
      AllLanguages &&
      AllLanguages.find((obj) => {
        return obj.key === o;
      });
    return newObj.value;
  };

  return (
    <Modal show={show} className="modalImprove">
      <Modal.Header>
        <Modal.Title className="cust-title">
          {t("VIEW_CATEGORY_LANGUAGES")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="languageInner-modal">
          {languageListing &&
            languageListing.length > 0 &&
            languageListing.map((obj, i) => (
              <li className="d-flex mb-2" key={i}>
                <p>
                  {languageLable(obj.key)}:{" "}
                  <span className={data && data[obj.key] ? "" : "color-grey"}>
                    {data && data[obj.key]
                      ? data[obj.key]
                      : `${t("NOT_AVAILABLE_IN")} ${t(languageLable(obj.key))}`}
                  </span>
                </p>
              </li>
            ))}
        </ul>
        <div className="Experience-btn-modal flex justify-content-center">
          <Button className="send-modal" onClick={() => setIsShow(!show)}>
            {t("CLOSE")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LanguagesViewModal;
