import { get, post, postExel, postPdf } from ".";

const URI = "/brands/dashboard";

const getQuickOverview = (payload, b_id) => {
  const URL = `${URI}/quick-overview/${b_id}`;
  return post(URL, payload);
};

const getBranchesForDashboard = (payload) => {
  const URL = `${URI}/branch-comparison`;
  return post(URL, payload);
};

const getCategoriesListingByBrandId = (b_id) => {
  const URL = `${URI}/category-list/${b_id}`;
  return get(URL);
};

const getCategoriesListing = (payload) => {
  const URL = `${URI}/category-comparison`;
  return post(URL, payload);
};

const getPerformanceChartData = (payload) => {
  const URL = `${URI}/performance-chart`;
  return post(URL, payload);
};

/**
 * @typedef {{
 *   branch_id: string[]
 *   experience_type_id: string[]
 *   rating: string[]
 *   category_id: string[]
 *   staff_id: string[]
 *   date: {
 *    min: string,
 *    max: string,
 *  }
 * }} Filter
 * @param {{
 *  brand_id: string,
 *  filter: Filter
 *  page: number
 * }} payload
 * @typedef {{ _id: string title: string }} ExperienceType
 * @typedef {{total: number totalPage: number currentPage: number}} Pagination
 * @typedef {{question_category_id: string rating: number category_name: {en: string ar: string} colour: string _id:string}} Category
 * @typedef {{ title: string, category: Category created_at: string feedback_id: string }} Feedback
 * @typedef {{
 *  status: number
 *  message: string
 *  data: {
 *   experience_type: ExperienceType
 *   data: {
 *    data: { lowest_rated_data: Feedback | {} }[]
 *    metaData: Pagination
 *   }
 *  }[]
 * }} Suggestions
 * @returns { Promise<import("axios").AxiosResponse<Suggestions>>}
 */
const getSuggestions = (payload) => {
  const URL = `${URI}/suggestions`;
  return post(URL, payload);
};

/**
 *
 * @param {{
 *  brand_id: string,
 *  filter: Filter
 *  page: number
 * }} payload
 * @typedef {{question_category_id: string rating: number category_name: {en: string ar: string} colour: string _id:string}} Category
 * @typedef {{
 *  status: number
 *  message: string
 *  data: {
 *   experience_type: ExperienceType
 *   data: {
 *    data: { highest_rated_data: Feedback | {} }[]
 *    metaData: Pagination
 *   }
 *  }[]
 * }} Compliments
 * @returns { Promise<import("axios").AxiosResponse<Compliments>>}
 */
const getCompliments = async (payload) => {
  const URL = `${URI}/compliments`;
  return post(URL, payload);
};

const getStaffListing = (payload) => {
  const URL = `${URI}/staff-comparison`;
  return post(URL, payload);
};

const getProductListing = (payload) => {
  const URL = `${URI}/product-comparison`;
  return post(URL, payload);
};

const getFeedbackListing = (payload) => {
  const URL = `${URI}/feedback-comparison`;
  return post(URL, payload);
};

const getFeedbackDataForModal = (f_id) => {
  const URL = `${URI}/feedback-model/${f_id}`;
  return post(URL);
};

const getOverAllRatingData = (payload) => {
  const URL = `${URI}/overall-rating`;
  return post(URL, payload);
};

/**
 * @param {{
 *  filter: {
 *    branch_id: string[]
 *    experience_type_id: string[]
 *    date?: {
 *      start?: string
 *      end?: string
 *  }
 * }
 *  brand_id: string
 * }} payload
 * @typedef {{
 *   status: number,
 *   message: string,
 *   error: string|null,
 *   data: {
 *    _id: string
 *    question: {
 *      en: string,
 *      ar?:string,
 *    }
 *    experience_type_id: string
 *  } []
 * }} SingleQuestions
 *
 * @returns {Promise<import("axios").AxiosResponse<SingleQuestions>>}
 */
const getAllSingleQuestions = (payload) => {
  const URL = `${URI}/additional-questions/single-questions`;
  return post(URL, payload);
};

/**
 * @param {{
 *  filter: {
 *    branch_id: string[]
 *    experience_type_id: [expId: string]
 *    question_id: string,
 *    staff_id: string[]
 *    date?: {
 *      start?: string
 *      end?: string
 *     }
 *    }
 *  brand_id: string
 * }} payload
 * @typedef {{
 *   status: number,
 *   message: string,
 *   error: string|null,
 *   data: [{
 *     data: {
 *       _id: string
 *       createdAt: string
 *       feed_back: { _id: string answer: { opinion: string } }
 *     }[]
 *     question: {
 *       _id: string
 *       question: {
 *          en: string,
 *          ar?:string,
 *        }
 *       }
 *       metaData: Pagination
 *       experience_type: {
 *         _id: string
 *         title: string
 *       }
 *  }]
 * }} SingleQuestion
 *
 * @returns {Promise<import("axios").AxiosResponse<SingleQuestion>>}
 */
const getSingleQuestion = (payload) => {
  const URL = `${URI}/additional-questions/single`;
  return post(URL, payload);
};

const getAdditionalQuestion = (payload) => {
  const URL = `${URI}/additional-questions`;
  return post(URL, payload);
};

const exportFeedbackData = (payload) => {
  const URL = `${URI}/export-excel`;
  return postExel(URL, payload);
};

const exportFeedbackDataAsPdf = (payload) => {
  const URL = `${URI}/export-pdf`;
  return postPdf(URL, payload);
};

const filterCategoryList = (b_id) => {
  const URL = `${URI}/category-list/${b_id}`;
  return get(URL);
};

const Dashboard = {
  getQuickOverview,
  getBranchesForDashboard,
  getCategoriesListingByBrandId,
  getCategoriesListing,
  getPerformanceChartData,
  getSuggestions,
  getCompliments,
  getStaffListing,
  getFeedbackDataForModal,
  getOverAllRatingData,
  getAdditionalQuestion,
  getAllSingleQuestions,
  getSingleQuestion,
  exportFeedbackData,
  exportFeedbackDataAsPdf,
  filterCategoryList,
  getProductListing,
  getFeedbackListing
};
export default Dashboard;
