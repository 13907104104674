import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { getPerformanceChart } from "../../../middlewares/detailReport";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    monthAndYearFormat,
    dateWithOnlyMonthFormat,
} from "../../../helpers/dateFormat";

const PerformaceOverTimeReport = ({ selectBrand, report }) => {
    const { t } = useTranslation();
    const [maxNumber, setMaxNumber] = useState(10);
    const { performanceChart } = useSelector(
        (state) => state.detailReport
    );
    const url = window.location.href
    const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
    const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
    const date = new Date(dateInSeconds * 1000);
    const dispatch = useDispatch();
    const language = localStorage.getItem("i18nextLng");

    useEffect(() => {
        if (selectBrand && report) {
            const filter = {
                experience_type_id: report ? report.report[0].experience_type_id : [],
                rating: [],
                branch_id: report ? report.report[0].branch_id : [],
                category_id: [],
                staff_id: [],
                date: [null, null]
            }
            const payload = {
                brand_id: selectBrand,
                filter: filter,
                report_id: report.report[0]._id,
                reportDate: date,
            };
            dispatch(getPerformanceChart(payload));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectBrand, report]);

    useEffect(() => {
        if (
            performanceChart &&
            performanceChart.ratingData &&
            performanceChart.ratingData.length > 0
        ) {
            const maxRatingObj = performanceChart.ratingData.reduce((p, c) =>
                p.rating_count > c.rating_count ? p : c
            );
            setMaxNumber(
                maxRatingObj?.rating_count === 0 ? 10 : maxRatingObj?.rating_count
            );
        } else {
            setMaxNumber(10);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performanceChart]);

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController,
        ChartDataLabels
    );
    const options = {
        layout: {
            padding: {
                responsive: true,
                top: 25,
            },
        },
        scales: {
            y: {
                grid: {
                    color: "rgba(43, 188, 145, 0.15)",
                    lineWidth: 1,
                },
                grace: 1,
                max: 5,
                position:
                    language === "ar" ||
                        language === "fa" ||
                        language === "he" ||
                        language === "ur"
                        ? "right"
                        : "left",
            },
            y1: {
                grid: {
                    tickBorderDashOffset: 100,
                    display: false,
                },
                grace: 2,
                max: Math.ceil(maxNumber / 10) * 10 + 10,
                position:
                    language === "ar" ||
                        language === "fa" ||
                        language === "he" ||
                        language === "ur"
                        ? "left"
                        : "right",
            },
            x: {
                grid: {
                    display: false,
                    lineDashed: true,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                    style: {
                        top: 50,
                    },
                },
            },
        },
    };

    const handleLabelData = (data) => {
        if (data && data.preset === "year") {
            const datalable =
                data && data.ratingData && data.ratingData.map((o) => o._id);
            return datalable;
        } else if (data && data.preset === "month") {
            const datalable =
                data &&
                data.ratingData &&
                data.ratingData.map((o) => monthAndYearFormat(o._id));
            return datalable;
        } else if (data && data.preset === "week") {
            const datalable =
                data && data.ratingData && data.ratingData.map((o) => o._id);
            return datalable;
        } else if (data && data.preset === "day") {
            const datalable =
                data &&
                data.ratingData &&
                data.ratingData.map((o) =>
                    dateWithOnlyMonthFormat(`${new Date().getFullYear()}-${o._id}`)
                );
            return datalable;
        } else if (data && data.preset === "hour") {
            const datalable =
                data && data.ratingData && data.ratingData.map((o) => o._id);
            return datalable;
        } else {
            return [];
        }
    };

    const data = {
        labels: handleLabelData(performanceChart ? performanceChart : []),
        datasets: [
            {
                yAxisID: "y1",
                type: "line",
                opposite: true,
                borderColor: "black",
                borderWidth: 0.5,
                lineTension: 0.5,
                label: "Total count",
                pointStyle: "circle",
                datalabels: {
                    color:
                        performanceChart && performanceChart?.ratingData
                            ? performanceChart.ratingData.map((o) => {
                                if (o.rating_count !== 0) return "#292D32";
                                else return "transparent";
                            })
                            : [],
                    anchor: "end",
                    align: "left",
                    padding: { left: 12 },
                    font: {
                        weight: "bold",
                    },
                },
                data:
                    performanceChart &&
                        performanceChart.ratingData &&
                        performanceChart.ratingData
                        ? performanceChart.ratingData.map((o) => {
                            return o.rating_count;
                        })
                        : [],
            },
            {
                yAxisID: "y",
                type: "bar",
                barThickness: 24,
                datalabels: {
                    color: "#292D32",
                    anchor: "end",
                    align: "top",
                    font: {
                        weight: "bold",
                    },
                },
                backgroundColor:
                    performanceChart &&
                        performanceChart.ratingData &&
                        performanceChart.ratingData
                        ? performanceChart.ratingData.map((o) => {
                            return o.overallExpreience_rating_avg > 4.99
                                ? "#2BBC91"
                                : o.overallExpreience_rating_avg > 3.99
                                    ? "#BCE29E"
                                    : o.overallExpreience_rating_avg > 1.99
                                        ? "#E5EBB2"
                                        : "#F8C4B4";
                        })
                        : "#FF8787",
                borderRadius: 100,
                label: "Average ratings",
                data:
                    performanceChart &&
                        performanceChart.ratingData &&
                        performanceChart.ratingData
                        ? performanceChart.ratingData.map((o) => {
                            return o.overallExpreience_rating_avg !== 0
                                ? parseFloat(o.overallExpreience_rating_avg).toFixed(2)
                                : null;
                        })
                        : [],
            },
        ],
    };

    return (
        <div className="box PerformaceOverTime">
            <h2>
                <strong>{t("PERFORMANCE_OVER_TIME")}</strong>
            </h2>
            <Chart
                type="bar"
                data={data}
                options={options}
                height={100}
                dir={
                    language === "ar" ||
                        language === "fa" ||
                        language === "he" ||
                        language === "ur"
                        ? "rtl"
                        : "ltr"
                }
            />
        </div>
    );
};

export default PerformaceOverTimeReport;
