import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { getStaffListing } from "../../../middlewares/detailReport";
import expImg from "../../../assets/images/exp-img.png";
import { getProductReport } from "../../../middlewares/summaryReport";

const LowestRatingProduct = ({ selectBrand, productData, singleReport }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const url = window.location.href
    const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
    const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
    const date = new Date(dateInSeconds * 1000);
    const [sort, setSort] = useState([
        {
            key: "name",
            column: "name",
            value: "Name",
            order: -1,
            activeSort: true,
        },
        {
            key: "rating",
            column: "rating",
            value: "Rating",
            order: -1,
            activeSort: false,
        },
        {
            key: "count",
            column: "count",
            value: "Count",
            order: -1,
            activeSort: false,
        },
    ]);

    // const handleSort = (key, exp_id) => {
    //     let sortObj = {};

    //     const newSortOrder = sort.map((o) => {
    //         if (o.key === key) {
    //             const newObj = {
    //                 ...o,
    //                 order: o.order === 1 ? -1 : 1,
    //                 activeSort: true,
    //             };
    //             sortObj = { ...newObj, column: newObj.key };
    //             return newObj;
    //         }
    //         return { ...o, order: -1, activeSort: false };
    //     });
    //     setSort(newSortOrder);
    //     const filterData = {
    //         experience_type_id: singleReport.report[0].experience_type_id,
    //         branch_id: singleReport.report[0].branch_id,
    //         rating: { min: 0, max: 5 },
    //     }
    //     const payload = {
    //         b_id: selectBrand,
    //         sort: sortObj,
    //         filter: filterData,
    //         reportDate: date,
    //         report_id: singleReport.report[0]._id
    //     };
    //     dispatch(getProductReport(payload));
    // };

    const [sortedProductList, setSortedProductList] = useState([]);
    const [sortKey, setSortKey] = useState('rating');

    // Effect to update the sorted product list when sorting changes
    useEffect(() => {
        const sortedList = [...productData].filter((o) => o.rating_avg < 5);
        // Apply sorting based on the selected sort option
        sortedList.sort((a, b) => {
            const sortOrder = sort.find((s) => s.key === sortKey).order;
            if(sortKey === 'count'){
                return sortOrder * (b.ratings?.length - a.ratings?.length);
            } else if(sortKey === 'rating'){
                return sortOrder * (b.rating_avg - a.rating_avg);
            } else if(sortKey === 'name'){
                return sortOrder * (b.name.en.localeCompare(a.name.en));
            }
        });

        setSortedProductList(sortedList);
    }, [productData, sort]);

    const handleSort = (key) => {
        const newSort = [...sort];
        setSortKey(key);
        // Find the current sort option and toggle the order
        const currentSort = newSort.find((s) => s.key === key);
        currentSort.order = currentSort.order === -1 ? 1 : -1;

        // Update the sort state
        setSort(newSort);
    };

    return (
        <div className="box">
            <h2>
                <strong>{t("LOWEST_RATING")}</strong>
            </h2>
            {(productData && productData.length > 0)  ? (
                <div className="categoriesRatingBox">
                    <table className="table">
                        <thead>
                            <tr>
                                {sort &&
                                    sort.map((obj, index) => (
                                        <th key={obj.key} className="width260">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleSort(obj.key)}
                                            >
                                                {t(obj.value)}
                                                {obj.order !== -1 ? (
                                                    <FontAwesomeIcon key={index} icon={`angle-up`} />
                                                ) : (
                                                    <FontAwesomeIcon key={index} icon={`angle-down`} />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedProductList && sortedProductList.length > 0 ? (
                                sortedProductList.map((o, i) => (
                                    <tr key={i}>
                                        <td>
                                            <div className="staffProfile">
                                                <span className="staff-img">
                                                    {o.logo ? (
                                                        <img
                                                            src={
                                                                `${process.env.REACT_APP_BASE_URL}${o.logo}` ||
                                                                require("../../../assets/images/noProfile.webp")
                                                            }
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src =
                                                                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                                                            }}
                                                            alt="profile-pic"
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            name={
                                                                o.name[t("language")]
                                                                    ? o.name[t("language")]
                                                                    : o.name[t("en")]
                                                            }
                                                            size="35px"
                                                            key={o._id}
                                                        />
                                                    )}
                                                </span>
                                                <p>
                                                    {o.name[t("language")]
                                                        ? o.name[t("language")]
                                                        : o.name[t("en")]}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="ratingsWrap">
                                                <div className="ratingsText">
                                                    {" "}
                                                    {o.rating_avg.toFixed(2)}
                                                </div>
                                                <StarRatings
                                                    rating={o.rating_avg}
                                                    starDimension="14px"
                                                    starSpacing="2px"
                                                    starRatedColor="#FFD600"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="ratingsWrap">
                                                <div className="ratingsText">
                                                    {" "}
                                                    {o.ratings.length}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>{t("NO_DATA_FOUND")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="experience-wpr flex">
                    <div className="experience-inner">
                        <img src={expImg} alt="loadding" />
                        <p>{t("NO_FEEDBACKS_FOUND")}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LowestRatingProduct;
