import { createSlice, current } from "@reduxjs/toolkit";
import { TRUSTREE_FEEDBACKS } from "./slice-names";
import { customer_data } from "../helpers/jsonData";

export const trustreeFeedbackSlice = createSlice({
  name: TRUSTREE_FEEDBACKS,
  initialState: {
    trustreeFeedback: [],
    openTrusttreeFeedback: null,
    openTrusttreeFeedbackLoading: false,

    allFeedbackPaginationLoading: false,
    resolvedFeedbackPaginationLoading: false,
    unresolvedFeedbackPaginationLoading: false,

    currentAllFeedbacksPage: 1,
    trustreeFeedbackPaginationCount: 0,

    currentResolvedFeedbacksPage: 1,
    trustreeResolvedFeedbackPaginationCount: 0,

    currentUnresolvedFeedbacksPage: 1,
    trustreeUnresolvedFeedbackPaginationCount: 0,

    trustreeFeedbackLoading: true,
    trustreeResFeedbackLoading: true,
    trustreeUnResFeedbackLoading: true,
    trustreeFeedbackErro: null,

    allFeedbackCountLoading: false,
    allFeedbackCountError: "",
    allFeedbackCount: {
      all: 0,
      resolved: 0,
      unresolved: 0
    },

    totalFeedbackCounts: 0,
    totalFeedbackCountLoading: false,
    totalFeedbackCountError: false,

    resolvedFeedbackCounts: 0,
    resolvedFeedbackCountLoading: false,
    resolvedFeedbackCountError: false,

    unresolvedFeedbackCounts: 0,
    unresolvedFeedbackCountLoading: false,
    unresolvedFeedbackCountError: false,

    resolvedTrustreeFeedback: [],
    unResolvedTrustreeFeedback: [],

    trustreeFeedbackFilter: {
      branch_id: [],
      experience_type_id: [],
      rating: [],
      staff_id: [],
      date: [null, null],
    },
    appliedTrustreeFeddbackFilter: [],

    branchFilter: false,
    expTypeFilter: false,
    ratingFilter: false,
    staffFilter: false,
    dateFilter: false,
  },
  reducers: {
    getAllFeedbackPaginationRequest: (state) => {
      state.allFeedbackPaginationLoading = true;
      state.trustreeFeedbackErro = null;
    },
    getResolvedFeedbackPaginationRequest: (state) => {
      state.resolvedFeedbackPaginationLoading = true;
      state.trustreeFeedbackErro = null;
    },
    getUnresolvedFeedbackPaginationRequest: (state) => {
      state.unresolvedFeedbackPaginationLoading = true;
      state.trustreeFeedbackErro = null;
    },
    getTrustreeFeedbackRequest: (state) => {
      state.trustreeFeedbackLoading = true;
      state.trustreeFeedbackErro = null;
    },
    setCurrentAllFeedbacksPage: (state, action) => {
      state.currentAllFeedbacksPage = action.payload;
    },

    getTrustreeFeedbackSuccess: (state, action) => {
      const prevFeedbackListData = current(state.trustreeFeedback);
      const prevPage = state.currentAllFeedbacksPage;

      if((prevPage === action.payload?.metadata?.currentPage) && prevPage !== 1) {
        state.trustreeFeedback = [...prevFeedbackListData];
      }
      else {
        if (
          prevFeedbackListData.length === action.payload?.data?.length &&
          prevFeedbackListData[0]._id === action.payload?.data[0]?._id
        ) {
          const customerObj = prevFeedbackListData.find((o) => o.isOpen);
          const customerId = customerObj?._id || null;
          const feedbacksIds =
            customerObj.customer_review
              .filter((o) => o.isOpen)
              .map((o) => o._id) || [];
          const newArr = action?.payload?.data?.map((user) => {
            const { customer_review } = user;
            const nextCustomerReview = customer_review.map((feedback, i) => {
              if (feedbacksIds.includes(feedback._id)) {
                return { ...feedback, isOpen: true };
              }
              if (i === 0 && prevFeedbackListData.length === 0) {
                return { ...feedback, isOpen: true };
              }
              return { ...feedback, isOpen: false };
            });
            return {
              ...user,
              customer_review: nextCustomerReview,
              isOpen: customerId && customerId === user._id,
            };
          })
          state.trustreeFeedback = action.payload?.metadata?.currentPage === prevPage ? newArr : [...prevFeedbackListData, ...newArr];
        } else {
          const newArr = action.payload?.data ? 
          action.payload?.data.map((o, i) => {
            const customeReview = [...o.customer_review];
            const customeReviewObj =
              customeReview &&
              customeReview.map((obj, index) => {
                if (index === 0 && prevFeedbackListData.length === 0) {
                  return { ...obj, isOpen: true };
                } else {
                  return { ...obj, isOpen: false };
                }
              });
            if (i === 0 && prevFeedbackListData.length === 0) {
              return { ...o, isOpen: true, customer_review: customeReviewObj };
            } else {
              return { ...o, isOpen: false, customer_review: customeReviewObj };
            }
          }) : [];
  
          state.trustreeFeedback = action.payload?.metadata?.currentPage === prevPage ? newArr : [...prevFeedbackListData, ...newArr];
        }
      }

      state.trustreeFeedbackLoading = false;
      state.allFeedbackPaginationLoading = false;
      state.trustreeFeedbackErro = null;
      state.trustreeFeedbackPaginationCount = action.payload?.metadata?.total;
      state.currentAllFeedbacksPage = action.payload?.metadata?.currentPage;
    },

    getTrustreeFeedbackError: (state, action) => {
      state.trustreeFeedbackLoading = false;
      state.trustreeFeedbackErro = action.payload;
    },

    getResolvedTrustreeFeedbackRequest: (state) => {
      state.trustreeResFeedbackLoading = true;
      state.trustreeFeedbackErro = null;
    },

    /*getResolvedTrustreeFeedbackSuccess: (state, action) => {
      const prevFeedbackListData = current(state.resolvedTrustreeFeedback);      
      if (
        prevFeedbackListData.length === action.payload.length &&
        prevFeedbackListData[0]._id === action.payload[0]._id
      ) {
        const customerObj = prevFeedbackListData.find((o) => o.isOpen);
        const customerId = customerObj?._id || null;
        const feedbacksIds =
          customerObj.customer_review
            .filter((o) => o.isOpen)
            .map((o) => o._id) || [];
        state.resolvedTrustreeFeedback = action.payload.map((user) => {
          const { customer_review } = user;
          const nextCustomerReview = customer_review.map((feedback, i) => {
            if (feedbacksIds.includes(feedback._id)) {
              return { ...feedback, isOpen: true };
            }
            if (i === 0) {
              return { ...feedback, isOpen: true };
            }
            return { ...feedback, isOpen: false };
          });
          return {
            ...user,
            customer_review: nextCustomerReview,
            isOpen: customerId && customerId === user._id,
          };
        });
      } else {
        state.resolvedTrustreeFeedback =
          action.payload &&
          action.payload.map((o, i) => {
            const customeReview = [...o.customer_review];
            const customeReviewObj =
              customeReview &&
              customeReview.map((obj, index) => {
                if (index === 0) {
                  return { ...obj, isOpen: true };
                } else {
                  return { ...obj, isOpen: false };
                }
              });
            if (i === 0) {
              return { ...o, isOpen: true, customer_review: customeReviewObj };
            } else {
              return { ...o, isOpen: false, customer_review: customeReviewObj };
            }
          });
      }
      state.trustreeResFeedbackLoading = false;
      state.trustreeFeedbackErro = null;
    },*/

    getResolvedTrustreeFeedbackSuccess: (state, action) => {
      const prevFeedbackListData = current(state.resolvedTrustreeFeedback);
      const prevPage = state.currentResolvedFeedbacksPage;

      if((prevPage === action.payload?.metadata?.currentPage) && prevPage !== 1) {
        state.resolvedTrustreeFeedback = [...prevFeedbackListData];
      }
      else {
        if (
          prevFeedbackListData.length === action.payload?.data?.length &&
          prevFeedbackListData[0]._id === action.payload?.data[0]?._id
        ) {
          const customerObj = prevFeedbackListData.find((o) => o.isOpen);
          const customerId = customerObj?._id || null;
          const feedbacksIds =
            customerObj.customer_review
              .filter((o) => o.isOpen)
              .map((o) => o._id) || [];
          const newArr = action?.payload?.data?.map((user) => {
            const { customer_review } = user;
            const nextCustomerReview = customer_review.map((feedback, i) => {
              if (feedbacksIds.includes(feedback._id)) {
                return { ...feedback, isOpen: true };
              }
              if (i === 0 && prevFeedbackListData.length === 0) {
                return { ...feedback, isOpen: true };
              }
              return { ...feedback, isOpen: false };
            });
            return {
              ...user,
              customer_review: nextCustomerReview,
              isOpen: customerId && customerId === user._id,
            };
          })
          state.resolvedTrustreeFeedback = action.payload?.metadata?.currentPage === prevPage ? newArr : [...prevFeedbackListData, ...newArr];
        } else {
          const newArr = action.payload?.data ? 
          action.payload?.data.map((o, i) => {
            const customeReview = [...o.customer_review];
            const customeReviewObj =
              customeReview &&
              customeReview.map((obj, index) => {
                if (index === 0 && prevFeedbackListData.length === 0) {
                  return { ...obj, isOpen: true };
                } else {
                  return { ...obj, isOpen: false };
                }
              });
            if (i === 0 && prevFeedbackListData.length === 0) {
              return { ...o, isOpen: true, customer_review: customeReviewObj };
            } else {
              return { ...o, isOpen: false, customer_review: customeReviewObj };
            }
          }) : [];
  
          state.resolvedTrustreeFeedback = action.payload?.metadata?.currentPage === prevPage ? newArr : [...prevFeedbackListData, ...newArr];
        }
      }

      state.trustreeResFeedbackLoading = false;
      state.resolvedFeedbackPaginationLoading = false;
      state.trustreeFeedbackErro = null;
      state.trustreeResolvedFeedbackPaginationCount = action.payload?.metadata?.total;
      state.currentResolvedFeedbacksPage = action.payload?.metadata?.currentPage;
    },

    getResolvedTrustreeFeedbackError: (state, action) => {
      state.trustreeResFeedbackLoading = false;
      state.trustreeFeedbackErro = action.payload;
    },

    getUnResolvedTrustreeFeedbackRequest: (state) => {
      state.trustreeUnResFeedbackLoading = true;
      state.trustreeFeedbackErro = null;
    },

    /*getUnResolvedTrustreeFeedbackSuccess: (state, action) => {
      const prevFeedbackListData = current(state.unResolvedTrustreeFeedback);
      if (
        prevFeedbackListData.length === action.payload.length &&
        prevFeedbackListData[0]._id === action.payload[0]._id
      ) {
        const customerObj = prevFeedbackListData.find((o) => o.isOpen);
        const customerId = customerObj?._id || null;
        const feedbacksIds =
          customerObj.customer_review
            .filter((o) => o.isOpen)
            .map((o) => o._id) || [];
        state.unResolvedTrustreeFeedback = action.payload.map((user) => {
          const { customer_review } = user;
          const nextCustomerReview = customer_review.map((feedback, i) => {
            if (feedbacksIds.includes(feedback._id)) {
              return { ...feedback, isOpen: true };
            }
            if (i === 0) {
              return { ...feedback, isOpen: true };
            }
            return { ...feedback, isOpen: false };
          });
          return {
            ...user,
            customer_review: nextCustomerReview,
            isOpen: customerId && customerId === user._id,
          };
        });
      } else {
        state.unResolvedTrustreeFeedback =
          action.payload &&
          action.payload.map((o, i) => {
            const customeReview = [...o.customer_review];
            const customeReviewObj =
              customeReview &&
              customeReview.map((obj, index) => {
                if (index === 0) {
                  return { ...obj, isOpen: true };
                } else {
                  return { ...obj, isOpen: false };
                }
              });
            if (i === 0) {
              return { ...o, isOpen: true, customer_review: customeReviewObj };
            } else {
              return { ...o, isOpen: false, customer_review: customeReviewObj };
            }
          });
      }
      state.trustreeUnResFeedbackLoading = false;
      state.trustreeFeedbackErro = null;
    },*/
    
    getUnResolvedTrustreeFeedbackSuccess: (state, action) => {
      const prevFeedbackListData = current(state.unResolvedTrustreeFeedback);
      const prevPage = state.currentUnresolvedFeedbacksPage;

      if((prevPage === action.payload?.metadata?.currentPage) && prevPage !== 1) {
        state.unResolvedTrustreeFeedback = [...prevFeedbackListData];
      }
      else {
        if (
          prevFeedbackListData.length === action.payload?.data?.length &&
          prevFeedbackListData[0]._id === action.payload?.data[0]?._id
        ) {
          const customerObj = prevFeedbackListData.find((o) => o.isOpen);
          const customerId = customerObj?._id || null;
          const feedbacksIds =
            customerObj.customer_review
              .filter((o) => o.isOpen)
              .map((o) => o._id) || [];
          const newArr = action?.payload?.data?.map((user) => {
            const { customer_review } = user;
            const nextCustomerReview = customer_review.map((feedback, i) => {
              if (feedbacksIds.includes(feedback._id)) {
                return { ...feedback, isOpen: true };
              }
              if (i === 0 && prevFeedbackListData.length === 0) {
                return { ...feedback, isOpen: true };
              }
              return { ...feedback, isOpen: false };
            });
            return {
              ...user,
              customer_review: nextCustomerReview,
              isOpen: customerId && customerId === user._id,
            };
          })
          state.unResolvedTrustreeFeedback = action.payload?.metadata?.currentPage === prevPage ? newArr : [...prevFeedbackListData, ...newArr];
        } else {
          const newArr = action.payload?.data ? 
          action.payload?.data.map((o, i) => {
            const customeReview = [...o.customer_review];
            const customeReviewObj =
              customeReview &&
              customeReview.map((obj, index) => {
                if (index === 0 && prevFeedbackListData.length === 0) {
                  return { ...obj, isOpen: true };
                } else {
                  return { ...obj, isOpen: false };
                }
              });
            if (i === 0 && prevFeedbackListData.length === 0) {
              return { ...o, isOpen: true, customer_review: customeReviewObj };
            } else {
              return { ...o, isOpen: false, customer_review: customeReviewObj };
            }
          }) : [];
  
          state.unResolvedTrustreeFeedback = action.payload?.metadata?.currentPage === prevPage ? newArr : [...prevFeedbackListData, ...newArr];
        }
      }

      state.trustreeUnResFeedbackLoading = false;
      state.unresolvedFeedbackPaginationLoading = false;
      state.trustreeFeedbackErro = null;
      state.trustreeUnresolvedFeedbackPaginationCount = action.payload?.metadata?.total;
      state.currentUnresolvedFeedbacksPage = action.payload?.metadata?.currentPage;
    },
    
    getUnResolvedTrustreeFeedbackError: (state, action) => {
      state.trustreeUnResFeedbackLoading = false;
      state.trustreeFeedbackErro = action.payload;
    },

    getAllFeedbackCountRequest: (state) => {
      state.allFeedbackCountLoading = true;
      state.allFeedbackCountError = "";
    },
    getAllFeedbackCountSuccess: (state, action) => {
      state.allFeedbackCount = action.payload;
      state.allFeedbackCountLoading = false;
    },

    getAllFeedbackCountError: (state, action) => {
      state.allFeedbackCountLoading = false;
      state.allFeedbackCountError = action.payload;
    },

    getTotalFeedbackCountRequest: (state) => {
      state.totalFeedbackCountLoading = true;
      state.totalFeedbackCountError = "";
    },

    getResolvedFeedbackCountRequest: (state) => {
      state.resolvedFeedbackCountLoading = true;
      state.resolvedFeedbackCountError = "";
    },

    getTotalFeedbackCountSuccess: (state, action) => {
      state.totalFeedbackCounts = action.payload;
      state.totalFeedbackCountLoading = false;
    },

    getResolvedFeedbackCountSuccess: (state, action) => {
      state.resolvedFeedbackCounts = action.payload;
      state.resolvedFeedbackCountLoading = false;
    },

    getTotalFeedbackCountError: (state, action) => {
      state.totalFeedbackCountLoading = false;
      state.totalFeedbackCountError = action.payload;
    },

    getResolvedFeedbackCountError: (state, action) => {
      state.resolvedFeedbackCountLoading = false;
      state.resolvedFeedbackCountError = action.payload;
    },

    getUnresolvedFeedbackCountRequest: (state) => {
      state.unresolvedFeedbackCountLoading = true;
      state.unresolvedFeedbackCountError = "";
    },
    getUnresolvedFeedbackCountSuccess: (state, action) => {
      state.unresolvedFeedbackCounts = action.payload;
      state.unresolvedFeedbackCountLoading = false;
    },
    getUnresolvedFeedbackCountError: (state, action) => {
      state.unresolvedFeedbackCountLoading = false;
      state.unresolvedFeedbackCountError = action.payload;
    },

    isOpenModalById: (state, action) => {
      const prevFeedbackListData = current(state.trustreeFeedback);
      state.trustreeFeedback = prevFeedbackListData.map((o) => {
        const newObj = { ...o };
        if (o._id === action.payload) {
          newObj.isOpen = true;
        } else {
          newObj.isOpen = false;
        }
        return newObj;
      });
    },

    isOpenResolvedModalById: (state, action) => {
      const prevFeedbackListData = current(state.resolvedTrustreeFeedback);
      state.resolvedTrustreeFeedback = prevFeedbackListData.map((o) => {
        const newObj = { ...o };
        if (o._id === action.payload) {
          newObj.isOpen = true;
        } else {
          newObj.isOpen = false;
        }
        return newObj;
      });
    },
    isOpenUnResolvedModalById: (state, action) => {
      const prevFeedbackListData = current(state.unResolvedTrustreeFeedback);
      state.unResolvedTrustreeFeedback = prevFeedbackListData.map((o) => {
        const newObj = { ...o };
        if (o._id === action.payload) {
          newObj.isOpen = true;
        } else {
          newObj.isOpen = false;
        }
        return newObj;
      });
    },

    setOpenTrusttreeFeedbackLoading: (state, action) => {
      state.openTrusttreeFeedbackLoading = true;
    },

    setSingleFeedback: (state, action) => {
      state.openTrusttreeFeedbackLoading = false;
      state.openTrusttreeFeedback = action.payload;
    },

    isOpenFeedbackModalById: (state, action) => {
      const prevTrustreeFeedback = current(state.openTrusttreeFeedback);
      const newReview = prevTrustreeFeedback.customer_review.map((obj) => {

            const newObject = { ...obj };
            if (obj._id === action.payload) {
              newObject.isOpen = !newObject.isOpen;
            }
            return newObject;

      });
      const newFeedback = {...prevTrustreeFeedback, customer_review: newReview};
      state.openTrusttreeFeedback = newFeedback;
    },

    isOpenFeedbackResolvedModalById: (state, action) => {
      const prevFeedbackListData = current(state.resolvedTrustreeFeedback);
      state.resolvedTrustreeFeedback = prevFeedbackListData.map((o) => {
        const customeReview = [...o.customer_review];
        const customeReviewObj =
          customeReview &&
          customeReview.map((obj) => {
            const newObject = { ...obj };
            if (obj._id === action.payload) {
              newObject.isOpen = !newObject.isOpen;
            }
            return newObject;
          });
        return { ...o, customer_review: customeReviewObj };
      });
    },
    isOpenFeedbackUnResolvedModalById: (state, action) => {
      const prevFeedbackListData = current(state.unResolvedTrustreeFeedback);
      state.unResolvedTrustreeFeedback = prevFeedbackListData.map((o) => {
        const customeReview = [...o.customer_review];
        const customeReviewObj =
          customeReview &&
          customeReview.map((obj) => {
            const newObject = { ...obj };
            if (obj._id === action.payload) {
              newObject.isOpen = !newObject.isOpen;
            }
            return newObject;
          });
        return { ...o, customer_review: customeReviewObj };
      });
    },

    setTrustreeFeedbackFilter: (state, action) => {
      state.trustreeFeedbackFilter = { ...action.payload.filter };
      state.appliedTrustreeFeddbackFilter =
        getAppliedFiltersForTrustreeFeedback(action.payload);
    },
    TrustreefilterToggle: (state, action) => {
      if (action.payload === "branch_id") {
        state.branchFilter = !state.branchFilter;
      } else if (action.payload === "experience_type_id") {
        state.expTypeFilter = !state.expTypeFilter;
      } else if (action.payload === "rating") {
        state.ratingFilter = !state.ratingFilter;
      } else if (action.payload === "staff_id") {
        state.staffFilter = !state.staffFilter;
      } else if (action.payload === "date") {
        state.dateFilter = !state.dateFilter;
      }
    },
    clearAllFeedback: (state) => {
      state.trustreeFeedbackLoading = true;
      state.trustreeResFeedbackLoading = true;
      state.trustreeUnResFeedbackLoading = true;
    },
  },
});
const getAppliedFiltersForTrustreeFeedback = (filters) => {
  let appliedTrustreeFeddbackFilter = [];
  if (filters?.filter?.branch_id && filters?.filter?.branch_id.length > 0) {
    appliedTrustreeFeddbackFilter.push({ title: "Branch", value: "branch_id" });
  }
  if (
    filters?.filter?.experience_type_id &&
    filters?.filter?.experience_type_id.length > 0
  ) {
    appliedTrustreeFeddbackFilter.push({
      title: "EXPERIENCE_TYPE",
      value: "experience_type_id",
    });
  }
  if (filters?.filter?.staff_id && filters?.filter?.staff_id.length > 0) {
    appliedTrustreeFeddbackFilter.push({
      title: "STAFF",
      value: "staff_id",
    });
  }
  if (filters?.filter?.rating && filters?.filter?.rating.length > 0) {
    appliedTrustreeFeddbackFilter.push({ title: "Rating", value: "rating" });
  }
  if (
    filters?.filter?.date &&
    filters?.filter?.date?.length > 0 &&
    ((filters?.filter?.date[0] && filters?.filter?.date[0] === null) ||
      (filters?.filter?.date[1] && filters?.filter?.date[1] === null))
  ) {
    appliedTrustreeFeddbackFilter.remove({ title: "DATE", value: "date" });
  } else if (filters?.filter?.date && filters?.filter?.date?.start) {
    appliedTrustreeFeddbackFilter.push({ title: "DATE", value: "date" });
  }
  return appliedTrustreeFeddbackFilter;
};

export const {
  getTrustreeFeedbackRequest,
  getTrustreeFeedbackSuccess,
  getTrustreeFeedbackError,
  getResolvedTrustreeFeedbackRequest,
  getResolvedTrustreeFeedbackSuccess,
  getResolvedTrustreeFeedbackError,
  getUnResolvedTrustreeFeedbackRequest,
  getUnResolvedTrustreeFeedbackSuccess,
  getUnResolvedTrustreeFeedbackError,
  isOpenModalById,
  isOpenFeedbackModalById,
  isOpenFeedbackResolvedModalById,
  isOpenFeedbackUnResolvedModalById,
  setTrustreeFeedbackFilter,
  isOpenResolvedModalById,
  isOpenUnResolvedModalById,
  TrustreefilterToggle,
  getTotalFeedbackCountRequest,
  getTotalFeedbackCountError,
  getTotalFeedbackCountSuccess,
  clearAllFeedback,
  getResolvedFeedbackCountRequest,
  getResolvedFeedbackCountSuccess,
  getResolvedFeedbackCountError,
  getUnresolvedFeedbackCountRequest,
  getUnresolvedFeedbackCountSuccess,
  getUnresolvedFeedbackCountError,
  setSingleFeedback,
  setOpenTrusttreeFeedbackLoading, 
  getAllFeedbackCountRequest,
  getAllFeedbackCountSuccess,
  getAllFeedbackCountError,
  setCurrentAllFeedbacksPage,
  getAllFeedbackPaginationRequest,
  getResolvedFeedbackPaginationRequest,
  getUnresolvedFeedbackPaginationRequest
} = trustreeFeedbackSlice.actions;

export default trustreeFeedbackSlice.reducer;
