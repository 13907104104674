import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getbrandsforDropdown } from "../../../middlewares/brands";
import QrCodes from "./QrCodes";
import downArrow from "../../../assets/images/downArrow.svg";
import brandImg from "../../../assets/images/Brandlogo.png";
import PlaceholderLoader from "../../user-listing/PlaceholderLoader";
import { totalFeedbackCount } from "../../../middlewares/trustreeFeedbacks";
import expImg from "../../../assets/images/exp-img.png";
import { cancelAllPendingRequests } from "../../../services";

const LinksAndQrCodes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dropDownBrands: brands, dropDownBrandsLoading } = useSelector(
    (state) => state.brand
  );
  const [selectBrand, setSelectBrand] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const b = localStorage.getItem("b_id");

  useEffect(() => {
    if (b) {
      let filter = {};
      const payload = {
        filter,
      };
      dispatch(totalFeedbackCount(b, payload.filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b]);

  useEffect(() => {
    dispatch(getbrandsforDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      cancelAllPendingRequests();
    }
  }, []);

  useEffect(() => {
    if (!dropDownBrandsLoading && initialLoading && brands) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownBrandsLoading]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      if (b) {
        const findBrandObj = brands.find((o) => o._id === b);
        if (findBrandObj) {
          setSelectBrand(b);
        } else {
          localStorage.setItem("b_id", brands[0]._id);
          setSelectBrand(brands[0]._id);
        }
      } else {
        localStorage.setItem("b_id", brands[0]._id);
        setSelectBrand(brands[0]._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, selectBrand]);

  const handleChange = (e) => {
    setSelectBrand(e);
    localStorage.setItem("b_id", e);
  };

  const brand_name =
    brands &&
    brands.find((obj) => {
      return obj._id === b;
    });

  return (
    <div className="contentWrap">
      <div className="contentHead justify-content-between">
        <h1>{t("LINKS_AND_QR_CODES")}</h1>
        <Form>
          {!initialLoading && (
            <div className="customSelect ms-auto staticIcon max-width-245 multipul">
              {brand_name && (
                <Dropdown className="ellipsis">
                  <Dropdown.Toggle className="form-control brand-toggle">
                    <div className="d-flex w-100 align-items-center ImgSelect">
                      <span>
                        {brand_name.logo ? (
                          <img
                            src={
                              `${process.env.REACT_APP_BASE_URL}${brand_name.logo}` ||
                              require("../../../assets/images/Brandlogo.png")
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://api.app.trustree.com/images/Brandlogo.png";
                            }}
                            alt="logo"
                          />
                        ) : (
                          <img src={brandImg} alt="logo" />
                        )}
                      </span>
                      <span
                        style={{ paddingRight: "30px" }}
                        className="dropdown-value ms-2"
                      >
                        {brand_name.name}
                      </span>
                      <span className="d-Arrow brandArrow">
                        <img src={downArrow} alt="downArrow" />
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={"span"} disabled>
                      {t("SELECT_BRAND")}
                    </Dropdown.Item>
                    {brands &&
                      brands.length > 0 &&
                      brands.map((o, i) => (
                        <Dropdown.Item
                          as={"span"}
                          value={o._id}
                          key={i}
                          onClick={() => handleChange(o._id)}
                        >
                          <span>
                            {o.logo ? (
                              <img
                                src={
                                  `${process.env.REACT_APP_BASE_URL}${o.logo}` ||
                                  require("../../../assets/images/Brandlogo.png")
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://api.app.trustree.com/images/Brandlogo.png";
                                }}
                                alt="logo"
                              />
                            ) : (
                              <img src={brandImg} alt="logo" />
                            )}
                          </span>
                          {o.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </Form>
      </div>
      {dropDownBrandsLoading && !initialLoading ? (
        <PlaceholderLoader />
      ) : (
        <>
          {brands && brands.length > 0 ? (
            brand_name ? (
              <QrCodes selectBrand={selectBrand} brandDetails={brand_name} />
            ) : (
              <div className="experience-wpr flex">
                <div className="experience-inner">
                  <img src={expImg} alt="loadding" />
                  <p>{t("NO_BRAND_IS_SELECTED")}</p>
                </div>
              </div>
            )
          ) : (
            <div className="experience-wpr flex">
              <div className="experience-inner">
                <img src={expImg} alt="loadding" />
                <p>{t("NO_BRANDS")}</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LinksAndQrCodes;
