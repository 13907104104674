import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackDataforPreview } from "../../middlewares/feedbacks";
import PreviewSurvey from "../../preview/pages";
import NoPreview from "../../preview/pages/NoPreview";
import {
  setFacebookLogo,
  setGoogleLogo,
  setInstagramLogo,
  setPreviewLogo,
  setRewardStatus,
  setButtonColor,
  setButtonTextColor,
  setIndex,
  setQuestionIndex,
} from "../../slices/feedbacks.slice";

const IFrame = ({ selectBrand, expId, data }) => {
  const dispatch = useDispatch();
  const { buttonColor, previewLoading, previewData, languageData } =
    useSelector((state) => state.feedbacks);

  useEffect(() => {
    if (selectBrand) {
      const payload = {
        brand_id: selectBrand,
      };
      if (expId) {
        payload.experience_type_id = expId;
      }
      dispatch(getFeedbackDataforPreview(payload));
      dispatch(setIndex(0));
      dispatch(setQuestionIndex(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBrand, expId, data]);

  useEffect(() => {
    if (previewData) {
      dispatch(setPreviewLogo(previewData?.brand_design?.show_logo));
      dispatch(setRewardStatus(previewData?.rewards?.is_reward_on));
      dispatch(setFacebookLogo(previewData?.social_media?.is_facebook_on));
      dispatch(setInstagramLogo(previewData?.social_media?.is_instagram_on));
      dispatch(setGoogleLogo(previewData?.social_media?.is_google_on));
      dispatch(setButtonColor(previewData?.brand_design?.button_colour));
      dispatch(
        setButtonTextColor(previewData?.brand_design?.button_text_colour)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewData]);

  return (
    <>
      <div className="MobileScreen">
        <div className="dataContect">
          {previewLoading ? (
            <Spinner
              className="MobileSpinner"
              style={{
                color: `${
                  buttonColor
                    ? buttonColor
                    : previewData?.brand_design?.button_colour
                }`,
              }}
              animation="border"
            />
          ) : (
            <>
              {previewData._id === undefined ? (
                <NoPreview />
              ) : (
                <PreviewSurvey
                  previewData={previewData}
                  languageData={languageData}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default IFrame;
