import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RewardAchievedModal = ({ data, show, setIsShow }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsShow(!show);
  };

  return (
    <Modal show={show} className="modal-mg">
      <Modal.Header>
        <Modal.Title className="cust-title">{t("REWARD_ACHIEVED")}</Modal.Title>
        <div className="">
          <Button className="close_btn" onClick={() => handleClose()}>
            <FontAwesomeIcon icon={`xmark`} />
          </Button>
        </div>
      </Modal.Header>

      <div className="mt-3">
        <div>
          {data.achievedDates.map((d, i) => (
            <p>
              {`${i + 1}. The reward achieved on `}
              <b>{new Date(d).toLocaleDateString()}</b>
            </p>
          ))}
        </div>
        <div className="justify-content-center d-flex w-100 mt-5">
          <Button
            type="btn"
            className="btn ml-1 w-20 "
            onClick={() => handleClose()}
          >
            {t("SAVE")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RewardAchievedModal;
