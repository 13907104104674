import Pagination from "react-bootstrap/Pagination";

const PaginationHelper = ({
  prev,
  prevDisabled,
  next,
  nextDisabled,
  items,
  itemClick,
  activeItem,
  maxCount,
  first_page,
  last_page,
}) => {
  let pages = [];
  const count = maxCount-1
  let min = activeItem - Math.floor(count / 2);
  const max = Math.max(min, 1) + count;
  if (min - items > -count) {
    min = items - count;
  }

  for (let i = Math.max(min, 1); i <= Math.min(max, items); i++) {
    pages.push(
      <Pagination.Item
        key={i}
        onClick={() => itemClick(i)}
        active={i === activeItem}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination size="sm">
      <Pagination.First onClick={first_page} disabled={prevDisabled} />
      <Pagination.Prev onClick={prev} disabled={prevDisabled} />
      {pages}
      <Pagination.Next onClick={next} disabled={nextDisabled} />
      <Pagination.Last onClick={last_page} disabled={nextDisabled} />
    </Pagination>
  );
};

export default PaginationHelper;
