import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import {
  brand_name,
  brand_selection,
} from "../../helpers/yup.validation.schema";
import BrandImage from "../brand/BrandImage";
import { useTranslation } from "react-i18next";
import { brand_category_data } from "../../helpers/jsonData";
import downArrow from "../../assets/images/downArrow.svg";
import styles from "./brandModal.module.css";
import { useEffect } from "react";

/**
 * @typedef {{name: string, category: string, logo?: File[]}} Brand
 * @param {{show: boolean, centered: boolean, initialBrand?: Brand, onSubmit: (brand?: Brand) => void}} props
 * @returns
 */
function BrandsAddModal({ show, centered, onSubmit, initialBrand }) {
  const { t } = useTranslation();

  const addBrandSchema = Yup.object().shape({
    name: brand_name,
    category: brand_selection,
  });

  const handleClose = () => {
    formik.resetForm();
    onSubmit();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
    },
    validationSchema: addBrandSchema,
    onSubmit: (val) => {
      onSubmit(val);
      formik.resetForm();
    },
  });

  const brand_category_label =
    brand_category_data &&
    brand_category_data.find((obj) => {
      return obj.value === formik.values.category;
    });

  useEffect(() => {
    initialBrand && formik.setValues(initialBrand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBrand]);

  return (
    <Modal show={show} centered={centered} className="modal-lg">
      <Modal.Header>
        <Modal.Title className="cust-title">
          {initialBrand ? t("UPDATE_BRAND") : t("ADD_NEW_BRAND_ONBOARDING")}
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body className={styles.modalBody}>
          <div>
            <div className={styles.inputGroup}>
              <Form.Label>{t("BRAND_NAME")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className={`${
                  formik.touched.name && formik.errors.name && "is-invalid"
                }`}
                placeholder={t("INPUT_BRAND_NAME")}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback d-block">
                  {formik.errors.name}
                </div>
              )}
            </div>
            <div className={styles.inputGroup}>
              <Form.Label>{t("BRAND_CATEGORY")}</Form.Label>
              <Dropdown className="ellipsis">
                <Dropdown.Toggle
                  name="category"
                  onBlur={formik.handleBlur}
                  className={`form-control ${
                    formik.touched.category &&
                    formik.errors.category &&
                    "is-invalid"
                  }`}
                >
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <span className="dropdown-value">
                      {t(brand_category_label.label)}
                    </span>
                    <span className="d-Arrow">
                      <img src={downArrow} alt="downArrow" />
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {brand_category_data &&
                    brand_category_data.map((o, i) => (
                      <Dropdown.Item
                        as={"span"}
                        value={o.value}
                        key={o.value}
                        onClick={() =>
                          formik.setFieldValue("category", o.value)
                        }
                      >
                        {t(o.label)}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              {formik.touched.category && formik.errors.category && (
                <div className="invalid-feedback d-block">
                  {formik.errors.category}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={styles.inputGroup}>
              <div className={styles.brandImageWrapper}>
                <label className="form-label">{t("BRAND_LOGO")}</label>
                <BrandImage
                  setFieldValue={formik.setFieldValue}
                  logo={formik.values.logo}
                  multiple={false}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="Experience-btn-modal flex justify-content-center">
          <Button
            type="btn"
            className="btn"
            onClick={formik.handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
          >
            {t("SAVE")}
          </Button>
          <Button
            className="btn btn-outline-secondary"
            onClick={() => handleClose()}
          >
            {t("CANCEL")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default BrandsAddModal;
