import React, { useState, useEffect } from "react";
import plus from "../../../assets/images/add-square.svg";
import downArrow from "../../../assets/images/downArrow.svg";
import ellipsis from "../../../assets/images/Ellipsis.svg";
import disableButton from "../../../assets/images/disable.svg";
import enableButton from "../../../assets/images/enable.svg";
import editButton from "../../../assets/images/brand-edit.svg";
import deleteButton from "../../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { getbrandListing } from "../../../middlewares/brands";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlaceholderLoader from "../../user-listing/PlaceholderLoader";
import brandImg from "../../../assets/images/Brandlogo.png";
import DisableModal from "../../../components/confirmation-modal/DisableModal";
import { cancelAllPendingRequests } from "../../../services";
import {
  deleteTeamRewards,
  disableTeamRewards,
  getQuickOverview,
  getTeamRewards,
  getTeamRewardsById,
  addRewards,
  updateTeamRewards,
} from "../../../middlewares/teamRewards";
import expImg from "../../../assets/images/exp-img.png";
import { getBranchesByBrandId } from "../../../middlewares/branches";
import { getExperienceTypeByBrandId } from "../../../middlewares/experienceType";
import DeleteModal from "../../../components/confirmation-modal/DeleteModal";
import RewardAchievedModal from "./RewardAchievedModal";
import TeamRewardModal from "../../../components/team-reward/TeamRewardModal";
import ToastService from "../../../helpers/toast-services";
import { resetForm } from "../../../slices/teamrewards.slice";

const TeamReward = () => {
  useEffect(() => {
    return () => {
      cancelAllPendingRequests();
    };
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [teamRewardModal, setTeamRewardModal] = useState(false);
  const [achievedModal, setAchievedModal] = useState(false);
  const [rewardData, setRewardData] = useState();
  const [deleteObj, setDeleteObj] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteObjTitle, setDeleteObjTitle] = useState("");
  const [isDisableModal, setIsDisableModal] = useState(false);
  const [disableObjTitle, setDisableObjTitle] = useState("");
  const [disableObj, setDisableObj] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialBranchesLoading, setInitialBranchesLoading] = useState(true);
  const [initialRewardsLoading, setInitialRewardsLoading] = useState(true);
  const {
    brands,
    loading,
    saveMessage,
    saveUpdateMessage,
    disEnableMessage,
    branchesListingLoading,
  } = useSelector((state) => state.brand);
  const {
    teamRewards,
    reportLodaing,
    singleTeamRewards,
    saveTeamRewardLoading,
    saveRewardLoading,
    saveRewardMessage,
    deleteRewardMessage,
    disableMessage,
    disableLoading,
    deleteRewardLoading,
    saveTeamRewardMessage,
    updateLoading,
  } = useSelector((state) => state.teamReward);

  const { experienceTypeData } = useSelector((state) => state.experience);
  const { branches } = useSelector((state) => state.branch);
  const b = localStorage.getItem("b_id");
  const [sort, setSort] = useState([
    {
      key: "reward",
      column: "reward",
      value: "Reward",
      order: -1,
      activeSort: true,
    },
    {
      key: "branch",
      column: "branch",
      value: "Branchs",
      order: -1,
      activeSort: false,
    },
    {
      key: "experience_type",
      column: "experience_type",
      value: "Experience Type",
      order: -1,
      activeSort: false,
    },
    {
      key: "staff",
      column: "staff",
      value: "Staff",
      order: -1,
      activeSort: false,
    },
    {
      key: "targets",
      column: "targets",
      value: "Targets",
      order: -1,
      activeSort: false,
    },
    {
      key: "reward_achieved",
      column: "reward_achieved",
      value: "Reward Achieved",
      order: -1,
      activeSort: false,
    },
    {
      key: "period",
      column: "period",
      value: "Period",
      order: -1,
      activeSort: false,
    },
    {
      key: "status",
      column: "status",
      value: "Status",
      order: -1,
      activeSort: false,
    },
    {
      key: "",
      column: "",
      value: "",
      order: -1,
      activeSort: false,
    },
  ]);
  const [mobileView, setMobileView] = useState(false);
  const [selectBrand, setSelectBrand] = useState("");

  useEffect(() => {
    if (selectBrand) {
      dispatch(getTeamRewards(selectBrand));
    }
  }, [
    selectBrand,
    saveRewardMessage,
    saveTeamRewardMessage,
    disableMessage,
    deleteRewardMessage,
  ]);

  useEffect(() => {
    // Call the API on page load or whenever an event occurs
    if (selectBrand && teamRewards?.length > 0) {
      const payload = {
        filter: {
          branch_id: [],
          experience_type_id: [],
          rating: [],
          category_id: [],
          staff_id: [],
          date: [null, null],
        },
      };

      dispatch(getQuickOverview(payload, selectBrand));
    }

    // Check if the API call has already been made today
    const lastApiCallDate = localStorage.getItem("lastApiCallDate");
    const currentDate = new Date();
    const today = currentDate.toISOString().slice(0, 10);

    if (lastApiCallDate !== today) {
      // If not, make the API call and update the localStorage flag
      const payload = {
        filter: {
          branch_id: [],
          experience_type_id: [],
          rating: [],
          category_id: [],
          staff_id: [],
          date: [null, null],
        },
      };

      dispatch(getQuickOverview(payload, selectBrand));

      localStorage.setItem("lastApiCallDate", today);
    }

    // Schedule a daily check at 12:00 am to reset the localStorage flag
    const checkTime = new Date();
    checkTime.setHours(0, 0, 0, 0);
    checkTime.setDate(checkTime.getDate() + 1);

    const timeUntilMidnight = checkTime - currentDate;
    const timer = setTimeout(() => {
      localStorage.removeItem("lastApiCallDate");
    }, timeUntilMidnight);

    return () => clearTimeout(timer); // Clear the timer when the component unmounts
  }, [selectBrand, teamRewards]);

  useEffect(() => {
    if (selectBrand) {
      dispatch(getBranchesByBrandId(selectBrand));
      dispatch(getExperienceTypeByBrandId(selectBrand));
    }
  }, [selectBrand]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      if (b) {
        const findBrandObj = brands.find((o) => o._id === b);
        if (findBrandObj) {
          setSelectBrand(b);
        } else {
          localStorage.setItem("b_id", brands[0]._id);
          setSelectBrand(brands[0]._id);
        }
      } else {
        localStorage.setItem("b_id", brands[0]._id);
        setSelectBrand(brands[0]._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, selectBrand]);

  useEffect(() => {
    setMobileView(window.innerWidth < 600);
    window.addEventListener("resize", () =>
      setMobileView(window.innerWidth < 600)
    );

    return () => {
      window.removeEventListener("resize", () =>
        setMobileView(window.innerWidth < 600)
      );
    };
  }, []);
  const perPageBrand = 25;
  const currentPage = 1;

  const brand_name =
    brands &&
    brands.find((obj) => {
      return obj._id === b;
    });

  const handleSortChange = (clickedKey) => {
    const updatedSort = [...sort];

    updatedSort.forEach((item) => {
      if (item.key === clickedKey) {
        item.order = item.order === -1 ? 1 : -1;
      } else {
        item.order = -1;
      }
    });
    setSort(updatedSort);
  };

  useEffect(() => {
    if (!loading && initialLoading && brands) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!branchesListingLoading && initialBranchesLoading && brands) {
      setInitialBranchesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesListingLoading]);

  useEffect(() => {
    if (!reportLodaing && initialRewardsLoading && teamRewards) {
      setInitialRewardsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportLodaing]);

  useEffect(() => {
    const payload = {
      perPage: perPageBrand,
      currentPage: currentPage,
    };
    dispatch(getbrandListing(payload));
  }, [saveMessage, saveUpdateMessage, disEnableMessage]);

  useEffect(() => {
    if (!saveTeamRewardLoading) {
      if (saveTeamRewardMessage) {
        ToastService.success(saveTeamRewardMessage);
        setTeamRewardModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveTeamRewardMessage]);

  useEffect(() => {
    if (!saveRewardLoading) {
      if (saveRewardMessage) {
        ToastService.success(saveRewardMessage);
        setTeamRewardModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveRewardMessage]);

  const handleChange = (e) => {
    setSelectBrand(e);
    localStorage.setItem("b_id", e);
  };

  const handleEditOpen = (e) => {
    const select_id = e;
    dispatch(getTeamRewardsById(selectBrand, select_id));
    setTeamRewardModal(true);
  };

  const handleModalOpen = (e) => {
    setRewardData(e);
    setAchievedModal(true);
  };

  const handleDeleteModal = (e, title) => {
    setDeleteObj(e);
    setDeleteObjTitle(title);
    setIsDeleteModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteTeamRewards(id));
  };

  const handleDisableModal = (e, title) => {
    setDisableObj(e);
    setDisableObjTitle(title);
    setIsDisableModal(true);
  };

  const handleDisable = (id) => {
    dispatch(disableTeamRewards(id));
  };

  return (
    <div>
      <div className="brandTable experience-wpr-content">
        <div className="contentHead justify-content-between">
          <h1>{t("TEAM_REWARDS")}</h1>
          <Form className={` ${!mobileView ? "" : "w-100"} `}>
            {!initialLoading && (
              <div
                className={`customSelect ${
                  !mobileView ? "ms-auto" : "mt-4 "
                } staticIcon`}
              >
                {brand_name && (
                  <Dropdown className="ellipsis">
                    <Dropdown.Toggle className="form-control">
                      <div className="d-flex w-100 align-items-center ImgSelect">
                        <span>
                          {brand_name.logo ? (
                            <img
                              src={
                                `${process.env.REACT_APP_BASE_URL}${brand_name.logo}` ||
                                require("../../../assets/images/Brandlogo.png")
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://api.app.trustree.com/images/Brandlogo.png";
                              }}
                              alt="logo"
                            />
                          ) : (
                            <img src={brandImg} alt="logo" />
                          )}
                        </span>
                        <span
                          style={{ paddingRight: "30px" }}
                          className="dropdown-value ms-2"
                        >
                          {brand_name.name}
                        </span>
                        <span className="d-Arrow brandArrow">
                          <img src={downArrow} alt="downArrow" />
                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={"span"} disabled>
                        {t("SELECT_BRAND")}
                      </Dropdown.Item>
                      {brands &&
                        brands.length > 0 &&
                        brands.map((o, i) => (
                          <Dropdown.Item
                            as={"span"}
                            value={o._id}
                            key={i}
                            onClick={() => handleChange(o._id)}
                          >
                            <span>
                              {o.logo ? (
                                <img
                                  src={
                                    `${process.env.REACT_APP_BASE_URL}${o.logo}` ||
                                    require("../../../assets/images/Brandlogo.png")
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://api.app.trustree.com/images/Brandlogo.png";
                                  }}
                                  alt="logo"
                                />
                              ) : (
                                <>
                                  <img src={brandImg} alt="logo" />
                                </>
                              )}
                            </span>
                            {o.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
          </Form>
        </div>
        {reportLodaing && loading && initialRewardsLoading ? (
          <div className="mt-3">
            <PlaceholderLoader />
          </div>
        ) : (
          <>
            {teamRewards && teamRewards.length > 0 ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      {sort &&
                        sort.map((obj, index) => (
                          <th key={obj.key} className="width260">
                            {obj.key !== "" ? ( // Check if key is not empty
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSortChange(obj.key)}
                              >
                                {t(obj.value)}
                                {obj.order !== -1 ? (
                                  <FontAwesomeIcon
                                    key={index}
                                    icon={`angle-up`}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    key={index}
                                    icon={`angle-down`}
                                  />
                                )}
                              </span>
                            ) : (
                              // Render without icon for the empty key
                              <span>{t(obj.value)}</span>
                            )}
                          </th>
                        ))}
                      <th className="width260"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamRewards?.map((teamReward, idx) => {
                      return (
                        <tr key={idx}>
                          <td
                            className={"de-activated"}
                            style={{ padding: "19px 12px" }}
                          >
                            <p>{t(teamReward.name)}</p>
                          </td>
                          <td className={"de-activated"}>
                            <p>
                              {teamReward.branchIds
                                .map((branchId) => {
                                  const branch = branches.find(
                                    (branch) => branch._id === branchId
                                  );
                                  return branch
                                    ? branch.name
                                    : "Unknown Branch";
                                })
                                .join(", ")}
                            </p>
                          </td>
                          <td className={"de-activated"}>
                            <p>
                              {teamReward.experienceTypeIds
                                .map((expTypeId) => {
                                  const expType = experienceTypeData.find(
                                    (type) => type._id === expTypeId
                                  );
                                  return expType
                                    ? expType.title
                                    : "Unknown Experience Type";
                                })
                                .join(", ")}
                            </p>
                          </td>
                          <td className="de-activated">
                            <p>{t(teamReward.staff.length || "-")}</p>
                          </td>
                          <td className={"de-activated"}>
                            <p>{t(teamReward.targets.length) || "-"}</p>
                          </td>
                          <td
                            className={"de-activated cursor-poienter"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              teamReward.achievedDates.length &&
                                handleModalOpen(teamReward);
                            }}
                          >
                            <p>{t(teamReward.achievedDates.length || "-")}</p>
                          </td>
                          <td className={"de-activated"}>
                            <p>{t(teamReward.period)}</p>
                          </td>
                          <td className={"de-activated"}>
                            {teamReward.status === true ? (
                              <div className="status">
                                <p className="active-status">{t("ACTIVE")}</p>
                              </div>
                            ) : (
                              <div className="status">
                                <p className="disabled-status">
                                  {t("DISABLED")}
                                </p>
                              </div>
                            )}
                          </td>
                          <td className={"de-activated"}>
                            <div className="brandIcons edit-exper justify-content-center sidebar-dropdown user-dropdown">
                              <Dropdown className="dis-dropdown">
                                <Dropdown.Toggle
                                  style={{
                                    padding: "10px",
                                  }}
                                >
                                  <img src={ellipsis} alt="loadding" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ margin: 0 }}>
                                  <li className="exper-drop-ul-li">
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleDisableModal(
                                          teamReward,
                                          "REWARDS"
                                        )
                                      }
                                    >
                                      {teamReward.status === false ? (
                                        <img src={enableButton} alt="enable" />
                                      ) : (
                                        <img
                                          src={disableButton}
                                          alt="disable"
                                        />
                                      )}
                                      {teamReward.status === false
                                        ? t("ENABLE")
                                        : t("DISABLE")}
                                    </Dropdown.Item>
                                  </li>
                                  <li className="exper-drop-ul-li">
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleEditOpen(teamReward._id)
                                      }
                                    >
                                      <img src={editButton} alt="edit" />
                                      {t("EDIT")}
                                    </Dropdown.Item>
                                  </li>
                                  <li className="exper-drop-ul-li">
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleDeleteModal(teamReward, "REWARD")
                                      }
                                    >
                                      <img src={deleteButton} alt="edit" />
                                      {t("DELETE")}
                                    </Dropdown.Item>
                                  </li>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ marginTop: "10px" }}>
                  <button
                    type="button"
                    className="user-btn"
                    onClick={() => setTeamRewardModal(true)}
                  >
                    <span className="user-icon">
                      <img src={plus} alt="plus" />
                    </span>
                    {t("ADD_NEW_REWARDS")}
                  </button>
                </div>
              </>
            ) : (
              <div className="experience-wpr flex">
                <div className="experience-inner">
                  <img src={expImg} alt="loadding" />
                  <p>{t("THERE_IS_NO_REWARD_YET")}</p>
                  <div className="exper-btn">
                    <Button
                      type="button"
                      className=""
                      onClick={() => setTeamRewardModal(true)}
                    >
                      {t("ADD_NEW_REWARDS")}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <TeamRewardModal
              show={teamRewardModal}
              brandId={selectBrand}
              initialReward={singleTeamRewards}
              loading={saveTeamRewardLoading || saveRewardLoading}
              initialRewardLoading={updateLoading}
              onSubmit={(teamReward) => {
                if (teamReward) {
                  dispatch(
                    singleTeamRewards
                      ? updateTeamRewards(
                          { ...teamReward, brandId: selectBrand },
                          singleTeamRewards._id
                        )
                      : addRewards({ ...teamReward, brandId: selectBrand })
                  );
                } else {
                  setTeamRewardModal(false);
                  dispatch(resetForm());
                }
              }}
            />
            {achievedModal && (
              <RewardAchievedModal
                show={achievedModal}
                setIsShow={(flag) => setAchievedModal(flag)}
                b_id={selectBrand}
                data={rewardData}
              />
            )}
            {isDeleteModal && (
              <DeleteModal
                show={isDeleteModal}
                setIsShow={(flag) => setIsDeleteModal(flag)}
                name={deleteObj?.title || deleteObj?.name}
                title={deleteObjTitle}
                click={() => handleDelete(deleteObj._id)}
                Loading={deleteRewardLoading}
                Message={deleteRewardMessage}
              />
            )}
            {isDisableModal && (
              <DisableModal
                show={isDisableModal}
                setIsShow={(flag) => setIsDisableModal(flag)}
                name={disableObj?.title || disableObj?.name}
                objDisabled={!disableObj?.status}
                title={disableObjTitle}
                click={() => handleDisable(disableObj._id)}
                Loading={disableLoading}
                Message={disableMessage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TeamReward;
