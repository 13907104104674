/**
 * @typedef {import("@fortawesome/free-solid-svg-icons").IconDefinition} IconDefinition
 * @param {{faIcon: IconDefinition, fill: string, width: number|string, height: number|string }} props
 * @returns
 */
const FaIcon = ({ width, height, faIcon, fill }) => {
  const [w, h, aliases, unicode, svgPathData] = faIcon.icon;
  return (
    <svg width={width || w} height={height || h} viewBox={`0 0 ${w} ${h}`}>
      <path fill={fill || "inherit"} d={svgPathData}></path>
    </svg>
  );
};

export default FaIcon;
