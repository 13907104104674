import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Button, Dropdown, Form, Modal, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import starOne from "../../../assets/images/starOne.svg";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar";
import { dateAndTimeFormat } from "../../../helpers/dateFormat";
import ellipsis from "../../../assets/images/Ellipsis.svg";
import trash from "../../../assets/images/trash.svg";
import closeSquare from "../../../assets/images/closeSquare.svg";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { reply } from "../../../helpers/yup.validation.schema";
import { useDispatch } from "react-redux";
import {
  deleteFeedback,
  replyFeedback,
  resolveFeedback,
} from "../../../middlewares/feedbacks";
import ToastService from "../../../helpers/toast-services";
import { clearState } from "../../../slices/feedbacks.slice";
import DeleteModal from "../../../components/confirmation-modal/DeleteModal";
import { useReactToPrint } from "react-to-print";
import { LightenDarkenColor } from "../../../helpers/colorGenerator";
/* eslint-disable */

const CustomerFeedbackModalReport = ({ show, setIsShow, feedbackData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef();
  const onAfterPrint = useRef();
  const [highest, setHighest] = useState("");
  const [lowest, setLowest] = useState("");
  const [isLowestRatedQuestion, setIsLowestRatedQuestion] = useState(null);
  const [isHighestRatedQuestion, setIsHighestRatedQuestion] = useState(null);
  const [isOverAllRating, setIsOverAllRating] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { userProfile } = useSelector((state) => state.user);
  const { feedbackModalLoading } = useSelector((state) => state.summaryReport);

  useEffect(() => {
    if (dataLoaded) {
      onBeforeGetContentResolve.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, onBeforeGetContentResolve]);

  useEffect(() => {
    if (feedbackData?.feed_back) {
      feedbackData?.feed_back?.map((o) => {
        let newArray = o?.answer?.category;
        if (newArray && newArray.length > 0) {
          const overAllRating = newArray.find(
            (o) => o?.category_name?.["en"] === "Overall Experience"
          );
          let sortRatingAsec = newArray.slice().sort((a, b) => {
            let rating1 = a.ratings;
            let rating2 = b.ratings;
            if (rating1 < rating2) {
              return -1;
            }
            if (rating1 > rating2) {
              return 1;
            }
            return 0;
          });
          setIsOverAllRating(overAllRating);
          setHighest(
            sortRatingAsec && sortRatingAsec[sortRatingAsec.length - 1]
          );
          setLowest(sortRatingAsec && sortRatingAsec[0]);
          if (
            sortRatingAsec?.[0]?.category_name?.en === "Overall Experience" &&
            feedbackData?.feed_back.find((i) => i.sequence === 1)
          ) {
            let LowestRatedQuestion = feedbackData?.feed_back.find(
              (i) => i.sequence === 1
            );
            setIsLowestRatedQuestion(LowestRatedQuestion);
          } else {
            setIsLowestRatedQuestion(null);
          }
          if (
            sortRatingAsec?.[sortRatingAsec.length - 1]?.category_name?.en ===
              "Overall Experience" &&
            feedbackData?.feed_back.find((i) => i.sequence === 2)
          ) {
            let highestRatedQuestion = feedbackData?.feed_back?.find(
              (i) => i.sequence === 2
            );
            setIsHighestRatedQuestion(highestRatedQuestion);
          } else {
            setIsHighestRatedQuestion(null);
          }
        }
        return o;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackData]);

  const handleSubmit = (values) => {
    const payload = {
      feedback_id: feedbackData._id,
      reply: values.reply,
    };
    dispatch(replyFeedback(payload));
    formik.resetForm();
  };

  const handleResolve = (id) => {
    const payload = {
      feedback_id: id,
    };
    dispatch(resolveFeedback(payload));
  };

  const handleDelete = (title, name) => {
    setDeleteObj(name);
    setDeleteObjTitle(title);
    setIsDeleteModal(true);
  };

  const handleDeleteFeedback = () => {
    dispatch(deleteFeedback(feedbackData?._id));
  };

  const replyschema = Yup.object().shape({
    reply: reply,
  });

  const formik = useFormik({
    initialValues: {
      reply: "",
    },
    validationSchema: replyschema,
    onSubmit: handleSubmit,
  });

  const handleShareModalOpen = () => {
    setIsOpen(true);
  };

  const handleOnBeforeGetContent = () => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setDataLoaded(true);
    });
  };

  const handleStateFalse = () => {
    return new Promise((resolve) => {
      onAfterPrint.current = resolve;
      setDataLoaded(false);
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleStateFalse,
  });

  const [showSingleFeedback, setShowSingleFeedback] = useState(true);

  useEffect(() => {
    if(show) setShowSingleFeedback(true);
  }, [show]);

  

  return (
    <>
      <Modal
        show={show}
        className="modal-lg cust-feedback"
        onHide={() => setIsShow(false)}
      >
        <Form>
          <Modal.Body className="spinerBody">
            {feedbackModalLoading ? (
              <Spinner style={{ color: "#56e2b8", marginTop: "100px", paddingTop: 0 }} animation="border" />
            ) : (
              <div className={`feedbackRight ${showSingleFeedback ? "" : "hideSingleFeedback"}`}>
              <div className="feedbackRightHead">
                  <ul>
                    <li>
                      <h3>
                        {feedbackData?.customer?.[0]?.name
                          ? feedbackData?.customer?.[0]?.name
                          : t("ANONYMOUS_CUSTOMER")}
                      </h3>
                      <p>
                        {t("Phone Number")}:{" "}
                        <strong>
                          {feedbackData?.customer?.[0]?.is_anonymous
                            ? t("ANONYMOUS_CUSTOMER")
                            : feedbackData?.customer?.[0]?.phone_number
                            ? feedbackData?.customer?.[0]?.phone_number
                            : t("ANONYMOUS_CUSTOMER")}
                        </strong>
                      </p>
                      <p>
                        {t("Email")}:{" "}
                        <strong>
                          {feedbackData?.customer?.[0]?.is_anonymous
                            ? t("ANONYMOUS_CUSTOMER")
                            : feedbackData?.customer?.[0]?.email
                            ? feedbackData?.customer?.[0]?.email
                            : t("ANONYMOUS_CUSTOMER")}
                        </strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        {t("AVERAGE_RATING")}{" "}
                        <strong>{feedbackData?.rating_avg?.toFixed(1)}</strong>{" "}
                        <em>
                          <img src={starOne} alt="star" />
                        </em>
                      </p>
                      <p>
                        {t("RATINGS")}:{" "}
                        <strong>
                          {" "}
                          {feedbackData?.customer?.[0]?.feedback_count
                            ? feedbackData?.customer?.[0]?.feedback_count
                            : "0"}
                        </strong>
                      </p>
                      <p>
                        {t("REDEEMED_VOUCHERS")}{" "}
                        <strong>
                          {feedbackData?.customer?.[0]?.redeemed_rewards
                            ? feedbackData?.customer?.[0]?.redeemed_rewards
                            : "0"}
                        </strong>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="flex justify-content-center align-items-center m-3">
                  <div className="feedbackRight">
                    <div className="feedbackRightHead">
                      <ul>
                        <li>
                          <div className="DominosFeedback">
                            <div>
                              <h4>
                                {t("BRANCH_CUST")}{" "}
                                <span>{feedbackData?.branch?.name}</span>{" "}
                              </h4>{" "}
                            </div>

                            <div>
                              <h4>
                                {t("EXPERIENCE_TYPE_CUST")}{" "}
                                <span>
                                  {feedbackData?.experience_type?.title}
                                </span>{" "}
                              </h4>{" "}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="DominosFeedback">
                            <div>
                              <h4>
                                {t("RATING_CUST")}{" "}
                                <span>
                                  {isOverAllRating?.ratings
                                    ? parseFloat(
                                        isOverAllRating?.ratings
                                      ).toFixed(1)
                                    : 0}
                                </span>{" "}
                                <em>
                                  <img src={starOne} alt="" />
                                </em>
                              </h4>{" "}
                            </div>

                            <div>
                              <h4>
                                {t("DATE_CUST")}{" "}
                                <span>
                                  {dateAndTimeFormat(feedbackData?.createdAt)}
                                </span>
                              </h4>{" "}
                            </div>
                          </div>
                        </li>
                        <li>
                          <button className="closeButton" onClick={(e) => {
                            e.preventDefault();
                            setShowSingleFeedback(false);
                          }}><img src={closeSquare}></img></button>
                          <div>
                            {feedbackData?.resolved ? (
                              <h4 className="resolvedGreen">{t("RESOLVED")}</h4>
                            ) : (
                              <h4 className="unresolvedRed">
                                {t("UNRESOLVED")}
                              </h4>
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="feedbackRightSection">
                      <ul className="feedbackRightSectionUl">
                        {lowest?.category_name?.en === "Overall Experience" &&
                          isLowestRatedQuestion !== null && (
                            <li className="feedbackRightSectionLi">
                              <div className="custFeedback">
                                {lowest && (
                                  <div className="feedDiv">
                                    <h6>
                                      <span
                                        style={{
                                          background: LightenDarkenColor(
                                            lowest.colour,
                                            100
                                          ),
                                          color: lowest.colour,
                                        }}
                                      >
                                        {lowest &&
                                        lowest?.category_name[t("language")]
                                          ? lowest?.category_name[t("language")]
                                          : lowest?.category_name[t("en")]}
                                      </span>
                                    </h6>
                                    <strong>
                                      {`${isLowestRatedQuestion.question[
                                        t("language")
                                      ].replace(
                                        /([\{(])(.+?)([\})])/g,
                                        `${
                                          lowest?.category_name[t("language")]
                                            ? lowest?.category_name[
                                                t("language")
                                              ]
                                            : lowest?.category_name[t("en")]
                                        }`
                                      )}`}
                                    </strong>
                                    <p>
                                      {isLowestRatedQuestion.answer.opinion}
                                    </p>
                                  </div>
                                )}
                                <div>
                                  <StarRatings
                                    rating={lowest.ratings}
                                    starDimension="14px"
                                    starSpacing="2px"
                                    starRatedColor="#FFD600"
                                  />
                                </div>
                              </div>
                            </li>
                          )}
                        {highest?.category_name?.en === "Overall Experience" &&
                          isHighestRatedQuestion !== null && (
                            <li className="feedbackRightSectionLi">
                              <div className="custFeedback">
                                {highest && (
                                  <div className="feedDiv">
                                    {(feedbackData?.feed_back?.[0].answer
                                      ?.category?.length > 1 ||
                                      !feedbackData.feed_back.find(
                                        (i) => i.sequence === 1
                                      )) && (
                                      <h6>
                                        <span
                                          style={{
                                            background: LightenDarkenColor(
                                              highest.colour,
                                              100
                                            ),
                                            color: highest.colour,
                                          }}
                                        >
                                          {highest &&
                                          highest?.category_name[t("language")]
                                            ? highest?.category_name[
                                                t("language")
                                              ]
                                            : highest?.category_name[t("en")]}
                                        </span>
                                      </h6>
                                    )}
                                    <strong>
                                      {`${isHighestRatedQuestion.question[
                                        t("language")
                                      ].replace(
                                        /([\{(])(.+?)([\})])/g,
                                        `${
                                          highest?.category_name[t("language")]
                                            ? highest?.category_name[
                                                t("language")
                                              ]
                                            : highest?.category_name[t("en")]
                                        }`
                                      )}`}
                                    </strong>
                                    <p>
                                      {isHighestRatedQuestion.answer.opinion}
                                    </p>
                                  </div>
                                )}
                                {(feedbackData?.feed_back?.[0].answer?.category
                                  ?.length > 1 ||
                                  !feedbackData.feed_back.find(
                                    (i) => i.sequence === 1
                                  )) && (
                                  <div>
                                    <StarRatings
                                      rating={highest.ratings}
                                      starDimension="14px"
                                      starSpacing="2px"
                                      starRatedColor="#FFD600"
                                    />
                                  </div>
                                )}
                              </div>
                            </li>
                          )}
                        {feedbackData &&
                          feedbackData.feed_back &&
                          feedbackData.feed_back.map((s, i) => (
                            <span key={i}>
                              {s.answer?.category?.map((j, index) => (
                                <span key={index}>
                                  {j.category_name !== lowest.category_name &&
                                    j.category_name !==
                                      highest.category_name && (
                                      <li className="feedbackRightSectionLi">
                                        <div className="custFeedback">
                                          <div className="feedDiv">
                                            <h6>
                                              <span
                                                style={{
                                                  background:
                                                    LightenDarkenColor(
                                                      j.colour,
                                                      100
                                                    ),
                                                  color: j.colour,
                                                }}
                                              >
                                                {j.category_name[t("language")]
                                                  ? j.category_name[
                                                      t("language")
                                                    ]
                                                  : j.category_name[t("en")]}
                                              </span>
                                            </h6>
                                          </div>
                                          <div>
                                            <StarRatings
                                              rating={j.ratings ? j.ratings : 0}
                                              starDimension="14px"
                                              starSpacing="2px"
                                              starRatedColor="#FFD600"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  {!feedbackData.feed_back.find(
                                    (i) => i.sequence === 1
                                  ) &&
                                    j?.category_name ===
                                      lowest?.category_name &&
                                    !(
                                      j?.category_name ===
                                      highest?.category_name
                                    ) && (
                                      <li className="feedbackRightSectionLi">
                                        <div className="custFeedback">
                                          <h6>
                                            <span
                                              style={{
                                                background: LightenDarkenColor(
                                                  lowest?.colour,
                                                  100
                                                ),
                                                color: lowest?.colour,
                                              }}
                                            >
                                              {lowest &&
                                              lowest.length &&
                                              lowest?.category_name[
                                                t("language")
                                              ]
                                                ? lowest?.category_name[
                                                    t("language")
                                                  ]
                                                : lowest?.category_name[
                                                    t("en")
                                                  ]}
                                            </span>
                                          </h6>
                                          <div>
                                            <StarRatings
                                              rating={lowest?.ratings}
                                              starDimension="14px"
                                              starSpacing="2px"
                                              starRatedColor="#FFD600"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  {!feedbackData.feed_back.find(
                                    (i) => i.sequence === 2
                                  ) &&
                                    j?.category_name ===
                                      highest?.category_name &&
                                    !(
                                      j?.category_name === lowest?.category_name
                                    ) && (
                                      <li className="feedbackRightSectionLi">
                                        <div className="custFeedback">
                                          <h6>
                                            <span
                                              style={{
                                                background: LightenDarkenColor(
                                                  highest?.colour,
                                                  100
                                                ),
                                                color: highest?.colour,
                                              }}
                                            >
                                              {highest &&
                                              highest.length &&
                                              highest?.category_name[
                                                t("language")
                                              ]
                                                ? highest?.category_name[
                                                    t("language")
                                                  ]
                                                : highest?.category_name[
                                                    t("en")
                                                  ]}
                                            </span>
                                          </h6>
                                          <div>
                                            <StarRatings
                                              rating={highest?.ratings}
                                              starDimension="14px"
                                              starSpacing="2px"
                                              starRatedColor="#FFD600"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                </span>
                              ))}
                              {s.sequence === 1 &&
                              lowest?.category_name?.en !==
                                "Overall Experience" ? (
                                <li className="feedbackRightSectionLi">
                                  <div className="custFeedback">
                                    {lowest && (
                                      <div className="feedDiv">
                                        <h6>
                                          <span
                                            style={{
                                              background: LightenDarkenColor(
                                                lowest.colour,
                                                100
                                              ),
                                              color: lowest.colour,
                                            }}
                                          >
                                            {lowest &&
                                            lowest?.category_name[t("language")]
                                              ? lowest?.category_name[
                                                  t("language")
                                                ]
                                              : lowest?.category_name[t("en")]}
                                          </span>
                                        </h6>
                                        <strong>
                                          {`${s.question[t("language")].replace(
                                            /([\{(])(.+?)([\})])/g,
                                            `${
                                              lowest?.category_name[
                                                t("language")
                                              ]
                                                ? lowest?.category_name[
                                                    t("language")
                                                  ]
                                                : lowest?.category_name[t("en")]
                                            }`
                                          )}`}
                                        </strong>
                                        <p>{s.answer.opinion}</p>
                                      </div>
                                    )}
                                    <div>
                                      <StarRatings
                                        rating={
                                          lowest.ratings ? lowest.ratings : 0
                                        }
                                        starDimension="14px"
                                        starSpacing="2px"
                                        starRatedColor="#FFD600"
                                      />
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <>
                                  {s.sequence === 2 &&
                                  highest?.category_name?.en !==
                                    "Overall Experience" ? (
                                    <li className="feedbackRightSectionLi">
                                      <div className="custFeedback">
                                        {highest && (
                                          <div className="feedDiv">
                                            <h6>
                                              <span
                                                style={{
                                                  background:
                                                    LightenDarkenColor(
                                                      highest.colour,
                                                      100
                                                    ),
                                                  color: highest.colour,
                                                }}
                                              >
                                                {highest?.category_name[
                                                  t("language")
                                                ]
                                                  ? highest?.category_name[
                                                      t("language")
                                                    ]
                                                  : highest?.category_name[
                                                      t("en")
                                                    ]}
                                              </span>
                                            </h6>
                                            <strong>
                                              {`${s.question[
                                                t("language")
                                              ].replace(
                                                /([\{(])(.+?)([\})])/g,
                                                `${
                                                  highest?.category_name[
                                                    t("language")
                                                  ]
                                                    ? highest?.category_name[
                                                        t("language")
                                                      ]
                                                    : highest?.category_name[
                                                        t("en")
                                                      ]
                                                }`
                                              )}`}
                                            </strong>
                                            <p>{s.answer.opinion}</p>
                                          </div>
                                        )}
                                        <div>
                                          {(feedbackData?.feed_back?.[0].answer
                                            ?.category?.length > 1 ||
                                            !feedbackData.feed_back.find(
                                              (i) => i.sequence === 1
                                            )) && (
                                            <StarRatings
                                              rating={
                                                highest.ratings
                                                  ? highest.ratings
                                                  : 0
                                              }
                                              starDimension="14px"
                                              starSpacing="2px"
                                              starRatedColor="#FFD600"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  ) : (
                                    <>
                                      {s.sequence !== 0 &&
                                        s.sequence !== 1 &&
                                        s.sequence !== 2 && (
                                          <li className="feedbackRightSectionLi">
                                            <div className="custFeedback flex">
                                              <div style={{ width: "100%" }}>
                                                <strong>
                                                  {s.question[t("language")]
                                                    ? s.question[t("language")]
                                                    : s.question[t("en")]}
                                                </strong>
                                                <p>{s?.answer?.opinion}</p>
                                                {s.answer?.staff?.map((k) => (
                                                  <div
                                                    key={k._id}
                                                    className="modalBorderBottom"
                                                  >
                                                    <div className="flex feedbackProfile">
                                                      <div className="feedback-img">
                                                        {k.profile_pic ? (
                                                          <img
                                                            src={
                                                              `${process.env.REACT_APP_BASE_URL}${k.profile_pic}` ||
                                                              require("../../../assets/images/noProfile.webp")
                                                            }
                                                            onError={(e) => {
                                                              e.target.onerror =
                                                                null;
                                                              e.target.src =
                                                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                                                            }}
                                                            alt="profile-pic"
                                                          />
                                                        ) : (
                                                          <Avatar
                                                            name={
                                                              k.name[
                                                                t("language")
                                                              ]
                                                                ? k.name[
                                                                    t(
                                                                      "language"
                                                                    )
                                                                  ]
                                                                : k.name[
                                                                    t("en")
                                                                  ]
                                                            }
                                                            size="35px"
                                                          />
                                                        )}
                                                        <span>
                                                          {k.name[t("language")]
                                                            ? k.name[
                                                                t("language")
                                                              ]
                                                            : k.name[t("en")]}
                                                        </span>
                                                      </div>
                                                      <StarRatings
                                                        rating={
                                                          k.ratings
                                                            ? k.ratings
                                                            : 0
                                                        }
                                                        starDimension="14px"
                                                        starSpacing="2px"
                                                        starRatedColor="#FFD600"
                                                      />
                                                    </div>
                                                    <p>{k.opinion}</p>
                                                  </div>
                                                ))}
                                                {s.answer?.service?.map((m) => (
                                                  <div
                                                    key={m._id}
                                                    className="modalBorderBottom"
                                                  >
                                                    <div className="flex feedbackProfile">
                                                      <div className="feedback-img">
                                                        {m.logo ? (
                                                          <img
                                                            src={
                                                              `${process.env.REACT_APP_BASE_URL}${m.logo}` ||
                                                              require("../../../assets/images/noProfile.webp")
                                                            }
                                                            onError={(e) => {
                                                              e.target.onerror =
                                                                null;
                                                              e.target.src =
                                                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                                                            }}
                                                            alt="profile-pic"
                                                          />
                                                        ) : (
                                                          <Avatar
                                                            name={
                                                              m.name[
                                                                t("language")
                                                              ]
                                                                ? m.name[
                                                                    t(
                                                                      "language"
                                                                    )
                                                                  ]
                                                                : m.name[
                                                                    t("en")
                                                                  ]
                                                            }
                                                            size="35px"
                                                          />
                                                        )}
                                                        <span>
                                                          {m.name[t("language")]
                                                            ? m.name[
                                                                t("language")
                                                              ]
                                                            : m.name[t("en")]}
                                                        </span>
                                                      </div>
                                                      <StarRatings
                                                        rating={
                                                          m.ratings
                                                            ? m.ratings
                                                            : 0
                                                        }
                                                        starDimension="14px"
                                                        starSpacing="2px"
                                                        starRatedColor="#FFD600"
                                                      />
                                                    </div>
                                                    <p>{m.opinion}</p>
                                                  </div>
                                                ))}
                                                {s.answer?.product?.map((p) => (
                                                  <div
                                                    key={p._id}
                                                    className="modalBorderBottom"
                                                  >
                                                    <div className="flex feedbackProfile">
                                                      <div className="feedback-img">
                                                        {p.logo ? (
                                                          <img
                                                            src={
                                                              `${process.env.REACT_APP_BASE_URL}${p.logo}` ||
                                                              require("../../../assets/images/noProfile.webp")
                                                            }
                                                            onError={(e) => {
                                                              e.target.onerror =
                                                                null;
                                                              e.target.src =
                                                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                                                            }}
                                                            alt="profile-pic"
                                                          />
                                                        ) : (
                                                          <Avatar
                                                            name={
                                                              p.name[
                                                                t("language")
                                                              ]
                                                                ? p.name[
                                                                    t(
                                                                      "language"
                                                                    )
                                                                  ]
                                                                : p.name[
                                                                    t("en")
                                                                  ]
                                                            }
                                                            size="35px"
                                                          />
                                                        )}
                                                        <span>
                                                          {p.name[t("language")]
                                                            ? p.name[
                                                                t("language")
                                                              ]
                                                            : p.name[t("en")]}
                                                        </span>
                                                      </div>
                                                      <StarRatings
                                                        rating={
                                                          p.ratings
                                                            ? p.ratings
                                                            : 0
                                                        }
                                                        starDimension="14px"
                                                        starSpacing="2px"
                                                        starRatedColor="#FFD600"
                                                      />
                                                    </div>
                                                    <p>{p.opinion}</p>
                                                  </div>
                                                ))}
                                                {s.answer &&
                                                  s.answer.selected_option &&
                                                  typeof s.answer
                                                    .selected_option ===
                                                    "object" && (
                                                    <p>
                                                      {s.answer.selected_option[
                                                        t("language")
                                                      ]
                                                        ? s.answer
                                                            .selected_option[
                                                            t("language")
                                                          ]
                                                        : s.answer
                                                            .selected_option[
                                                            t("en")
                                                          ]}
                                                    </p>
                                                  )}
                                              </div>
                                            </div>
                                          </li>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                            </span>
                          ))}
                      </ul>
                    </div>
                    {userProfile &&
                      userProfile.permission !== "viewer" &&
                      feedbackData?.customer?.[0]?.is_anonymous === false && (
                        <div className="feedbackRightSection">
                          <textarea
                            className="form-control"
                            rows={8}
                            placeholder={t("WRITE_THE_REPLY_HERE")}
                            name="reply"
                            onChange={formik.handleChange}
                            value={formik.values.reply}
                            onBlur={formik.handleBlur}
                          />
                          <div className="sendBtn">
                            <Button
                              type="btn"
                              onClick={formik.handleSubmit}
                              disabled={!(formik.isValid && formik.dirty)}
                            >
                              {t("SEND")}
                            </Button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerFeedbackModalReport;
