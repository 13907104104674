import { useState } from "react";
import styles from "./paymentBanner.module.css";
import { useTranslation } from "react-i18next";
import Payment from "../../services/payment";
import { useQuery } from "react-query";
import { isPast, subDays, differenceInDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";
import { useSelector } from "react-redux";

const DAYS_BEFORE_WARNING = 14;

function PaymentBanner() {
  const [show, setShow] = useState(!sessionStorage.getItem("alerted-payment"));

  const { data, isLoading, isError } = useQuery("subscription", {
    queryFn: Payment.getUserSubscription,
  });

  const { t } = useTranslation();

  const { loggedInUser } = useSelector((state) => state.auth);
  const isPermitted = loggedInUser?.permission === "owner";

  if (
    isLoading ||
    isError ||
    !data?.data?.data?.[0] ||
    data?.data?.data?.[0].isExpired ||
    !isPermitted
  )
    return null;

  const endDate = data?.data?.data?.[0]?.endDate;
  const daysToEnd = differenceInDays(new Date(endDate), new Date());
  const past = isPast(subDays(new Date(endDate), DAYS_BEFORE_WARNING));

  return (
    <div
      className={`${styles.container} ${
        show && past ? styles.show : styles.hide
      }`}
    >
      <span>
        {`${t("YOUR_SUBSCRIPTION_WILL_END_IN")} ${daysToEnd} ${
          daysToEnd > 1 ? t("DAYS") : t("DAY")
        }, ${t("VISIT")} `}
        <Link
          onClick={() => {
            sessionStorage.setItem("alerted-payment", true);
            setShow(false);
          }}
          className={styles.link}
          to={ROUTE_URLS.PAYMENT}
        >
          {t("PAYMENT")}
        </Link>
      </span>
      <Button
        className={styles.btn}
        onClick={() => {
          sessionStorage.setItem("alerted-payment", true);
          setShow(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </Button>
    </div>
  );
}

export default PaymentBanner;
