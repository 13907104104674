import { Children } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "./stepper.module.css";
import FaIcon from "../fa-icon/FaIcon";

/**
 * @typedef {import("react").ReactNode} ReactNode
 * @typedef {{label: string, children: ReactNode }} StepProps
 * @param  {StepProps} props
 * @returns
 */
function Step({ label, ...props }) {
  return <div {...props} role={"tabpanel"} />;
}

/**
 * @typedef {import("react").FC} FC
 * @typedef {import("react").ReactNode} ReactNode
 * @param {{children: ReactNode,  activeStep: number }} props description
 * @returns {ReactNode}
 */
function Stepper({ children, activeStep }) {
  return (
    <>
      <div role={"tablist"} className={styles.steps}>
        {Children.map(children, (el, i) => {
          const isActive = i === activeStep;
          const isDone = activeStep > i;
          return el ? (
            <div
              role="tab"
              tabIndex={isActive ? 0 : -1}
              className={`${styles.stepTab} ${
                isDone ? styles.stepTabDone : ""
              }`}
            >
              <span className={styles.circle}>
                {isDone ? (
                  <FaIcon
                    faIcon={faCheck}
                    width={16}
                    height={16}
                    fill="white"
                  />
                ) : (
                  i + 1
                )}
              </span>
              {el.props.label || ""}
            </div>
          ) : null;
        })}
      </div>
      {Children.map(children, (el, i) => (el && i === activeStep ? el : null))}
    </>
  );
}

Stepper.Step = Step;

export default Stepper;
