import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../slices/feedbacks.slice";
import BrandLogo from "../components/BrandLogo";

const SurveyHome = ({ previewData, languageData, click }) => {
  const dispatch = useDispatch();
  const [langData, setLangData] = useState([]);
  const { buttonColor, buttonTextColor } = useSelector(
    (state) => state.feedbacks
  );
  const { AllLanguages } = useSelector((state) => state.brand);

  useEffect(() => {
    const langData =
      languageData &&
      languageData.map((o) => {
        const newId =
          AllLanguages &&
          AllLanguages.find((obj) => {
            return obj.key === o;
          });
        return newId;
      });
    if (langData && langData.length > 1) {
      const sorter = (a, b) => {
        if (a?.key === previewData?.brand_design?.default_language) {
          return -1;
        }
        if (b?.key === previewData?.brand_design?.default_language) {
          return 1;
        }
        return a?.key < b?.key ? -1 : 1;
      };
      langData.sort(sorter);
    }
    setLangData(langData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLanguageClick = (e) => {
    dispatch(setLanguage(e));
    click();
  };

  return (
    <div className="paddingApp">
      <div className="surveyBtn">
        <div className="NavLogo">
          <BrandLogo object={previewData} />
        </div>
        <div className="multiButton">
          {langData &&
            langData.length > 0 &&
            langData.map((languages, i) => {
              return (
                <Button
                  key={i}
                  className="btn btn-primary mob_Btn"
                  style={{
                    backgroundColor: buttonColor
                      ? buttonColor
                      : previewData?.brand_design?.button_colour,
                    color: buttonTextColor
                      ? buttonTextColor
                      : previewData?.brand_design?.button_text_colour,
                    borderColor: buttonColor
                      ? buttonColor
                      : previewData?.brand_design?.button_colour,
                  }}
                  onClick={() => handleLanguageClick(languages.key)}
                >
                  {languages?.value}
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SurveyHome;
