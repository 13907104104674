import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";

function ProgressiveBar() {
  const { buttonColor, previewData, questionIndex, previewLanguage } =
    useSelector((state) => state.feedbacks);

  let now = (questionIndex / (previewData?.question?.length - 1)) * 100;

  return (
    <ProgressBar
      dir={
        previewLanguage === "ar" ||
        previewLanguage === "fa" ||
        previewLanguage === "he" ||
        previewLanguage === "ur"
          ? "rtl"
          : ""
      }
      children={[
        <div
          key="1"
          role={"progressbar"}
          className="progress-bar"
          style={{
            backgroundColor: `${
              buttonColor
                ? buttonColor
                : previewData?.brand_design?.button_colour
            }`,
            width: `${now}%`,
          }}
        >
          <span hidden>{`${now}%`}</span>
        </div>,
      ]}
    />
  );
}

export default ProgressiveBar;
