import * as Yup from "yup";
import { Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { branch_name } from "../../helpers/yup.validation.schema";
import { useTranslation } from "react-i18next";
import styles from "./branchModal.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
/**
 * @typedef {{ name: string, address?: string google_link: string}} Branch
 * @param {{show: boolean, centered: boolean, initialBranch?: Branch onSubmit?:(branch?: Branch) => void}} param0
 * @returns
 */
function BranchModal({ show, centered, onSubmit, initialBranch }) {
  const { t } = useTranslation();

  const addBranchSchema = Yup.object().shape({
    name: branch_name,
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      google_link: "",
    },
    validationSchema: addBranchSchema,
    onSubmit: (branch) => {
      onSubmit && onSubmit(branch);
      formik.resetForm();
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onSubmit();
  };

  useEffect(() => {
    initialBranch &&
      formik.setValues({
        name: initialBranch.name,
        address: initialBranch.address,
        google_link: initialBranch.google_link,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBranch]);

  return (
    <Modal show={show} centered={centered}>
      <Modal.Header>
        <Modal.Title className="cust-title">
          {initialBranch ? t("UPDATE_BRANCH") : t("ADD_NEW_BRANCH_ONBOARDING")}
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <div className={styles.inputGroup}>
            <Form.Label>{t("BRANCH_NAME")}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              className={`${
                formik.touched.name && formik.errors.name && "is-invalid"
              }`}
              placeholder={t("INPUT_BRANCH_NAME")}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="invalid-feedback d-block">
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.labelContainer}>
              <Form.Label>{t("GOOGLE_LINK")}</Form.Label>
              <Link
                className="link-text"
                target="_blank"
                to={`https://www.youtube.com/watch?v=Mvx5CLol-8I`}
              >
                <small>{t("GET_LINK")}</small>
              </Link>
            </div>
            <Form.Control
              type="text"
              name="google_link"
              className={`${
                formik.touched.google_link &&
                formik.errors.google_link &&
                "is-invalid"
              }`}
              placeholder={t("INPUT_GOOGLE_LINK")}
              value={formik.values.google_link}
              onChange={formik.handleChange}
            />
            {formik.touched.google_link && formik.errors.google_link && (
              <div className="invalid-feedback d-block">
                {formik.errors.google_link}
              </div>
            )}
          </div>
          <div className={styles.inputGroup}>
            <Form.Label>
              {t("ADDRESS")} <small>({t("OPTIONAL")}) </small>
            </Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder={t("INPUT_BRANCH_ADDRESS")}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
          <div className="Experience-btn-modal flex justify-content-center">
            <Button
              type="btn"
              className="btn"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}
            >
              {t("SAVE")}
            </Button>
            <Button
              className="btn btn-outline-secondary"
              onClick={() => handleClose()}
            >
              {t("CANCEL")}
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export default BranchModal;
