import React, { useEffect, lazy } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/arabic.css";
import Login from "./pages/login/Login";
import { Route, Routes } from "react-router";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import registerFAIcons from "./helpers/font-awesome-icon-services";
import RouteAuthGuard from "./components/route-auth-guard/Route-Auth-Guard";
import RouteAuthGuardRestrictLoggedUser from "./components/route-auth-guard-restrict-logged-user/RouteAuthGuardRestrictLoggedUser";
import ROUTE_URLS, { BASE_URL } from "./config/routes";
import SetPassword from "./pages/set-password/SetPassword";
import { ToastContainer } from "react-toastify";
import Thankyou from "./pages/thankyou/Thankyou";
import AppLayout from "./templates/AppLayout";
import ResetPassword from "./pages/reset-password/ResetPassword";
import UserList from "./pages/user-listing/UserList";
import Home from "./pages/home/Home";
import Brand from "./pages/brand/Brand";
import Profile from "./pages/profile/Profile";
import PasswordUpdate from "./pages/profile/PasswordUpdate";
import BrandOptions from "./pages/brand/brand-option/BrandOptions";
import { useDispatch } from "react-redux";
import { Component } from "./components/dummy/Component";
// import Catalogues from "./pages/brand/catelogues/Catalogues";
import QuestionsTemplate from "./pages/brand/questions/QuestionsTemplate";
import LinksAndQrCodes from "./pages/brand/links-and-qr-codes/LinksAndQrCodes";
// import CustomerListing from "./pages/customer-listing/CustomerListing";
// import CustomerFeedback from "./pages/customer-listing/CustomerFeedback";
// import TrustreeFeedback from "./pages/feedback/trustree_feedbacks/TrustreeFeedback";
import GoogleFeedback from "./pages/feedback/google-ratings/GoogleFeedback";
import "./assets/css/responsive.css";
import { getLanguages } from "./middlewares/brands";
import "./pages/customer-listing/customer.scss";
import "./pages/feedback/feedback.scss";
import "./pages/brand/catelogues/catelogues.scss";
import SummaryReport from "./pages/brand/summary-report/BrandDailyReport";
import TeamReward from "./pages/brand/team-reward/TeamRewards";
import DetailedReport from "./pages/brand/detailed-report/BrandDetailedReport";

import Onboarding from "./pages/onboarding/Onboarding";
import Payment from "./pages/payment/Payment";
import { QueryClient, QueryClientProvider } from "react-query";
import SubscriptionGuard from "./components/subscription-guard/SubscriptionGuard";
import OnboardingGuard from "./components/onboarding-guard/OnboardingGuard";
import OwnerMasterGuard from "./components/owner-master-guard/OwnerMasterGuard";
import OwnerGuard from "./components/owner-guard/OwnerGuard";

const TrustreeFeedback = lazy(() =>
  import("./pages/feedback/trustree_feedbacks/TrustreeFeedback")
);
const CustomerListing = lazy(() =>
  import("./pages/customer-listing/CustomerListing")
);
const CustomerFeedback = lazy(() =>
  import("./pages/customer-listing/CustomerFeedback")
);
const Catalogues = lazy(() => import("./pages/brand/catelogues/Catalogues"));

const queryClient = new QueryClient();

registerFAIcons();

const App = () => {
  const dispatch = useDispatch();
  const body = document.querySelector("body");
  const language = localStorage.getItem("i18nextLng");

  useEffect(() => {
    dispatch(getLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      language === "ar" ||
      language === "fa" ||
      language === "he" ||
      language === "ur"
    ) {
      if (body) {
        document.querySelector("body").classList.add("arabic");

        if (body.classList.contains("arabic")) {
          document.getElementsByTagName("html")[0].lang = language;
          document.getElementsByTagName("html")[0].dir = "rtl";
        }
      }
    } else {
      if (body) {
        if (body.classList.contains("arabic")) {
          document.querySelector("body").classList.remove("arabic");
          document.getElementsByTagName("html")[0].lang = language;
          document.getElementsByTagName("html")[0].dir = "";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={`/${ROUTE_URLS.COMPONENT}`} element={<Component />} />
        <Route
          path={`/${ROUTE_URLS.ONBOARDING}`}
          element={
            <RouteAuthGuard>
              <OnboardingGuard>
                <Onboarding />
              </OnboardingGuard>
            </RouteAuthGuard>
          }
        />
        <Route
          path={BASE_URL}
          element={
            <RouteAuthGuard>
              <SubscriptionGuard>
                <AppLayout />
              </SubscriptionGuard>
            </RouteAuthGuard>
          }
        >
          <Route
            path={ROUTE_URLS.BRANDS}
            element={
              <RouteAuthGuard>
                <Brand />
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.USERS}
            element={
              <RouteAuthGuard>
                <OwnerMasterGuard>
                  <UserList />
                </OwnerMasterGuard>
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.CUSTOMERS}
            element={
              <RouteAuthGuard>
                <CustomerListing />
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.CUSTOMER_FEEDBACKS_WITH_ID}
            element={
              <RouteAuthGuard>
                <CustomerFeedback />
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.CUSTOMER_FEEDBACKS_WITH_FEEDBACKID}
            element={
              <RouteAuthGuard>
                <CustomerFeedback />
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.HOME}
            element={
              <RouteAuthGuard>
                <Home />
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.TRUSTREE_FEEDBACKS}
            element={
              <RouteAuthGuard>
                <TrustreeFeedback />
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.GOOGLE_FEEDBACKS}
            element={
              <RouteAuthGuard>
                <GoogleFeedback />
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.PROFILE}
            element={
              <RouteAuthGuard>
                <Profile />
              </RouteAuthGuard>
            }
          />

          <Route
            path={ROUTE_URLS.PASSWORD_UPDATE}
            element={
              <RouteAuthGuard>
                <PasswordUpdate />
              </RouteAuthGuard>
            }
          />
          <Route
            path={`/${ROUTE_URLS.PAYMENT}`}
            element={
              <RouteAuthGuard>
                <OwnerGuard>
                  <Payment />
                </OwnerGuard>
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.BRANDS_OPTIONS}
            element={
              <RouteAuthGuard>
                <BrandOptions />
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.CATALOGUES}
            element={
              <RouteAuthGuard>
                <Catalogues />
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.QUESTIONS}
            element={
              <RouteAuthGuard>
                <QuestionsTemplate />
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.LINKS_AND_QR_CODES}
            element={
              <RouteAuthGuard>
                <LinksAndQrCodes />
              </RouteAuthGuard>
            }
          />
          <Route
            path={ROUTE_URLS.TEAM_REWARD}
            element={
              <RouteAuthGuard>
                <TeamReward />
              </RouteAuthGuard>
            }
          />
        </Route>
        <Route
          path={ROUTE_URLS.LOGIN}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <Login />
            </RouteAuthGuardRestrictLoggedUser>
          }
        />
        <Route
          path={ROUTE_URLS.FORGOT_PASSWORD}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <ForgotPassword />
            </RouteAuthGuardRestrictLoggedUser>
          }
        />
        <Route
          path={`${ROUTE_URLS.SET_PASSWORD}/:code`}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <SetPassword />
            </RouteAuthGuardRestrictLoggedUser>
          }
        />

        <Route
          path={`${ROUTE_URLS.RESET_PASSWORD}/:reset_password_token`}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <ResetPassword />
            </RouteAuthGuardRestrictLoggedUser>
          }
        />

        <Route path={ROUTE_URLS.SUMMARY_REPORT} element={<SummaryReport />} />

        <Route path={ROUTE_URLS.DETAILED_REPORT} element={<DetailedReport />} />

        <Route
          path={`${ROUTE_URLS.INVITATION_CODE}/:code/:status`}
          element={<Thankyou />}
        />
      </Routes>
      <ToastContainer
        position={language && language === "ar" ? "top-left" : "top-right"}
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </QueryClientProvider>
  );
};

export default App;
