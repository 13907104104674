import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import FaIcon from "../../components/fa-icon/FaIcon";
import styles from "./iconBtn.module.css";

/**
 *
 * @param {{icon: import("@fortawesome/fontawesome-svg-core").IconDefinition, label: string}} param0
 * @returns
 */
function IconBtn({ className = "", icon = faPlusSquare, label, ...props }) {
  return (
    <button className={`${styles.iconBtn} ${className}`} {...props}>
      <FaIcon faIcon={icon} />
      <span>{label}</span>
    </button>
  );
}

export default IconBtn;
