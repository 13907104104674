const { get, post, del, put } = require(".");

const URL = "/pay";

/**
 * @typedef {{ _id: string type: string amount: number months: number }} PaymentPlan
 * @typedef {{ success: boolean data: PaymentPlan[]}} PaymentPlanResponse
 * @returns { Promise<import("axios").AxiosResponse<PaymentPlanResponse>>}
 */
async function getAllPaymentPlans() {
  const url = `${URL}/plans`;
  return get(url);
}

/**
 * @typedef {{ message: string }} CheckoutResponse
 * @param {{ paymentPlanId: string }} payload
 * @returns { Promise<import("axios").AxiosResponse<CheckoutResponse>>}
 */
async function checkout(payload) {
  const url = `${URL}/checkout`;
  return post(url, payload);
}

/**
 * @typedef {{
 *  _id: string
 *  userId: string
 *  endDate: string
 *  paymentPlanId: string
 *  paymentPlan: [{_id: string type: string amount: number months: number}]
 *  amount: number
 *  branchIds: string[]
 *  isDisabled: boolean
 * isExpired: boolean
 *  canPay: boolean
 *   }} Subscription
 * @typedef {{ message: string data: [sub?: Subscription]}} SubscriptionResponse
 * @returns { Promise<import("axios").AxiosResponse<SubscriptionResponse>>}
 */
async function getUserSubscription() {
  const url = `${URL}/subscription`;
  return get(url);
}

/**
 * @typedef {{
 *  daysLeft: number
 *  daysSince: number
 *  price: string
 *  pricePerDay: number
 *  originalPrice: number
 *  planName: string
 *  }} Price
 * @typedef {{ message: string data?: Price}} CalculateBranchResponse
 * @param {string} branchID
 * @returns {Promise<import("axios").AxiosResponse<CalculateBranchResponse>>}
 */
async function calculateBranch(branchID) {
  const url = `${URL}/branch/${branchID}`;
  return get(url);
}

/**
 * @typedef {{ message: string session?: Session success?: false}} PayBranchResponse
 * @param {string} branchID
 * @returns {Promise<import("axios").AxiosResponse<PayBranchResponse>>}
 */
async function payBranch(branchID) {
  const url = `${URL}/branch/${branchID}`;
  return post(url);
}

/**
 * @param {{success_url: string}} payload
 * @typedef {{ message: string session?: Session success?: false}} PayNextPaymentResponse
 * @returns {Promise<import("axios").AxiosResponse<PayNextPaymentResponse>>}
 */
async function payNextPayment(payload) {
  const url = `${URL}/next`;
  return post(url, payload);
}

/**
 * @typedef {{
 *  id: string,
 *  brand: "Visa" | "Mastercard" | "American Express" | "Discover" | "JCB" | "Diners Club" | "unknown"
 *  funding: "credit"| "debit"| "prepaid" | "unknown"
 *  last4: string
 *  exp_month: number
 *  exp_year: number
 *  object: "card"
 *  isDefault: boolean
 * }} Card
 * For more info about the card object: https://stripe.com/docs/api/cards/object
 * @typedef {{ message: string data: { data: { data: Card[] }}[] }} AllCardsResponse
 * @returns {Promise<import("axios").AxiosResponse<AllCardsResponse>>}
 */
async function getAllCard() {
  const url = `${URL}/cards`;
  return get(url);
}

/**
 * @typedef {{ data: { url: string }}} AddCardResponse
 * @param {{success_url: string}} payload
 * @returns {Promise<import("axios").AxiosResponse<AddCardResponse>>}
 */
async function addCard(payload) {
  const url = `${URL}/cards`;
  return post(url, payload);
}

/**
 * @typedef {{ message: string }} DeleteCardResponse
 * @param {string} id
 * @returns {Promise<import("axios").AxiosResponse<DeleteCardResponse>>}
 */
async function deleteCard(id) {
  const url = `${URL}/cards/${id}`;
  return del(url);
}

/**
 * response date is ignored
 * @typedef {{ message: string }} SetDefaultCardResponse
 * @param {{ cardId: string }} payload
 * @returns {Promise<import("axios").AxiosResponse<SetDefaultCardResponse>>}
 */
async function setDefaultCard(payload) {
  const url = `${URL}/cards/default`;
  return put(url, payload);
}

/**
 * @typedef {{
 *  _id: string
 *  success: boolean
 *  pending: boolean
 *  userId: string
 *  subscription: { endDate: string branchIds: string[] numberOfBranches: number amount: number  paymentPlanId: string}
 *  paymentPlanId: string
 *  paymentPlan: [{ _id: string type: string months: number amount: number}]
 *  numOfBranches: number
 *  branchIds: string[]
 *  amount: number
 *  createdAt: string
 * invoiceUrl: string
 * }} PaymentHistory
 * For more info about the card object: https://stripe.com/docs/api/cards/object
 * @typedef {{ message: string data: [{ data: PaymentHistory[] metaData: [{total: number}]}] }} PaymentHistoryResponse
 * @returns {Promise<import("axios").AxiosResponse<PaymentHistoryResponse>>}
 */
async function paymentHistory(page = 1) {
  const url = `${URL}/history`;
  return get(url, { params: { page } });
}

/**
 * @param {{paymentPlanId: string}} payload
 * @typedef {{ message: string, }} PaymentHistoryResponse
 * @returns {Promise<import("axios").AxiosResponse<PaymentHistoryResponse>>}
 */
async function changePaymentPlan(payload) {
  const url = `${URL}/plans`;
  return put(url, payload);
}

const Payment = {
  getAllPaymentPlans,
  checkout,
  getUserSubscription,
  calculateBranch,
  payBranch,
  payNextPayment,
  getAllCard,
  addCard,
  deleteCard,
  setDefaultCard,
  paymentHistory,
  changePaymentPlan,
};

export default Payment;
