import TeamRewards from "../services/teamRewards";
import {
    getTeamRewardsRequest,
    getTeamRewardsSuccess,
    getTeamRewardsError,
    getTeamRewardsByIdRequest,
    getTeamRewardsByIdSuccess,
    getTeamRewardsByIdError,
    addTeamRewardRequest,
    addTeamRewardSuccess,
    addTeamRewardError,
    editTeamRewardRequest,
    editTeamRewardSuccess,
    editTeamRewardError,
    deleteTeamRewardRequest,
    deleteTeamRewardSuccess,
    deleteTeamRewardError,
    disableTeamRewardRequest,
    disableTeamRewardError,
    disableTeamRewardSuccess,
    getQuestionsRequest,
    getQuestionsSuccess,
    getQuestionsError,
    rewardAchievedRequest,
    rewardAchievedSuccess,
    rewardAchievedError,
    getStaffMembersRequest,
    getStaffMembersError,
    getStaffMembersSuccess,
    getUsersError,
    getUsersRequest,
    getUsersSuccess,
} from "../slices/teamrewards.slice";

export function getTeamRewards(brand_id) {
    return (dispatch) => {
        dispatch(getTeamRewardsRequest());
        TeamRewards.getTeamRewards(brand_id)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getTeamRewardsSuccess(data));
                } else {
                    dispatch(getTeamRewardsError(error));
                }
            })
            .catch((error) => {
                dispatch(getTeamRewardsError(error));
            })
    }
}

export function addRewards(payload) {
    return (dispatch) => {
        dispatch(addTeamRewardRequest());
        TeamRewards.addTeamRewards(payload)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(addTeamRewardSuccess(message));
                } else {
                    dispatch(addTeamRewardError(error));
                }
            })
            .catch((error) => {
                dispatch(addTeamRewardError(error));
            })
    }
}

export function getTeamRewardsById(brand_id, reward_id) {
    return (dispatch) => {
        dispatch(getTeamRewardsByIdRequest());
        TeamRewards.getTeamRewardsById(brand_id, reward_id)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getTeamRewardsByIdSuccess(data));
                } else {
                    dispatch(getTeamRewardsByIdError(error));
                }
            })
            .catch((error) => {
                dispatch(getTeamRewardsByIdError(error));
            });
    }
}

export function updateTeamRewards(payload, report_id) {
    return (dispatch) => {
        dispatch(editTeamRewardRequest());
        TeamRewards.updateTeamRewards(payload, report_id)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(editTeamRewardSuccess(message));
                } else {
                    dispatch(editTeamRewardError(error));
                }
            })
            .catch((error) => {
                dispatch(editTeamRewardError(error));
            });
    };
}

export function deleteTeamRewards(report_id) {
    return (dispatch) => {
        dispatch(deleteTeamRewardRequest());
        TeamRewards.deleteTeamRewards(report_id)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(deleteTeamRewardSuccess(message));
                } else {
                    dispatch(deleteTeamRewardError(error));
                }
            })
            .catch((error) => {
                dispatch(deleteTeamRewardError(error));
            });
    };
}

export function disableTeamRewards(report_id) {
    return (dispatch) => {
        dispatch(disableTeamRewardRequest());
        TeamRewards.disableTeamRewards(report_id)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(disableTeamRewardSuccess(message));
                } else {
                    dispatch(disableTeamRewardError(error));
                }
            })
            .catch((error) => {
                dispatch(disableTeamRewardError(error));
            });
    };
}

export function getQuestions(select_id, payload) {
    return (dispatch) => {
        dispatch(getQuestionsRequest());
        TeamRewards.getQuestions(select_id, payload)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getQuestionsSuccess(data));
                } else {
                    dispatch(getQuestionsError(error));
                }
            })
            .catch((error) => {
                dispatch(getQuestionsError(error));
            });
    };
}

export function getStaffMembers(payload, brand_id) {
    return (dispatch) => {
      dispatch(getStaffMembersRequest());
      TeamRewards.getStaffMembers(payload, brand_id)
        .then((response) => {
          const { data, status, error } = response.data;
          if (status === 1) {
            dispatch(getStaffMembersSuccess(data));
          } else {
            dispatch(getStaffMembersError(error));
          }
        })
        .catch((error) => {
          dispatch(getStaffMembersError(error));
        });
    };
  }

export function getQuickOverview(payload, brand_id) {
    return (dispatch) => {
        dispatch(rewardAchievedRequest());
        TeamRewards.getRewradAchieved(payload, brand_id)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(rewardAchievedSuccess(data));
                } else {
                    dispatch(rewardAchievedError(error));
                }
            })
            .catch((error) => {
                dispatch(rewardAchievedError(error));
            });
    };
}

export function getUsers(payload) {
    return (dispatch) => {
        console.log(dispatch);
        dispatch(getUsersRequest());
        TeamRewards.getUsers()
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getUsersSuccess(data));
                } else {
                    dispatch(getUsersError(error));
                }
            })
            .catch((error) => {
                dispatch(getUsersError(error));
            });
    };
}