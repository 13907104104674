import { get, post, del } from ".";

const URI = "/brands/reports";

const getSummaryReport = (payload, brand_id) => {
    const URL = `${URI}/summary/${brand_id}`;
    return post(URL, payload);
};

const getSummaryReportBranch = (payload) => {
    const URL = `${URI}/branch-comparison`;
    return post(URL, payload);
};

const getSummaryReportCategory = (payload) => {
    const URL = `${URI}/category-comparison`;
    return post(URL, payload);
};

const getProductReport = (payload) => {
    const URL = `${URI}/product-comparison`;
    return post(URL, payload);
};

const getStaffReport = (payload) => {
    const URL = `${URI}/staff-comparison`;
    return post(URL, payload);
};

const getFeedBackReport = (payload) => {
    const URL = `${URI}/all-feedback`;
    return post(URL, payload);
};

const getFeedbackDataForModal = (f_id) => {
    const URL = `${URI}/feedback-modal/${f_id}`;
    return post(URL);
};


const SummaryReport = {
    getSummaryReport,
    getSummaryReportBranch,
    getSummaryReportCategory,
    getProductReport,
    getStaffReport,
    getFeedBackReport,
    getFeedbackDataForModal
};
export default SummaryReport;
