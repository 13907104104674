import React, { useEffect } from "react";
import HomeReward from "./HomeReward";
import SurveyHome from "./SurveyHome";
import Information from "./Information";
import Footer from "../components/Footer";
import { setIndex, setLanguage } from "../../slices/feedbacks.slice";
import { useSelector, useDispatch } from "react-redux";
import QuestionPageRenderer from "./QuestionPageRenderer";
import SocialMedia from "../components/SocialMedia";

const PreviewSurvey = ({ previewData, languageData }) => {
  const dispatch = useDispatch();
  const { Index } = useSelector((state) => state.feedbacks);
  const { AllLanguages } = useSelector((state) => state.brand);

  const componentArray = [
    <SurveyHome
      previewData={previewData}
      languageData={languageData}
      click={() => handleButtonClick()}
      backClick={() => handleBackClick()}
    />,
    <HomeReward
      previewData={previewData}
      languageData={languageData}
      click={() => handleButtonClick()}
      backClick={() => handleBackClick()}
    />,
    <QuestionPageRenderer
      previewData={previewData}
      click={() => handleButtonClick()}
      backClick={() => handleBackClick()}
    />,
    <Information
      previewData={previewData}
      click={() => handleButtonClick()}
      backClick={() => handleBackClick()}
    />,
    <SocialMedia
      previewData={previewData}
      backClick={() => handleBackClick()}
    />,
  ];

  const langData =
    languageData &&
    languageData.map((o) => {
      const newId =
        AllLanguages &&
        AllLanguages.find((obj) => {
          return obj.key === o;
        });
      return newId;
    });

  useEffect(() => {
    if (languageData && languageData.length === 1) {
      dispatch(setLanguage(languageData[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langData]);

  const handleButtonClick = () => {
    dispatch(setIndex(Index + 1));
  };

  const handleBackClick = () => {
    dispatch(setIndex(Index - 1));
  };

  return (
    <>
      <div>
        {languageData && languageData.length > 1
          ? componentArray[Index]
          : componentArray[Index + 1]}
        <Footer />
      </div>
    </>
  );
};

export default PreviewSurvey;
