import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Placeholder, OverlayTrigger,Tooltip, } from "react-bootstrap";
import ReportModal from "./ReportModal";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ellipsis from "../../../../assets/images/Ellipsis.svg";
import { getExperienceTypeByBrandId } from "../../../../middlewares/experienceType";
import disableButton from "../../../../assets/images/disable.svg";
import enableButton from "../../../../assets/images/enable.svg";
import editButton from "../../../../assets/images/brand-edit.svg";
import deleteButton from "../../../../assets/images/trash.svg"
import { useTranslation } from "react-i18next";
import plus from '../../../../assets/images/add-square.svg';
import { addReports, deleteReports, updateReports, getReports, getReportsById, disableReport } from '../../../../middlewares/reports';
import expImg from "../../../../assets/images/exp-img.png";
import DeleteModal from "../../../../components/confirmation-modal/DeleteModal";
import DisableModal from "../../../../components/confirmation-modal/DisableModal";
import PlaceholderLoader from "../../../user-listing/PlaceholderLoader";

const Report = (props) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteObj, setDeleteObj] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteObjTitle, setDeleteObjTitle] = useState("");
  const [isDisableModal, setIsDisableModal] = useState(false);
  const [disableObjTitle, setDisableObjTitle] = useState("");
  const [disableObj, setDisableObj] = useState(null);
  const { reports, deleteMessage, loading,
    deleteLoading, saveMessage, disableLoading, saveReportMessage, disableMessage } = useSelector(
      (state) => state.reports
    );

  useEffect(() => {
    if (!loading && initialLoading && reports) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (props.selectBrand) {
      dispatch(getReports(props.selectBrand));
    }
  }, [props.selectBrand, saveMessage, deleteMessage, saveReportMessage, disableMessage]);

  const [sort, setSort] = useState([
    {
      key: "title",
      column: "title",
      value: "Title",
      order: -1,
      activeSort: true,
    },
    {
      key: "send_every",
      column: "send_every",
      value: "Send Every",
      order: -1,
      activeSort: false,
    },
    {
      key: "send_to",
      column: "send_to",
      value: "Send To",
      order: -1,
      activeSort: false,
    },
    {
      key: "status",
      column: "status",
      value: "Status",
      order: -1,
      activeSort: false,
    },
    {
      key: "",
      column: "",
      value: "",
      order: -1,
    },
  ]);

  const handleSortChange = (clickedKey) => {
    const updatedSort = [...sort];

    updatedSort.forEach((item) => {
      if (item.key === clickedKey) {
        item.order = item.order === -1 ? 1 : -1;
      } else {
        item.order = -1;
      }
    });
    setSort(updatedSort);
  };

  const handleEditOpen = (e) => {
    const select_id = e;
    dispatch(getReportsById(props.selectBrand, select_id));
    setIsEditMode(true);
  };

  const handleDeleteModal = (e, title) => {
    setDeleteObj(e);
    setDeleteObjTitle(title);
    setIsDeleteModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteReports(id));
  };

  const handleDisableModal = (e, title) => {
    setDisableObj(e);
    setDisableObjTitle(title);
    setIsDisableModal(true);
  };

  const handleDisable = (id) => {
    dispatch(disableReport(id));
  };

  return (
    <div>
      {loading && initialLoading ? (
        <div className="mt-3">
          <PlaceholderLoader />
        </div>
      ) : (
        <>
          <div className="brandTable experience-wpr-content">
            {reports && reports.length > 0 ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      {sort &&
                        sort.map((obj, index) => (
                          <th key={obj.key} className="width260" style={{ width: "21%" }}>
                            {obj.key !== "" ? ( // Check if key is not empty
                              <span style={{ cursor: "pointer" }} onClick={() => handleSortChange(obj.key)}>
                                {t(obj.value)}
                                {obj.order !== -1 ? (
                                  <FontAwesomeIcon key={index} icon={`angle-up`} />
                                ) : (
                                  <FontAwesomeIcon key={index} icon={`angle-down`} />
                                )}
                              </span>
                            ) : (
                              // Render without icon for the empty key
                              <span>{t(obj.value)}</span>
                            )}
                          </th>
                        ))}
                      <th className="width260"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      reports.map((o, idx) => {
                        return (
                          <tr>
                            <td
                              className={"de-activated"}
                            >
                              <p>{t(o.title)} ({(o.report_type === 'summery_report' ? "summarized" : "detailed")})</p>
                            </td>
                            <td
                              className={"de-activated"}
                            >
                              <p>{t(o.report_send)}</p>
                              {
                                o.report_send === "day" ?
                                  <p style={{ opacity: 0.7 }}>{t("12:00 AM")}</p>
                                  : (o.report_send === "week" ?
                                    <p style={{ opacity: 0.7 }}>{t("First day of the Week")}</p>
                                    :
                                    <p style={{ opacity: 0.7 }}>{t("First day of the Month")}</p>
                                  )
                              }
                            </td>
                            <td
                              className="de-activated"
                            >
                              <div className="flex center">
                                <li>
                                  {o.user_email &&
                                    o.user_email.length === 1 ? (
                                    <span>
                                      {o.user_email &&
                                        o.user_email[0]?.username}
                                    </span>
                                  ) : (
                                    <p>
                                      {o.user_email &&
                                        o.user_email[0]?.username}
                                    </p>
                                  )}
                                </li>
                                {o.user_email.length > 1 && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id="button-tooltip-2">
                                        {o.user_email &&
                                          o.user_email.map((obj) => (
                                            <li key={obj._id}>{obj.username}</li>
                                          ))}
                                      </Tooltip>
                                    }
                                  >
                                    <li className="settlement-24">
                                      +{Object.keys(o.user_email).length - 1}
                                    </li>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </td>
                            <td
                              className={"de-activated"}
                            >
                              {
                                o.status === true ?
                                  <div className="status">
                                    <p className="active-status">
                                      {o.status === true && t("ACTIVE")}
                                    </p>
                                  </div>
                                  :
                                  <div className="status">
                                    <p className="disabled-status">
                                      {o.status === false && t("DISABLED")}
                                    </p>
                                  </div>
                              }
                            </td>
                            <td
                              className={"de-activated"}
                            >
                              <div className="brandIcons edit-exper justify-content-center sidebar-dropdown user-dropdown">
                                <Dropdown className="dis-dropdown">
                                  <Dropdown.Toggle
                                    style={{
                                      padding: "10px",
                                    }}
                                  >
                                    <img
                                      src={ellipsis}
                                      alt="loadding"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu style={{ margin: 0 }}>
                                    <li className="exper-drop-ul-li">
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDisableModal(
                                            o,
                                            "REPORTS"
                                          )
                                        }
                                      >
                                        {o.status === false ? (
                                          <img
                                            src={enableButton}
                                            alt="enable"
                                          />
                                        ) : (
                                          <img
                                            src={disableButton}
                                            alt="disable"
                                          />
                                        )}
                                        {o.status === false
                                          ? t("ENABLE")
                                          : t("DISABLE")}
                                        {/* <img
                                    src={enableButton}
                                    alt="enable"
                                  />
                                  {t("DISABLE")} */}
                                      </Dropdown.Item>
                                    </li>
                                    <li className="exper-drop-ul-li">
                                      <Dropdown.Item
                                        onClick={() => handleEditOpen(o._id)}
                                      >
                                        <img
                                          src={editButton}
                                          alt="edit"
                                        />
                                        {t("EDIT")}
                                      </Dropdown.Item>
                                    </li>
                                    <li className="exper-drop-ul-li">
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteModal(
                                            o,
                                            "REPORT"
                                          )
                                        }
                                      >
                                        <img
                                          src={deleteButton}
                                          alt="edit"
                                        />
                                        {t("DELETE")}
                                      </Dropdown.Item>
                                    </li>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <div style={{ marginTop: "10px" }}>
                  <button
                    type="button"
                    className="user-btn"
                    onClick={() => setIsEditMode(true)}
                  >
                    <span className="user-icon">
                      <img src={plus} alt="plus" />
                    </span>
                    {t("ADD_NEW_REPORT")}
                  </button>
                </div>
              </>
            ) : (
              <div className="experience-wpr flex">
                <div className="experience-inner">
                  <img src={expImg} alt="loadding" />
                  <p>{t("THERE_IS_NO_REPORT_YET")}</p>
                  <div className="exper-btn">
                    <Button
                      type="button"
                      className=""
                      onClick={() => setIsEditMode(true)}
                    >
                      {t("ADD_NEW_REPORT")}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="addBranch mt-3">
              <button
                className="plainBTN"
                onClick={() => setIsEditMode(true)}
              >
                <span>
                  <img src={plus} alt="" />
                </span>
                <strong>{t("ADD_NEW_REPORT")}</strong>
              </button>
            </div> */}
          </div>
          {isEditMode && (
            <ReportModal
              show={isEditMode}
              brandId={props.selectBrand}
              setIsShow={(flag) => setIsEditMode(flag)}
            />
          )}
          {isDeleteModal && (
            <DeleteModal
              show={isDeleteModal}
              setIsShow={(flag) => setIsDeleteModal(flag)}
              name={deleteObj?.title || deleteObj?.name}
              title={deleteObjTitle}
              click={() => handleDelete(deleteObj._id)}
              Loading={deleteLoading}
              Message={deleteMessage}
            />
          )}
          {isDisableModal && (
            <DisableModal
              show={isDisableModal}
              setIsShow={(flag) => setIsDisableModal(flag)}
              name={disableObj?.title || disableObj?.name}
              objDisabled={!(disableObj?.status)}
              title={disableObjTitle}
              click={() => handleDisable(disableObj._id)}
              Loading={disableLoading}
              Message={disableMessage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Report;
