import { del, get, post, postFormData, putFormData } from ".";

const URI = "/brands";

const getbrandListing = (payload) => {
  const URL = `${URI}`;
  return post(URL, payload);
};
const getbrandforDropDown = () => {
  const URL = `${URI}/brands-dropdown`;
  return get(URL);
};

/**
 * @typedef {{
 *  name: string;
 *  logo: string;
 *  category: string;
 *  branches: number;
 *  is_disable: boolean;
 *  socialMedia_id: string | null;
 *  _id: string;
 *  owner_id: string;
 *  created_by: string;
 *  updated_by: string;
 *  createdAt: string;
 *  updatedAt: string;
 * }} Brand
 * @typedef {{
 *   status: number;
 *   message: string;
 *   data: Brand;
 *   error: null | string;
 * }} AddBrand
 * @returns { Promise<import("axios").AxiosResponse<AddBrand>>}
 */
const addBrand = (payload) => {
  const URL = `${URI}/create`;
  return postFormData(URL, payload);
};

const getOneBrandToUpdate = (selectId) => {
  const URL = `${URI}/update/${selectId}`;
  return get(URL);
};

const updateBrand = (payload, brand_id) => {
  const URL = `${URI}/update/${brand_id}`;
  return putFormData(URL, payload);
};

const getLanguages = () => {
  const URL = `${URI}/get-languages`;
  return get(URL);
};

const getCurrency = () => {
  const URL = `${URI}/get-currency`;
  return get(URL);
};

const disEnableBrandsByID = (b_id) => {
  const URL = `${URI}/disable-enable/${b_id}`;
  return del(URL);
};

const Brands = {
  getbrandListing,
  addBrand,
  getOneBrandToUpdate,
  updateBrand,
  getLanguages,
  getbrandforDropDown,
  disEnableBrandsByID,
  getCurrency,
};
export default Brands;
