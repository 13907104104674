import axios from "axios";
import {
  GENERIC_ERROR_MESSAGE,
  SERVER_AUTH_ERROR_STATUS_CODE,
  SERVER_VALIDATION_STATUS_CODE,
} from "../config/constants";
import ROUTE_URLS from "../config/routes";
import LocalstorageService from "../helpers/localstorage-services";
import i18next from "i18next";
import { ZodError } from "zod";
//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL || "";

let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

function generateNewCancelTokenSource() {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source();
}

export function cancelAllPendingRequests() {
  console.log("cancelling all requestsss");
  CANCEL_TOKEN_SOURCE.cancel("All Requests have been cancelled");
  generateNewCancelTokenSource();
}

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = LocalstorageService.getLoggedInUserToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["ttxlanguage"] = i18next.resolvedLanguage;
      config.headers["ttxTimeZone"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { data, status } = error.response;
      if (status === SERVER_AUTH_ERROR_STATUS_CODE) {
        LocalstorageService.logoutUser();
        window.location.replace(ROUTE_URLS.LOGIN);
      }
      if (status === SERVER_VALIDATION_STATUS_CODE) {
        const { error } = data;
        const errorsArray = [];
        for (const key in error) {
          if (Object.hasOwnProperty.call(error, key)) {
            const _error = error[key];
            errorsArray.push(_error);
          }
        }
        return Promise.reject(errorsArray);
      }
      // Zod validation error
      if (status === 400 && Array.isArray(data)) {
        return Promise.reject({
          isZodErr: true,
          data: data.reduce(
            (acc, item) => ({
              ...acc,
              [item.type]: new ZodError(item.errors.issues).format(),
            }),
            {}
          ),
        });
      }
      return Promise.reject(GENERIC_ERROR_MESSAGE);
    }
    return Promise.reject(error?.message);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, {
    ...config,
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
}

export async function post(url, data, config = {}) {
  return axiosApi.post(
    url,
    { ...data },
    { ...config, cancelToken: CANCEL_TOKEN_SOURCE.token }
  );
}

export async function put(url, data, config = {}) {
  return axiosApi.put(
    url,
    { ...data },
    { ...config, cancelToken: CANCEL_TOKEN_SOURCE.token }
  );
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, {
    ...config,
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
}

export async function delPayload(url, data = {}, config = {}) {
  return await axiosApi.delete(url, {
    Authorization: { ...config },
    data: { ...data },
  });
}

export async function postFormData(url, data, config = {}) {
  return axiosApi.post(url, data, {
    "Content-Type": "multipart/form-data",
    ...config,
  });
}

export async function putFormData(url, data, config = {}) {
  return axiosApi.put(url, data, {
    "Content-Type": "multipart/form-data",
    ...config,
  });
}

export async function getPdf(url, responseType = "blob", config = {}) {
  return axiosApi.get(url, { ...config, responseType });
}

export async function postPdf(
  url,
  responseType = "application/pdf",
  config = {},
  data
) {
  return axiosApi.post(
    url,
    responseType,
    {
      ...config,
    },
    data
  );
}

export async function postExel(
  url,
  responseType = "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  config = {},
  data
) {
  return axiosApi.post(url, responseType, { ...config }, { ...data });
}
