import trustree_feedbacks from "../services/trustreefeedbacks";
import {
  getAllFeedbackCountError,
  getAllFeedbackCountRequest,
  getAllFeedbackCountSuccess,
  getAllFeedbackPaginationRequest,
  getResolvedFeedbackPaginationRequest,
  getResolvedTrustreeFeedbackError,
  getResolvedTrustreeFeedbackRequest,
  getResolvedTrustreeFeedbackSuccess,
  getTotalFeedbackCountError,
  getTotalFeedbackCountRequest,
  getTotalFeedbackCountSuccess,
  getTrustreeFeedbackError,
  getTrustreeFeedbackRequest,
  getTrustreeFeedbackSuccess,
  getUnResolvedTrustreeFeedbackError,
  getUnResolvedTrustreeFeedbackRequest,
  getUnResolvedTrustreeFeedbackSuccess,
} from "../slices/trustreeFeedbacks.slice";

export function getTrustreeFeedbacks(brand_id, payload) {
  return (dispatch) => {
    if(payload.page <= 1 || !payload.page) dispatch(getTrustreeFeedbackRequest()) 
    else dispatch(getAllFeedbackPaginationRequest());

    trustree_feedbacks
      .getTrustreefeedbacks(brand_id, payload)
      .then((response) => {
        const { data, error, status } = response.data;
        if (status === 1) {
          dispatch(getTrustreeFeedbackSuccess({
            ...data,
            metadata: {
              ...data.metadata[0],
              currentPage: payload.page ? payload.page : 1
            }
          }));
        } else {
          dispatch(getTrustreeFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(getTrustreeFeedbackError(error));
      });
  };
}

export function getResolvedTrustreeFeedbacks(brand_id, payload) {
  return (dispatch) => {
    if(payload.page <= 1 || !payload.page) dispatch(getResolvedTrustreeFeedbackRequest());
    else dispatch(getResolvedFeedbackPaginationRequest());

    trustree_feedbacks
      .getResolvedTrustreefeedbacks(brand_id, payload)
      .then((response) => {
        const { data, error, status } = response.data;
        if (status === 1) {
          dispatch(getResolvedTrustreeFeedbackSuccess(
            {
              ...data,
              metadata: {
                ...data.metadata[0],
                currentPage: payload.page ? payload.page : 1
              }
            }
          ));
        } else {
          dispatch(getResolvedTrustreeFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(getResolvedTrustreeFeedbackError(error));
      });
  };
}

export function getUnResolvedTrustreeFeedbacks(brand_id, payload) {
  return (dispatch) => {
    if(payload.page <= 1 || !payload.page) dispatch(getUnResolvedTrustreeFeedbackRequest());
    else dispatch(getResolvedFeedbackPaginationRequest());

    trustree_feedbacks
      .getUnResolvedTrustreefeedbacks(brand_id, payload)
      .then((response) => {
        const { data, error, status } = response.data;
        if (status === 1) {
          dispatch(getUnResolvedTrustreeFeedbackSuccess(
            {
              ...data,
              metadata: {
                ...data.metadata[0],
                currentPage: payload.page ? payload.page : 1
              }
            }
          ));
        } else {
          dispatch(getUnResolvedTrustreeFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(getTrustreeFeedbackError(error));
      });
  };
}

export function requestAllFeedbackCount(brand_id, data = {}) {
  return (dispatch) => {
    dispatch(getAllFeedbackCountRequest());
    trustree_feedbacks
      .getAllFeedbackCount(brand_id, data)
      .then((response) => {
        const { data, error, status } = response.data;
        if (status === 1) {
          dispatch(getAllFeedbackCountSuccess(data));
        } else {
          dispatch(getAllFeedbackCountError(error));
        }
      })
      .catch((error) => {
        dispatch(getAllFeedbackCountError(error));
      });
  };
}


export function totalFeedbackCount(brand_id, data) {
  return (dispatch) => {
    dispatch(getTotalFeedbackCountRequest());
    trustree_feedbacks
      .getTotalFeedbackCount(brand_id, data)
      .then((response) => {
        const { data, error, status } = response.data;
        if (status === 1) {
          dispatch(getTotalFeedbackCountSuccess(data));
        } else {
          dispatch(getTotalFeedbackCountError(error));
        }
      })
      .catch((error) => {
        dispatch(getTotalFeedbackCountError(error));
      });
  };
}
