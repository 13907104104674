import React, { useEffect, useState, useRef, useCallback } from "react";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik, FieldArray, FormikProvider } from "formik";
import {
    Button, Dropdown, Form, Modal, Spinner, FormCheck,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addBrand, updateBrand } from "../../../../middlewares/brands";
import ToastService from "../../../../helpers/toast-services";
import {
    report_title,
    brand_selection,
    experience_type,
} from "../../../../helpers/yup.validation.schema";
import {
    getBranchesByBrandId,
} from "../../../../middlewares/branches";
import {
    getExperienceTypeByBrandId,
} from "../../../../middlewares/experienceType";
import trash from "../../../../assets/images/trash.svg";
import plus from "../../../../assets/images/add-square.svg";
import { resetForm } from "../../../../slices/reports.slice";
import ErrorList from "../../../../components/error-list/ErrorList";
import { useTranslation } from "react-i18next";
import { branch_data, report_type_data, send_every } from "../../../../helpers/jsonData";
import downArrow from "../../../../assets/images/downArrow.svg";
import { addReports, deleteReports, updateReports, getReports } from '../../../../middlewares/reports';
import { getUsers } from "../../../../middlewares/users";

const ReportModal = (props) => {
    const { singleReport, updateLoading } = useSelector((state) => state.reports);
    const { experienceTypeData, } = useSelector((state) => state.experience);
    const { branches } = useSelector((state) => state.branch);
    const [selectedExperienceTypes, setSelectedExperienceTypes] = useState([]);
    const [selectedBranchTypes, setSelectedBranchTypes] = useState([]);
    const experienceTypePlaceholder = "Select The Experience Type";
    const branchPlaceholder = "Select The Branch";
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentNumber, setCurrentNumber] = useState(1);
    const [focusedRow, setFocusedRow] = useState(null);
    const dropdownRef = useRef(null);
    const [userName, setUserName] = useState('');
    const [User, setUser] = useState();
    const [inputValue, setInputValue] = useState('');
    const [userData, setUserData] = useState();

    const {
        users,
    } = useSelector((state) => state.user);

    useEffect(() => {
        setUser(users.filter((user) => user.user_status === "active"))
    }, [users])

    const handleUserNameChange = (index, value) => {
        formik.setFieldValue(`users.${index}.name`, value);
        setFocusedRow(null);
    };

    const handleDropdownSelect = (index, value, email) => {
        setUserName(value)
        formik.setFieldValue(`users.${index}.name`, value);
        formik.setFieldValue(`users.${index}.email`, email);
        setFocusedRow(null);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            event.target !== document.activeElement // Exclude clicks on the input field
        ) {
            setFocusedRow(null);
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (props.brandId) {
            dispatch(getBranchesByBrandId(props.brandId));
            dispatch(getExperienceTypeByBrandId(props.brandId));
        }
    }, [props.brandId]);

    useEffect(() => {
        const payload = {
            perPage: "",
            currentPage: "",
            sort: "",
            search: "",
            filter: "",
        };
        dispatch(getUsers(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.brandId]);

    const [sort] = useState([
        {
            key: "#",
            column: "number",
            value: "#",
        },
        {
            key: "name",
            column: "name",
            value: "Name",
        },
        {
            key: "email",
            column: "email",
            value: "Email",
        },
        {
            key: "type",
            column: "type",
            value: "Type",
        },
        {
            key: "",
            column: "",
            value: "",
        },
    ]);

    useEffect(() => {
        if (singleReport !== null) {
            formik.setValues({
                name: singleReport.report.title,
                category: singleReport.report.report_type,
                branch: singleReport.report.branch_id,
                experienceTypes: singleReport.report.experience_type_id,
                send: singleReport.report.report_send,
                users: singleReport.report.user_email.map((user) => ({
                    name: user.username,
                    email: user.userEmail,
                })),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleReport]);


    const defaultServiceObject = {
        name: "",
        email: "",
        permission: "",
    };
    const formikRef = useRef(null);

    const handleAddNewService = useCallback(
        (arrayHelpers) => {
            arrayHelpers.push(defaultServiceObject);
            // Access the formik object from the ref and call setFieldValue
            if (formikRef.current) {
                const newUsers = [...formikRef.current.values.users, defaultServiceObject];
                formikRef.current.setFieldValue("users", newUsers);
            }
        },
        [formikRef]
    );

    const {
        saveReportMessage,
        saveReportLoading,
        saveReportError,
        saveError,
        saveMessage,
        saveLoading
    } = useSelector((state) => state.reports);

    useEffect(() => {
        if (!saveReportLoading) {
            if (saveReportMessage) {
                ToastService.success(saveReportMessage);
                props.setIsShow(!props.show);
                formik.resetForm();
                dispatch(resetForm());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveReportMessage]);

    useEffect(() => {
        if (!saveLoading) {
            if (saveMessage) {
                ToastService.success(saveMessage);
                props.setIsShow(!props.show);
                formik.resetForm();
                dispatch(resetForm());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveMessage]);

    const addBranchSchema = Yup.object().shape({
        name: report_title,
        category: brand_selection,
        branch: experience_type,
        experienceTypes: experience_type,
        send: brand_selection,
    });

    const handleClose = () => {
        props.setIsShow(!props.show);
        formik.resetForm();
        dispatch(resetForm());
    };

    const handleSubmit = (values) => {

        const reportData = {
            brand_id: props.brandId,
            title: values.name,
            report_type: values.category,
            branch_id: values.branch,
            experience_type_id: values.experienceTypes,
            report_send: values.send,
            user_email: values.users.map((user) => ({
                username: user.name,
                userEmail: user.email,
            })),
            status: true
        };
        if (singleReport !== null) {
            dispatch(updateReports(reportData, singleReport?.report?._id));
        } else {
            dispatch(addReports(reportData));
        }
    };

    const formik = useFormik({
        initialValues: {
            users: [
                {
                    name: "",
                    email: "",
                    permission: "",
                },
            ],
            name: "",
            category: "",
            branch: [],
            experienceTypes: [],
            send: "",
        },
        validationSchema: addBranchSchema,
        onSubmit: handleSubmit,
    });


    const handleChange = (field, value) => {
        formik.setFieldValue(field, value);
    };


    const brand_category_label =
        (report_type_data &&
            report_type_data.find((obj) => {
                return obj.value === formik.values.category;
            })) || { label: t('SELECT_THE_REPORT_TYPE') }

    const send_every_label =
        (send_every &&
            send_every.find((obj) => {
                return obj.value == formik.values.send;
            })) || { label: t('SELECT_THE_TYPE') }

    useEffect(() => {
        if (
            formik.values.experienceTypes &&
            formik.values.experienceTypes.length > 0 &&
            experienceTypeData &&
            experienceTypeData.length > 0
        ) {
            const selectedTypes = formik.values.experienceTypes.map((o) =>
                experienceTypeData.find((obj) => obj._id == o)
            );
            setSelectedExperienceTypes(selectedTypes);
        } else {
            setSelectedExperienceTypes([]);
        }
    }, [formik.values.experienceTypes, experienceTypeData]);

    useEffect(() => {
        if (
            formik.values.branch &&
            formik.values.branch.length > 0 &&
            branches &&
            branches.length > 0
        ) {
            const selectedTypes = formik.values.branch.map((o) =>
                branches.find((obj) => obj._id == o)
            );
            setSelectedBranchTypes(selectedTypes);
        } else {
            setSelectedBranchTypes([]);
        }
    }, [formik.values.branch, branches]);


    useEffect(() => {
        let filtered = User;
        if (inputValue) {
            filtered = filtered.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()));
        }
        setUserData(filtered);
    }, [inputValue, User])

    return (
        <Modal show={props.show} className="modal-lg">
            {!updateLoading && (
                <Modal.Header>
                    <Modal.Title className="cust-title">
                        {singleReport !== null
                            ? t("THE_UPDATE_REPORT")
                            : t("THE_REPORT")}
                    </Modal.Title>
                    <div>
                        <Button className="close_btn" onClick={() => handleClose()}>
                            <FontAwesomeIcon icon={`xmark`} />
                        </Button>
                    </div>
                </Modal.Header>
            )}
            {saveReportError && <ErrorList error={saveReportError} />}
            {saveError && <ErrorList error={saveError} />}
            <Form>
                {updateLoading ? (
                    <Spinner
                        className="spinner-auto"
                        style={{ color: "#164665" }}
                        animation="border"
                    />
                ) : (
                    <>
                        <Modal.Body>
                            <div className="flex">
                                <div className="modalAddBranchLeft">
                                    <div className="inputWrap mb-3">
                                        <Form.Label>{t("REPORT_TITLE")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            className={`${formik.touched.name &&
                                                formik.errors.name &&
                                                "is-invalid"
                                                }`}
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            style={{ borderColor: "rgba(43, 188, 145, 0.25)", padding: "4px 10px" }}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.name}
                                            </div>
                                        )}
                                    </div>
                                    <div className="inputWrap mb-3">
                                        <Form.Label>{t("REPORT_TYPE")}</Form.Label>
                                        <Dropdown className="ellipsis">
                                            <Dropdown.Toggle
                                                name="category"
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.category &&
                                                    formik.errors.category &&
                                                    "is-invalid"
                                                    }`}
                                            >
                                                <div className="d-flex justify-content-between w-100 align-items-center">
                                                    <span className="dropdown-value">
                                                        {t(brand_category_label.label)}
                                                    </span>
                                                    <span className="d-Arrow">
                                                        <img src={downArrow} alt="downArrow" />
                                                    </span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {report_type_data &&
                                                    report_type_data.map((o, i) => (
                                                        <Dropdown.Item
                                                            as={"span"}
                                                            value={o.value}
                                                            key={i}
                                                            onClick={() => handleChange("category", o.value)}
                                                        >
                                                            {t(o.label)}
                                                        </Dropdown.Item>
                                                    ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {formik.touched.category && formik.errors.category && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.category}
                                            </div>
                                        )}
                                    </div>
                                    <div className="inputWrap mb-3">
                                        <Form.Label>{t("BRANCHES")}</Form.Label>
                                        <Dropdown
                                            autoClose={"outside"}
                                            className="ellipsis"
                                            id="dropdown-branch-type"
                                        >
                                            <Dropdown.Toggle variant="success" className="form-control">
                                                {/* <span className="dropdown-badge me-2">
                                                    {selectedExperienceTypes.length > 0 ? selectedExperienceTypes.length : "0"}
                                                </span> */}
                                                <div className="d-flex justify-content-between w-100 align-items-center">
                                                    {selectedBranchTypes.length > 0 && selectedBranchTypes[0] !== null ? (
                                                        <div className="d-flex justify-content-start overflow-hidden multi">
                                                            {selectedBranchTypes.map((o, i) => (
                                                                <div key={i}>
                                                                    <span className="dropdown-value">{(i ? ", " : "") + o?.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <span className="dropdown-value">{branchPlaceholder}</span>
                                                    )}
                                                    <span className="d-Arrow">
                                                        <img src={downArrow} alt="downArrow" />
                                                    </span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu id="dropdown-branch-type-menu">
                                                {branches.map((o, index) => (
                                                    <Dropdown.Item as={"span"} key={index}>
                                                        <div className="radio-and-check d-flex">
                                                            <div className="d-flex align-items-center">
                                                                <FormCheck.Input
                                                                    name="branch"
                                                                    type="checkbox"
                                                                    value={o._id}
                                                                    onChange={formik.handleChange}
                                                                    checked={
                                                                        formik.values.branch &&
                                                                        formik.values.branch.includes(o._id)
                                                                    }
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                <FormCheck.Label className="ps-1">{o.name}</FormCheck.Label>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {formik.touched.branch && formik.errors.branch && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.branch}
                                            </div>
                                        )}
                                    </div>
                                    <div className="inputWrap mb-3">
                                        <Form.Label>{t("Experience Type")}</Form.Label>
                                        <Dropdown
                                            autoClose={"outside"}
                                            className="ellipsis"
                                            id="dropdown-experience-type"
                                        >
                                            <Dropdown.Toggle variant="success" className="form-control">
                                                {/* <span className="dropdown-badge me-2">
                                                    {selectedExperienceTypes.length > 0 ? selectedExperienceTypes.length : "0"}
                                                </span> */}
                                                <div className="d-flex justify-content-between w-100 align-items-center">
                                                    {selectedExperienceTypes.length > 0 && selectedExperienceTypes[0] !== null ? (
                                                        <div className="d-flex justify-content-start overflow-hidden multi">
                                                            {selectedExperienceTypes.map((o, i) => (
                                                                <div key={i}>
                                                                    <span className="dropdown-value">{(i ? ", " : "") + o?.title}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <span className="dropdown-value">{experienceTypePlaceholder}</span>
                                                    )}
                                                    <span className="d-Arrow">
                                                        <img src={downArrow} alt="downArrow" />
                                                    </span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu id="dropdown-experience-type-menu">
                                                {experienceTypeData.map((o, index) => (
                                                    <Dropdown.Item as={"span"} key={index}>
                                                        <div className="radio-and-check d-flex">
                                                            <div className="d-flex align-items-center">
                                                                <FormCheck.Input
                                                                    name="experienceTypes"
                                                                    type="checkbox"
                                                                    value={o._id}
                                                                    onChange={formik.handleChange}
                                                                    checked={
                                                                        formik.values.experienceTypes &&
                                                                        formik.values.experienceTypes.includes(o._id)
                                                                    }
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                <FormCheck.Label className="ps-1">{o.title}</FormCheck.Label>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {formik.touched.experienceTypes && formik.errors.experienceTypes && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.experienceTypes}
                                            </div>
                                        )}
                                    </div>
                                    <div className="inputWrap mb-3">
                                        <Form.Label>{t("SEND_EVRY")}</Form.Label>
                                        <Dropdown className="ellipsis">
                                            <Dropdown.Toggle
                                                name="send"
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.send &&
                                                    formik.errors.send &&
                                                    "is-invalid"
                                                    }`}
                                            >
                                                <div className="d-flex justify-content-between w-100 align-items-center">
                                                    <span className="dropdown-value">
                                                        {t(send_every_label.label)}
                                                    </span>
                                                    <span className="d-Arrow">
                                                        <img src={downArrow} alt="downArrow" />
                                                    </span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {send_every &&
                                                    send_every.map((o, i) => (
                                                        <Dropdown.Item
                                                            as={"span"}
                                                            value={o.value}
                                                            key={i}
                                                            onClick={() => handleChange("send", o.value)}
                                                        >
                                                            {t(o.label)}
                                                        </Dropdown.Item>
                                                    ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {formik.touched.send && formik.errors.send && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.send}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Form.Label>{t("EMAILS")}</Form.Label>

                                <FormikProvider value={formik}>
                                    <FieldArray
                                        name="users"
                                        render={(arrayHelpers) => (
                                            <>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {sort &&
                                                                sort.map((obj, index) => (
                                                                    <th
                                                                        key={obj.key}
                                                                        className="width260"
                                                                        style={{
                                                                            width:
                                                                                obj.key === "#" || obj.key === "type" || obj.key === ""
                                                                                    ? "5%"
                                                                                    : "40%",
                                                                        }}
                                                                    >
                                                                        {obj.key !== "" ? ( // Check if key is not empty
                                                                            <span style={{ cursor: "pointer" }}>
                                                                                {t(obj.value)}
                                                                            </span>
                                                                        ) : (
                                                                            // Render without icon for the empty key
                                                                            <span>{t(obj.value)}</span>
                                                                        )}
                                                                    </th>
                                                                ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {formik.values.users.map((o, index) => (
                                                            <tr>
                                                                <td style={{ padding: "14px 10px", width: "2%" }}>
                                                                    {/* Display the current number in the "# field" */}
                                                                    <span>{currentNumber + index}</span>
                                                                </td>
                                                                <td style={{ padding: "14px 10px", width: "40%" }}>
                                                                    <Dropdown className="ellipsis">
                                                                        <Dropdown.Toggle
                                                                            id={`users.${index}.name`}
                                                                            onBlur={formik.handleBlur}
                                                                            className={`form-control  ${formik.touched.users &&
                                                                                formik.touched.users[index] &&
                                                                                formik.touched.users[index]
                                                                                    .name &&
                                                                                formik.errors.users &&
                                                                                formik.errors.users[index] &&
                                                                                formik.errors.users[index].name &&
                                                                                "is-invalid hover"
                                                                                } `}
                                                                            style={{ borderColor: "#ffffff", padding: "0px 0px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between w-100 align-items-center" style={{ gap: "25px" }}>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name={`users.${index}.name`}
                                                                                    className={`${formik.touched.users &&
                                                                                        formik.touched.users[index] &&
                                                                                        formik.touched.users[index].name &&
                                                                                        formik.errors.users &&
                                                                                        formik.errors.users[index] &&
                                                                                        formik.errors.users[index].name &&
                                                                                        "is-invalid"
                                                                                        }`}
                                                                                    value={o.name}
                                                                                    onChange={(e) => {
                                                                                        formik.handleChange(e)
                                                                                        setInputValue(e.target.value)
                                                                                    }}
                                                                                    onBlur={formik.handleBlur}
                                                                                    style={{ borderColor: "#dee2e6", padding: "4px 10px" }}
                                                                                />
                                                                                {formik.touched.users &&
                                                                                    formik.touched.users[index] &&
                                                                                    formik.touched.users[index].name &&
                                                                                    formik.errors.users &&
                                                                                    formik.errors.users[index] &&
                                                                                    formik.errors.users[index].name && (
                                                                                        <div className="invalid-feedback d-block mb-2">
                                                                                            {formik.errors.users[index].name}
                                                                                        </div>
                                                                                    )}
                                                                                {/* <span className="d-Arrow">
                                                                                    <img src={downArrow} alt="downArrow" />
                                                                                </span> */}
                                                                            </div>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu style={{
                                                                            overflow: "auto",
                                                                            height: "190px",
                                                                        }}>
                                                                            {userData  &&
                                                                                userData.map((o, i) => (
                                                                                    <Dropdown.Item
                                                                                        as={"span"}
                                                                                        value={o.value}
                                                                                        key={i}
                                                                                        onClick={() => {
                                                                                            handleChange(`users.${index}.name`, o.name)
                                                                                            formik.setFieldValue(`users.${index}.email`, o.email);
                                                                                        }}
                                                                                    >
                                                                                        {t(o.name)}
                                                                                    </Dropdown.Item>
                                                                                ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                                <td style={{ padding: "14px 10px", width: "40%" }}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`users.${index}.email`}
                                                                        className={`${((formik.touched.users &&
                                                                            formik.touched.users[index] &&
                                                                            formik.touched.users[index].email &&
                                                                            formik.errors.users &&
                                                                            formik.errors.users[index] &&
                                                                            formik.errors.users[index].email) ||
                                                                            (formik.errors &&
                                                                                formik.errors[index] &&
                                                                                formik.errors[index].email)) &&
                                                                            "is-invalid"
                                                                            }`}
                                                                        value={o.email}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        style={{ borderColor: "#dee2e6", padding: "4px 10px" }}
                                                                    />
                                                                    {formik.touched.users &&
                                                                        formik.touched.users[index] &&
                                                                        formik.touched.users[index].email &&
                                                                        formik.errors.users &&
                                                                        formik.errors.users[index] &&
                                                                        formik.errors.users[index].email && (
                                                                            <div className="invalid-feedback d-block mb-2">
                                                                                {formik.errors.users[index].email}
                                                                            </div>
                                                                        )}
                                                                    {formik.errors &&
                                                                        formik.errors[index] &&
                                                                        formik.errors[index].email && (
                                                                            <div className="invalid-feedback d-block mb-2">
                                                                                {formik.errors[index].email}
                                                                            </div>
                                                                        )}
                                                                </td>
                                                                <td style={{ padding: "14px 10px", width: "5%" }}>
                                                                    <span>{o.name === "" ? t("-") : o.name === userName ? t("User") : t("Email")}</span>
                                                                </td>
                                                                <td style={{ padding: "14px 10px", width: "5%" }}>
                                                                    {index >= 0 && (
                                                                        <div className=" delete">
                                                                            <img
                                                                                src={trash}
                                                                                alt="trash"
                                                                                className="ms-2"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <button
                                                    type="button"
                                                    className="user-btn"
                                                    style={{ marginTop: "10px" }}
                                                    // data-toggle="modal"
                                                    // data-target="#exampleModal"
                                                    onClick={() => handleAddNewService(arrayHelpers)}
                                                >
                                                    <span className="user-icon">
                                                        <img src={plus} alt="plus" />
                                                    </span>
                                                    {t("ADD_USER")}
                                                </button>
                                            </>
                                        )}
                                    />
                                </FormikProvider>
                            </div>
                        </Modal.Body>
                        <div className="Experience-btn-modal flex justify-content-center">
                            <Button
                                type="btn"
                                className="btn"
                                onClick={formik.handleSubmit}
                                disabled={
                                    !(formik.isValid && formik.dirty) ||
                                    saveLoading ||
                                    saveReportLoading
                                }
                            >
                                {saveLoading || saveReportLoading ? t("LOADING") : [t("SAVE")]}
                            </Button>
                            <Button
                                className="btn btn-outline-secondary"
                                onClick={() => handleClose()}
                            >
                                {t("CANCEL")}
                            </Button>
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default ReportModal;
