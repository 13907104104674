import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  FormCheck,
  Tab,
  Tabs,
} from "react-bootstrap";
import "../feedback.scss";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getbrandsforDropdown } from "../../../middlewares/brands";
import exportData from "../../../assets/images/exportData.svg";
import { useFormik } from "formik";
import { getMonth, getYear } from "date-fns";
import DatePicker from "react-datepicker";
import { rating_filter } from "../../../helpers/jsonData";
import LeftArrow from "../../../assets/images/u_arrow-left.svg";
import RightArrow from "../../../assets/images/u_arrow-right.svg";
import dateImg from "../../../assets/images/Date.svg";
import downArrow from "../../../assets/images/downArrow.svg";
import { getBranchesByBrandId } from "../../../middlewares/branches";
import AllGoogleFeedback from "./AllGoogleFeedback";
import ResolvedGoogleFeedback from "./ResolvedGoogleFeedback";
import UnResolvedGoogleFeedback from "./UnResolvedGoogleFeedback";
import ExportFeedbackDataModal from "../trustree_feedbacks/ExportFeedbackDataModal";
import brandImg from "../../../assets/images/Brandlogo.png";
import { totalFeedbackCount } from "../../../middlewares/trustreeFeedbacks";
import locale from "../../../helpers/date-importer";
import { cancelAllPendingRequests } from "../../../services";

const GoogleFeedback = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { branches } = useSelector((state) => state.branch);
  const { dropDownBrands: brands } = useSelector((state) => state.brand);
  const [selectBrand, setSelectBrand] = useState("");
  const [isMonth, setIsMonth] = useState(new Date().getMonth());
  const [isYear, setIsYear] = useState(new Date().getFullYear());
  const [isFirst, setIsFirst] = useState([]);
  const [isSecond, setIsSecond] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const b = localStorage.getItem("b_id");

  useEffect(() => {
    let filter = {};
    const payload = {
      filter,
    };
    dispatch(totalFeedbackCount(b, payload.filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b]);

  useEffect(() => {
    dispatch(getbrandsforDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      cancelAllPendingRequests();
    }
  }, []);

  useEffect(() => {
    if (brands && brands.length > 0) {
      if (b) {
        const findBrandObj = brands.find((o) => o._id === b);
        if (findBrandObj) {
          setSelectBrand(b);
        } else {
          localStorage.setItem("b_id", brands[0]._id);
          setSelectBrand(brands[0]._id);
        }
      } else {
        localStorage.setItem("b_id", brands[0]._id);
        setSelectBrand(brands[0]._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, selectBrand]);

  useEffect(() => {
    let month = isMonth;
    let first = new Date(isYear, month, 1);
    let last = new Date(isYear, month + 1, 0);
    setIsFirst(first);
    setIsSecond(last);
  }, [isMonth, isYear]);

  const brand_name =
    brands &&
    brands.find((obj) => {
      return obj._id === b;
    });

  const handleChange = (e) => {
    setSelectBrand(e);
    localStorage.setItem("b_id", e);
  };

  useEffect(() => {
    if (selectBrand) {
      dispatch(getBranchesByBrandId(selectBrand));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBrand]);

  const formik = useFormik({
    initialValues: {
      branch_id: "",
      rating: "",
      date: "",
      search: "",
      resolvedSearch: "",
      unresolvedSearch: "",
    },
  });

  const range = (a, b) => {
    let year = [];
    for (let i = a; i <= b; i++) {
      year.push(i);
    }
    return year;
  };

  const years = range(getYear(new Date()) - 100, getYear(new Date()) + 1, 1);
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const handleExportClick = () => {
    setIsShow(true);
  };

  return (
    <>
      <Form>
        <div className="contentWrap">
          <div className="contentHead">
            <h1>Google Feedbacks</h1>
            <div className="customSelect ms-auto staticIcon">
              {brand_name && (
                <Dropdown className="ellipsis">
                  <Dropdown.Toggle className="form-control">
                    <div className="d-flex w-100 align-items-center ImgSelect">
                      <span>
                        {brand_name.logo ? (
                          <img
                            src={
                              `${process.env.REACT_APP_BASE_URL}${brand_name.logo}` ||
                              require("../../../assets/images/Brandlogo.png")
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://api.app.trustree.com/images/Brandlogo.png";
                            }}
                            alt="logo"
                          />
                        ) : (
                          <img src={brandImg} alt="logo" />
                        )}
                      </span>
                      <span
                        style={{ paddingRight: "30px" }}
                        className="dropdown-value ms-2"
                      >
                        {brand_name.name}
                      </span>
                      <span className="d-Arrow brandArrow">
                        <img src={downArrow} alt="downArrow" />
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={"span"} disabled>
                      {t("SELECT_BRAND")}
                    </Dropdown.Item>
                    {brands &&
                      brands.length > 0 &&
                      brands.map((o, i) => (
                        <Dropdown.Item
                          as={"span"}
                          value={o._id}
                          key={i}
                          onClick={() => handleChange(o._id)}
                        >
                          <span>
                            {o.logo ? (
                              <img
                                src={
                                  `${process.env.REACT_APP_BASE_URL}${o.logo}` ||
                                  require("../../../assets/images/Brandlogo.png")
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://api.app.trustree.com/images/Brandlogo.png";
                                }}
                                alt="logo"
                              />
                            ) : (
                              <img src={brandImg} alt="logo" />
                            )}
                          </span>
                          {o.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <button
              onClick={() => handleExportClick()}
              type="button"
              className="ms-3 btnIconDashBDR"
            >
              <em>
                <img src={exportData} alt="" />
              </em>{" "}
              <strong style={{color: "#2BBC91"}}>Export Data</strong>
            </button>
          </div>
          <div className="FilterBox google-Filter-Box">
            <ul>
              <li>
                <Dropdown
                  autoClose={"outside"}
                  className="ellipsis customSelect"
                  id="dropdown-basic"
                  style={{ color: "black" }}
                >
                  <Dropdown.Toggle className="selectpicker justify-content-between">
                    <span style={{ color: "black" }}> Branch</span>
                    <span className="d-Arrow">
                      <img src={downArrow} alt="downArrow" />
                    </span>
                  </Dropdown.Toggle>
                  {branches && branches.length > 0 && (
                    <Dropdown.Menu id="dropdown-menu">
                      {branches &&
                        branches.map((o, index) => (
                          <Dropdown.Item as={"span"} key={index}>
                            <div className="radio-and-check ">
                              <div className="d-flex align-items-center">
                                <FormCheck.Input
                                  name="branch_id"
                                  type="checkbox"
                                  value={o._id ? o._id : null}
                                  onChange={formik.handleChange}
                                  checked={
                                    formik.values.branch_id &&
                                    formik.values.branch_id.includes(o._id)
                                  }
                                  onBlur={formik.handleBlur}
                                />
                                <FormCheck.Label className="ps-1">
                                  {o.name}
                                </FormCheck.Label>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ))}
                      <Dropdown.Item>
                        <Button
                          type="button"
                          className="custApplyBtn"
                          style={{ color: "black" }}
                        >
                          Apply
                        </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </li>
              <li>
                <Dropdown
                  autoClose={"outside"}
                  className="ellipsis customSelect"
                  id="dropdown-basic"
                  style={{ color: "black" }}
                >
                  <Dropdown.Toggle className="selectpicker justify-content-between">
                    <span style={{ color: "black" }}>Rating</span>
                    <span className="d-Arrow">
                      <img src={downArrow} alt="downArrow" />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu id="dropdown-menu">
                    {rating_filter &&
                      rating_filter.map((o, index) => (
                        <Dropdown.Item as={"span"} key={index}>
                          <div className="radio-and-check ">
                            <div className="d-flex align-items-center">
                              <FormCheck.Input
                                name="rating"
                                type="checkbox"
                                value={o.value}
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.rating &&
                                  formik.values.rating.includes(o.value)
                                }
                                onBlur={formik.handleBlur}
                              />
                              <FormCheck.Label className="ps-1">
                                {o.label}
                              </FormCheck.Label>
                            </div>
                          </div>
                        </Dropdown.Item>
                      ))}
                    <Dropdown.Item>
                      <Button
                        className="custApplyBtn"
                        type="button"
                        style={{ color: "black" }}
                      >
                        Apply
                      </Button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown
                  autoClose={"outside"}
                  className="ellipsis customSelect"
                  id="dropdown-basic"
                  style={{ color: "black" }}
                >
                  <Dropdown.Toggle className="selectpicker justify-content-between">
                    <span style={{ color: "black" }}>Date</span>
                    <span>
                      <img src={dateImg} alt="dateImg" />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    id="dropdown-menu"
                    className="calenderWPR tt-cal"
                  >
                    <Dropdown.Item>
                      <DatePicker
                        selectsRange={true}
                        startDate={formik.values?.date[0]}
                        endDate={formik.values?.date[1]}
                        onChange={(dateArr) => {
                          formik.setFieldValue("date", dateArr);
                        }}
                        minDate={isFirst}
                        maxDate={isSecond}
                        isClearable={true}
                        locale={locale[localStorage.getItem("i18nextLng")]}
                        inline
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <>
                            <div
                              style={{
                                margintop: 10,
                                marginbottom: 10,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  onClick={() => {
                                    decreaseMonth();
                                    setIsMonth(
                                      isMonth === 0 ? 11 : isMonth - 1
                                    );
                                    setIsYear(
                                      isMonth === 0 ? isYear - 1 : isYear
                                    );
                                  }}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <img src={LeftArrow} alt="monthDecrease" />
                                </span>
                              </div>

                              <select
                                className="form-select monthsSelect"
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) => {
                                  changeMonth(months.indexOf(value));
                                  setIsMonth(months.indexOf(value));
                                }}
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {t(option)}
                                  </option>
                                ))}
                              </select>

                              <select
                                className="form-select yearSelect "
                                value={getYear(date)}
                                onChange={({ target: { value } }) => {
                                  changeYear(value);
                                  setIsYear(value);
                                }}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <div
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  onClick={() => {
                                    increaseMonth();
                                    setIsMonth(
                                      isMonth === 11 ? 0 : isMonth + 1
                                    );
                                    setIsYear(
                                      isMonth === 11 ? isYear + 1 : isYear
                                    );
                                  }}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <img src={RightArrow} alt="monthIncrease" />
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      />
                      <div className="calenderButton">
                        <Button className="custApplyBtn mb-3">Apply</Button>
                        {formik.values.date &&
                          formik.values.date[0] &&
                          formik.values.date[0] !== null && (
                            <button className="bg-transparent border-0 clrfilter">
                              Clear All
                            </button>
                          )}
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
          <div className="feedbacksBox feedBackWpr">
            <Tabs className="tabs" defaultActiveKey="All">
              <Tab
                eventKey="All"
                className="catalogue-nav-items"
                tabClassName="Catalogues-tab"
                title={
                  <React.Fragment>
                    {t("All")}
                    <Badge className="num color-gray">{"0"}</Badge>
                  </React.Fragment>
                }
              >
                <AllGoogleFeedback />
              </Tab>
              <Tab
                eventKey="Unresolved"
                className="catalogue-nav-items"
                tabClassName="Catalogues-tab"
                title={
                  <React.Fragment>
                    {t("Unresolved")}
                    <Badge className="num color-gray">{"0"}</Badge>
                  </React.Fragment>
                }
              >
                <UnResolvedGoogleFeedback />
              </Tab>
              <Tab
                eventKey="Resolved"
                className="catalogue-nav-items"
                tabClassName="Catalogues-tab"
                title={
                  <React.Fragment>
                    {t("Resolved")}
                    <Badge className="num color-gray">{"0"}</Badge>
                  </React.Fragment>
                }
              >
                <ResolvedGoogleFeedback />
              </Tab>
            </Tabs>
          </div>
        </div>
        {isShow && (
          <ExportFeedbackDataModal
            show={isShow}
            setIsShow={(flag) => setIsShow(flag)}
            branch={branches}
          />
        )}
      </Form>
    </>
  );
};

export default GoogleFeedback;
