import { createSlice, current } from "@reduxjs/toolkit";
import { DASHBOARD } from "./slice-names";
import { endOfDay, startOfDay, sub } from "date-fns";

export const dashboardSlice = createSlice({
  name: DASHBOARD,
  initialState: {
    loading: true,
    quickOverview: null,
    message: "",
    error: "",

    branchesDashboardLoading: false,
    branchesDashboard: [],
    branchesDashboardError: "",

    categoriesFilterLoading: false,
    categoriesFilter: [],
    categoriesFilterError: "",

    performanceChartLoading: false,
    performanceChart: [],
    performanceChartErro: "",

    overAllRatingLoading: false,
    overAllRating: null,
    overAllRatingError: null,

    categoriesLoading: false,
    categories: [],
    categoriesError: "",

    suggestionsLoading: {},
    suggestions: {},
    suggestionsError: {},

    complimentsLoading: {},
    compliments: {},
    complimentsError: {},

    staffListingLoading: false,
    staffListing: [],
    staffListingError: "",

    productListingLoading: false,
    productListing: [],
    productListingError: "",

    feedbackModalLoading: true,
    feedbackModal: [],
    feedbackModalError: "",

    additionalQuestionsLoading: false,
    additionalQuestions: [],
    additionalQuestionsError: "",

    exportPdfLoading: false,
    exportPdfMessage: "",
    exportPdfError: "",

    feedBackReportLoading: false,
    feedBackReport: [],
    feedBackReportError: "",

    dashboardFilter: {
      branch_id: [],
      experience_type_id: [],
      rating: [],
      staff_id: [],
      date: {
        start: sub(startOfDay(new Date()), { months: 1 }).toISOString(),
        end: endOfDay(new Date()).toISOString(),
      },
      category_id: [],
    },
    appliedDashboardFilter: [{ value: "date", title: "DATE" }],

    branchFilter: false,
    expTypeFilter: false,
    ratingFilter: false,
    staffFilter: false,
    dateFilter: false,
    categoryFilter: false,
  },
  reducers: {
    getQuickOverviewRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getQuickOverviewSuccess: (state, action) => {
      state.loading = false;
      state.quickOverview = action.payload;
    },
    getQuickOverviewError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setDashboardFilter: (state, action) => {
      state.dashboardFilter = { ...action.payload };
      state.appliedDashboardFilter = getAppliedFiltersForDashboard(
        action.payload
      );
    },
    dashboardFilterToggle: (state, action) => {
      if (action.payload === "branch_id") {
        state.branchFilter = !state.branchFilter;
      } else if (action.payload === "experience_type_id") {
        state.expTypeFilter = !state.expTypeFilter;
      } else if (action.payload === "rating") {
        state.ratingFilter = !state.ratingFilter;
      } else if (action.payload === "staff_id") {
        state.staffFilter = !state.staffFilter;
      } else if (action.payload === "date") {
        state.dateFilter = !state.dateFilter;
      } else if (action.payload === "category_id") {
        state.categoryFilter = !state.categoryFilter;
      }
    },
    getBranchesForDashboardRequest: (state) => {
      state.branchesDashboardLoading = true;
      state.branchesDashboardError = null;
    },
    getBranchesForDashboardSuccess: (state, action) => {
      state.branchesDashboardLoading = false;
      state.branchesDashboard = action.payload;
    },
    getBranchesForDashboardError: (state, action) => {
      state.branchesDashboardLoading = false;
      state.branchesDashboardError = action.payload;
    },
    getCategoriesListingByBrandIdRequest: (state) => {
      state.categoriesFilterLoading = true;
      state.categoriesFilterError = null;
    },
    getCategoriesListingByBrandIdSuccess: (state, action) => {
      state.categoriesFilterLoading = false;
      state.categoriesFilter = action.payload;
    },
    getCategoriesListingByBrandIdError: (state, action) => {
      state.categoriesFilterLoading = false;
      state.categoriesFilterError = action.payload;
    },
    getCategoriesListingRequest: (state) => {
      state.categoriesLoading = true;
      state.categoriesError = null;
    },
    getCategoriesListingSuccess: (state, action) => {
      state.categoriesLoading = false;
      const previousCategories = current(state.categories);
      if (
        previousCategories &&
        previousCategories.length > 0 &&
        action.payload.exp_id
      ) {
        const data = previousCategories.map((o) => {
          const dataObj = { ...o };
          const newData = action.payload.data.find(
            (s) => s.experience_type._id === action.payload.exp_id
          );
          if (dataObj.experience_type._id === action.payload.exp_id) {
            dataObj.data = newData.data;
          }
          return dataObj;
        });
        state.categories = data;
      } else {
        state.categories = action.payload.data;
      }
    },
    getCategoriesListingError: (state, action) => {
      state.categoriesLoading = false;
      state.categoriesError = action.payload;
    },
    getProductListingRequest: (state) => {
      state.productListingLoading = true;
      state.productListingError = null;
    },
    getProductListingSuccess: (state, action) => {
      state.productListingLoading = false;
      state.productListing = action.payload.data;
    },
    getProductListingError: (state, action) => {
      state.productListingLoading = false;
      state.productListingError = action.payload;
    },
    getFeedbackRequest: (state) => {
      state.feedBackReportLoading = true;
      state.feedBackReportError = null;
    },
    getFeedbackSuccess: (state, action) => {
      state.feedBackReportLoading = false;
      const previousFeedback = current(state.feedBackReport);
      if (
        previousFeedback &&
        previousFeedback.length > 0 &&
        action.payload.exp_id
      ) {
        const data = previousFeedback.map((o) => {
          const dataObj = { ...o };
          const newData = action.payload.data.find(
            (s) => s.experience_type._id === action.payload.exp_id
          );
          if (dataObj.experience_type._id === action.payload.exp_id) {
            dataObj.data = newData.data;
          }
          return dataObj;
        });
        state.feedBackReport = data;
      } else {
        state.feedBackReport = action.payload.data;
      }
    },
    getFeedbackError: (state, action) => {
      state.feedBackReportLoading = false;
      state.feedBackReportError = action.payload;
    },
    getPerformanceChartRequest: (state, action) => {
      state.performanceChartLoading = true;
      state.performanceChartErro = null;
    },
    getPerformanceChartSuccess: (state, action) => {
      state.performanceChartLoading = false;
      state.performanceChart = action.payload;
      state.performanceChartErro = null;
    },
    getPerformanceChartError: (state, action) => {
      state.performanceChartLoading = false;
      state.performanceChartErro = action.payload;
    },
    getSuggestionsByIdRequest: (state, action) => {
      const experience_type_id = action?.payload;
      if (experience_type_id) {
        state.suggestionsLoading = {
          ...state.suggestionsLoading,
          [experience_type_id]: true,
        };
        state.suggestionsError = {
          ...state.suggestionsError,
          [experience_type_id]: null,
        };
      }
    },
    getSuggestionsByIdSuccess: (state, action) => {
      const experience_type_id = action?.payload?.experience_type?._id;
      if (experience_type_id) {
        state.suggestionsLoading = {
          ...state.suggestionsLoading,
          [experience_type_id]: false,
        };
        const oldSuggestion = state.suggestions[experience_type_id] || {
          pages: [],
        };

        const { data, metaData } = action.payload.data;

        // Rest if first page
        if (metaData.currentPage === 1) {
          oldSuggestion.pages = [];
        }

        // only add non-empty pages
        if (data.length) {
          oldSuggestion.pages[metaData.currentPage - 1] = data;
          oldSuggestion.metaData = metaData;
          state.suggestions = {
            ...state.suggestions,
            [experience_type_id]: oldSuggestion,
          };
        }
      }
    },
    getSuggestionsByIdError: (state, action) => {
      const { experience_type_id } = action?.payload;
      if (experience_type_id) {
        state.suggestionsLoading = {
          ...state.suggestionsLoading,
          [experience_type_id]: false,
        };
        state.suggestionsError = {
          ...state.suggestionsError,
          [experience_type_id]: action.payload,
        };
      }
    },
    getComplimentsByIdRequest: (state, action) => {
      const experience_type_id = action?.payload;
      if (experience_type_id) {
        state.complimentsLoading = {
          ...state.complimentsLoading,
          [experience_type_id]: true,
        };
        state.complimentsError = {
          ...state.complimentsError,
          [experience_type_id]: null,
        };
      }
    },
    getComplimentsByIdSuccess: (state, action) => {
      const experience_type_id = action?.payload?.experience_type?._id;
      if (experience_type_id) {
        state.complimentsLoading = {
          ...state.complimentsLoading,
          [experience_type_id]: false,
        };
        const oldCompliments = state.compliments[experience_type_id] || {
          pages: [],
        };
        const { data, metaData } = action.payload.data;

        // Rest if first page
        if (metaData.currentPage === 1) {
          oldCompliments.pages = [];
        }

        // only add non-empty pages
        if (data.length) {
          oldCompliments.pages[metaData.currentPage - 1] = data;
          oldCompliments.metaData = metaData;
          state.compliments = {
            ...state.compliments,
            [experience_type_id]: oldCompliments,
          };
        }
      }
    },
    getComplimentsByIdError: (state, action) => {
      const { experience_type_id } = action?.payload;
      if (experience_type_id) {
        state.complimentsLoading = {
          ...state.complimentsLoading,
          [experience_type_id]: false,
        };
        state.complimentsError = {
          ...state.complimentsError,
          [experience_type_id]: action.payload,
        };
      }
    },
    getStaffListingRequest: (state) => {
      state.staffListingLoading = true;
      state.staffListingError = null;
    },
    getStaffListingSuccess: (state, action) => {
      state.staffListingLoading = false;
      const previousStaff = current(state.staffListing);
      if (previousStaff && previousStaff.length > 0 && action.payload.exp_id) {
        const data = previousStaff.map((o) => {
          const dataObj = { ...o };
          const newData = action.payload.data.find(
            (s) => s.experience_type._id === action.payload.exp_id
          );
          if (dataObj.experience_type._id === action.payload.exp_id) {
            dataObj.data = newData.data;
          }
          return dataObj;
        });
        state.staffListing = data;
      } else {
        state.staffListing = action.payload.data;
      }
    },
    getStaffListingError: (state, action) => {
      state.staffListingLoading = false;
      state.staffListingError = action.payload;
    },
    getFeedbackDataForModalRequest: (state) => {
      state.feedbackModalLoading = true;
      state.feedbackModalError = null;
      state.feedbackModal = [];
    },
    getFeedbackDataForModalSuccess: (state, action) => {
      state.feedbackModalLoading = false;
      state.feedbackModal = action.payload;
    },
    getFeedbackDataForModalError: (state, action) => {
      state.feedbackModalLoading = false;
      state.feedbackModalError = action.payload;
    },
    getOverAllRatingRequest: (state, action) => {
      state.overAllRatingLoading = true;
      state.overAllRatingError = null;
    },
    getOverAllRatingSuccess: (state, action) => {
      state.overAllRatingLoading = false;
      state.overAllRating = action.payload;
      state.overAllRatingError = null;
    },
    getOverAllRatingError: (state, action) => {
      state.overAllRatingLoading = false;
      state.overAllRatingError = action.payload;
    },
    getAdditionalQuestionRequest: (state, action) => {
      state.additionalQuestionsLoading = true;
      state.additionalQuestionsError = "";
    },
    getAdditionalQuestionSuccess: (state, action) => {
      state.additionalQuestionsLoading = false;
      state.additionalQuestions = action.payload;
    },
    getAdditionalQuestionError: (state, action) => {
      state.additionalQuestionsLoading = false;
      state.additionalQuestionsError = action.payload;
    },
    exportFeedbackDataRequest: (state, action) => {
      state.exportPdfLoading = true;
      state.exportPdfError = "";
    },
    exportFeedbackDataSuccess: (state, action) => {
      state.exportPdfLoading = false;
      state.exportPdfMessage = action.payload;
    },
    exportFeedbackDataError: (state, action) => {
      state.exportPdfLoading = false;
      state.exportPdfError = action.payload;
    },
    exportFeedbackDataAsPdfRequest: (state, action) => {
      state.exportPdfLoading = true;
      state.exportPdfError = "";
    },
    exportFeedbackDataAsPdfSuccess: (state, action) => {
      state.exportPdfLoading = false;
      state.exportPdfMessage = action.payload;
    },
    exportFeedbackDataAsPdfError: (state, action) => {
      state.exportPdfLoading = false;
      state.exportPdfError = action.payload;
    },
    exportPDFMessageClear: (state) => {
      state.exportPdfError = "";
    },
  },
});

const getAppliedFiltersForDashboard = (filters) => {
  let appliedDashboardFilter = [];
  if (filters?.branch_id && filters?.branch_id.length > 0) {
    appliedDashboardFilter.push({ title: "Branch", value: "branch_id" });
  }
  if (filters?.experience_type_id && filters?.experience_type_id.length > 0) {
    appliedDashboardFilter.push({
      title: "EXPERIENCE_TYPE",
      value: "experience_type_id",
    });
  }
  if (filters?.staff_id && filters?.staff_id.length > 0) {
    appliedDashboardFilter.push({
      title: "STAFF",
      value: "staff_id",
    });
  }
  if (filters?.rating && filters?.rating.length > 0) {
    appliedDashboardFilter.push({ title: "Rating", value: "rating" });
  }
  if (filters?.category_id && filters?.category_id.length > 0) {
    appliedDashboardFilter.push({ title: "CATEGORY", value: "category_id" });
  }
  if (!filters?.date?.start || !filters?.date?.end) {
    appliedDashboardFilter = appliedDashboardFilter.filter(
      (item) => item.value !== "date"
    );
  } else {
    appliedDashboardFilter.push({ title: "DATE", value: "date" });
  }
  return appliedDashboardFilter;
};

export const {
  getQuickOverviewRequest,
  getQuickOverviewSuccess,
  getQuickOverviewError,
  setDashboardFilter,
  dashboardFilterToggle,
  getBranchesForDashboardRequest,
  getBranchesForDashboardSuccess,
  getBranchesForDashboardError,
  getCategoriesListingByBrandIdRequest,
  getCategoriesListingByBrandIdSuccess,
  getCategoriesListingByBrandIdError,
  getCategoriesListingRequest,
  getCategoriesListingSuccess,
  getCategoriesListingError,
  getPerformanceChartRequest,
  getPerformanceChartSuccess,
  getPerformanceChartError,
  getSuggestionsByIdRequest,
  getSuggestionsByIdSuccess,
  getSuggestionsByIdError,
  getComplimentsByIdError,
  getComplimentsByIdRequest,
  getComplimentsByIdSuccess,
  getStaffListingRequest,
  getStaffListingSuccess,
  getStaffListingError,
  getProductListingError,
  getProductListingRequest,
  getProductListingSuccess,
  getFeedbackDataForModalRequest,
  getFeedbackDataForModalSuccess,
  getFeedbackDataForModalError,
  getOverAllRatingRequest,
  getOverAllRatingSuccess,
  getOverAllRatingError,
  getAdditionalQuestionRequest,
  getAdditionalQuestionSuccess,
  getAdditionalQuestionError,
  exportFeedbackDataRequest,
  exportFeedbackDataSuccess,
  exportFeedbackDataError,
  exportFeedbackDataAsPdfRequest,
  exportFeedbackDataAsPdfSuccess,
  exportFeedbackDataAsPdfError,
  exportPDFMessageClear,
  getFeedbackRequest,
  getFeedbackError,
  getFeedbackSuccess
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
