import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBranchGraphByBranchID } from "../../middlewares/branches";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";
import {
  dateFormat,
  dateWithOnlyMonthFormat,
  monthAndYearFormat,
} from "../../helpers/dateFormat";
import { useTranslation } from "react-i18next";

const BrandChart = ({ show, hide, branch_id, branch_name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { graph } = useSelector((state) => state.branch);
  const { filter } = useSelector((state) => state.brand);
  // const language = localStorage.getItem("i18nextLng");
  const [maxNumber, setMaxNumber] = useState(10);

  useEffect(() => {
    const payload = {
      filter: {
        date:
          filter?.date[0] !== null && filter?.date[1] !== null
            ? {
                start: `${dateFormat(filter?.date[0])}T00:00:00.000Z`,
                end: `${dateFormat(filter?.date[1])}T23:59:59.999Z`,
              }
            : filter?.date[0] !== null && filter?.date[1] === null
            ? {
                start: `${dateFormat(filter?.date[0])}T00:00:00.000Z`,
                end: `${dateFormat(filter?.date[0])}T23:59:59.999Z`,
              }
            : [null, null],
        rating: filter?.trustree_rating,
      },
    };
    dispatch(getBranchGraphByBranchID(branch_id, payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch_id]);

  useEffect(() => {
    if (graph && graph.ratingData && graph.ratingData.length > 0) {
      const maxRatingObj = graph.ratingData.reduce((p, c) =>
        p.rating_count > c.rating_count ? p : c
      );
      setMaxNumber(
        maxRatingObj?.rating_count === 0 ? 10 : maxRatingObj?.rating_count
      );
    } else {
      setMaxNumber(10);
    }
  }, [graph]);

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    ChartDataLabels
  );

  const options = {
    layout: {
      padding: {
        responsive: true,
        top: 25,
      },
    },
    scales: {
      y: {
        grid: {
          color: "rgba(43, 188, 145, 0.15)",
          lineWidth: 1,
        },
        grace: 1,
        max: 5,
        //   position:
        //     language === "ar" ||
        //     language === "fa" ||
        //     language === "he" ||
        //     language === "ur"
        //       ? "right"
        //       : "left",
        position: "left",
      },
      y1: {
        grid: {
          tickBorderDashOffset: 100,
          display: false,
        },
        grace: 2,
        max: Math.ceil(maxNumber / 10) * 10 + 10,
        // position:
        //   language === "ar" ||
        //   language === "fa" ||
        //   language === "he" ||
        //   language === "ur"
        //     ? "left"
        //     : "right",
        position: "right",
      },
      x: {
        grid: {
          display: false,
          lineDashed: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          style: {
            top: 50,
          },
        },
      },
    },
  };

  const handleLabelData = (data) => {
    if (data && data.preset === "year") {
      const datalable =
        data && data.ratingData && data.ratingData.map((o) => o._id);
      return datalable;
    } else if (data && data.preset === "month") {
      const datalable =
        data &&
        data.ratingData &&
        data.ratingData.map((o) => monthAndYearFormat(o._id));
      return datalable;
    } else if (data && data.preset === "week") {
      const datalable =
        data && data.ratingData && data.ratingData.map((o) => o._id);
      return datalable;
    } else if (data && data.preset === "day") {
      const datalable =
        data &&
        data.ratingData &&
        data.ratingData.map((o) => dateWithOnlyMonthFormat(o._id));
      return datalable;
    } else if (data && data.preset === "hour") {
      const datalable =
        data && data.ratingData && data.ratingData.map((o) => o._id);
      return datalable;
    } else {
      return [];
    }
  };

  const data = {
    labels: handleLabelData(graph ? graph : []),
    datasets: [
      {
        yAxisID: "y1",
        type: "line",
        opposite: true,
        borderColor: "black",
        borderWidth: 0.5,
        lineTension: 0.5,
        label: "Total count",
        pointStyle: "circle",
        datalabels: {
          color:
            graph && graph?.ratingData
              ? graph.ratingData.map((o) => {
                  if (o.rating_count !== 0) return "#292D32";
                  else return "transparent";
                })
              : [],
          anchor: "end",
          align: "top",
          padding: { left: 12 },
          font: {
            weight: "bold",
          },
        },
        data:
          graph && graph.ratingData && graph.ratingData
            ? graph.ratingData.map((o) => {
                return o.rating_count;
              })
            : [],
      },
      {
        yAxisID: "y",
        type: "bar",
        barThickness: 24,
        datalabels: {
          color: "#292D32",
          anchor: "end",
          align: "top",
          font: {
            weight: "bold",
          },
        },
        backgroundColor:
          graph && graph.ratingData && graph.ratingData
            ? graph.ratingData.map((o) => {
                return o.rating_avg > 4.99
                  ? "#2BBC91"
                  : o.rating_avg > 3.99
                  ? "#BCE29E"
                  : o.rating_avg > 1.99
                  ? "#E5EBB2"
                  : "#F8C4B4";
              })
            : "#FF8787",
        borderRadius: 100,
        label: "Average ratings",
        data:
          graph && graph.ratingData && graph.ratingData
            ? graph.ratingData.map((o) => {
                return o.rating_avg !== 0
                  ? parseFloat(o.rating_avg).toFixed(2)
                  : null;
              })
            : [],
      },
    ],
  };

  return (
    <>
      <Modal size="xl" show={show} onHide={hide}>
        <Modal.Header className="justify-content-center">
          <Modal.Title className="h5 ">
            {branch_name !== null
              ? `${t("PERFORMANCE_OVER_TIME")} - ${branch_name}`
              : `${t("PERFORMANCE_OVER_TIME")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Chart
              type="bar"
              data={data}
              options={options}
              height={100}
              // dir={
              //   language === "ar" ||
              //   language === "fa" ||
              //   language === "he" ||
              //   language === "ur"
              //     ? "rtl"
              //     : "ltr"
              // }
            />
            <div className="Experience-btn-modal flex justify-content-center">
              <Button className="send-modal" onClick={hide}>
                {t("CLOSE")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BrandChart;
