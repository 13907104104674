import Reports from "../services/reports";
import {
    getReportsError,
    getReportsRequest,
    getReportsByIdRequest,
    getReportsByIdSuccess,
    getReportsByIdError,
    getReportsSuccess,
    addReportError,
    addReportRequest,
    addReportSuccess,
    editReportError,
    editReportRequest,
    editReportSuccess,
    deleteReportError,
    deleteReportRequest,
    deleteReportSuccess,
    disableReportRequest,
    disableReportSuccess,
    disableReportError,
    getUsersError,
    getUsersRequest,
    getUsersSuccess,
} from "../slices/reports.slice";

export function getReports(brand_id) {
    return (dispatch) => {
        dispatch(getReportsRequest());
        Reports.getReports(brand_id)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getReportsSuccess(data));
                } else {
                    dispatch(getReportsError(error));
                }
            })
            .catch((error) => {
                dispatch(getReportsError(error));
            });
    };
}

export function getReportsById(brand_id, report_id) {
    return (dispatch) => {
        dispatch(getReportsByIdRequest());
        Reports.getReportsById(brand_id, report_id)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getReportsByIdSuccess(data));
                } else {
                    dispatch(getReportsByIdError(error));
                }
            })
            .catch((error) => {
                dispatch(getReportsByIdError(error));
            });
    };
}

export function addReports(payload) {
    return (dispatch) => {
        dispatch(addReportRequest());
        Reports.addReports(payload)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(addReportSuccess(message));
                } else {
                    dispatch(addReportError(error));
                }
            })
            .catch((error) => {
                dispatch(addReportError(error));
            });
    };
}

export function updateReports(payload, report_id) {
    return (dispatch) => {
        dispatch(editReportRequest());
        Reports.updateReports(payload, report_id)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(editReportSuccess(message));
                } else {
                    dispatch(editReportError(error));
                }
            })
            .catch((error) => {
                dispatch(editReportError(error));
            });
    };
}

export function deleteReports(report_id) {
    return (dispatch) => {
        dispatch(deleteReportRequest());
        Reports.deleteReport(report_id) // Pass the report_id here
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(deleteReportSuccess(message));
                } else {
                    dispatch(deleteReportError(error));
                }
            })
            .catch((error) => {
                dispatch(deleteReportError(error));
            });
    };
}

export function disableReport(report_id) {
    return (dispatch) => {
        dispatch(disableReportRequest());
        Reports.disableReport(report_id)
            .then((response) => {
                const { message, status, error } = response.data;
                if (status === 1) {
                    dispatch(disableReportSuccess(message));
                } else {
                    dispatch(disableReportError(error));
                }
            })
            .catch((error) => {
                dispatch(disableReportError(error));
            });
    };
}

export function getUsers(payload) {
    return (dispatch) => {
        console.log(dispatch);
        dispatch(getUsersRequest());
        Reports.getUsers(payload)
            .then((response) => {
                const { data, status, error } = response.data;
                if (status === 1) {
                    dispatch(getUsersSuccess(data));
                } else {
                    dispatch(getUsersError(error));
                }
            })
            .catch((error) => {
                dispatch(getUsersError(error));
            });
    };
}