import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { reply } from "../../../helpers/yup.validation.schema";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

const GoogleFeedbackData = () => {
  const { userProfile } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const replyschema = Yup.object().shape({
    reply: reply,
  });

  const formik = useFormik({
    initialValues: {
      reply: "",
    },
    validationSchema: replyschema,
  });
  return (
    <>
      <div className="feedbackRight">
        <div className="feedbackRightHead">
          <ul>
            <li>
              <img alt="" />
              <h3>Mahmoud Mokhtar </h3>{" "}
              <span>
                {" "}
                Average Rating: <strong> 4</strong>{" "}
                <em>
                  <img src="images/starOne.svg" alt="" />{" "}
                </em>
              </span>
            </li>
            <li>
              <h4>
                Phone Number: <span>01020402612</span>{" "}
              </h4>{" "}
              <span>
                Ratings: <strong>2</strong>
              </span>
            </li>
            <li>
              <h4>
                <span>Email: </span> M.Mokhtar@cloudabill.com
              </h4>{" "}
              <span>
                Redeemed vouchers: <strong>0</strong>
              </span>
            </li>
          </ul>
        </div>
        <div className="feedbackRightBody">
          <div className="accordion" id="accordionExample">
            <div className="feedbackRightSection">
              <ul className="feedbackRightSectionUl p-0">
                <li className="feedbackRightSectionLi">
                  <div className="custFeedback">
                    <strong>Rating</strong>
                    <StarRatings
                      rating={4}
                      starDimension="14px"
                      starSpacing="2px"
                      starRatedColor="#FFD600"
                    />
                  </div>
                </li>
                <li className="feedbackRightSectionLi">
                  <div className="mb-3">
                    <strong>Review:</strong>
                  </div>
                  <h6>It was vry nice experience</h6>
                </li>
                <li className="mb-4">
                  <div className="d-flex my-3 justify-content-center">
                    <button className="btn btn-outline-primary filter-btn me-1">
                      {t("RESOLVED")}
                    </button>
                    <button className="btn btn-outline-primary filter-btn me-1">
                      {t("PRINT")}
                    </button>
                    <button className="btn btn-outline-primary filter-btn me-1">
                      {t("SHARE")}
                    </button>
                  </div>
                </li>
                <li className="mt-3">
                  {userProfile && userProfile.permission !== "viewer" && (
                    <div className="feedbackRightSection">
                      <textarea
                        className="form-control"
                        rows={8}
                        placeholder="Write the reply here....."
                        name="reply"
                        onChange={formik.handleChange}
                        value={formik.values.reply}
                        onBlur={formik.handleBlur}
                      />
                      <div className="sendBtn">
                        <Button disabled={!(formik.isValid && formik.dirty)}>
                          {t("SEND")}
                        </Button>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleFeedbackData;
