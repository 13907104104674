import React, { useEffect, useState } from "react";
import { getMonth, getYear } from "date-fns";
import { useFormik } from "formik";
import { Button, Form, FormCheck, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LeftArrow from "../../../assets/images/u_arrow-left.svg";
import RightArrow from "../../../assets/images/u_arrow-right.svg";
import { dateFormat } from "../../../helpers/dateFormat";
import {
  exportFeedbackData,
  exportFeedbackDataAsPdf,
} from "../../../middlewares/dashboard";
import ErrorList from "../../../components/error-list/ErrorList";
import { useSelector } from "react-redux";
import locale from "../../../helpers/date-importer";
import { exportPDFMessageClear } from "../../../slices/dashboard.slice";

const ExportFeedbackDataModal = ({
  show,
  setIsShow,
  branch,
  experience_type,
  brandId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMonth, setIsMonth] = useState(new Date().getMonth());
  const [isYear, setIsYear] = useState(new Date().getFullYear());
  const [isFirst, setIsFirst] = useState([]);
  const [isSecond, setIsSecond] = useState([]);
  const { exportPdfError, exportPdfMessage, exportPdfLoading } = useSelector(
    (state) => state.dashboard
  );
  const range = (a, b) => {
    let year = [];
    for (let i = a; i <= b; i++) {
      year.push(i);
    }
    return year;
  };

  const years = range(getYear(new Date()) - 100, getYear(new Date()) + 1, 1);
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  useEffect(() => {
    if (!exportPdfError && exportPdfMessage) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPdfError, exportPdfMessage]);

  useEffect(() => {
    let month = isMonth;
    let first = new Date(isYear, month, 1);
    let last = new Date(isYear, month + 1, 0);
    setIsFirst(first);
    setIsSecond(last);
  }, [isMonth, isYear]);

  const handleSubmit = (values) => {
    const payload = {
      filter: {
        branch_id: values.branch,
        experience_type_id: values.experience_type,
        date:
          values.date && values.date[0] !== null
            ? values.date[1] === null
              ? {
                  start: `${dateFormat(values.date[0])}T00:00:00.000Z`,
                  end: `${dateFormat(values.date[0])}T23:59:59.999Z`,
                }
              : {
                  start: `${dateFormat(values.date[0])}T00:00:00.000Z`,
                  end: `${dateFormat(values.date[1])}T23:59:59.999Z`,
                }
            : [null, null],
      },
      brand_id: brandId,
    };
    if (values.file_type === "excel_sheet") {
      dispatch(exportFeedbackData(payload));
    }
    if (values.file_type === "pdf") {
      dispatch(exportFeedbackDataAsPdf(payload));
    }
  };

  const handleChange = (title) => {
    if (title === "branch") {
      const allBranches =
        branch &&
        branch.length > 0 &&
        branch.map((o) => {
          return o._id;
        });
      formik.setFieldValue("branch", allBranches);
    } else {
      const allExperience =
        experience_type &&
        experience_type.length > 0 &&
        experience_type.map((o) => {
          return o._id;
        });
      formik.setFieldValue("experience_type", allExperience);
    }
  };
  const formik = useFormik({
    initialValues: {
      date: [null, null],
      branch: [],
      experience_type: [],
      file_type: "",
    },
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="cust-title">
          <div>
            <h6 style={{ color: "#101820", fontSize: "18px" }}>
              {t("EXPORT_FEEDBACK_DATA")}
            </h6>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Form>
        {exportPdfError && <ErrorList error={exportPdfError} />}
        <Modal.Body>
          <p>{t("CHOOSE_RANGE_OF_DATE")}</p>
          <div className="modalCalender tt-cal">
            <DatePicker
              selectsRange={true}
              startDate={formik.values?.date[0]}
              endDate={formik.values?.date[1]}
              onChange={(dateArr) => {
                formik.setFieldValue("date", dateArr);
              }}
              minDate={isFirst}
              maxDate={isSecond}
              isClearable={true}
              locale={locale[localStorage.getItem("i18nextLng")]}
              inline
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <>
                  <div
                    style={{
                      margintop: 10,
                      marginbottom: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => {
                          decreaseMonth();
                          setIsMonth(isMonth === 0 ? 11 : isMonth - 1);
                          setIsYear(isMonth === 0 ? isYear - 1 : isYear);
                        }}
                        disabled={prevMonthButtonDisabled}
                      >
                        <img src={LeftArrow} alt="monthDecrease" />
                      </span>
                    </div>

                    <select
                      className="form-select monthsSelect"
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) => {
                        changeMonth(months.indexOf(value));
                        setIsMonth(months.indexOf(value));
                      }}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {t(option)}
                        </option>
                      ))}
                    </select>

                    <select
                      className="form-select yearSelect "
                      value={getYear(date)}
                      onChange={({ target: { value } }) => {
                        changeYear(value);
                        setIsYear(value);
                      }}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <div
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => {
                          increaseMonth();
                          setIsMonth(isMonth === 11 ? 0 : isMonth + 1);
                          setIsYear(isMonth === 11 ? isYear + 1 : isYear);
                        }}
                        disabled={nextMonthButtonDisabled}
                      >
                        <img src={RightArrow} alt="monthIncrease" />
                      </span>
                    </div>
                  </div>
                </>
              )}
            />
          </div>
          {branch && branch.length > 0 && (
            <div className="SelectExport">
              <h3>{t("SELECT_BRANCHES")}</h3>
              <div className="radio-and-check align-items-center ">
                <div className="check-border mb-1">
                  <FormCheck.Input
                    name="select_all_branch"
                    className="check-border-color"
                    type="checkbox"
                    onChange={() => handleChange("branch")}
                    onBlur={formik.handleBlur}
                  />
                  <Form.Label className="mb-0 mx-2">
                    {t("SELECT_ALL")}
                  </Form.Label>
                </div>
                {branch.map((o, i) => (
                  <div
                    className="d-flex align-items-center check-border mb-1"
                    key={i}
                  >
                    <FormCheck.Input
                      name="branch"
                      type="checkbox"
                      className="check-border-color"
                      value={o._id ? o._id : null}
                      onChange={formik.handleChange}
                      checked={
                        formik.values.branch &&
                        formik.values.branch.includes(o._id)
                      }
                      onBlur={formik.handleBlur}
                    />
                    <FormCheck.Label>{o.name}</FormCheck.Label>
                  </div>
                ))}
              </div>
            </div>
          )}
          {experience_type && experience_type.length > 0 && (
            <div className="SelectExport">
              <h3>{t("SELECT_EXPERIENCE_TYPE")}</h3>
              <div className="radio-and-check align-items-center">
                <div className="check-border mb-1">
                  <FormCheck.Input
                    name="select_all_experience"
                    type="checkbox"
                    className="check-border-color"
                    value={formik.values.experience_type}
                    onChange={() => handleChange("experience")}
                  />
                  <Form.Label className="mb-0 mx-2">
                    {t("SELECT_ALL")}
                  </Form.Label>
                </div>
                {experience_type.map((o, index) => (
                  <div
                    className="d-flex align-items-center check-border mb-1"
                    key={index}
                  >
                    <FormCheck.Input
                      name="experience_type"
                      type="checkbox"
                      className="check-border-color"
                      value={o._id ? o._id : null}
                      onChange={formik.handleChange}
                      checked={
                        formik.values.experience_type &&
                        formik.values.experience_type.includes(o._id)
                      }
                      onBlur={formik.handleBlur}
                    />
                    <FormCheck.Label>{o.title}</FormCheck.Label>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="SelectExport">
            <h3>{t("FILE_TYPE")}</h3>
            <div>
              <Form.Check
                className="check-border d-flex FileRadioWpr"
                name="file_type"
                type="radio"
                value="excel_sheet"
                onChange={formik.handleChange}
                checked={formik.values.file_type === "excel_sheet"}
                label={t("EXCEL_SHEET")}
              />
              <Form.Check
                className="check-border d-flex FileRadioWpr"
                name="file_type"
                type="radio"
                value="pdf"
                onChange={formik.handleChange}
                checked={formik.values.file_type === "pdf"}
                label={t("PDF")}
              />
            </div>
          </div>
          <div className="flex justify-content-center gap-3">
            <Button
              type="btn"
              onClick={formik.handleSubmit}
              className="btn btn-primary me-1"
              disabled={
                (formik.values.file_type === "" ? true : false) ||
                (exportPdfLoading ? true : false)
              }
            >
              {exportPdfLoading ? t("LOADING") : t("EXPORT")}
            </Button>
            <Button
              className="btn btn-outline-primary filter-btn me-1 btn-outline-secondary"
              type="button"
              onClick={() => {
                setIsShow(!show);
                dispatch(exportPDFMessageClear());
              }}
            >
              {t("CANCEL")}
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default ExportFeedbackDataModal;
