import styles from "./brandsRow.module.css";
import ImageLoader from "../../components/imageLoader/ImageLoader";
import TrashIcon from "../../components/icons/TrashIcon";
import EditIcon from "../../components/icons/EditIcon";

/**
 * @typedef {{name: string, category: string, logo?: File[]}} Brand
 * @param {{brand: Brand, existing: boolean, onRemove: (brand: Brand) => void, onUpdate: (brand: Brand)=> void}} props
 * @returns
 */
function BrandRow({ brand, existing, onRemove, onUpdate }) {
  return (
    <div className={styles.brandRow}>
      <div className={styles.brandInfo}>
        <span className={styles.brandLogo}>
          {existing ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${brand.logo}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = require("../../assets/images/Brandlogo.png");
              }}
              alt="logo"
            />
          ) : (
            <ImageLoader
              img={brand.logo && brand.logo[0]}
              fallback="https://api.app.trustree.com/images/Brandlogo.png"
              alt="Brand Logo"
            />
          )}
        </span>
        <span>{brand.name}</span>
      </div>
      <div className={styles.brandAction}>
        <button
          type="button"
          className={styles.iconBtn}
          onClick={() => onUpdate(brand)}
        >
          <EditIcon />
        </button>
        {!existing && (
          <button
            type="button"
            className={styles.iconBtn}
            onClick={() => onRemove(brand)}
          >
            <TrashIcon />
          </button>
        )}
      </div>
    </div>
  );
}

export default BrandRow;
