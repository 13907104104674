import { useState } from "react";
import trusTree from "../../assets/images/new-logo.svg";
import errorImg from "../../assets/images/accessError.svg";
import styles from "./noAccess.module.css";
import Logout from "../../pages/logout/Logout";
import { useTranslation } from "react-i18next";
function NoAccess() {
  const [logoutModal, setLogoutModal] = useState(false);
  const { t } = useTranslation();
  return (
    <main className={styles.main}>
      <a
        href="#"
        className={styles.logoutNav}
        onClick={() => setLogoutModal(true)}
      >
        <em></em>
        <strong>{t("Log out")}</strong>
      </a>
      <Logout show={logoutModal} setIsShow={() => setLogoutModal(false)} />
      <header className={styles.header}>
        <img src={trusTree} alt="Trustree Logo" />
      </header>
      <section className={styles.container}>
        <img src={errorImg} alt="Error image" />
        <h1>{t("CANT_ACCESS_TRUSTREE")}</h1>
        <p>{t("SUBSCRIPTION_EXPIRED_NO_PAYMENT")}</p>
        <p>{t("CONTACT_ACCOUNT_OWNER_TO_RENEW_SUBSCRIPTION")}</p>
      </section>
    </main>
  );
}

export default NoAccess;
