import styles from "./emailRow.module.css";
import { Form } from "react-bootstrap";
import TrashIcon from "../icons/TrashIcon";

/**
 *
 * @typedef {{
 * name: string
 * email: string
 * }} Email
 * @param {{
 * index: number
 * name: string
 * value: Email
 * error?: {name: string email: string}
 * touched?: {name: boolean email: boolean}
 * onRemove: () => void
 * handleChange: (e) => void
 * handleBlur: (e) => void
 * }} props
 * @returns
 */
function EmailRow({
  index,
  name,
  error,
  touched,
  handleBlur,
  handleChange,
  value,
  onRemove,
}) {
  return (
    <div className={styles.tableRow}>
      <span>{index + 1}</span>
      <span>
        <Form.Control
          type="text"
          name={`${name}.name`}
          className={`${styles.input} ${
            touched?.name && error?.name && "is-invalid"
          }`}
          value={value.name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched?.name && error?.name && (
          <div className="invalid-feedback d-block">{error?.name}</div>
        )}
      </span>
      <span>
        <Form.Control
          type="text"
          name={`${name}.email`}
          className={`${styles.input} ${
            touched?.email && error?.email && "is-invalid"
          }`}
          email
          value={value.email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched?.email && error?.email && (
          <div className="invalid-feedback d-block">{error?.email}</div>
        )}
      </span>
      <button
        type="button"
        className={styles.iconBtn}
        onClick={() => onRemove()}
      >
        <TrashIcon />
      </button>
    </div>
  );
}

export default EmailRow;
