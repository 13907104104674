import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../src/translations/en/translation.json";
import translationAR from "../src/translations/ar/translation.json";
//import translationAF from "../src/translations/af/translation.json";
//import translationAM from "../src/translations/am/translation.json";
//import translationAZ from "../src/translations/az/translation.json";
//import translationBE from "../src/translations/be/translation.json";
//import translationBG from "../src/translations/bg/translation.json";
//import translationBN from "../src/translations/bn/translation.json";
//import translationBS from "../src/translations/bs/translation.json";
//import translationCA from "../src/translations/ca/translation.json";
//import translationCS from "../src/translations/cs/translation.json";
//import translationCEB from "../src/translations/ceb/translation.json";
//import translationCO from "../src/translations/co/translation.json";
//import translationCY from "../src/translations/cy/translation.json";
//import translationDA from "../src/translations/da/translation.json";
//import translationDE from "../src/translations/de/translation.json";
//import translationEL from "../src/translations/el/translation.json";
//import translationEO from "../src/translations/eo/translation.json";
//import translationES from "../src/translations/es/translation.json";
//import translationET from "../src/translations/et/translation.json";
//import translationEU from "../src/translations/eu/translation.json";
//import translationFA from "../src/translations/fa/translation.json";
//import translationFI from "../src/translations/fi/translation.json";
//import translationFIL from "../src/translations/fil/translation.json";
//import translationFR from "../src/translations/fr/translation.json";
//import translationFY from "../src/translations/fy/translation.json";
//import translationGA from "../src/translations/ga/translation.json";
//import translationGD from "../src/translations/gd/translation.json";
//import translationGL from "../src/translations/gl/translation.json";
//import translationGU from "../src/translations/gu/translation.json";
//import translationHA from "../src/translations/ha/translation.json";
//import translationHAW from "../src/translations/haw/translation.json";
//import translationHE from "../src/translations/he/translation.json";
//import translationHI from "../src/translations/hi/translation.json";
//import translationHMN from "../src/translations/hmn/translation.json";
//import translationHR from "../src/translations/hr/translation.json";
//import translationHT from "../src/translations/ht/translation.json";
//import translationHU from "../src/translations/hu/translation.json";
//import translationHY from "../src/translations/hy/translation.json";
//import translationID from "../src/translations/id/translation.json";
//import translationIG from "../src/translations/ig/translation.json";
//import translationIS from "../src/translations/is/translation.json";
//import translationIT from "../src/translations/it/translation.json";
//import translationJA from "../src/translations/ja/translation.json";
//import translationKA from "../src/translations/ka/translation.json";
//import translationKK from "../src/translations/kk/translation.json";
//import translationKM from "../src/translations/km/translation.json";
//import translationKN from "../src/translations/kn/translation.json";
//import translationKO from "../src/translations/ko/translation.json";
//import translationKU from "../src/translations/ku/translation.json";
//import translationKY from "../src/translations/ky/translation.json";
//import translationLA from "../src/translations/la/translation.json";
//import translationLB from "../src/translations/lb/translation.json";
//import translationLO from "../src/translations/lo/translation.json";
//import translationLT from "../src/translations/lt/translation.json";
//import translationLV from "../src/translations/lv/translation.json";
//import translationMG from "../src/translations/mg/translation.json";
//import translationMI from "../src/translations/mi/translation.json";
//import translationMK from "../src/translations/mk/translation.json";
//import translationML from "../src/translations/ml/translation.json";
//import translationMN from "../src/translations/mn/translation.json";
//import translationMR from "../src/translations/mr/translation.json";
//import translationMS from "../src/translations/ms/translation.json";
//import translationMT from "../src/translations/mt/translation.json";
//import translationMY from "../src/translations/my/translation.json";
//import translationNE from "../src/translations/ne/translation.json";
//import translationNL from "../src/translations/nl/translation.json";
//import translationNO from "../src/translations/no/translation.json";
//import translationNY from "../src/translations/ny/translation.json";
//import translationOR from "../src/translations/or/translation.json";
//import translationPA from "../src/translations/pa/translation.json";
//import translationPL from "../src/translations/pl/translation.json";
//import translationPS from "../src/translations/ps/translation.json";
//import translationPT from "../src/translations/pt/translation.json";
//import translationRO from "../src/translations/ro/translation.json";
//import translationRU from "../src/translations/ru/translation.json";
//import translationRW from "../src/translations/rw/translation.json";
//import translationSD from "../src/translations/sd/translation.json";
//import translationSI from "../src/translations/si/translation.json";
//import translationSK from "../src/translations/sk/translation.json";
//import translationSL from "../src/translations/sl/translation.json";
//import translationSM from "../src/translations/sm/translation.json";
//import translationSN from "../src/translations/sn/translation.json";
//import translationSO from "../src/translations/so/translation.json";
//import translationSQ from "../src/translations/sq/translation.json";
//import translationSR from "../src/translations/sr/translation.json";
//import translationST from "../src/translations/st/translation.json";
//import translationSU from "../src/translations/su/translation.json";
//import translationSV from "../src/translations/sv/translation.json";
//import translationSW from "../src/translations/sw/translation.json";
//import translationTA from "../src/translations/ta/translation.json";
//import translationTE from "../src/translations/te/translation.json";
//import translationTG from "../src/translations/tg/translation.json";
//import translationTH from "../src/translations/th/translation.json";
//import translationTK from "../src/translations/tk/translation.json";
//import translationTL from "../src/translations/tl/translation.json";
//import translationTR from "../src/translations/tr/translation.json";
//import translationTT from "../src/translations/tt/translation.json";
//import translationUG from "../src/translations/ug/translation.json";
//import translationUK from "../src/translations/uk/translation.json";
//import translationUR from "../src/translations/ur/translation.json";
//import translationUZ from "../src/translations/uz/translation.json";
//import translationVI from "../src/translations/vi/translation.json";
//import translationXH from "../src/translations/xh/translation.json";
//import translationYI from "../src/translations/yi/translation.json";
//import translationYO from "../src/translations/yo/translation.json";
//import translationZU from "../src/translations/zu/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { supportedLanguages } from "./helpers/supportedLanguages";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  /*af: {
    translation: translationAF,
  },
  am: {
    translation: translationAM,
  },
  az: {
    translation: translationAZ,
  },
  be: {
    translation: translationBE,
  },
  bg: {
    translation: translationBG,
  },
  bn: {
    translation: translationBN,
  },
  bs: {
    translation: translationBS,
  },
  ca: {
    translation: translationCA,
  },
  ceb: {
    translation: translationCEB,
  },
  co: {
    translation: translationCO,
  },
  cs: {
    translation: translationCS,
  },
  cy: {
    translation: translationCY,
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDE,
  },
  el: {
    translation: translationEL,
  },
  eo: {
    translation: translationEO,
  },
  es: {
    translation: translationES,
  },
  et: {
    translation: translationET,
  },
  eu: {
    translation: translationEU,
  },
  fa: {
    translation: translationFA,
  },
  fr: {
    translation: translationFR,
  },
  fi: {
    translation: translationFI,
  },
  fil: {
    translation: translationFIL,
  },
  fy: {
    translation: translationFY,
  },
  ga: {
    translation: translationGA,
  },
  gd: {
    translation: translationGD,
  },
  gl: {
    translation: translationGL,
  },
  gu: {
    translation: translationGU,
  },
  ha: {
    translation: translationHA,
  },
  haw: {
    translation: translationHAW,
  },
  he: {
    translation: translationHE,
  },
  hi: {
    translation: translationHI,
  },
  hmn: {
    translation: translationHMN,
  },
  hr: {
    translation: translationHR,
  },
  ht: {
    translation: translationHT,
  },
  hu: {
    translation: translationHU,
  },
  hy: {
    translation: translationHY,
  },
  id: {
    translation: translationID,
  },
  ig: {
    translation: translationIG,
  },
  is: {
    translation: translationIS,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  ka: {
    translation: translationKA,
  },
  kk: {
    translation: translationKK,
  },
  km: {
    translation: translationKM,
  },
  kn: {
    translation: translationKN,
  },
  ko: {
    translation: translationKO,
  },
  ku: {
    translation: translationKU,
  },
  ky: {
    translation: translationKY,
  },
  la: {
    translation: translationLA,
  },
  lb: {
    translation: translationLB,
  },
  lo: {
    translation: translationLO,
  },
  lt: {
    translation: translationLT,
  },
  lv: {
    translation: translationLV,
  },
  mg: {
    translation: translationMG,
  },
  mi: {
    translation: translationMI,
  },
  mk: {
    translation: translationMK,
  },
  ml: {
    translation: translationML,
  },
  mn: {
    translation: translationMN,
  },
  mr: {
    translation: translationMR,
  },
  ms: {
    translation: translationMS,
  },
  mt: {
    translation: translationMT,
  },
  my: {
    translation: translationMY,
  },
  ne: {
    translation: translationNE,
  },
  nl: {
    translation: translationNL,
  },
  no: {
    translation: translationNO,
  },
  ny: {
    translation: translationNY,
  },
  or: {
    translation: translationOR,
  },
  pa: {
    translation: translationPA,
  },
  pl: {
    translation: translationPL,
  },
  ps: {
    translation: translationPS,
  },
  pt: {
    translation: translationPT,
  },
  ro: {
    translation: translationRO,
  },
  rw: {
    translation: translationRW,
  },
  ru: {
    translation: translationRU,
  },
  sd: {
    translation: translationSD,
  },
  si: {
    translation: translationSI,
  },
  sk: {
    translation: translationSK,
  },
  sl: {
    translation: translationSL,
  },
  sm: {
    translation: translationSM,
  },
  sn: {
    translation: translationSN,
  },
  so: {
    translation: translationSO,
  },
  sq: {
    translation: translationSQ,
  },
  sr: {
    translation: translationSR,
  },
  st: {
    translation: translationST,
  },
  su: {
    translation: translationSU,
  },
  sv: {
    translation: translationSV,
  },
  sw: {
    translation: translationSW,
  },
  ta: {
    translation: translationTA,
  },
  te: {
    translation: translationTE,
  },
  tg: {
    translation: translationTG,
  },
  th: {
    translation: translationTH,
  },
  tk: {
    translation: translationTK,
  },
  tl: {
    translation: translationTL,
  },
  tr: {
    translation: translationTR,
  },
  tt: {
    translation: translationTT,
  },
  ug: {
    translation: translationUG,
  },
  uk: {
    translation: translationUK,
  },
  ur: {
    translation: translationUR,
  },
  uz: {
    translation: translationUZ,
  },
  vi: {
    translation: translationVI,
  },
  xh: {
    translation: translationXH,
  },
  yi: {
    translation: translationYI,
  },
  yo: {
    translation: translationYO,
  },
  zu: {
    translation: translationZU,
  },*/
};

const i8 = () => {
  const supportLang = [];
  supportedLanguages &&
    supportedLanguages.map((o, i) => supportLang.push(o.key));
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: "en",
      load: "languageOnly",
      supportedLngs: supportLang ? supportLang : ["en", "ar"],
      // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export default i8;
