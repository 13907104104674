import { createSlice } from "@reduxjs/toolkit";
import { GOOGLE_FEEDBACKS } from "./slice-names";

export const googleFeedbacksSlice = createSlice({
  name: GOOGLE_FEEDBACKS,
  initialState: {
    googleFeedback: [],
    googleFeedbackLoading: true,
    googleFeedbackErro: null,

    resolvedGoogleFeedback: [],
    unResolvedGoogleFeedback: [],

    googleFeedbackFilter: {
      branch_id: [],
      rating: [],
      date: [null, null],
    },
    appliedGoogleFeddbackFilter: [],

    branchFilter: false,
    ratingFilter: false,
    dateFilter: false,
  },
  reducers: {
    getGoogleFeedbackRequest: (state) => {
      state.googleFeedback = [];
      state.googleFeedbackLoading = true;
      state.googleFeedbackErro = null;
    },
    getGoogleFeedbackSuccess: (state, action) => {
      state.googleFeedbackLoading = false;
      state.googleFeedbackErro = null;
    },
    getGoogleFeedbackError: (state, action) => {
      state.googleFeedback = [];
      state.googleFeedbackLoading = false;
      state.googleFeedbackErro = action.payload;
    },

    getResolvedGoogleFeedbackRequest: (state) => {
      state.resolvedGoogleFeedback = [];
      state.googleFeedbackLoading = true;
      state.googleFeedbackErro = null;
    },
    getResolvedGoogleFeedbackSuccess: (state, action) => {
      state.googleFeedbackLoading = false;
      state.googleFeedbackErro = null;
    },
    getResolvedGoogleFeedbackError: (state, action) => {
      state.resolvedGoogleFeedback = [];
      state.googleFeedbackLoading = false;
      state.googleFeedbackErro = action.payload;
    },

    getUnResolvedGoogleFeedbackRequest: (state) => {
      state.unResolvedGoogleFeedback = [];
      state.googleFeedbackLoading = true;
      state.googleFeedbackErro = null;
    },
    getUnResolvedGoogleFeedbackSuccess: (state, action) => {
      state.googleFeedbackLoading = false;
      state.googleFeedbackErro = null;
    },
    getUnResolvedGoogleFeedbackError: (state, action) => {
      state.unResolvedGoogleFeedback = [];
      state.googleFeedbackLoading = false;
      state.googleFeedbackErro = action.payload;
    },

    isOpenModalById: (state, action) => {},

    isOpenResolvedModalById: (state, action) => {},
    isOpenUnResolvedModalById: (state, action) => {},

    setGoogleFeedbackFilter: (state, action) => {
      state.googleFeedbackFilter = { ...action.payload };
      state.appliedGoogleFeddbackFilter = getAppliedFiltersForGoogleFeedback(
        action.payload
      );
    },
    GooglefilterToggle: (state, action) => {
      if (action.payload === "branch_id") {
        state.branchFilter = !state.branchFilter;
      } else if (action.payload === "rating") {
        state.ratingFilter = !state.ratingFilter;
      } else if (action.payload === "date") {
        state.dateFilter = !state.dateFilter;
      }
    },
  },
});

const getAppliedFiltersForGoogleFeedback = (filters) => {
  let appliedTrustreeFeddbackFilter = [];
  if (filters?.filter?.branch_id && filters?.filter?.branch_id.length > 0) {
    appliedTrustreeFeddbackFilter.push({ title: "Branch", value: "branch_id" });
  }
  if (filters?.filter?.rating && filters?.filter?.rating.length > 0) {
    appliedTrustreeFeddbackFilter.push({ title: "Rating", value: "rating" });
  }
  if (
    filters?.filter?.date &&
    filters?.filter?.date?.length > 0 &&
    ((filters?.filter?.date[0] && filters?.filter?.date[0] === null) ||
      (filters?.filter?.date[1] && filters?.filter?.date[1] === null))
  ) {
    appliedTrustreeFeddbackFilter.remove({ title: "Date", value: "date" });
  } else if (filters?.filter?.date && filters?.filter?.date?.start) {
    appliedTrustreeFeddbackFilter.push({ title: "Date", value: "date" });
  }
  return appliedTrustreeFeddbackFilter;
};

export const {
  getGoogleFeedbackRequest,
  getGoogleFeedbackSuccess,
  getGoogleFeedbackError,
  getResolvedGoogleFeedbackRequest,
  getResolvedGoogleFeedbackSuccess,
  getResolvedGoogleFeedbackError,
  getUnResolvedGoogleFeedbackRequest,
  getUnResolvedGoogleFeedbackSuccess,
  getUnResolvedGoogleFeedbackError,
  isOpenModalById,
  isOpenResolvedModalById,
  isOpenUnResolvedModalById,
  setGoogleFeedbackFilter,
  GooglefilterToggle,
} = googleFeedbacksSlice.actions;
export default googleFeedbacksSlice.reducer;
