import { useEffect, useState } from "react";

/**
 *
 * @returns {"online"|"offline"| undefined}
 */
const useNetworkStatus = () => {
  const [status, setStatus] = useState();
  useEffect(() => {
    function onlineListener() {
      setStatus("online");
    }
    function offlineListener() {
      setStatus("offline");
    }
    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", offlineListener);

    return () => {
      window.removeEventListener("online", onlineListener);
      window.removeEventListener("offline", offlineListener);
    };
  }, []);

  return status;
};

export default useNetworkStatus;
