import { createSlice } from "@reduxjs/toolkit";
import { TEAMREWARDS } from "./slice-names";

const initialState = {
  loading: true,
  teamRewards: [],
  message: "",
  error: "",
  languages: null,
  offSet: 0,
  reportLodaing: true,

  updateLoading: false,
  singleTeamRewards: null,
  updateError: "",

  saveTeamRewardLoading: false,
  saveTeamRewardMessage: null,
  saveTeamRewardError: null,

  saveRewardLoading: false,
  saveRewardMessage: null,
  saveError: null,

  disableLoading: false,
  disableMessage: null,
  disableError: null,

  deleteRewardLoading: false,
  deleteRewardMessage: null,
  deleteError: null,

  saveCategoryLoading: false,
  category: [],
  saveCategoryError: null,

  saveUsersLoading: false,
  users: [],
  saveUsersError: null,

  saveMembersLoading: false,
  members: [],
  saveMembersError: null,

  rewardAchievedLoading: false,
  rewardAchieved: null,
  rewardAchievedError: null,
};

export const teamRewardsSlice = createSlice({
  name: TEAMREWARDS,
  initialState,
  reducers: {
    getTeamRewardsRequest: (state) => {
      state.loading = true;
      state.reportLodaing = true;
      state.error = "";
      state.saveRewardMessage = null;
      state.saveTeamRewardMessage = null;
      state.disableMessage = null;
      state.deleteRewardMessage = null;
      state.singleTeamRewards = null;
      state.rewardAchieved = null;
      state.saveTeamRewardError = null;
      state.saveError = null;
    },
    getTeamRewardsSuccess: (state, action) => {
      state.loading = false;
      state.reportLodaing = false;
      state.teamRewards = action.payload;
    },
    getTeamRewardsError: (state, action) => {
      state.loading = false;
      state.reportLodaing = false;
      state.error = action.payload;
    },
    getTeamRewardsByIdRequest: (state) => {
      state.updateLoading = true;
      state.updateError = "";
      state.saveBranchMessage = "";
      state.saveTeamRewardMessage = null;
    },
    getTeamRewardsByIdSuccess: (state, action) => {
      state.updateLoading = false;
      state.singleTeamRewards = action.payload;
    },
    getTeamRewardsByIdError: (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    },
    addTeamRewardRequest: (state) => {
      state.saveRewardLoading = true;
      state.saveError = null;
    },
    addTeamRewardSuccess: (state, action) => {
      state.saveRewardLoading = false;
      state.saveRewardMessage = action.payload;
    },
    addTeamRewardError: (state, action) => {
      state.saveRewardLoading = false;
      state.saveError = action.payload;
      state.saveRewardMessage = null;
    },
    editTeamRewardRequest: (state) => {
      state.saveTeamRewardLoading = true;
      state.saveTeamRewardMessage = null;
      state.saveTeamRewardError = null;
    },
    editTeamRewardSuccess: (state, action) => {
      state.saveTeamRewardLoading = false;
      state.saveTeamRewardMessage = action.payload;
    },
    editTeamRewardError: (state, action) => {
      state.saveTeamRewardLoading = false;
      state.saveTeamRewardError = action.payload;
    },
    deleteTeamRewardRequest: (state) => {
      state.deleteRewardLoading = true;
      state.deleteRewardMessage = null;
      state.deleteError = null;
    },
    deleteTeamRewardSuccess: (state, action) => {
      state.deleteRewardLoading = false;
      state.deleteRewardMessage = action.payload;
    },
    deleteTeamRewardError: (state, action) => {
      state.deleteRewardLoading = false;
      state.deleteError = action.payload;
    },
    disableTeamRewardRequest: (state) => {
      state.disableLoading = true;
      state.disableMessage = null;
      state.disableError = null;
    },
    disableTeamRewardSuccess: (state, action) => {
      state.disableLoading = false;
      state.disableMessage = action.payload;
    },
    disableTeamRewardError: (state, action) => {
      state.disableLoading = false;
      state.disableError = action.payload;
    },
    rewardAchievedRequest: (state) => {
      state.rewardAchievedLoading = true;
      state.rewardAchieved = null;
      state.rewardAchievedError = null;
    },
    rewardAchievedSuccess: (state, action) => {
      state.rewardAchievedLoading = false;
      state.rewardAchieved = action.payload;
    },
    rewardAchievedError: (state, action) => {
      state.rewardAchievedLoading = false;
      state.rewardAchievedError = action.payload;
    },
    getQuestionsRequest: (state) => {
      state.saveCategoryLoading = true;
      state.saveCategoryError = "";
      state.saveRewardMessage = null;
      state.disableMessage = null;
      state.deleteRewardMessage = null;
    },
    getQuestionsSuccess: (state, action) => {
      state.saveCategoryLoading = false;
      const data =
        action.payload &&
        action.payload.getSurvey &&
        action.payload.getSurvey.map((o) => {
          const questions = [...o.question];
          const questionObj =
            questions &&
            questions.map((obj, i) => {
              if (i === 0) {
                return { isOpen: true, ...obj };
              } else {
                return { isOpen: false, ...obj };
              }
            });
          return { ...o, question: questionObj };
        });
      state.category = data;
      state.languages = action.payload.lang_det;
    },
    getQuestionsError: (state, action) => {
      state.saveCategoryLoading = false;
      state.saveCategoryError = action.payload;
    },
    getStaffMembersRequest: (state) => {
      state.saveMembersLoading = true;
      state.saveMembersError = null;
      state.saveRewardMessage = null;
      state.disableMessage = null;
      state.deleteRewardMessage = null;
      state.saveError = "";
      state.saveTeamRewardError = "";
    },
    getStaffMembersSuccess: (state, action) => {
      state.saveMembersLoading = false;
      state.members = action.payload;
    },
    getStaffMembersError: (state, action) => {
      state.saveMembersLoading = false;
      state.saveMembersError = action.payload;
    },
    getUsersRequest: (state) => {
      state.saveUsersLoading = true;
      state.saveUsersError = "";
    },
    getUsersSuccess: (state, action) => {
      state.saveUsersLoading = false;
      state.users = action.payload;
    },
    getUsersError: (state, action) => {
      state.saveUsersLoading = false;
      state.saveUsersError = action.payload;
    },
    setOffSet: (state, action) => {
      state.offSet = action.payload;
    },
    resetForm: (state) => {
      state.singleTeamRewards = null;
    },
  },
});

export const {
  getTeamRewardsRequest,
  getTeamRewardsSuccess,
  getTeamRewardsError,
  getTeamRewardsByIdRequest,
  getTeamRewardsByIdSuccess,
  getTeamRewardsByIdError,
  addTeamRewardRequest,
  addTeamRewardSuccess,
  addTeamRewardError,
  editTeamRewardRequest,
  editTeamRewardSuccess,
  editTeamRewardError,
  deleteTeamRewardRequest,
  deleteTeamRewardSuccess,
  deleteTeamRewardError,
  disableTeamRewardRequest,
  disableTeamRewardError,
  disableTeamRewardSuccess,
  rewardAchievedRequest,
  rewardAchievedSuccess,
  rewardAchievedError,
  getQuestionsRequest,
  getQuestionsSuccess,
  getQuestionsError,
  getStaffMembersRequest,
  getStaffMembersError,
  getStaffMembersSuccess,
  getUsersError,
  getUsersRequest,
  getUsersSuccess,
  resetForm,
  setOffSet,
} = teamRewardsSlice.actions;

export default teamRewardsSlice.reducer;
