import { get, post, del } from ".";

const URI = "/brands/reports";

const getReports = (brand_id) => {
    const URL = `${URI}/${brand_id}`;
    return get(URL);
};

const getReportsById = (brand_id, report_id) => {
    const URL = `${URI}/${brand_id}/${report_id}`;
    return get(URL);
};

const getUsers = (payload) => {
    const URL = `${URI}/users`;
    return post(URL, payload);
};

const addReports = (payload) => {
    const URL = `${URI}`;
    return post(URL, payload);
};

const updateReports = (payload, report_id) => {
    const URL = `${URI}/${report_id}`;
    return post(URL, payload);
};

const deleteReport = (report_id, payload) => {
    const URL = `${URI}/${report_id}`;
    return del(URL, payload);
};

const disableReport = (report_id) => {
    const URL = `${URI}/update/${report_id}`;
    return post(URL);
};

const Reports = {
    getReports,
    addReports,
    updateReports,
    deleteReport,
    getReportsById,
    disableReport,
    getUsers
};
export default Reports;
