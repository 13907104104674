import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { profile_pic } from "../../helpers/yup.validation.schema";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const ProfileImg = (props) => {
  const { t } = useTranslation();
  const { setFieldValue } = props;
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    onDrop: (acceptedFiles) => {
      const filesArr = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(filesArr);
      formik.setFieldValue("profile_pic", filesArr);
    },
    maxFiles: 1,
  });

  const thumbs = files.map((file) => (
    <div className="profile m-auto" key={file.name}>
      <img
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
        alt="Profile Pic"
      />
    </div>
  ));

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values) => {
    setFieldValue("profile_pic", values.profile_pic[0]);
    setFiles([]);
    props.setIsShow(!props.show);
  };

  const fileSchema = Yup.object().shape({
    profile_pic: profile_pic,
  });

  const formik = useFormik({
    initialValues: {
      profile_pic: null,
    },
    validationSchema: fileSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} size="">
      <Modal.Header>
        <Modal.Title>{t("UPDATE_PROFILE_IMAGE")}</Modal.Title>
        <div>
          <Button className="close_btn">
            <FontAwesomeIcon
              icon={`xmark`}
              onClick={() => props.setIsShow(!props.show)}
            />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} name="profile_pic" />
            {files && files.length <= 0 ? (
              <>
                {props.logo && props.logo.path ? (
                  <div className="profile m-auto">
                    <img
                      src={
                        `${URL.createObjectURL(props.logo)}` ||
                        require("../../assets/images/noProfile.webp")
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                      }}
                      alt="profile-pic"
                    />
                  </div>
                ) : props.logo ? (
                  <div className="profile m-auto">
                    <img
                      src={
                        `${process.env.REACT_APP_BASE_URL}${props.logo}` ||
                        require("../../assets/images/noProfile.webp")
                      }
                      alt="Profile Pic"
                    />
                  </div>
                ) : (
                  <div className="drop_box">
                    <h4>
                      {t("UPLOAD_FILE_FROM_FROM_YOUR_DEVICE")}{" "}
                      <span>{t("OR_DRAG_FILE_HERE")}</span>
                    </h4>
                    <p>{t("PNG_JPG_GIF_UP_TO_1_MB")}</p>
                  </div>
                )}
                {fileRejections && fileRejections.length <= 0 ? null : (
                  <span className="invalid-feedback d-block">
                    {t("FILE_TYPE_IS_NOT_SUPPORTED")}
                  </span>
                )}
              </>
            ) : (
              <aside className="thumbsContainer">{thumbs}</aside>
            )}
          </div>
        </section>
        {formik.errors.profile_pic && (
          <div
            className="invalid-feedback d-block"
            style={{ marginLeft: "25px" }}
          >
            {formik.errors.profile_pic}
          </div>
        )}
      </Modal.Body>
      <div className="d-flex justify-content-center mt-3">
        <Button
          type="btn"
          className="m-sm-2 btn btn-primary"
          onClick={formik.handleSubmit}
          disabled={!(formik.isValid && formik.dirty)}
        >
          {t("SAVE")}
        </Button>
        <Button
          className="m-sm-2 btn btn-outline-secondary "
          onClick={() => props.setIsShow(!props.show)}
        >
          {t("CANCEL")}
        </Button>
      </div>
    </Modal>
  );
};

export default ProfileImg;
