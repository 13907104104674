import { post } from ".";
import { branch_id } from "../helpers/yup.validation.schema";

const URI = "/brands/trustree-feedback";

const getTrustreefeedbacks = (brand_id, payload) => {
  const URL = `${URI}/all-customer/${brand_id}`;
  return post(URL, payload);
};

const getResolvedTrustreefeedbacks = (brand_id, payload) => {
  const URL = `${URI}/resolved-customer/${brand_id}`;
  return post(URL, payload);
};
const getUnResolvedTrustreefeedbacks = (brand_id, payload) => {
  const URL = `${URI}/un_resolved-customer/${brand_id}`;
  return post(URL, payload);
};

const getAllFeedbackCount = (brand_id, payload) => {
  const URL = `${URI}/all_count/${brand_id}`;
  return post(URL, payload);
};

const getTotalFeedbackCount = (brand_id, payload) => {
  const URL = `${URI}/feedback_count/${brand_id}`;
  return post(URL, payload);
};

const getAllSingleFeedbackRequest = (customer_id, brand_id) => {
  const URL = `${URI}/all-customer/single/${brand_id}`;
  return post(URL, {customer_id});
};

const getResolvedSingleFeedbackRequest = (customer_id, brand_id) => {
  const URL = `${URI}/resolved-customer/single/${brand_id}`;
  return post(URL, {customer_id});
};

const getUnresolvedSingleFeedbackRequest = (customer_id, brand_id) => {
  const URL = `${URI}/unresolved-customer/single/${brand_id}`;
  return post(URL, {customer_id});
};

const trustree_feedbacks = {
  getTrustreefeedbacks,
  getResolvedTrustreefeedbacks,
  getUnResolvedTrustreefeedbacks,
  getTotalFeedbackCount,
  getAllSingleFeedbackRequest,
  getResolvedSingleFeedbackRequest,
  getUnresolvedSingleFeedbackRequest,
  getAllFeedbackCount
};

export default trustree_feedbacks;
