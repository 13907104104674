import { del, getPdf, post, put } from ".";

const URI = "/brands/feedbacks";

const genrateQr = (payload, select_id) => {
  const URL = `${URI}/get-qr-code/${select_id}`;
  return post(URL, payload);
};

const getFeedbackDataforPreview = (payload) => {
  const URL = `${URI}/get-preview`;
  return post(URL, payload);
};

const resolveFeedback = (payload) => {
  const URL = `${URI}/resolve-feedback`;
  return put(URL, payload);
};

const replyFeedback = (payload) => {
  const URL = `${URI}/reply-feedback`;
  return post(URL, payload);
};

const deleteFeedback = (f_id) => {
  const URL = `${URI}/delete-feedback/${f_id}`;
  return del(URL);
};

const sharePDF = (payload) => {
  const URL = `${URI}/share-pdf`;
  return post(URL, payload);
};

const printPdf = (id) => {
  const URL = `${URI}/print-pdf/${id}`;
  return getPdf(URL);
};

const Feedbacks = {
  genrateQr,
  getFeedbackDataforPreview,
  resolveFeedback,
  replyFeedback,
  deleteFeedback,
  sharePDF,
  printPdf,
};
export default Feedbacks;
