import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Receipt from "./Receipt";
import styles from "./receiptModal.module.css";
import { useQuery } from "react-query";
import Payment from "../../services/payment";
import ToastService from "../../helpers/toast-services";

/**
 * @typedef {{ name: string, address?: string}} Branch
 * @param {{show: boolean, centered: boolean, loading: boolean branchId?: string, onSubmit?:(branchId: string) => void}} props
 * @returns
 */
function ReceiptModal({ show, centered, onSubmit, branchId, loading }) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(["calcBranch", branchId], {
    queryFn: () => {
      return branchId && Payment.calculateBranch(branchId);
    },
    onError: (err) => ToastService.error(err),
    enabled: show,
  });

  const { planName, originalPrice, daysLeft, daysSince, pricePerDay, price } =
    data?.data?.data || {};

  return (
    <Modal show={show} centered={centered} size="md">
      <Modal.Header>
        <Modal.Title className="cust-title">{t("RECEIPT")}</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {isLoading ? (
            <Spinner className={styles.spinner} />
          ) : (
            data?.data?.data && (
              <>
                <Receipt
                  items={[
                    {
                      label: t(`PRICE_FOR_${planName?.toLocaleUpperCase()}`),
                      info: `1x ${t("BRANCH")}`,
                      amount: originalPrice,
                    },
                    {
                      label: t(`DEDUCTED_DAYS`),
                      info: `${daysSince} ${t("DAYS")}`,
                      amount: (-pricePerDay * daysSince).toFixed(2),
                    },
                    {
                      label: t(`TOTAL`),
                      amount: price,
                    },
                  ]}
                />
                <div className={styles.message}>
                  {t("YOU_WILL_PAY_FOR")}{" "}
                  <b>{`${daysLeft} ${t(daysLeft > 1 ? "DAYS" : "DAY")}`}</b>
                  {` ${t("ONLY")}, ${t(
                    `FROM_NEXT_PAYMENT_${planName.toLocaleUpperCase()}`
                  )}`}
                  <b>{` ${originalPrice}$`}</b>
                </div>
                <div className="Experience-btn-modal flex justify-content-center">
                  <Button
                    type="button"
                    className={styles.btn}
                    disabled={loading}
                    onClick={() => onSubmit(branchId)}
                  >
                    {loading ? (
                      <Spinner className={styles.spinner} />
                    ) : (
                      t("PAY")
                    )}
                  </Button>
                  <Button
                    type="button"
                    className={styles.btnSec}
                    disabled={loading}
                    onClick={() => onSubmit()}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              </>
            )
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export default ReceiptModal;
