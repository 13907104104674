import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { getStaffListing } from "../../../middlewares/detailReport";
import expImg from "../../../assets/images/exp-img.png";

const StaffRatingReport = ({ selectBrand, staffListing, exp_data, singleReport }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dashboardFilter, appliedDashboardFilter } = useSelector(
        (state) => state.dashboard
    );
    const url = window.location.href
    const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
    const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
    const date = new Date(dateInSeconds * 1000);
    const [sort, setSort] = useState([
        {
            key: "name",
            column: "name",
            value: "Name",
            order: -1,
            activeSort: true,
        },
        {
            key: "branch",
            column: "branch",
            value: "Branch",
            order: -1,
            activeSort: true,
        },
        {
            key: "rating",
            column: "rating",
            value: "Rating",
            order: -1,
            activeSort: false,
        },
    ]);

    const handleSort = (key, exp_id) => {
        let sortObj = {};

        const newSortOrder = sort.map((o) => {
            if (o.key === key) {
                const newObj = {
                    ...o,
                    order: o.order === 1 ? -1 : 1,
                    activeSort: true,
                };
                sortObj = { ...newObj, column: newObj.key };
                return newObj;
            }
            return { ...o, order: -1, activeSort: false };
        });
        setSort(newSortOrder);
        const filterData = {
            experience_type_id: singleReport.report[0].experience_type_id,
            rating: [],
            branch_id: singleReport.report[0].branch_id,
            category_id: [],
            staff_id: [],
            date: [null, null]
        }
        const payload = {
            brand_id: selectBrand,
            sort: sortObj,
            filter: filterData,
            reportDate: date,
            report_id: singleReport.report[0]._id
        };
        dispatch(getStaffListing(payload));
    };

    return (
        <div className="box">
            <h2>
                <strong>{t("STAFF_RATING")}</strong> <span>({exp_data?.title})</span>
            </h2>
            {(staffListing && staffListing.length > 0) ||
                appliedDashboardFilter.length > 0 ? (
                <div className="categoriesRatingBox">
                    <table className="table">
                        <thead>
                            <tr>
                                {sort &&
                                    sort.map((obj, index) => (
                                        <th key={obj.key} className="width260">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleSort(obj.key, exp_data._id)}
                                            >
                                                {t(obj.value)}
                                                {obj.order !== -1 ? (
                                                    <FontAwesomeIcon key={index} icon={`angle-up`} />
                                                ) : (
                                                    <FontAwesomeIcon key={index} icon={`angle-down`} />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {staffListing && staffListing.length > 0 ? (
                                staffListing.map((o, i) => (
                                    <tr key={i}>
                                        <td>
                                            <div className="staffProfile">
                                                <span className="staff-img">
                                                    {o.profile_pic ? (
                                                        <img
                                                            src={
                                                                `${process.env.REACT_APP_BASE_URL}${o.profile_pic}` ||
                                                                require("../../../assets/images/noProfile.webp")
                                                            }
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src =
                                                                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                                                            }}
                                                            alt="profile-pic"
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            name={
                                                                o.staff_name[t("language")]
                                                                    ? o.staff_name[t("language")]
                                                                    : o.staff_name[t("en")]
                                                            }
                                                            size="35px"
                                                            key={o._id}
                                                        />
                                                    )}
                                                </span>
                                                <p>
                                                    {o.staff_name[t("language")]
                                                        ? o.staff_name[t("language")]
                                                        : o.staff_name[t("en")]}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex center">
                                                <li>
                                                    {o.branches && o.branches.length === 1 ? (
                                                        <span>{o.branches && o.branches[0].name}</span>
                                                    ) : (
                                                        <p>{o.branches && o.branches[0].name}</p>
                                                    )}
                                                </li>
                                                {o.branches.length > 1 && (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={
                                                            <Tooltip id="button-tooltip-2">
                                                                {o.branches &&
                                                                    o.branches.map((obj) => (
                                                                        <li key={obj._id}>{obj.name}</li>
                                                                    ))}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <li className="settlement-24">
                                                            +{Object.keys(o.branches).length - 1}
                                                        </li>
                                                    </OverlayTrigger>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="ratingsWrap">
                                                <div className="ratingsText">
                                                    {" "}
                                                    {o.rating_avg.toFixed(2)}
                                                </div>
                                                <StarRatings
                                                    rating={o.rating_avg}
                                                    starDimension="14px"
                                                    starSpacing="2px"
                                                    starRatedColor="#FFD600"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>{t("NO_DATA_FOUND")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="experience-wpr flex">
                    <div className="experience-inner">
                        <img src={expImg} alt="loadding" />
                        <p>{t("NO_FEEDBACKS_FOUND")}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StaffRatingReport;
