import styles from "./receipt.module.css";

/**
 * @typedef {import("./BrandModal").Brand  & {branches: import("./BranchModal").Branch[]}} Brand
 * @param {{ items: {label: string info: string amount: number}[] }} props
 * @returns
 */
function Receipt({ items = [] }) {
  return (
    <div className={styles.receiptContainer}>
      {items.map((item) => (
        <div key={item.label} className={styles.receiptRow}>
          <span className={styles.receiptItem}>
            <span>{item.label}</span>
            <span>{item.info}</span>
          </span>
          <span className={styles.receiptPrice}>{`${item.amount}$`}</span>
        </div>
      ))}
    </div>
  );
}

export default Receipt;
