import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/new-logo.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="Logo_bottom">
      <ul>
        <li>
          {" "}
          <span style={{ marginRight: "2px" }}>{t("BY")}</span>{" "}
          <img src={logo} alt="logo" />
        </li>
      </ul>
    </div>
  );
};

export default Footer;
