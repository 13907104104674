import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import { getStaffListing } from "../../../middlewares/detailReport";
import expImg from "../../../assets/images/exp-img.png";
import starOne from "../../../assets/images/starOne.svg"

const LowestRatingFeedback = ({ selectBrand, productData, singleReport, feedback_id }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const url = window.location.href
    const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
    const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
    const date = new Date(dateInSeconds * 1000);
    const [sort, setSort] = useState([
        {
            key: "customer_name",
            column: "customer_name",
            value: "customer Name",
            order: -1,
            activeSort: true,
        },
        {
            key: "customer_phone",
            column: "customer_phone",
            value: "Customer Phone",
            order: -1,
            activeSort: false,
        },
        {
            key: "branch",
            column: "barnch",
            value: "Branch",
            order: -1,
            activeSort: false,
        },
        {
            key: "rating",
            column: "rating",
            value: "Rating",
            order: -1,
            activeSort: false,
        },
        {
            key: "avg_rating",
            column: "avg_rating",
            value: "Avg. Rating",
            order: -1,
            activeSort: false,
        },
        {
            key: "no_of_rating",
            column: "no_of_rating",
            value: "No. of Ratings",
            order: -1,
            activeSort: false,
        },
        {
            key: "",
            column: "",
            value: "",
            order: "",
            activeSort: false,
        },
    ]);

    const handleSort = (key, exp_id) => {
        let sortObj = {};

        const newSortOrder = sort.map((o) => {
            if (o.key === key) {
                const newObj = {
                    ...o,
                    order: o.order === 1 ? -1 : 1,
                    activeSort: true,
                };
                sortObj = { ...newObj, column: newObj.key };
                return newObj;
            }
            return { ...o, order: -1, activeSort: false };
        });
        setSort(newSortOrder);
        const filterData = {
            experience_type_id: singleReport.report[0].experience_type_id,
            rating: [],
            branch_id: singleReport.report[0].branch_id,
            category_id: [],
            staff_id: [],
            date: [null, null]
        }
        const payload = {
            brand_id: selectBrand,
            sort: sortObj,
            filter: filterData,
            reportDate: date,
            report_id: singleReport.report[0]._id
        };
        dispatch(getStaffListing(payload));
    };

    return (
        <div className="box">
            <h2>
                <strong>{t("LOWEST_FEEDBACKS")}</strong>
            </h2>
            {(productData && productData.length > 0) ? (
                <div className="categoriesRatingBox">
                    <table className="table">
                        <thead>
                            <tr>
                                {sort &&
                                    sort.map((obj, index) => (
                                        <th key={obj.key} className="width260">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleSort(obj.key)}
                                            >
                                                {t(obj.value)}
                                                {
                                                    obj.key === "" ?
                                                        ""
                                                        :
                                                        obj.order !== -1 ? (
                                                            <FontAwesomeIcon key={index} icon={`angle-up`} />
                                                        ) : (
                                                            <FontAwesomeIcon key={index} icon={`angle-down`} />
                                                        )
                                                }
                                            </span>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {productData && productData.length > 0 ? (
                                productData.map((o, i) => (
                                    <tr key={i}>
                                        <td>
                                            {o.customer_name}
                                        </td>
                                        <td>
                                            {o.customer_phone}
                                        </td>
                                        <td>
                                            {o.branch}
                                        </td>
                                        <td>
                                            <div className="ratingsWrap d-flex align-items-center">
                                                <div className="ratingsText">
                                                    {" "}
                                                    {o.overall_rating ? o.overall_rating : 0}
                                                    <em>
                                                        <img src={starOne} alt="star One" />
                                                    </em>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="ratingsWrap d-flex align-items-center">
                                                <div className="ratingsText">
                                                    {" "}
                                                    {o.rating_avg.toFixed(2)}
                                                    <em>
                                                        <img src={starOne} alt="star One" />
                                                    </em>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                        {o.customerData.feed_back_count}
                                        </td>
                                        <td onClick={() => feedback_id(o._id)}>
                                            <div style={{ marginLeft: "15px" }} >
                                                <strong className="view_button">{t("View FeedBack")}</strong>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>{t("NO_DATA_FOUND")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="experience-wpr flex">
                    <div className="experience-inner">
                        <img src={expImg} alt="loadding" />
                        <p>{t("NO_FEEDBACKS_FOUND")}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LowestRatingFeedback;
