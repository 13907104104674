import { useTranslation } from "react-i18next";
import styles from "./paymentPlanCard.module.css";

/**
 * @param {{plan: import("../../services/payment").PaymentPlan, price: number, highestPrice: number, selected: boolean}} props
 * @returns
 */

function PaymentPlanCard({ plan, highestPrice, selected, onClick }) {
  const { t } = useTranslation();
  const discount = Math.round(
    ((highestPrice - plan.amount) / highestPrice) * 100
  );
  return (
    <button
      type="button"
      className={`${styles.paymentCard} ${
        selected ? styles.paymentCardSelected : ""
      }`}
      onClick={onClick}
    >
      <span className={styles.paymentPlan}>
        <span className={styles.paymentName}>{t(plan.type.toUpperCase())}</span>
        <span className={discount && styles.paymentDiscount}>
          {discount ? `${discount}% ${t("OFF")}` : ""}
        </span>
      </span>
      <span className={styles.paymentPrice}>{`${plan.amount} $`}</span>
      <span className={styles.paymentType}>{t("PER_LOCATION_PER_MONTH")}</span>
    </button>
  );
}

export default PaymentPlanCard;
