const { postFormData, get } = require(".");

const URL = "/onboarding";

/**
 * @typedef {{ data: string[]}} OnboardingCreateResponse
 * @param {FormData} payload
 * @returns { Promise<import("axios").AxiosResponse<OnboardingCreateResponse>> }
 */
async function create(payload) {
  const url = `${URL}/create`;
  return postFormData(url, payload);
}

/**
 * @typedef {string[]} PayableBranches
 * @returns { Promise<import("axios").AxiosResponse<PayableBranches>> }
 */
async function getPayableBranches() {
  const url = `${URL}/payable-branches`;
  return get(url);
}

/**
 * @typedef {{_id: string, name: string google_link: string address: string, is_disable: boolean}} ExistingBranch
 * @typedef {{_id: string name: string category: string logo: string }} ExistingBrand
 * @returns { Promise<import("axios").AxiosResponse<ExistingBrand>> }
 */
async function getExistingBrands() {
  const url = `${URL}/existing-brands`;
  return get(url);
}

const Onboarding = {
  create,
  getPayableBranches,
  getExistingBrands,
};

export default Onboarding;
