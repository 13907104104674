import { useEffect, useState } from "react";

/**
 * @param {{img?: File, fallback: string}} props
 * @returns
 */
function ImageLoader({ img, fallback, alt, ...props }) {
  const [logo, setLogo] = useState();
  useEffect(() => {
    setLogo(img && URL.createObjectURL(img));
    return () => {
      URL.revokeObjectURL(logo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img]);

  return <img {...props} src={logo ? logo : fallback} alt={alt || "Image"} />;
}

export default ImageLoader;
