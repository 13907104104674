import React from "react";
import { useSelector } from "react-redux";

const BrandLogo = (props) => {
  const { previewLogo } = useSelector((state) => state.feedbacks);
  return (
    <div className="BrandLogo">
      {previewLogo === true && (
        <img
          src={
            `${process.env.REACT_APP_BASE_URL}${props?.object?.brand_detail?.logo}` ||
            require("../../assets/images/noProfile.webp")
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
          }}
          alt="logo"
          id={props.id}
        />
      )}
    </div>
  );
};

export default BrandLogo;
