import React, { useEffect, useState, lazy } from "react";
import "../login/Login.css";
// import MainMenu from "../../templates/menu/MainMenu";
import downArrow from "../../assets/images/downArrow.svg";
import exportdata from "../../assets/images/exportData.svg";
import filterIcon from "../../assets/images/filterIcon.svg";
import { useTranslation } from "react-i18next";
import { Dropdown, Form } from "react-bootstrap";
import { getbrandsforDropdown } from "../../middlewares/brands";
import { useDispatch, useSelector } from "react-redux";
import { getExperienceTypeByBrandId } from "../../middlewares/experienceType";
import { getBranchesByBrandId } from "../../middlewares/branches";
import { getStaffList } from "../../middlewares/staffMembers";
import {
  getCategoriesListingByBrandId,
  getQuickOverview,
} from "../../middlewares/dashboard";
import ExportFeedbackDataModal from "../feedback/trustree_feedbacks/ExportFeedbackDataModal";
import expImg from "../../assets/images/exp-img.png";
import brandImg from "../../assets/images/Brandlogo.png";
import PlaceholderLoader from "../user-listing/PlaceholderLoader";
import { totalFeedbackCount } from "../../middlewares/trustreeFeedbacks";
import { Suspense } from "react";
import { cancelAllPendingRequests } from "../../services";

const MainMenu = lazy(() => import("../../templates/menu/MainMenu"));

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { branches } = useSelector((state) => state.branch);
  const [selectBrand, setSelectBrand] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const { dropDownBrands: brands, dropDownBrandsLoading } = useSelector(
    (state) => state.brand
  );
  const { experienceTypeData } = useSelector((state) => state.experience);
  const { dashboardFilter } = useSelector((state) => state.dashboard);
  const b = localStorage.getItem("b_id");

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setMobileView(window.innerWidth < 600);
    window.addEventListener("resize", () =>
      setMobileView(window.innerWidth < 600)
    );

    return () => {
      window.removeEventListener("resize", () =>
        setMobileView(window.innerWidth < 600)
      );
    };
  }, []);

  useEffect(() => {
    if (b) {
      let filter = {};
      const payload = {
        filter,
      };
      dispatch(totalFeedbackCount(b, payload.filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b]);

  useEffect(() => {
    dispatch(getbrandsforDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      cancelAllPendingRequests();
    };
  }, []);

  useEffect(() => {
    if (!dropDownBrandsLoading && initialLoading && brands) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownBrandsLoading]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      if (b) {
        const findBrandObj = brands.find((o) => o._id === b);
        if (findBrandObj) {
          setSelectBrand(b);
        } else {
          localStorage.setItem("b_id", brands[0]._id);
          setSelectBrand(brands[0]._id);
        }
      } else {
        localStorage.setItem("b_id", brands[0]._id);
        setSelectBrand(brands[0]._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, selectBrand]);

  useEffect(() => {
    if (selectBrand) {
      dispatch(getExperienceTypeByBrandId(selectBrand));
      dispatch(getBranchesByBrandId(selectBrand));
      dispatch(getStaffList(selectBrand));
      dispatch(getCategoriesListingByBrandId(selectBrand));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBrand]);

  useEffect(() => {
    if (selectBrand) {
      const payload = {
        filter: dashboardFilter,
      };
      dispatch(getQuickOverview(payload, selectBrand));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBrand, dashboardFilter]);

  const brand_name =
    brands &&
    brands.length > 0 &&
    brands.find((obj) => {
      return obj._id === b;
    });

  const handleChange = (e) => {
    localStorage.setItem("b_id", e);
    setSelectBrand(e);
  };

  const handleExportClick = () => {
    setIsShow(true);
  };

  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);

  return (
    <>
      <Form>
        <div className="contentWrap">
          <div className="contentHead">
            {!initialLoading && (
              <>
                <div
                  className={`customSelect ${
                    !mobileView ? "ms-auto" : "flex-grow-1"
                  } staticIcon`}
                >
                  {brand_name && (
                    <Dropdown className="ellipsis">
                      <Dropdown.Toggle className="form-control">
                        <div className="d-flex w-100 align-items-center ImgSelect">
                          <span>
                            {brand_name?.logo ? (
                              <img
                                src={
                                  `${process.env.REACT_APP_BASE_URL}${brand_name.logo}` ||
                                  require("../../assets/images/Brandlogo.png")
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://api.app.trustree.com/images/Brandlogo.png";
                                }}
                                alt="logo"
                              />
                            ) : (
                              <img src={brandImg} alt="logo" />
                            )}
                          </span>
                          <span
                            style={{ paddingRight: "30px" }}
                            className="dropdown-value ms-2"
                          >
                            {brand_name?.name
                              ? brand_name?.name
                              : "Select Brand"}
                          </span>
                          <span className="d-Arrow brandArrow">
                            <img src={downArrow} alt="downArrow" />
                          </span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={"span"} disabled>
                          {t("SELECT_BRAND")}
                        </Dropdown.Item>
                        {brands &&
                          brands.length > 0 &&
                          brands.map((o, i) => (
                            <Dropdown.Item
                              as={"span"}
                              value={o._id}
                              key={i}
                              onClick={() => handleChange(o._id)}
                            >
                              <span>
                                {o.logo ? (
                                  <img
                                    src={
                                      `${process.env.REACT_APP_BASE_URL}${o.logo}` ||
                                      require("../../assets/images/Brandlogo.png")
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://api.app.trustree.com/images/Brandlogo.png";
                                    }}
                                    alt="logo"
                                  />
                                ) : (
                                  <img src={brandImg} alt="logo" />
                                )}
                              </span>
                              {o.name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {/* )} */}
                </div>
                {!mobileView ? (
                  <button
                    onClick={() => handleExportClick()}
                    className="ms-3 btnIconDashBDR"
                    type="button"
                  >
                    <em>
                      <img src={exportdata} alt="export data" />
                    </em>
                    <strong style={{ color: "#2BBC91" }}>
                      {t("EXPORT_DATA")}
                    </strong>
                  </button>
                ) : (
                  <button
                    className="mobileFilterButton"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMobileFilterModal((show) => !show);
                    }}
                  >
                    <img src={filterIcon} />
                  </button>
                )}
              </>
            )}
          </div>
          {dropDownBrandsLoading && !initialLoading ? (
            <PlaceholderLoader />
          ) : (
            <>
              {brands && brands.length > 0 ? (
                brand_name ? (
                  <Suspense
                    fallback={
                      <>
                        <PlaceholderLoader />
                      </>
                    }
                  >
                    <MainMenu
                      selectBrand={selectBrand}
                      mobileView={mobileView}
                      showMobileFilterModal={showMobileFilterModal}
                      setShowMobileFilterModal={setShowMobileFilterModal}
                    />
                  </Suspense>
                ) : (
                  <div className="experience-wpr flex">
                    <div className="experience-inner">
                      <img src={expImg} alt="loadding" />
                      <p>{t("NO_BRAND_IS_SELECTED")}</p>
                    </div>
                  </div>
                )
              ) : (
                <div className="experience-wpr flex">
                  <div className="experience-inner">
                    <img src={expImg} alt="loadding" />
                    <p>{t("NO_BRANDS")}</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Form>
      {isShow && (
        <ExportFeedbackDataModal
          show={isShow}
          setIsShow={(flag) => setIsShow(flag)}
          branch={branches}
          experience_type={experienceTypeData}
          brandId={selectBrand}
        />
      )}
    </>
  );
};

export default Home;
