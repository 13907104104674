export const BASE_URL = "/";
const ROUTE_URLS = {
  HOME: BASE_URL,
  LOGIN: `${BASE_URL}login`,
  FORGOT_PASSWORD: `${BASE_URL}forgot-password`,
  SET_PASSWORD: `${BASE_URL}set-password`,
  RESET_PASSWORD: `${BASE_URL}reset-password`,
  INVITATION_CODE: `${BASE_URL}invitation-code`,
  USERS: `${BASE_URL}users`,
  BRANDS: `${BASE_URL}brands`,
  PROFILE: `${BASE_URL}profile`,
  PROFILE_UPDATE: `${BASE_URL}profile-update`,
  PASSWORD_UPDATE: `${BASE_URL}password-update`,
  TRUSTREE_FEEDBACKS: `${BASE_URL}trustree-feedbacks`,
  GOOGLE_FEEDBACKS: `${BASE_URL}google-feedbacks`,
  BRANDS_OPTIONS: `${BASE_URL}brand-options`,
  COMPONENT: `${BASE_URL}component`,
  CATALOGUES: `${BASE_URL}catalogues`,
  QUESTIONS: `${BASE_URL}questions`,
  LINKS_AND_QR_CODES: `${BASE_URL}links-and-qr-codes`,
  CUSTOMERS: `${BASE_URL}customers`,
  CUSTOMER_FEEDBACKS: `${BASE_URL}customers-feedback`,
  CUSTOMER_FEEDBACKS_WITH_ID: `${BASE_URL}customers-feedback/:id`,
  CUSTOMER_FEEDBACKS_WITH_FEEDBACKID: `${BASE_URL}customers-feedback/:id/:f_id`,
  SUMMARY_REPORT: `${BASE_URL}summery-report/:date/:id/:r_id`,
  TEAM_REWARD: `${BASE_URL}team-reward`,
  DETAILED_REPORT: `${BASE_URL}detailed-report/:date/:id/:r_id`,
  ONBOARDING: `${BASE_URL}onboarding`,
  PAYMENT: `${BASE_URL}payment`,
};

export default ROUTE_URLS;
