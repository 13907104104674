import React, { Suspense, useEffect } from "react";
import SideBar from "./sidebar/SideBar";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../middlewares/users";
import { getCurrency } from "../middlewares/brands";
import { Spinner } from "react-bootstrap";
import NetWorkStatus from "../components/networkStatus/NetworkStatus";
import PaymentBanner from "../components/paymentBanner/PaymentBanner";

const AppLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PaymentBanner />
      <NetWorkStatus />
      <div className="Wrapper">
        <SideBar />
        <Suspense
          fallback={
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="spinner-auto"
                  style={{
                    color: "#56e2b8",
                  }}
                  animation="border"
                />
              </div>
            </>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </>
  );
};

export default AppLayout;
