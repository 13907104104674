import React, { useState } from "react";
import BackNav from "../../assets/images/BackNav.svg";
import ForwardNav from "../../assets/images/ForwardNav.svg";
import trusTree from "../../assets/images/new-logo.svg";
import "../menu/Dashbord.css";
import { Link, useLocation } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";
import { useTranslation } from "react-i18next";
import Logout from "../../pages/logout/Logout";
import { useSelector } from "react-redux";
import { Badge, Dropdown } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { totalFeedbackCount } from "../../middlewares/trustreeFeedbacks";

const SideBar = () => {
  const { t, i18n } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const brandsSubMenus = [
    ROUTE_URLS.BRANDS,
    ROUTE_URLS.BRANDS_OPTIONS,
    ROUTE_URLS.QUESTIONS,
    ROUTE_URLS.LINKS_AND_QR_CODES,
    ROUTE_URLS.TEAM_REWARD,
  ];
  const feedbacksSubMenus = [
    ROUTE_URLS.TRUSTREE_FEEDBACKS,
    ROUTE_URLS.GOOGLE_FEEDBACKS,
  ];
  const accountSettingsSubMenus = [ROUTE_URLS.PROFILE, ROUTE_URLS.USERS];
  const langaugeSubMenus = [];
  const { userProfile } = useSelector((state) => state.user);
  const { AllLanguages } = useSelector((state) => state.brand);
  const { trustreeFeedbackFilter, totalFeedbackCounts } = useSelector(
    (state) => state.trustreeFeedbacks
  );

  const selectBrand = localStorage.getItem("b_id");

  useEffect(() => {
    let filter = trustreeFeedbackFilter;
    if (selectBrand) {
      const payload = {
        filter,
      };
      dispatch(totalFeedbackCount(selectBrand, payload.filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBrand, trustreeFeedbackFilter]);

  const getParentActiveClass = (path, defaultClasses = "") => {
    let classes = defaultClasses;
    if (pathname === path) {
      classes += " active ";
    }
    return classes;
  };

  const getParentActiveClassByChildren = (paths = [], defaultClasses = "") => {
    let classes = defaultClasses;
    if (paths.indexOf(pathname) >= 0) {
      classes += " active ";
    }
    return classes;
  };

  const toggleParentActiveClass = (e, pathsToAvoid = []) => {
    if (pathsToAvoid.indexOf(pathname) < 0) {
      const {
        currentTarget: { classList },
      } = e;
      if (classList.contains("active")) {
        classList.remove("active");
      } else {
        classList.add("active");
      }
    }
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
    window.location.reload();
  };

  const selectedLanguauge =
    AllLanguages &&
    AllLanguages.find((obj) => {
      return obj.key === localStorage.getItem("i18nextLng");
    });

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setMobileView(window.innerWidth < 600);
    window.addEventListener("resize", () =>
      setMobileView(window.innerWidth < 600)
    );

    return () => {
      window.removeEventListener("resize", () =>
        setMobileView(window.innerWidth < 600)
      );
    };
  }, []);

  const isOwnerMaster =
    userProfile?.permission === "owner" || userProfile?.permission === "master";

  const isOwner = userProfile?.permission === "owner";

  return (
    <>
      <div className="sidebarContent">
        <div
          className="MobileHead align-self-center"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <Link to={"#"} id="show-sidebar" className="">
            <img src={BackNav} alt="back navigation" />
          </Link>
          <div id="close-sidebar" className="ForwardNav NavBox">
            <img src={ForwardNav} alt="forword navigation" />
          </div>
        </div>
        <div className="Logo">
          <Link to={""}>
            <img
              src={trusTree}
              alt="logo"
              style={{
                width: "auto",
                height: "auto",
                maxWidth: "150px",
                maxHeight: "45px",
              }}
            />
          </Link>
        </div>
        <div
          className={`navBar ${
            showSidebar ? "sliderBarOpen" : "sliderBarClose"
          }`}
        >
          <div
            className="sidebar-menu navBarHead content mCustomScrollbar"
            data-mcs-theme="dark"
            style={{ overflowY: "auto" }}
          >
            <ul className="navUL">
              <li
                className={getParentActiveClass(ROUTE_URLS.HOME, "homeNav")}
                onClick={() => setShowSidebar(false)}
              >
                <Link to={ROUTE_URLS.HOME}>
                  <em></em>
                  <strong>{t("Home")}</strong>
                </Link>
              </li>
              <li
                className={`customersNav ${
                  pathname === ROUTE_URLS.CUSTOMERS ||
                  pathname.includes("customers-feedback")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={ROUTE_URLS.CUSTOMERS}
                  className={`${
                    pathname.includes("customers-feedback") ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSidebar(false);
                  }}
                >
                  <em></em>
                  <strong>{t("CUSTOMERS")}</strong>
                </Link>
              </li>
              <li
                className={getParentActiveClassByChildren(
                  feedbacksSubMenus,
                  "feedbacks-sidebar-dropdown feedbacksNav"
                )}
                onClick={(e) => toggleParentActiveClass(e, feedbacksSubMenus)}
              >
                <Link>
                  <em></em>
                  <strong>{t("Feedbacks")}</strong>
                  <Badge className="num color-orange">
                    {totalFeedbackCounts
                      ? totalFeedbackCounts.trustreefeedbacks
                      : "0"}
                  </Badge>
                </Link>
                <div className="sidebar-submenu">
                  <ul>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.TRUSTREE_FEEDBACKS
                            ? "active"
                            : ""
                        }`}
                        to={ROUTE_URLS.TRUSTREE_FEEDBACKS}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("TRUSTREE_FEEDBACKS")}
                        <Badge className="num color-orange">
                          {totalFeedbackCounts
                            ? totalFeedbackCounts.trustreefeedbacks
                            : "0"}
                        </Badge>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.GOOGLE_FEEDBACKS
                            ? "active"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <p>
                          {t("GOOGLE_FEEDBACKS")}
                          <br />
                          (Coming Soon)
                        </p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={getParentActiveClassByChildren(
                  brandsSubMenus,
                  "brand-sidebar-dropdown brandsNav"
                )}
                onClick={(e) => toggleParentActiveClass(e, brandsSubMenus)}
              >
                <Link>
                  <em></em>

                  <strong>{t("Brands")}</strong>
                </Link>
                <div className="sidebar-submenu">
                  <ul>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.BRANDS ? "active" : ""
                        }`}
                        to={ROUTE_URLS.BRANDS}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("Brands")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.BRANDS_OPTIONS ? "active" : ""
                        }`}
                        to={ROUTE_URLS.BRANDS_OPTIONS}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("BRAND_OPTIONS")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.QUESTIONS ? "active" : ""
                        }`}
                        to={ROUTE_URLS.QUESTIONS}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("QUESTIONS")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.LINKS_AND_QR_CODES
                            ? "active"
                            : ""
                        }`}
                        to={ROUTE_URLS.LINKS_AND_QR_CODES}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("LINKS_AND_QR_CODES")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.TEAM_REWARD ? "active" : ""
                        }`}
                        to={ROUTE_URLS.TEAM_REWARD}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("TEAM_REWARDS")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={getParentActiveClass(
                  ROUTE_URLS.CATALOGUES,
                  "cataloguesNav"
                )}
                onClick={(e) =>
                  toggleParentActiveClass(e, ROUTE_URLS.CATALOGUES)
                }
              >
                <Link
                  to={ROUTE_URLS.CATALOGUES}
                  onClick={() => setShowSidebar(false)}
                >
                  <em></em>
                  <strong>{t("CATALOGUES")}</strong>
                </Link>
              </li>
              <li
                className={getParentActiveClassByChildren(
                  accountSettingsSubMenus,
                  "account-sidebar-dropdown accountsNav"
                )}
                onClick={(e) =>
                  toggleParentActiveClass(e, accountSettingsSubMenus)
                }
              >
                <Link>
                  <em></em>
                  <strong>{t("Account Settings")}</strong>
                </Link>
                <div className="sidebar-submenu">
                  <ul>
                    <li>
                      <Link
                        className={`${
                          pathname === ROUTE_URLS.PROFILE ? "active" : ""
                        }`}
                        to={ROUTE_URLS.PROFILE}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowSidebar(false);
                        }}
                      >
                        {t("My Profile")}{" "}
                      </Link>
                    </li>
                    {isOwnerMaster && (
                      <li>
                        <Link
                          className={`${
                            pathname === ROUTE_URLS.USERS ? "active" : ""
                          }`}
                          to={ROUTE_URLS.USERS}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowSidebar(false);
                          }}
                        >
                          {t("Users")}
                        </Link>
                      </li>
                    )}
                    {isOwner && (
                      <li>
                        <Link
                          className={`${
                            pathname === ROUTE_URLS.PAYMENT ? "active" : ""
                          }`}
                          to={ROUTE_URLS.PAYMENT}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowSidebar(false);
                          }}
                        >
                          {t("PAYMENT")}
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
              {mobileView ? (
                <>
                  <li
                    className={getParentActiveClassByChildren(
                      langaugeSubMenus,
                      "language-sidebar-dropdown sidebar-dropdown-language languageNav ellipsis"
                    )}
                  >
                    <Dropdown className="dropDown-language-sidebar">
                      <Dropdown.Toggle>
                        {selectedLanguauge ? (
                          <Link>
                            <em></em>
                            {selectedLanguauge.value}
                          </Link>
                        ) : (
                          <Link>
                            <em></em>
                            {t("English")}
                          </Link>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {AllLanguages &&
                          AllLanguages.map((o, i) => (
                            <Dropdown.Item
                              as={"span"}
                              key={i}
                              onClick={() => handleLanguageChange(o.key)}
                            >
                              {o.value}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="logout-sidebar logoutNav">
                    <Link onClick={() => setIsEditMode(true)}>
                      <em></em>
                      <strong>{t("Log out")}</strong>
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
          {!mobileView ? (
            <div className="navBarFooter">
              <ul className="navUL">
                <li
                  className={getParentActiveClassByChildren(
                    langaugeSubMenus,
                    "language-sidebar-dropdown sidebar-dropdown-language languageNav ellipsis"
                  )}
                >
                  <Dropdown className="dropDown-language-sidebar">
                    <Dropdown.Toggle>
                      {selectedLanguauge ? (
                        <Link>
                          <em></em>
                          {selectedLanguauge.value}
                        </Link>
                      ) : (
                        <Link>
                          <em></em>
                          {t("English")}
                        </Link>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {AllLanguages &&
                        AllLanguages.map((o, i) => (
                          <Dropdown.Item
                            as={"span"}
                            key={i}
                            onClick={() => handleLanguageChange(o.key)}
                          >
                            {o.value}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="logout-sidebar logoutNav">
                  <Link onClick={() => setIsEditMode(true)}>
                    <em></em>
                    <strong>{t("Log out")}</strong>
                  </Link>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      {isEditMode && (
        <Logout show={isEditMode} setIsShow={(flag) => setIsEditMode(flag)} />
      )}
    </>
  );
};
export default SideBar;
