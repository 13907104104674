import React, { useEffect, useState } from "react";
import downArrow from "../../../assets/images/downArrow.svg";
import exportdata from "../../../assets/images/exportData.svg";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form } from "react-bootstrap";
import { getOneBrandToUpdate, getbrandsforDropdown } from "../../../middlewares/brands";
import { useDispatch, useSelector } from "react-redux";
import { getExperienceTypeByBrandId } from "../../../middlewares/experienceType";
import { getBranchesByBrandId } from "../../../middlewares/branches";
import DownArrow from '../../../assets/images/Down__Arrow.svg'
import { getCategoryReport, getFeedBackReport, getFeedbackDataForModal, getProductReport, getQuickOverviewReport } from "../../../middlewares/summaryReport";
import { getReports, } from "../../../middlewares/reports";
import { getAdditionalQuestion, getReportsById } from "../../../middlewares/detailReport";
import './brandReport.scss';
import UpArrow from '../../../assets/images/Up_Arrow.svg';
import PerformaceOverTimeReport from "./PerformaceOverTime";
import BranchesComparisonReport from "./BranchComparison";
import { getCategoriesListing, getStaffListing } from "../../../middlewares/detailReport";
import CategoriesRatingReport from "./CategoriesRating";
import StaffRatingReport from "./StaffRating";
import HighestRatingProduct from "./HighestProduct";
import LowestRatingProduct from "./LowestProduct";
import HighestRatingFeedback from "./HighestFeedBack";
import LowestRatingFeedback from "./LowestFeedBack";
import CustomerFeedbackModalReport from "./feedBackModal";
import MultipleQuestionModal from "./MultipleQuestionModal";
import PlaceholderLoader from "../../user-listing/PlaceholderLoader";

const DetailedReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const url = window.location.href
    const date1 = url.split('/')[4];
    const dateInMilliseconds = Number(url.split('/')[4]); // Parse the string as a number
    const dateInSeconds = dateInMilliseconds / 1000; // Convert to seconds
    const date = new Date(dateInSeconds * 1000); // Convert back to milliseconds and create a Date object
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const originalDate = new Date(parseInt(date1));
    // Calculate one week before
    const oneWeekBefore = new Date(originalDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    // Format the oneWeekBefore date
    const formattedDate = oneWeekBefore.toLocaleDateString('en-In', options);
    const oneDayBefore = new Date(originalDate.getTime() - 24 * 60 * 60 * 1000);
    const formattedDate1 = oneDayBefore.toLocaleDateString('en-In', options);
    const brand_id = url.split('/')[5];
    const report_id = url.split('/')[6];
    const [isShow, setIsShow] = useState(false);
    const { categories, staffListing, singleReport, additionalQuestions, updateLoading } = useSelector(
        (state) => state.detailReport
    );
    const [dataAvailable, setDataAvailable] = useState(0);

    // Simulate loading data with a delay (you should replace this with your actual data loading logic)
    useEffect(() => {
        if (singleReport) {
            setDataAvailable(1);
        } else if (updateLoading === false && singleReport === null) {
            setDataAvailable(2)
        }
    }, [singleReport]);

    const {
        quickOverview,
        productReport,
        feedBackReport,
        feedbackModal,
        categoryReport,
    } = useSelector((state) => state.summaryReport);

    const combinedData = categoryReport?.flatMap(item => item.data);

    const highestCategory = combinedData?.reduce((highest, current) => {
        if (current.rating_avg > highest.rating_avg) {
            return current;
        }
        return highest;
    }, combinedData[0]);

    const lowestCategory = combinedData?.reduce((lowest, current) => {
        if (current.rating_avg < lowest.rating_avg) {
            return current;
        }
        return lowest;
    }, combinedData[0]);

    const highestRatedProducts = [];
    const lowestRatedProducts = [];

    productReport?.forEach((item) => {
        if (item.ratings.length >= 1) {
            highestRatedProducts.push(item);
            lowestRatedProducts.push(item);
        }
    });

    // Sort highestRatedProducts in descending order based on rating_avg
    highestRatedProducts?.sort((a, b) => b.rating_avg - a.rating_avg);

    // Sort lowestRatedProducts in ascending order based on rating_avg
    lowestRatedProducts?.sort((a, b) => a.rating_avg - b.rating_avg);

    const feedbackData = feedBackReport;

    const lowestRatings = feedbackData?.filter(entry => entry.overall_rating !== null && entry.overall_rating <= 2.5);
    const highestRatings = feedbackData?.filter(entry => entry.overall_rating !== null && entry.overall_rating > 2.5);

    useEffect(() => {
        if (brand_id && singleReport) {
            const filterData = {
                experience_type_id: singleReport?.report[0].experience_type_id,
                rating: [],
                branch_id: singleReport?.report[0].branch_id,
                category_id: [],
                staff_id: [],
                date: [null, null]
            }
            const payload = {
                brand_id: brand_id,
                sort: {
                    key: "name",
                    column: "name",
                    value: "Name",
                    order: -1,
                    activeSort: true,
                },
                filter: filterData,
                reportDate: date,
                report_id: singleReport?.report[0]._id
            };
            const payloadQuestions = {
                brand_id: brand_id,
                filter: filterData,
                reportDate: date,
                report_id: singleReport?.report[0]._id
            }
            dispatch(getAdditionalQuestion(payloadQuestions));
            dispatch(getCategoriesListing(payload))
            dispatch(getFeedBackReport(payload));
            dispatch(getStaffListing(payload));
            dispatch(getCategoryReport(payload))
            const filter = {
                experience_key: singleReport?.report[0].experience_type_id,
                rating: { min: 0, max: 5 },
                branch_id: singleReport?.report[0].branch_id,
            }
            const productPayload = {
                b_id: brand_id,
                filter: filter,
                reportDate: date,
                report_id: singleReport?.report[0]._id
            }
            dispatch(getProductReport(productPayload))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_id, singleReport]);

    useEffect(() => {
        if (brand_id && report_id) {
            dispatch(getReportsById(brand_id, report_id))
        }
    }, [report_id]);

    useEffect(() => {
        if (brand_id && singleReport) {
            const filter = {
                experience_type_id: singleReport?.report[0].experience_type_id,
                rating: [],
                branch_id: singleReport?.report[0].branch_id,
                category_id: [],
                staff_id: [],
                date: [null, null]
            }
            const payload = {
                report_id: singleReport?.report[0]._id,
                filter: filter,
                reportDate: date,
            };
            dispatch(getQuickOverviewReport(payload, brand_id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_id, singleReport]);

    const handleModalOpen = (e) => {
        setIsShow(true);
        dispatch(getFeedbackDataForModal(e));
    };

    const handleButtonClick = () => {
        const url = 'https://app.staging.trustree.com';
        window.open(url, '_blank');
    };
    return (
        <>
            {
                (singleReport && singleReport !== null && singleReport?.report[0]?.status === true && singleReport?.report[0]?.report_type === "detailed_report") ?
                    singleReport.report.map((o, idx) => {
                        return (
                            <>
                                <div className="contentWrap" style={{ padding: "25px" }}>
                                    <div className="contentHead" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={
                                                    o.brand_name[0] ?
                                                        `${process.env.REACT_APP_BASE_URL}${o.brand_name[0]}`
                                                        :
                                                        "https://res.cloudinary.com/dhpvia1ae/image/upload/v1693831105/Group_826_oyntqe.png"
                                                }
                                                alt="logo"
                                                height={48}
                                                width={48}
                                                style={{ borderRadius: "25px" }}
                                            />
                                            <div style={{ marginLeft: "10px" }}>
                                                <strong className="brand_name_report">{t(o.title)}</strong>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: "10px" }}>
                                            <span className="brand_name">{t(o.report_send === "day" ? "Daily Report" : "Weekly Report")} : {(o.report_send === "day" ? formattedDate1 : `${formattedDate} to ${formattedDate1}`)}</span>
                                        </div>
                                    </div>
                                    <div className="contentType d-flex align-items-center">
                                        <div>
                                            <span className="experience_type" style={{ marginRight: "5px" }}>
                                                {t(`EXPERIENCE_TYPES`)} ({o.experience_type_names.length}):
                                            </span>
                                            {
                                                o.experience_type_names.map((name, indx) => {
                                                    return (
                                                        <span className="experience_type" >
                                                            {name}
                                                            {indx < o.experience_type_names.length - 1 ? ', ' : ''}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div style={{ marginLeft: "20px" }}>
                                            <span className="experience_type" style={{ marginRight: "5px" }}>
                                                {t(`BRANCHES`)} ({o.branch_names.length}):
                                            </span>
                                            {
                                                o.branch_names.map((name, indx) => {
                                                    return (
                                                        <span className="experience_type" >
                                                            {name}
                                                            {indx < o.branch_names.length - 1 ? ', ' : ''}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="multiBoxes" style={{ marginTop: "15px" }}>
                                        <ul>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("BRAND_RATING")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {quickOverview ? quickOverview?.average_rating.toFixed(2) : 0}
                                                        </p>
                                                        <div className={quickOverview?.percentageChange < 0 ? "decrement_status" : "increment_status"}>
                                                            {quickOverview?.percentageChange < 0 ? (
                                                                <img src={DownArrow} alt="" />
                                                            ) : (
                                                                <img src={UpArrow} alt="" />
                                                            )}
                                                            <span className={quickOverview?.percentageChange < 0 ? "decrement_count" : "increment_count"}>
                                                                {quickOverview?.percentageChange ? Math.abs(quickOverview?.percentageChange).toFixed(0) : 0}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("NUMBER_OF_RATING")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {quickOverview ? quickOverview?.feedback_count : 0}
                                                        </p>
                                                        <div className={quickOverview?.feedbackCountCountPercentageChange < 0 ? "decrement_status" : "increment_status"}>
                                                            {quickOverview?.feedbackCountCountPercentageChange < 0 ? (
                                                                <img src={DownArrow} alt="" />
                                                            ) : (
                                                                <img src={UpArrow} alt="" />
                                                            )}
                                                            <span className={quickOverview?.feedbackCountCountPercentageChange < 0 ? "decrement_count" : "increment_count"}>
                                                                {quickOverview?.feedbackCountCountPercentageChange ? Math.abs(quickOverview?.feedbackCountCountPercentageChange).toFixed(0) : 0}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("REDEEMED_VOUCHER")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {quickOverview ? quickOverview?.redeemed_count : 0}
                                                        </p>
                                                        <div className={quickOverview?.redeemed_count_percentage_change < 0 ? "decrement_status" : "increment_status"}>
                                                            {quickOverview?.redeemed_count_percentage_change < 0 ? (
                                                                <img src={DownArrow} alt="" />
                                                            ) : (
                                                                <img src={UpArrow} alt="" />
                                                            )}
                                                            <span className={quickOverview?.redeemed_count_percentage_change < 0 ? "decrement_count" : "increment_count"}>
                                                                {quickOverview?.redeemed_count_percentage_change ? Math.abs(quickOverview?.redeemed_count_percentage_change).toFixed(0) : 0}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("RESOLVED_FEEDBACKS")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {quickOverview ? quickOverview?.resolved_count : 0}
                                                        </p>
                                                        <div className={quickOverview?.resolved_count_percentage_change < 0 ? "decrement_status" : "increment_status"}>
                                                            {quickOverview?.resolved_count_percentage_change < 0 ? (
                                                                <img src={DownArrow} alt="" />
                                                            ) : (
                                                                <img src={UpArrow} alt="" />
                                                            )}
                                                            <span className={quickOverview?.resolved_count_percentage_change < 0 ? "decrement_count" : "increment_count"}>
                                                                {quickOverview?.resolved_count_percentage_change ? Math.abs(quickOverview?.resolved_count_percentage_change).toFixed(0) : 0}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul style={{ marginTop: "15px" }}>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("UNRESOLVED_FEEDBACKS")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {quickOverview ? quickOverview?.unresolved_count : 0}
                                                        </p>
                                                        <div className={quickOverview?.unresolved_count_percentage_change < 0 ? "decrement_status" : "increment_status"}>
                                                            {quickOverview?.unresolved_count_percentage_change < 0 ? (
                                                                <img src={DownArrow} alt="" />
                                                            ) : (
                                                                <img src={UpArrow} alt="" />
                                                            )}
                                                            <span className={quickOverview?.unresolved_count_percentage_change < 0 ? "decrement_count" : "increment_count"}>
                                                                {quickOverview?.unresolved_count_percentage_change ? Math.abs(quickOverview?.unresolved_count_percentage_change).toFixed(0) : 0}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("HIGHEST_CATEGORY")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {highestCategory?.category_name.en ? highestCategory?.category_name.en : "-"}
                                                        </p>
                                                        <h3>
                                                            {"Service"}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="multiBox" style={{ borderRadius: "12px" }}>
                                                    <h3>{t("LOWEST_CATEGORY")}</h3>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p>
                                                            {lowestCategory?.category_name.en ? lowestCategory?.category_name.en : "-"}
                                                        </p>
                                                        <h3>
                                                            {"Service"}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="equalHeight" style={{ marginTop: "15px" }}>
                                        <PerformaceOverTimeReport selectBrand={brand_id} report={singleReport} />
                                    </div>

                                    <div className="row equalHeight">
                                        <div className="col-md-6">
                                            <BranchesComparisonReport selectBrand={brand_id} report={singleReport} />
                                        </div>
                                        {categories &&
                                            categories.length > 0 &&
                                            categories.map((o, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <CategoriesRatingReport
                                                        selectBrand={brand_id}
                                                        categories={o.data}
                                                        exp_data={o.experience_type}
                                                        singleReport={singleReport}
                                                    />
                                                </div>
                                            ))}
                                        {staffListing &&
                                            staffListing.length > 0 &&
                                            staffListing.map((s, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <StaffRatingReport
                                                        selectBrand={brand_id}
                                                        staffListing={s.data}
                                                        exp_data={s.experience_type}
                                                        singleReport={singleReport}
                                                    />
                                                </div>
                                            ))}
                                        {highestRatedProducts &&
                                            highestRatedProducts.length > 0 &&
                                            <div className="col-md-6" >
                                                <HighestRatingProduct
                                                    selectBrand={brand_id}
                                                    productData={highestRatedProducts}
                                                    singleReport={singleReport}
                                                />
                                            </div>}
                                        {lowestRatedProducts &&
                                            lowestRatedProducts.length > 0 &&
                                            <div className="col-md-6" >
                                                <LowestRatingProduct
                                                    selectBrand={brand_id}
                                                    productData={lowestRatedProducts}
                                                    singleReport={singleReport}
                                                />
                                            </div>}
                                        {additionalQuestions &&
                                            additionalQuestions.length > 0 &&
                                            additionalQuestions.map(
                                                (o) =>
                                                    o?.questionData &&
                                                    o?.questionData.multiple &&
                                                    o?.questionData.multiple.length > 0 &&
                                                    o.questionData.multiple.map((j, index) => {
                                                        return (
                                                            <div className="col-md-6" key={index}>
                                                                <MultipleQuestionModal
                                                                    selectBrand={brand_id}
                                                                    multipleTypeQuestion={j}
                                                                    experience_type={o?.experience_type_id}
                                                                />
                                                            </div>
                                                        );
                                                    })
                                            )}
                                        {highestRatings &&
                                            highestRatings.length > 0 &&
                                            <div className="" >
                                                <HighestRatingFeedback
                                                    selectBrand={brand_id}
                                                    productData={highestRatings}
                                                    singleReport={singleReport}
                                                    feedback_id={handleModalOpen}
                                                />
                                            </div>}
                                        {lowestRatings &&
                                            lowestRatings.length > 0 &&
                                            <div className="" >
                                                <LowestRatingFeedback
                                                    selectBrand={brand_id}
                                                    productData={lowestRatings}
                                                    singleReport={singleReport}
                                                    feedback_id={handleModalOpen}
                                                />
                                            </div>}
                                    </div>
                                </div>
                                {
                                    isShow && (
                                        <CustomerFeedbackModalReport
                                            show={isShow}
                                            setIsShow={(flag) => setIsShow(flag)}
                                            feedbackData={feedbackModal}
                                        />
                                    )
                                }
                            </>
                        )
                    })
                    :
                    <>
                        <div className="mt-3">
                            <PlaceholderLoader />
                        </div>

                        {
                            (singleReport?.report[0]?.status === false || singleReport?.report[0]?.report_type === "summery_report") &&
                            <div className="contentWrap d-flex flex-column justify-content-center" style={{ padding: "25px", textAlign: "center", fontSize: "30px" }}>
                                The Report has been disabled!
                                <Button
                                    type="btn"
                                    className="btn"
                                    onClick={handleButtonClick}
                                    style={{ width: "15%", margin: "20px auto", }}
                                >
                                    {'View More'}
                                </Button>
                            </div>
                        }
                    </>
            }
        </>
    );
};

export default DetailedReport;



