import React from "react";
import { useSelector } from "react-redux";
import UnAuthorized from "../../pages/error-page/UnAuthorized";

const OwnerGuard = ({ children }) => {
  const { loggedInUser } = useSelector((state) => state.auth);

  if (!loggedInUser) return children;

  const isPermitted = loggedInUser?.permission === "owner";

  if (isPermitted) return children;
  return <UnAuthorized />;
};

export default OwnerGuard;
