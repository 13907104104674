import styles from "./targetRow.module.css";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "../select/Select";
import TrashIcon from "../icons/TrashIcon";

const KPIS = [
  { value: "averageRating", label: "BRAND_RATINGS" },
  { value: "ratingCount", label: "NUMBER_OF_RATINGS" },
  { value: "staffAverageRating", label: "STAFF_RATINGS" },
  { value: "staffRatingCount", label: "NUMBER_OF_STAFF_RATINGS" },
];

const CONDS = [
  { value: "reached", label: "REACHED" },
  { value: "increased", label: "INCREASED" },
];

const VALUE_TYPES = [
  { value: "percent", label: "PERCENTAGE" },
  { value: "number", label: "NUMBER" },
];

const decimal = {
  staffAverageRating: true,
  averageRating: true,
};

/**
 *
 * @typedef {{
 *  kpi: "averageRating" | "ratingCount" | "staffAverageRating" | "staffRatingCount"
 *  cond: "reached" | "increased"
 *  value: number
 *  valueType: "percent" | "number"
 * }} Target
 * @param {{
 * index: number
 * name: string
 * value: Target
 * onChange: (target: Target) => void
 * onRemove: () => void
 * currentValues: {
 *  averageRating?: number
 *  ratingCount?: number
 *  staffAverageRating?: number
 *  staffRatingCount?: number
 * }
 * pastValues: {
 *  averageRating?: number
 *  ratingCount?: number
 *  staffAverageRating?: number
 *  staffRatingCount?: number
 * }
 * }} props
 * @returns
 */
function TargetRow({
  index,
  name,
  onChange,
  value: target,
  onRemove,
  currentValues = {},
  pastValues = {},
}) {
  const { t } = useTranslation();

  const onKPIChange = (kpi) => {
    onChange({
      ...target,
      kpi,
      cond: decimal[kpi] ? "reached" : target.cond,
      value: decimal[kpi] ? Math.min(5, target.value) : parseInt(target.value),
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.targetContainer}>
        {`${index + 1}. ${t("IF_THE")}`}
        <span>
          <Select
            name={`${name}.kpi`}
            options={KPIS.map((o) => ({ ...o, label: t(o.label) }))}
            value={target.kpi}
            title={t(KPIS.find(({ value: v }) => v === target.kpi)?.label)}
            onChange={onKPIChange}
          />
        </span>
        {decimal[target.kpi] ? (
          t("HAVE_REACHED")
        ) : (
          <>
            {`${t("HAVE")} `}
            <span>
              <Select
                name={`${name}.cond`}
                value={target.cond}
                onChange={(cond) => onChange({ ...target, cond })}
                options={CONDS.map((o) => ({ ...o, label: t(o.label) }))}
                title={t(
                  CONDS.find(({ value: v }) => v === target.cond)?.label
                )}
              />
            </span>
          </>
        )}
        {target.cond === "reached" ? (
          <>
            <span>
              <Form.Control
                type="number"
                name={`${name}.value`}
                className={styles.numberInput}
                min={0}
                step={decimal[target.kpi] ? 0.01 : 1}
                max={decimal[target.kpi] ? 5 : undefined}
                value={target.value}
                onChange={(e) =>
                  onChange({
                    ...target,
                    value: decimal[target.kpi]
                      ? Math.max(Math.min(parseFloat(e.target.value), 5), 0)
                      : Math.max(parseInt(e.target.value), 0),
                  })
                }
              />
            </span>
            {`${t("YOU_ARE_ELIGIBLE_FOR_INCENTIVE.")}`}
          </>
        ) : (
          <>
            {t("BY")}
            <span>
              <Form.Control
                type="number"
                className={styles.numberInput}
                name={`${name}.value`}
                min={0}
                max={target.valueType === "percent" ? 100 : undefined}
                step={1}
                value={target.value}
                onChange={(e) =>
                  onChange({
                    ...target,
                    value:
                      target.valueType === "percent"
                        ? Math.max(Math.min(parseInt(e.target.value), 100), 0)
                        : Math.max(parseInt(e.target.value), 0),
                  })
                }
              />
            </span>
            <span>
              <Select
                options={VALUE_TYPES.map((v) => ({
                  ...v,
                  label: t(v.label),
                }))}
                name={`${name}.valueType`}
                selected={target.valueType}
                onChange={(valueType) =>
                  onChange({
                    ...target,
                    valueType,
                    value:
                      valueType === "percent"
                        ? Math.max(Math.min(parseInt(target.value), 100), 0)
                        : Math.max(parseInt(target.value), 0),
                  })
                }
                title={t(
                  VALUE_TYPES.find(({ value: v }) => v === target.valueType)
                    ?.label
                )}
              />
            </span>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.valueInfo}>
          <span className={styles.infoCurrent}>{`${t("CURRENT_VALUE")}: ${
            decimal[target.kpi]
              ? (currentValues[target.kpi] || 0).toFixed(2)
              : target.cond === "reached"
              ? currentValues[target.kpi] || 0
              : pastValues[target.kpi] || 0
          }`}</span>
          <span className={styles.infoTarget}>{`${t("TARGET_VALUE")}: ${
            target.cond === "reached"
              ? target.value
              : target.valueType === "number"
              ? (pastValues[target.kpi] || 0) + (target.value || 0)
              : Math.round(
                  ((pastValues[target.kpi] || 0) * (target.value || 0)) / 100 +
                    (pastValues[target.kpi] || 0)
                )
          }`}</span>
        </div>
        <button type="button" className={styles.iconBtn} onClick={onRemove}>
          <TrashIcon />
        </button>
      </div>
    </div>
  );
}

export default TargetRow;
