import Feedbacks from "../services/feedbacks";
import {
  deleteFeedbackError,
  deleteFeedbackRequest,
  deleteFeedbackSuccess,
  genrateQrError,
  genrateQrRequest,
  genrateQrSuccess,
  getFeedbackDataforPreviewError,
  getFeedbackDataforPreviewRequest,
  getFeedbackDataforPreviewSuccess,
  printPDFError,
  printPDFRequest,
  printPDFSuccess,
  replyFeedbackError,
  replyFeedbackRequest,
  replyFeedbackSuccess,
  resolveFeedbackError,
  resolveFeedbackRequest,
  resolveFeedbackSuccess,
  sharePDFError,
  sharePDFRequest,
  sharePDFSuccess,
} from "../slices/feedbacks.slice";

export function genrateQr(payload, select_id) {
  return (dispatch) => {
    dispatch(genrateQrRequest());
    Feedbacks.genrateQr(payload, select_id)
      .then((response) => {
        const { message, status, error, data } = response.data;
        if (status === 1) {
          dispatch(genrateQrSuccess({ data, message }));
        } else {
          dispatch(genrateQrError(error));
        }
      })
      .catch((error) => {
        dispatch(genrateQrError(error));
      });
  };
}

export function getFeedbackDataforPreview(payload) {
  return (dispatch) => {
    dispatch(getFeedbackDataforPreviewRequest());
    Feedbacks.getFeedbackDataforPreview(payload)
      .then((response) => {
        const { status, data, error } = response.data;
        if (status === 1) {
          dispatch(getFeedbackDataforPreviewSuccess(data));
        } else {
          dispatch(getFeedbackDataforPreviewError(error));
        }
      })
      .catch((error) => {
        dispatch(getFeedbackDataforPreviewError(error));
      });
  };
}

export function resolveFeedback(payload) {
  return (dispatch) => {
    dispatch(resolveFeedbackRequest());
    Feedbacks.resolveFeedback(payload)
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(resolveFeedbackSuccess(message));
        } else {
          dispatch(resolveFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(resolveFeedbackError(error));
      });
  };
}

export function replyFeedback(payload) {
  return (dispatch) => {
    dispatch(replyFeedbackRequest());
    Feedbacks.replyFeedback(payload)
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(replyFeedbackSuccess(message));
        } else {
          dispatch(replyFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(replyFeedbackError(error));
      });
  };
}

export function deleteFeedback(f_id) {
  return (dispatch) => {
    dispatch(deleteFeedbackRequest());
    Feedbacks.deleteFeedback(f_id)
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(deleteFeedbackSuccess(message));
        } else {
          dispatch(deleteFeedbackError(error));
        }
      })
      .catch((error) => {
        dispatch(deleteFeedbackError(error));
      });
  };
}

export function sharePDF(payload) {
  return (dispatch) => {
    dispatch(sharePDFRequest());
    Feedbacks.sharePDF(payload)
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(sharePDFSuccess(message));
        } else {
          dispatch(sharePDFError(error));
        }
      })
      .catch((error) => {
        dispatch(sharePDFError(error));
      });
  };
}

export function printPdf(id) {
  return (dispatch) => {
    dispatch(printPDFRequest());
    Feedbacks.printPdf(id)
      .then((response) => {
        dispatch(printPDFSuccess());
        const file = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `${id}.pdf`;
        link.click();
      })
      .catch((err) => {
        dispatch(printPDFError(err));
      });
  };
}
