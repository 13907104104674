import { useTranslation } from "react-i18next";
import styles from "./payment.module.css";
import NextPayment from "./NextPayment";
import PaymentPlan from "./PaymentPlan";
import PaymentMethod from "./PaymentMethod";
import PaymentHistory from "./PaymentHistory";

function Payment() {
  const { t } = useTranslation();
  return (
    <>
      <main className={styles.container}>
        <h1>{t("PAYMENT")}</h1>
        <section className={styles.section}>
          <NextPayment />
        </section>
        {/* <section className={styles.section}>
          <h2>{t("PAYMENT_PLAN")}</h2>
          <PaymentPlan />
        </section>
        <section className={styles.section}>
          <h2>{t("PAYMENT_METHOD")}</h2>
          <PaymentMethod />
        </section>
        <section className={styles.section}>
          <h2>{t("PAYMENT_HISTORY")}</h2>
          <PaymentHistory />
        </section> */}
      </main>
    </>
  );
}

export default Payment;
